import { FundriseRouteConfig } from 'vue-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const helpCenterRoutes: FundriseRouteConfig[] = [
	{
		path: `/help`,
		name: 'help-center-landing',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "help-center-landing" */ '@views/help-center/help-center-landing.vue')
	},
	{
		path: `/help/categories/:categoryId`,
		name: 'help-center-category',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const categoryId = to.params.categoryId as string;
			if (categoryId.includes('-')) {
				next({ name: 'help-center-category', params: { categoryId: categoryId.split('-')[0] }, replace: true });
			} else {
				next();
			}
		},
		component: () =>
			import(/* webpackChunkName: "help-center-category" */ '@views/help-center/help-center-category-page.vue')
	},
	{
		path: `/help/sections/:sectionId`,
		name: 'help-center-section',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const sectionId = to.params.sectionId as string;
			if (sectionId.includes('-')) {
				next({ name: 'help-center-section', params: { sectionId: sectionId.split('-')[0] }, replace: true });
			} else {
				next();
			}
		},
		component: () =>
			import(/* webpackChunkName: "help-center-section" */ '@views/help-center/help-center-section-page.vue')
	},
	{
		path: `/help/articles/:articleId`,
		name: 'help-center-article',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const articleId = to.params.articleId as string;
			if (articleId.includes('-')) {
				next({ name: 'help-center-article', params: { articleId: articleId.split('-')[0] }, replace: true });
			} else {
				next();
			}
		},
		component: () =>
			import(/* webpackChunkName: "help-center-article" */ '@views/help-center/help-center-article-page.vue')
	},
	{
		path: `/help/search`,
		name: 'help-center-search',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "help-center-search" */ '@views/help-center/help-center-search-page.vue')
	}
];

export default helpCenterRoutes;
