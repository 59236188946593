import {
	handleAuthenticatedScope,
	handlePrivilegedAccess,
	handleThirdPartyScope,
	handleTwoFactor,
	handleWebviewRoute,
	includesVerifyRoute,
	refreshTokenAndRedirect,
	saveLoginRedirect
} from './auth-guard-helpers';
import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import { app } from '@store/modules/app';
import { checkCookieExists } from '@utils/web-storage';
import { TokenScope } from 'types/security';

export const handleAuthentication = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized
): Promise<ReturnType<NavigationGuard>> => {
	const { isAuthenticated, tokenScope } = app;
	const minTokenScopeRequired: TokenScope = to.meta?.minTokenScopeRequired;
	const userHasRefreshToken = checkCookieExists('userLoggedIn');
	const webviewRoutesTest = /\/webview|\/mobile-view|story\/\d+/; // /webview OR /mobile-view OR /story/${one or more digits}
	const isMobileWebviewRoute = webviewRoutesTest.test(to.path) || to.query?.webview === 'true';

	app.UPDATE_ROUTE_TRANSITION({ to, from });

	if (isMobileWebviewRoute) {
		return handleWebviewRoute(minTokenScopeRequired, isAuthenticated);
	} else if (!isAuthenticated && userHasRefreshToken && tokenScope !== 'REQUIRES_ADDITIONAL_AUTH') {
		return refreshTokenAndRedirect(to, from);
	} else if (minTokenScopeRequired === 'UNAUTHENTICATED') {
		return true;
	} else if (tokenScope === 'THIRD_PARTY_ACCESS') {
		return handleThirdPartyScope(to, from, minTokenScopeRequired);
	} else if (tokenScope === 'REQUIRES_ADDITIONAL_AUTH') {
		return handleTwoFactor(to);
	} else if (minTokenScopeRequired === 'PRIVILEGED_ACCESS') {
		return handlePrivilegedAccess(tokenScope, to, from);
	} else if (isAuthenticated) {
		return handleAuthenticatedScope(to, from, minTokenScopeRequired);
	} else {
		if (includesVerifyRoute(to.fullPath)) {
			app.ADD_TOAST({ type: 'info', message: 'Please log in to verify your e-mail address.' });
		}
		saveLoginRedirect(to);
		return { name: 'login' };
	}
};
