import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getTaxData } from '@api/tax';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'tax',
	store
})
class TaxModule extends VuexModule {
	isReit1099Delivered = false;

	isEFundK1Delivered = false;

	isK3Delivered = false;

	willReceiveReit1099 = false;

	willReceiveEFundK1 = false;

	willReceiveK3 = false;

	currentTaxYear = '';

	@Action({ rawError: true })
	public async storeTaxData(): Promise<void> {
		if (!this.currentTaxYear) {
			const taxData = await getTaxData();
			this.UPDATE_CURRENT_TAX_YEAR(taxData.currentTaxYear);
			this.UPDATE_WILL_RECEIVE_E_FUND_K1(taxData.willReceiveEFundK1);
			this.UPDATE_WILL_RECEIVE_K3(taxData.willReceiveK3);
			this.UPDATE_WILL_RECEIVE_REIT1099(taxData.willReceiveReit1099);
			this.UPDATE_IS_E_FUND_K1_DELIVERED(taxData.isEFundK1Delivered);
			this.UPDATE_IS_K3_DELIVERED(taxData.isK3Delivered);
			this.UPDATE_IS_REIT1099_DELIVERED(taxData.isReit1099Delivered);
		}
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_CURRENT_TAX_YEAR('');
		this.UPDATE_WILL_RECEIVE_E_FUND_K1(false);
		this.UPDATE_WILL_RECEIVE_REIT1099(false);
		this.UPDATE_IS_E_FUND_K1_DELIVERED(false);
		this.UPDATE_IS_REIT1099_DELIVERED(false);
	}

	@Mutation
	public UPDATE_CURRENT_TAX_YEAR(year: string): void {
		this.currentTaxYear = year;
	}

	@Mutation
	public UPDATE_WILL_RECEIVE_E_FUND_K1(bool: boolean): void {
		this.willReceiveEFundK1 = bool;
	}

	@Mutation
	public UPDATE_WILL_RECEIVE_K3(bool: boolean): void {
		this.willReceiveK3 = bool;
	}

	@Mutation
	public UPDATE_WILL_RECEIVE_REIT1099(bool: boolean): void {
		this.willReceiveReit1099 = bool;
	}

	@Mutation
	public UPDATE_IS_E_FUND_K1_DELIVERED(bool: boolean): void {
		this.isEFundK1Delivered = bool;
	}

	@Mutation
	public UPDATE_IS_K3_DELIVERED(bool: boolean): void {
		this.isK3Delivered = bool;
	}

	@Mutation
	public UPDATE_IS_REIT1099_DELIVERED(bool: boolean): void {
		this.isReit1099Delivered = bool;
	}
}

export const tax = getModule(TaxModule);
