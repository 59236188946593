<template>
	<BaseButton :classes="buttonClasses" @click="emits('retry-clicked')"
		><div class="display-flex align-items-center gap-50"
			><BaseSvgIcon name="reload" width="16" height="16" /> Retry</div
		></BaseButton
	>
</template>
<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	theme?: 'light' | 'dark';
}

interface Emits {
	(e: 'retry-clicked'): void;
}

const props = withDefaults(defineProps<Props>(), {
	theme: 'light'
});

const emits = defineEmits<Emits>();

const buttonClasses = computed((): string => {
	const baseClasses = 'button button-retry';
	return props.theme === 'light' ? baseClasses : `${baseClasses} button-retry__reversed`;
});
</script>
<style lang="scss" scoped>
@use '../../../styles/constants/_colors.scss' as *;

.button.button-retry {
	color: dark(100);
	background-color: #fff;
	border-color: dark(10);
	border-radius: 1rem;
	padding: 0.25rem 1rem;
	height: 2rem;
	font-weight: 400;

	&.button-retry__reversed {
		color: #fff;
		background-color: dark(80);
		border-color: dark(50);
	}
}
</style>
