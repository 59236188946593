import { SelectOption, SelectOptionAmount } from 'types/layout';

export const autoInvestAmounts: Array<SelectOptionAmount> = [
	{ value: '100', label: '$100', sortIndex: 100 },
	{ value: '250', label: '$250', sortIndex: 250 },
	{ value: '500', label: '$500', sortIndex: 500 },
	{ value: '1000', label: '$1,000', sortIndex: 1000 },
	{ value: '5000', label: '$5,000', sortIndex: 5000 },
	{ value: '10000', label: '$10,000', sortIndex: 10000 },
	{ value: 'other', label: 'Other amount', sortIndex: 1000000 }
];

export const autoInvestFrequency: Array<SelectOption> = [
	{ value: 'MONTHLY', label: 'Once a month' },
	{ value: 'SEMI_MONTHLY', label: 'Twice a month' }
];

export const autoInvestMonthlyDays: Array<SelectOption> = [
	{ value: '1', label: '1st' },
	{ value: '2', label: '2nd' },
	{ value: '3', label: '3rd' },
	{ value: '4', label: '4th' },
	{ value: '5', label: '5th' },
	{ value: '6', label: '6th' },
	{ value: '7', label: '7th' },
	{ value: '8', label: '8th' },
	{ value: '9', label: '9th' },
	{ value: '10', label: '10th' },
	{ value: '11', label: '11th' },
	{ value: '12', label: '12th' },
	{ value: '13', label: '13th' },
	{ value: '14', label: '14th' },
	{ value: '15', label: '15th' },
	{ value: '16', label: '16th' },
	{ value: '17', label: '17th' },
	{ value: '18', label: '18th' },
	{ value: '19', label: '19th' },
	{ value: '20', label: '20th' },
	{ value: '21', label: '21st' },
	{ value: '22', label: '22nd' },
	{ value: '23', label: '23rd' },
	{ value: '24', label: '24th' },
	{ value: '25', label: '25th' },
	{ value: '26', label: '26th' },
	{ value: '27', label: '27th' },
	{ value: '28', label: '28th' }
];

export const autoInvestSemiMonthlyDays: Array<SelectOption> = [
	{ value: '1', label: '1st & 15th' },
	{ value: '2', label: '2nd & 16th' },
	{ value: '3', label: '3rd & 17th' },
	{ value: '4', label: '4th & 18th' },
	{ value: '5', label: '5th & 19th' },
	{ value: '6', label: '6th & 20th' },
	{ value: '7', label: '7th & 21st' },
	{ value: '8', label: '8th & 22nd' },
	{ value: '9', label: '9th & 23rd' },
	{ value: '10', label: '10th & 24th' },
	{ value: '11', label: '11th & 25th' },
	{ value: '12', label: '12th & 26th' },
	{ value: '13', label: '13th & 27th' },
	{ value: '14', label: '14th & 28th' }
];
