<template>
	<div ref="target">
		<div v-if="isLoading || isError" class="loading-module p-100">
			<div class="display-flex flex-direction-column gap-100 col-6-md">
				<div v-if="isError" class="text-color-white" v-html="moduleLoadingErrorMessage" />
				<ModularDashboardPlaceholderLines
					:line-count="1"
					theme="dark"
					:show-header="true"
					:large-lines="true"
					:use-animation="isLoading"
				/>
				<ModularDashboardPlaceholderLines :line-count="2" theme="dark" :use-animation="isLoading" />
				<div v-if="isError">
					<ModularDashboardRetryButton theme="dark" @retry-clicked="loadModule()" />
				</div>
			</div>
		</div>
		<FeaturedCardComponent v-else-if="moduleData" :featured-card-data="moduleData" />
	</div>
</template>

<script lang="ts">
export default {
	name: 'FeaturedModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { FeaturedCard } from 'types/layout';
import FeaturedCardComponent from '@components/layout/featured-card.vue';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { ModularDashboardModule } from 'types/modular-dashboard';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants';
import { trackMixpanelScrollIntoView } from '@utils/analytics';

interface Props {
	module: ModularDashboardModule;
}

const props = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

const isLoading = ref(true);
const isError = ref(false);

const moduleData = computed((): FeaturedCard | null => {
	return modularDashboard.featuredModuleData[props.module.name];
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': moduleData.value?.heading,
			'View Content': moduleData.value?.eyebrow,
			'View ID': props.module.name
		});
	}
});

async function loadModule(): Promise<void> {
	isLoading.value = true;
	isError.value = false;

	try {
		await modularDashboard.storeFeaturedModuleData(props.module.name);
	} catch {
		isError.value = true;
	} finally {
		isLoading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>

<style lang="scss" scoped>
.loading-module {
	background: #24272f;
	border-radius: 8px;
	min-height: 250px;
}
</style>
