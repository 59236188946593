<template>
	<span class="ratings-stars" :style="starsStyles" :aria-label="`${rating}/5 stars`"></span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	rating?: string;
	starsColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
	rating: '5',
	starsColor: ''
});

const starsStyles = computed((): string => {
	if (props.starsColor) {
		const ratingsVar = `--rating: ${props.rating};`;
		const colorVar = `--star-background: ${props.starsColor}`;
		return ratingsVar + colorVar;
	}
	return `--rating: ${props.rating}`;
});
</script>
