import { FundriseRouteConfig, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { addFunds } from '@store/modules/add-funds';
import { AddFundsRoute } from 'types/add-funds';
import { addFundsRouteOrder } from '@constants';
import { app } from '@store/modules/app';
import { flattenQueryParam } from '@utils/request';
import { investmentEntity } from '@store/modules/investment-entity';
import { redirect } from '@utils/client';
import { windowReservationCapacityFull } from '@constants/error-codes';

const getFlowStepIndex = (name: AddFundsRoute): number => {
	return addFundsRouteOrder.indexOf(name) + 1;
};

const stepConstructor = (route: RouteLocationNormalized): number => {
	return getFlowStepIndex(route.name as AddFundsRoute);
};

const addFundsEntryRedirectHandling = (): string | null => {
	switch (investmentEntity.additionalInvestmentEligibility) {
		case 'ENTITY_NO_INVESTMENTS':
			return '/start-investing';
		case 'ENTITY_NO_PLAN_SELECTION':
			return '/account/ria-upgrade';
		case 'ENTITY_MISSING_INFORMATION':
			switch (investmentEntity.entityType) {
				case 'INDIVIDUAL':
					return `/account/profile/personal-information`;
				case 'JOINT_ACCOUNT':
					return `/account/investment-accounts/joint-account/account-holder-information/edit`;
				case 'TRUST':
					return `/account/investment-accounts/trust/information/edit`;
				case 'LLC':
					return `/account/investment-accounts/entity-account/entity-information/edit`;
				default:
					return null;
			}
		default:
			return null;
	}
};

const addFundsOfferingEntryRedirectHandling = (): null | RouteLocationRaw => {
	if (investmentEntity.isIraEntity) {
		return {
			name: 'ira-add-funds-start',
			query: { reitOfferingVintageId: addFunds.offeringVintageId },
			replace: true
		};
	}
	switch (investmentEntity.additionalInvestmentEligibility) {
		case 'ENTITY_NO_INVESTMENTS':
			return { name: 'start-investing', replace: true };
		case 'ENTITY_MISSING_INFORMATION':
			switch (investmentEntity.entityType) {
				case 'INDIVIDUAL':
					return {
						name: 'profile-address-edit',
						replace: true
					};
				case 'JOINT_ACCOUNT':
					return {
						name: 'settings-joint-account-information-edit',
						replace: true
					};
				case 'TRUST':
					return {
						name: 'settings-trust-information-edit',
						replace: true
					};
				case 'LLC':
					return {
						name: 'settings-entity-account-information-edit',
						replace: true
					};
				default:
					return null;
			}
		default:
			return null;
	}
};

const isOfferingAmountStepIncomplete = (): boolean => {
	return !addFunds.amount || !addFunds.paymentMethodId || !addFunds.reitInvestmentOrderGroupId;
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const addFundsRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/repeat-investment/ria`,
		name: 'add-funds-ria',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () =>
			import(/* webpackChunkName: "add-funds-ria" */ '@views/account/add-funds/ria/add-funds-ria.vue'),
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.isRedeemedNoDirectInvestNoPlanAccount) {
				next({ name: 'ria-upgrade', replace: true });
			} else if (
				investmentEntity.additionalInvestmentEligibility !== 'VALID' ||
				investmentEntity.entityType === 'IRA'
			) {
				const addFundsSpecialRedirect = addFundsEntryRedirectHandling();
				if (addFundsSpecialRedirect) {
					redirect(addFundsSpecialRedirect);
				} else {
					next({ name: 'account-overview', replace: true });
					app.ADD_TOAST({
						type: 'error',
						message: 'The current account is not eligible for an additional investment at this time.'
					});
				}
			} else {
				if (addFunds.orderComplete) {
					addFunds.clearPreviousOrder();
				}
				await addFunds.fetchAndStoreAddFundsMetadata();

				addFunds.UPDATE_ADD_FUNDS_FLOW('RIA');
				next();
			}
		},
		redirect: { name: 'add-funds-ria-amount' },
		children: [
			{
				path: 'amount',
				name: 'add-funds-ria-amount',
				meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					addFunds.UPDATE_INVEST_TAB_ADD_FUNDS(false);
					next();
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-ria-amount" */ '@views/account/add-funds/ria/add-funds-ria-amount.vue'
					)
			},
			{
				path: 'view-allocation',
				name: 'add-funds-ria-view-allocation',
				meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!addFunds.amount) {
						next({ name: 'account-invest', replace: true });
					} else if (!addFunds.investTabAddFunds) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-ria-view-allocation" */ '@views/account/add-funds/ria/add-funds-ria-view-allocation.vue'
					)
			},
			{
				path: 'review',
				name: 'add-funds-ria-review',
				meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!addFunds.amount || !addFunds.reitInvestmentOrderGroupId) {
						next({ name: 'add-funds-ria-amount', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-ria-review" */ '@views/account/add-funds/ria/add-funds-ria-review.vue'
					)
			},
			{
				path: 'success',
				name: 'add-funds-ria-success',
				meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!addFunds.reitInvestmentOrderGroupId) {
						next({ name: 'add-funds-ria-amount', replace: true });
					} else {
						addFunds.clearPreviousOrder();
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-ria-success" */ '@views/account/add-funds/ria/add-funds-ria-success.vue'
					)
			}
		]
	},
	{
		path: `/account/repeat-investment/offering/plaid-redirect`,
		name: `add-funds-offering-plaid-redirect`,
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await addFunds.fetchAndUpdatePriorOfferingSelections();
			next({
				path: `/account/repeat-investment/offering/${addFunds.offeringVintageId}`,
				query: { oauth_state_id: to.query.oauth_state_id }
			});
		},
		// revisit using redirect
		component: () =>
			import(/* webpackChunkName: "add-funds-ria" */ '@views/account/add-funds/offering/add-funds-offering.vue')
	},
	{
		path: `/account/repeat-investment/offering/new-investor`,
		name: 'cross-sell-offering',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () =>
			import(
				/* webpackChunkName: "cross-sell-offering" */ '@views/account/add-funds/offering/post-signup-cross-sell/cross-sell-offering.vue'
			),
		redirect: { name: 'cross-sell-offering-amount' },
		beforeEnter: async (to, from, next) => {
			await addFunds.checkCrossSellEligibility();

			if (addFunds.crossSellResponse?.eligible) {
				await addFunds.fetchAndStoreRepeatOfferingInvestmentMetadata();
				addFunds.UPDATE_ADD_FUNDS_FLOW('CROSS_SELL');
				next();
			} else {
				next({ name: 'add-funds-ria' });
			}
		},
		children: [
			{
				path: 'amount',
				name: 'cross-sell-offering-amount',
				meta: {
					step: stepConstructor,
					minTokenScopeRequired: 'FULL_ACCESS',
					customAnalyticsViewName: 'Post Signup X-sell Prompt',
					suppressDefaultMxpViewEvent: true
				},
				component: () =>
					import(
						/* webpackChunkName: "cross-sell-offering-amount" */ '@views/account/add-funds/offering/post-signup-cross-sell/cross-sell-offering-amount.vue'
					)
			},
			{
				path: 'review',
				name: 'cross-sell-offering-review',
				meta: {
					step: stepConstructor,
					minTokenScopeRequired: 'FULL_ACCESS',
					customAnalyticsViewName: 'Post Signup X-sell Review Order'
				},
				beforeEnter: async (to, from, next) => {
					if (from.name === 'cross-sell-offering-success') {
						next({ name: 'account-overview', replace: true });
					} else if (!addFunds.amount || !addFunds.reitInvestmentOrderGroupId) {
						next({ name: 'cross-sell-offering-amount', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "cross-sell-offering-review" */ '@views/account/add-funds/offering/post-signup-cross-sell/cross-sell-offering-review.vue'
					)
			},
			{
				path: 'success',
				name: 'cross-sell-offering-success',
				meta: {
					step: stepConstructor,
					minTokenScopeRequired: 'FULL_ACCESS',
					customAnalyticsViewName: 'Post Signup X-sell Order Success'
				},
				beforeEnter: async (to, from, next) => {
					if (!addFunds.reitInvestmentOrderGroupId) {
						next({ name: 'cross-sell-offering-amount', replace: true });
					} else {
						addFunds.clearPreviousOrder();
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "post-signup-offering-success" */ '@views/account/add-funds/offering/post-signup-cross-sell/cross-sell-offering-success.vue'
					)
			}
		]
	},
	{
		path: `/reits/checkout/repeat/start`,
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const reitOfferingVintageId = flattenQueryParam(to.query.reitOfferingVintageId);
			if (!reitOfferingVintageId) {
				next({ name: 'account-overview', replace: true });
			} else {
				next({
					name: 'add-funds-offering',
					params: { offeringVintageId: reitOfferingVintageId },
					query: { defaultAmount: to.query.amount }
				});
			}
		},
		redirect: (to) => {
			const reitOfferingVintageId = flattenQueryParam(to.query.reitOfferingVintageId);
			if (!reitOfferingVintageId) {
				return { name: 'account-overview', replace: true };
			} else {
				return {
					name: 'add-funds-offering',
					params: { offeringVintageId: reitOfferingVintageId },
					query: { defaultAmount: to.query.amount }
				};
			}
		}
	},
	{
		path: `/account/repeat-investment/offering/:offeringVintageId`,
		name: 'add-funds-offering',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () =>
			import(/* webpackChunkName: "add-funds-ria" */ '@views/account/add-funds/offering/add-funds-offering.vue'),
		beforeEnter: async (to, from, next) => {
			if (addFunds.orderComplete) {
				addFunds.clearPreviousOrder();
			}
			addFunds.resetReservationWindowData();

			const offeringVintageId = to.params.offeringVintageId as string;

			addFunds.UPDATE_OFFERING_VINTAGE_ID(offeringVintageId);
			try {
				await addFunds.fetchAndStoreRepeatOfferingInvestmentMetadata();
			} catch {}

			if (
				(addFunds.investmentEligibility !== 'ELIGIBLE' &&
					addFunds.investmentEligibility !== 'RESERVATION_ELIGIBLE') ||
				investmentEntity.isIraEntity
			) {
				const addFundsSpecialRedirect = addFundsOfferingEntryRedirectHandling();
				if (addFundsSpecialRedirect) {
					next(addFundsSpecialRedirect);
				} else if (addFunds.offeringType === 'FUNDRISE_IPO') {
					next({ name: 'account-overview', replace: true });
					app.ADD_TOAST({
						type: 'error',
						message: `Thank you for your investment in the Fundrise iPO. You've already invested the maximum amount available to you.`
					});
				} else {
					next({ name: 'account-overview', replace: true });
					app.ADD_TOAST({
						type: 'error',
						message: 'You are not eligible for direct investment in the selected offering at this time.'
					});
				}
			} else {
				addFunds.UPDATE_ADD_FUNDS_FLOW('EDIRECT');

				next();
			}
		},
		redirect: { name: 'add-funds-offering-amount' },
		children: [
			{
				path: 'reservation-start',
				name: 'add-funds-offering-reservation-start',
				meta: { step: 0, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					try {
						const preselectedCohortId = flattenQueryParam(to.query.cohortId);
						await addFunds.fetchAndStoreReservationWindowData(preselectedCohortId);
						if (addFunds.reservationWindows.length > 0) {
							next();
						} else {
							next({ name: 'account-overview', replace: true });
						}
					} catch (e) {
						next({ name: 'account-overview', replace: true });
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-offering-reservation-start" */ '@views/account/add-funds/offering/add-funds-offering-reservation-start.vue'
					)
			},
			{
				path: 'amount',
				name: 'add-funds-offering-amount',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (addFunds.selectedWindow) {
						await addFunds.fetchAndStoreRepeatOfferingInvestmentMetadata();
					}
					next();
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-offering-amount" */ '@views/account/add-funds/offering/add-funds-offering-amount.vue'
					)
			},
			{
				path: 'review',
				name: 'add-funds-offering-review',
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (isOfferingAmountStepIncomplete()) {
						next({
							name: 'add-funds-offering-amount',
							params: { offeringVintageId: addFunds.offeringVintageId ?? '' },
							replace: true
						});
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-offering-review" */ '@views/account/add-funds/offering/add-funds-offering-review.vue'
					)
			},
			{
				path: 'success',
				name: 'add-funds-offering-success',
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!addFunds.reitInvestmentOrderGroupId) {
						next({
							name: 'add-funds-offering-amount',
							params: { offeringVintageId: addFunds.offeringVintageId ?? '' },
							replace: true
						});
					} else {
						addFunds.clearPreviousOrder();
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-offering-success" */ '@views/account/add-funds/offering/add-funds-offering-success.vue'
					)
			},
			{
				path: 'error',
				name: 'add-funds-offering-error',
				meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (
						from.name !== 'add-funds-offering-review' ||
						addFunds.offeringSubmissionErrorCode !== windowReservationCapacityFull
					) {
						next({
							name: 'add-funds-offering-amount',
							params: { offeringVintageId: addFunds.offeringVintageId ?? '' },
							replace: true
						});
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "add-funds-offering-error" */ '@views/account/add-funds/offering/add-funds-offering-error.vue'
					)
			}
		]
	}
];

export default addFundsRoutes;
