import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { getReferralByKey } from '@api/referral';

const referralNavigationGuard: NavigationGuard = async (to, from, next) => {
	try {
		const referrerUser = await getReferralByKey(to.params.inviteKey as string);
		if (referrerUser) {
			let referralCampaign = 'investor-invitation';

			if (to.name === 'ios-invitation-redirect') {
				referralCampaign = 'investor-invitation-app';
			} else if (to.name === 'android-invitation-redirect') {
				referralCampaign = 'investor-invitation-android';
			}

			next({
				name: 'referral-welcome',
				query: {
					inviteKey: to.params.inviteKey,
					utm_campaign: referralCampaign,
					utm_medium: 'invite-link',
					utm_source: 'fundrise'
				}
			});
		} else {
			next({ name: 'home' });
		}
	} catch (error) {
		next({ name: 'home' });
	}
};

const referralRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/r/:inviteKey',
		name: 'web-invitation-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: referralNavigationGuard,
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	},
	{
		path: '/i/:inviteKey',
		name: 'ios-invitation-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: referralNavigationGuard,
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	},
	{
		path: '/a/:inviteKey',
		name: 'android-invitation-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: referralNavigationGuard,
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	}
];

export default referralRoutes;
