<template>
	<div ref="animation" />
</template>

<script lang="ts">
export default {
	name: 'LottieAnimation'
};
</script>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import lottie, { AnimationConfigWithPath, AnimationItem } from 'lottie-web/build/player/lottie_light';
import { logError } from '@utils/error-tracking';

type AutoplayBehaviorOption = 'ON_LOAD' | 'ON_SCROLL' | 'DISABLED';

interface Props {
	lottieFile: string;
	config?: Partial<AnimationConfigWithPath<'svg'>>;
	thresholds?: Array<number>;
	autoplayBehavior?: AutoplayBehaviorOption;
	loop?: boolean;
	maxRetries?: number;
}

const props = withDefaults(defineProps<Props>(), {
	lottieFile: '',
	config: undefined,
	thresholds: () => [],
	autoplayBehavior: 'ON_LOAD',
	loop: true,
	maxRetries: 0
});

const emit = defineEmits(['thresholdReached', 'onComplete', 'onFail']);

const animation = ref<null | HTMLElement>(null);
const lottiePlayer = ref<null | AnimationItem>(null);
const dataReady = ref(false);
const dataFailed = ref(false);
const failures = ref(0);
const animationComplete = ref(false);

const autoplay = computed((): boolean => {
	return props.autoplayBehavior === 'ON_LOAD';
});

const retriesExceeded = computed((): boolean => {
	return props.maxRetries ? props.maxRetries < failures.value : false;
});

onMounted(async () => {
	const animationObserver = new IntersectionObserver(playAnimation);
	animationObserver.observe(animation.value as unknown as Element);

	await loadPlayer(autoplay.value);
});

onUnmounted(() => {
	lottiePlayer.value?.destroy();
});

async function loadPlayer(autoplay = false, delay = 0): Promise<void> {
	lottiePlayer.value?.destroy();

	if (animation.value) {
		const config: AnimationConfigWithPath<'svg'> = {
			container: animation.value,
			renderer: 'svg',
			path: props.lottieFile,
			loop: props.loop,
			autoplay,
			...props.config
		};

		if (delay > 0) {
			lottiePlayer.value = await new Promise((resolve) =>
				setTimeout(() => resolve(lottie.loadAnimation(config)), delay)
			);
		} else {
			lottiePlayer.value = lottie.loadAnimation(config);
		}

		const thresholdTracker: Array<number> = [];

		lottiePlayer.value?.addEventListener('enterFrame', function (e) {
			props.thresholds.forEach((threshold) => {
				if (lottiePlayer.value) {
					if (
						e.currentTime > lottiePlayer.value.getDuration(true) * (threshold / 100) &&
						thresholdTracker.indexOf(threshold) === -1
					) {
						emit('thresholdReached', threshold);
						thresholdTracker.push(threshold);
					}
				}
			});
		});

		if (lottiePlayer.value) {
			lottiePlayer.value?.addEventListener('data_ready', function () {
				dataFailed.value = false;
				dataReady.value = true;
			});

			lottiePlayer.value?.addEventListener('data_failed', async function () {
				dataFailed.value = true;
				dataReady.value = false;
				failures.value++;

				await retry();
			});

			lottiePlayer.value?.addEventListener('complete', function () {
				emit('onComplete');
				animationComplete.value = true;
				while (thresholdTracker.length > 0) {
					thresholdTracker.pop();
				}
			});
		}
	}
}

function playAnimation(entries: Array<IntersectionObserverEntry>): void {
	if (entries[0].intersectionRatio !== 0 && lottiePlayer.value && props.autoplayBehavior === 'ON_SCROLL') {
		lottiePlayer.value?.play();
	}
}

async function replayAnimation(): Promise<void> {
	animationComplete.value = false;
	lottiePlayer.value?.goToAndPlay(0);
}

async function retry(delay = 0): Promise<void> {
	if (retriesExceeded.value) {
		logError(`Lottie Animation Failure - File: ${props.lottieFile}`);
		emit('onFail');
		return;
	}

	dataFailed.value = false;
	dataReady.value = false;

	await loadPlayer(true, delay);
}

defineExpose({ dataReady, dataFailed, retriesExceeded, animationComplete, retry, replayAnimation });
</script>
