import { AccreditationVerificationTypeInfo } from 'types/accreditation';
import { currency } from '@filters/shared-filters';

export const accreditationVerificationTypes: Array<AccreditationVerificationTypeInfo> = [
	{
		name: 'PERSONAL_INCOME',
		label: `Individual income of ${currency(200000, 0)} or more`,
		description: `I have individual income exceeding ${currency(
			200000,
			0
		)} in each of the past two years and expect to reach the same this year.`
	},
	{
		name: 'JOINT_INCOME',
		label: `Joint income of ${currency(300000, 0)} or more`,
		description: `I have combined income with my spouse exceeding ${currency(
			300000,
			0
		)} in each of the past two years and expect to reach the same this year.`
	},
	{
		name: 'PERSONAL_NET_WORTH',
		label: `Net worth of ${currency(1, 0)} million or more`,
		description: `I have individual net worth, or joint net worth with my spouse, that exceeds ${currency(
			1,
			0
		)} million (excluding the value of my primary residence).`
	},
	{
		name: 'ENTITY_WITH_ACCREDITED_EQUITY_OWNERS',
		label: 'Company representative',
		description: `I invest on behalf of a business or investment company with more than ${currency(
			5,
			0
		)} million in assets and / or all of the equity owners are accredited.`
	}
];
