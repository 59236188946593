<template>
	<div v-if="link.text">
		<BaseLink :link="{ router: link.router }" classes="display-flex align-items-center mobile-nav__item-link bt">
			<span>
				{{ link.text }}
			</span>
			<Badge v-if="isTaggedLink" class="ml-50" :text="link.badgeText" background="slate" size="medium" />
		</BaseLink>
	</div>
	<div v-else>
		<MobileNavAccordion :start-opened="link.startOpened">
			<template #toggle>{{ link.dropdown }}</template>
			<template #drawer>
				<div
					v-for="(dropdownLink, dropdownLinkIndex) in link.links"
					:key="dropdownLinkIndex"
					class="link-container"
				>
					<BaseLink :link="dropdownLink.link" classes="mobile-nav__sub-item-link">
						<p>{{ dropdownLink.link.text }}</p>
					</BaseLink>
				</div>
			</template>
		</MobileNavAccordion>
	</div>
</template>

<script setup lang="ts">
import Badge from '@components/badges/badge-component.vue';
import { computed } from 'vue';
import MobileNavAccordion from '@components/header/navigation/mobile/mobile-nav-accordion.vue';
import { NavigationLink } from 'types/header';
interface Props {
	link: NavigationLink;
}
const props = defineProps<Props>();

const isTaggedLink = computed((): boolean => {
	return 'badgeText' in props.link;
});
</script>

<style lang="scss" scoped>
.link-container {
	padding: 0.5rem 1rem;

	&:first-of-type {
		padding-top: 0;
	}
}
</style>
