import { apiSecureRequest, oauthSecureRequest, oauthTokenRequest } from '@utils/request';
import axios, { AxiosResponse } from 'axios';
import { CorniceTokenResponse, OauthTokenResponse } from 'types/security';
import {
	LoginForm,
	TwoFactorAuthCodeForm,
	TwoFactorCodeDeliveryMethod,
	TwoFactorMetadata,
	TwoFactorType
} from 'types/authentication';
import { LoginMobileVerifyMeta } from 'types/login';
import { setCookie } from '@utils/web-storage';

export const getLoginMobileVerifyMeta = async (): Promise<LoginMobileVerifyMeta> => {
	const response = await apiSecureRequest.get('/login-mobile-verify/visited');
	return response.data;
};

export const getTwoFactorMetadata = async (): Promise<TwoFactorMetadata> => {
	const response = await oauthSecureRequest.get('/two-factor-auth/two-factor-meta');
	return response.data;
};

export const persistVisitToLoginMobileVerifyFlow = async (): Promise<void> => {
	await apiSecureRequest.post('/login-mobile-verify/visited');
};

export const sendEmail2faCode = async (): Promise<void> => {
	await oauthSecureRequest.post(`/two-factor-auth/login/email/resend`);
};

export const sendSms2faCode = async (): Promise<void> => {
	await oauthSecureRequest.post(`/two-factor-auth/login/mobile/send?textCode=true`);
};

export const sendPhoneCall2faCode = async (): Promise<void> => {
	await oauthSecureRequest.post(`/two-factor-auth/login/mobile/send?textCode=false`);
};

export const getTwoFactorStatus = async (): Promise<TwoFactorType> => {
	const response = await apiSecureRequest.get(`/two-factor-auth/status`);
	return response.data.twoFactorStatus;
};

export const submitTwoFactorAuthCode = async (
	twoFactorAuthForm: TwoFactorAuthCodeForm
): Promise<OauthTokenResponse> => {
	const response = await oauthSecureRequest.post(`/two-factor-auth/login/verify`, twoFactorAuthForm, {
		withCredentials: true
	});
	setCookie('userLoggedIn', 'true', response.data.refresh_expires_in);
	return response.data;
};

export const resend2faCode = async (method: TwoFactorCodeDeliveryMethod): Promise<void> => {
	switch (method) {
		case 'EMAIL':
			await sendEmail2faCode();
			break;
		case 'SMS':
			await sendSms2faCode();
			break;
		case 'PHONE_CALL':
			await sendPhoneCall2faCode();
			break;
		default:
	}
};

export const submitLoginForm = async (loginForm: LoginForm): Promise<OauthTokenResponse> => {
	const payload = {
		grant_type: 'password',
		loginType: 'PASSWORD',
		...loginForm
	};

	const response = await oauthTokenRequest.post(`/oauth/token`, payload, {
		suppressToast: (resp: AxiosResponse) => resp.status === 400
	});

	const loginResponse: OauthTokenResponse = response.data;
	if (loginResponse.scope !== 'REQUIRES_ADDITIONAL_AUTH') {
		setCookie('userLoggedIn', 'true', response.data.refresh_expires_in);
	}
	return loginResponse;
};

export const getNewAccessToken = async (): Promise<OauthTokenResponse> => {
	const payload = {
		grant_type: 'refresh_token'
	};

	const response = await oauthTokenRequest.post('/oauth/token', payload);
	return response.data;
};

export const resendVerificationEmail = async (): Promise<void> => {
	await apiSecureRequest.post('/email-verification');
};

export const verifyEmail = async (signupKey: string): Promise<OauthTokenResponse> => {
	const response = await apiSecureRequest.post(
		`/user/verify`,
		{
			signupKey,
			clientId: `${process.env.VUE_APP_API_CLIENT_ID}`
		},
		{ withCredentials: true }
	);

	return response.data.accessToken;
};

export const getAccessTokenFromCornice = async (): Promise<CorniceTokenResponse> => {
	const response = await axios.get(`${process.env.VUE_APP_CORNICE_BASE_URL}/admin/substitute-user-token`, {
		withCredentials: true
	});
	return response.data;
};

export const verifyPassword = async (password: string): Promise<OauthTokenResponse> => {
	const response = await oauthSecureRequest.post(
		'/privileged-token',
		{ password },
		{ suppressToast: (resp: AxiosResponse) => resp.status === 400, withCredentials: true }
	);
	setCookie('userLoggedIn', 'true', response.data.refresh_expires_in);
	return response.data;
};
