import { Market, ReitElementApi, ReitElementRating } from 'types/reit-element';
import big from 'big.js';
import { RealEstateStrategy } from 'types/real-estate-strategy';

export default class ReitElement {
	public id!: string;
	public name!: string;
	public reits?: Array<{ id: string; name: string; reitDollarsInvestedInElement: string }>;
	public reitName!: string;
	public coverPhotoUrl!: string;
	public thumbnailUrl!: string;
	public status!: string;
	public assetType!: string;
	public address?: string;
	public city?: string;
	public state?: string;
	public zip?: string;
	public lat?: string;
	public lon?: string;
	public structure!: string;
	public realizedReturn!: string;
	public totalSize!: string;
	public acquisitionDate!: string;
	public exitDate!: string;
	public returnRateType!: 'PROJECTED_RETURN_RANGE' | 'ANNUAL_INTEREST_RATE' | 'NO_RETURN_PROJECTION';
	public riskScore!: string;
	public rating!: ReitElementRating;
	public strategy!: RealEstateStrategy;
	public inScopeForInvestorSubmissions!: boolean;
	public geoJson?: string;
	public submarket?: string;
	public externalReturnType!: string;
	public completed!: boolean;
	public weight!: big;
	public market!: Market;
	public hasMultipleLocations!: boolean;

	public partOfReit(reitId: string): boolean {
		return this.reits?.some((reit) => reit.id === reitId) ?? false;
	}

	public constructor(apiResponse: ReitElementApi, weight = '0') {
		Object.assign(this, apiResponse);
		this.weight = big(weight);
		this.completed = this.status === 'REDEEMED';
		this.externalReturnType = this.completed ? 'Realized Return' : 'Projected Return';
	}
}
