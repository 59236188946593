<template>
	<div class="net-returns-over-time-chart-value-item">
		<!-- color marker -->
		<div :class="{ 'py-25': stretch, 'py-50': !stretch }" data-test="item-indicator">
			<span
				class="net-returns-over-time-chart-value-item-indicator"
				:class="{ stretch: stretch }"
				:style="{ backgroundColor: color }"
			></span>
		</div>
		<!-- content -->
		<div>
			<div :class="['overline', labelClasses]" data-test="item-label">{{ label }}</div>
			<div :class="valueClasses" data-test="item-value">{{ formattedValue }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { formattedPerformanceValue } from '@utils/performance-portfolio';
interface Props {
	color: string;
	label: string;
	value: string;
	stretch?: boolean;
	labelClasses?: string;
	valueClasses?: Array<string>;
}

const props = withDefaults(defineProps<Props>(), {
	stretch: false,
	labelClasses: '',
	valueClasses: () => []
});

const formattedValue = computed((): string => {
	return formattedPerformanceValue(props.value, 'currency', true, true);
});
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;
@use '../../styles/constants/typography' as *;

.net-returns-over-time-chart-value-item {
	display: flex;
	gap: 8px;

	.net-returns-over-time-chart-value-item-indicator {
		display: block;
		width: 8px;
		height: 8px;
		border-radius: 50%;

		&.stretch {
			height: 100%;
			border-radius: 20px;
		}
	}
}
</style>
