<template>
	<transition
		:mode="mode"
		name="collapse"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave"
	>
		<slot />
	</transition>
</template>
<script lang="ts">
export default {
	name: 'TransitionCollapse'
};
</script>
<script setup lang="ts">
interface Props {
	mode?: string;
}
withDefaults(defineProps<Props>(), {
	mode: ''
});

function enter(el: HTMLElement): void {
	const { height } = getComputedStyle(el);
	el.style.height = '0';
	setTimeout(() => {
		el.style.height = height;
	});
}

function afterEnter(el: HTMLElement): void {
	el.style.height = 'auto';
}

function beforeLeave(el: HTMLElement): void {
	const { height } = getComputedStyle(el);
	el.style.height = height;
}

function leave(el: HTMLElement): void {
	setTimeout(() => {
		el.style.height = '0';
	});
}
</script>
<style lang="scss" scoped>
$transition-duration: 350ms;
$transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);

.collapse-enter {
	opacity: 0;
}
.collapse-enter-active {
	opacity: 1;
	overflow: hidden;
	transition-duration: $transition-duration;
	transition-timing-function: $transition-timing-function;
}
.collapse-leave {
	opacity: 1;
}
.collapse-leave-active {
	opacity: 0;
	overflow: hidden;
	transition-duration: $transition-duration;
	transition-timing-function: $transition-timing-function;
}
</style>
