import { BankAccount, WirePaymentMethod } from 'types/payment';

export const plaidEntry: BankAccount = {
	id: '-1',
	label: 'New bank account',
	primaryAccount: false,
	accountType: 'CHECKING',
	accountTypeLabel: '',
	accountNumber: '',
	routingNumber: '',
	bankName: '',
	paymentMethodType: 'ACH'
};

export const wireEntry: WirePaymentMethod = {
	id: '-3',
	wirePaymentMethodId: '',
	label: 'Wire transfer',
	primaryAccount: true,
	paymentMethodType: 'WIRE'
};
