import axios, { AxiosResponseInterceptorParams } from 'axios';
import { isSuppressedStatusCode, logError } from '@utils/error-tracking';
import { app } from '@store/modules/app';
import { appBasePath } from '@constants';
import { Toast } from 'types/layout';

/**
 * This handler controls showing Error toasts and logging the error to Sentry/Datadog.
 * This interceptor handles the logic when `suppressToast` or a custom `errorToast` is passed to the axis config.
 * We suppress toasts/logging errors on the following status codes, as they all have their own unique handling: 401 (unauthorized), 540 (maintenance mode), 405 (WAF);
 * Additionally, we suppress any errors as a result of cornice substitute user read-only access and server validation response for forms. These errors are handled, and showing the toast/logging the error is unneeded.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorToast(err: any): Toast {
	if (err.config?.errorToast) {
		return err.config.errorToast;
	}

	return {
		type: 'error',
		message:
			'An error has occurred. The error has been logged and we&rsquo;ll look into it immediately. If you need assistance, contact us at&nbsp;<a href="mailto:support@fundrise.com">support@fundrise.com</a>.'
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorHandler(err: any): Promise<unknown> {
	const statusMaintenanceMode = 540;
	const isSuppressedStatus = isSuppressedStatusCode(err);
	const isSubUserReadOnlyError = axios.isCancel(err) && app.isReadOnlyAccess;
	const isServerValidationResponse = !!err.response?.data?.fieldErrorMap;
	const isHandledError = isSubUserReadOnlyError || isServerValidationResponse;
	const suppressToast = err.config?.suppressToast?.(err.response) ?? false;

	if (statusMaintenanceMode === err.response?.status) {
		window.location.href = `${appBasePath}/maintenance`;
	}

	if (isSuppressedStatus || isHandledError || suppressToast) {
		return Promise.reject(err);
	}

	logError(err, 'error');
	app.ADD_TOAST(getErrorToast(err));
	return Promise.reject(err);
}

export const errorHandlerInterceptor: AxiosResponseInterceptorParams = [
	(response) => response,
	(error) => errorHandler(error)
];
