import { OAuthAuthorizationResponse, OAuthAuthorizationStatus } from 'types/oauth-authorization';
import { Dictionary } from 'vue-router';
import { oauthSecureRequest } from '@utils/request';

export const submitOAuthAuthorization = async (
	query: Dictionary<string | (string | null)[]>
): Promise<OAuthAuthorizationResponse> => {
	const response = await oauthSecureRequest.get('/authorize', {
		params: query
	});

	return response.data;
};

export const getOauthAuthorizations = async (): Promise<Array<OAuthAuthorizationStatus>> => {
	const response = await oauthSecureRequest.get('/authorization/status');
	return response.data;
};

export const revokeOauthAuthorization = async (clientId: string): Promise<void> => {
	await oauthSecureRequest.post('/client/revoke-consent', { clientId });
};
