import { FundriseRouteConfig } from 'vue-router';
import { midYearReviewId } from '@constants';

const marketingRedirectRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/midyear',
		name: 'mid-year-review',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: {
			name: 'investor-update',
			params: {
				investorUpdateId: midYearReviewId
			}
		}
	}
];

export default marketingRedirectRoutes;
