import axios, { AxiosError, AxiosResponse, AxiosResponseInterceptorParams } from 'axios';
import { app } from '@store/modules/app';
import { getWafCaptchaToken } from '@utils/waf';
import { logError } from '@utils/error-tracking';
import WafCaptchaModal from '@components/modals/waf-captcha-modal.vue';

/**
 * When we receive status code 202 that has a 'x-amzn-waf-action' header of challenge,
 * this retries the request with a new 'x-aws-waf-token' header
 */
async function WafChallengeResponse(response: AxiosResponse): Promise<unknown> {
	const { config } = response;
	const challengeStatusCode = 202;
	const hasChallengeStatus = response?.status === challengeStatusCode;
	const hasChallengeHeader = response?.headers['x-amzn-waf-action'] === 'challenge';

	if (hasChallengeStatus && hasChallengeHeader) {
		const wafCaptchaToken = await getWafCaptchaToken();
		return new Promise((resolveP) => {
			config.headers = { ...config.headers, ...{ 'x-aws-waf-token': wafCaptchaToken } };
			resolveP(axios(config));
		});
	} else {
		return Promise.resolve(response);
	}
}

/**
 * When we receive status code 405 that has a 'x-amzn-waf-action' header of captcha,
 * this renders the WAF captcha modal and handles success/failure.
 */
async function WafCaptchaResponse(err: AxiosError): Promise<unknown> {
	const { config } = err;
	const wafStatusCode = 405;
	const hasWafErrorStatus = err?.response?.status === wafStatusCode;
	const wafActionHeader = err.response?.headers['x-amzn-waf-action'];
	const wafActionIsCaptcha = wafActionHeader === 'captcha';
	const showWafCaptcha = hasWafErrorStatus && wafActionIsCaptcha;

	if (hasWafErrorStatus && wafActionHeader === undefined) {
		logError('405 without x-amzn-waf-action header exposed', 'error');
	}
	if (showWafCaptcha) {
		return new Promise((resolveP, rejectP) => {
			app.UPDATE_CURRENT_MODAL({
				modal: WafCaptchaModal,
				props: {
					resolve: async () => {
						const wafCaptchaToken = await getWafCaptchaToken();
						config.headers = { ...config.headers, ...{ 'x-aws-waf-token': wafCaptchaToken } };
						resolveP(axios(config));
					},
					reject: () => {
						rejectP(err);
					}
				}
			});
		});
	}

	return Promise.reject(err);
}

export const wafChallengeCaptchaInterceptor: AxiosResponseInterceptorParams = [
	(config) => WafChallengeResponse(config),
	(error) => WafCaptchaResponse(error)
];
