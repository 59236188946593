import { BankAccount, WirePaymentMethod } from 'types/payment';
import { DebtOffering } from 'types/debt-offerings';
import { DocumentListItem } from 'types/documents';

export interface CreateNoteInvestmentRequest {
	amount: string;
	debtOfferingId: string;
	paymentMethodId: string;
}

export interface NoteInvestmentOrderDetails {
	amount: string;
	debtOffering: DebtOffering;
	paymentMethod: BankAccount | WirePaymentMethod;
}

export interface NoteInvestmentAcknowledgmentsResponse {
	acknowledgments: Array<string>;
	debtOfferingDocuments: Array<DocumentListItem>;
}

export interface NoteInvestmentCreatedResponse {
	bankAccountId: string;
	noteInvestmentId: string;
	orderAmount: string;
	orderHasBeenPlaced: boolean;
	transactionType: string;
	userEmailIsVerified: boolean;
}

export enum NoteInvestmentAccessError {
	AccreditedOnly = 'accredited-only',
	IRANotAllowed = 'ira-not-allowed',
	NoActiveOfferings = 'no-active-offerings'
}
