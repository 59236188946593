import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { noteInvestment } from '@store/modules/note-investment';
import { NoteInvestmentAccessError } from 'types/notes';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const noteInvestmentRoutes: FundriseRouteConfig = {
	path: `/account/note-investment`,
	name: 'note-investment',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	component: () => import(/* webpackChunkName: "note-investment" */ '@views/note-investment/note-investment.vue'),
	beforeEnter: async (to, from, next) => {
		await noteInvestment.initializeFlow();

		if (to.name === 'note-investment-error') {
			next();
		} else if (investmentEntity.isIraEntity) {
			next({ name: 'note-investment-error', params: { errorType: NoteInvestmentAccessError.IRANotAllowed } });
		} else if (!investmentEntity.isVerifiedAccredited) {
			next({ name: 'note-investment-error', params: { errorType: NoteInvestmentAccessError.AccreditedOnly } });
		} else if (noteInvestment.debtOfferings.length === 0) {
			next({ name: 'note-investment-error', params: { errorType: NoteInvestmentAccessError.NoActiveOfferings } });
		} else {
			next();
		}
	},
	redirect: { name: 'note-investment-order-form' },
	children: [
		{
			path: `start`,
			name: 'note-investment-order-form',
			meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(
					/* webpackChunkName: "note-investment-order-form" */ '@views/note-investment/note-investment-order-form.vue'
				)
		},
		{
			path: `new-bank`,
			name: 'note-investment-new-bank',
			meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(
					/* webpackChunkName: "note-investment-new-bank" */ '@views/note-investment/note-investment-new-bank.vue'
				)
		},
		{
			path: `agreements`,
			name: 'note-investment-agreements',
			meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				if (noteInvestment.noteInvestmentId === '') {
					next({ name: 'note-investment-order-form' });
				} else {
					next();
				}
			},
			component: () =>
				import(
					/* webpackChunkName: "note-investment-agreements" */ '@views/note-investment/note-investment-agreements.vue'
				)
		},
		{
			path: `review`,
			name: 'note-investment-review',
			meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				if (
					noteInvestment.noteInvestmentId === '' ||
					noteInvestment.debtOfferingDocumentRevisionIds.length === 0 ||
					!noteInvestment.debtOffering
				) {
					next({ name: 'note-investment-order-form' });
				} else if (noteInvestment.orderHasBeenPlaced) {
					next({ name: 'note-investment' });
				} else {
					next();
				}
			},
			component: () =>
				import(
					/* webpackChunkName: "note-investment-review" */ '@views/note-investment/note-investment-review.vue'
				)
		},
		{
			path: `success`,
			name: 'note-investment-success',
			meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(
					/* webpackChunkName: "note-investment-success" */ '@views/note-investment/note-investment-success.vue'
				)
		},
		{
			path: `error/:errorType`,
			name: 'note-investment-error',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(
					/* webpackChunkName: "note-investment-error" */ '@views/note-investment/note-investment-error.vue'
				)
		}
	]
};

export default noteInvestmentRoutes;
