import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { completeUpgrade, getRiaPlanMarketingInfo, getRiaPlansByEntityV2 } from '@api/ria';
import { RiaPlan, RiaPlanMarketingStrategy } from 'types/plan';
import { DripStatuses } from 'types/investment-entity';
import { RiaUpgradePreference } from 'types/ria';
import store from '..';
import { user } from './user';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'ria-upgrade',
	store
})
class RiaUpgradeModule extends VuexModule {
	riaPlanId = '';
	reinvestmentPreference!: DripStatuses;
	riaPlans: Array<RiaPlan> = [];
	marketingContent: Array<RiaPlanMarketingStrategy> = [];
	hasSubmitted = false;

	get selectedPlan(): RiaPlan | null {
		return this.riaPlans.find((r) => r.id === this.riaPlanId) ?? null;
	}

	@Action({ rawError: true })
	public async selectPlanId(planId: string): Promise<string> {
		this.UPDATE_RIA_PLAN_ID(planId);

		return 'ria-upgrade-confirm-investment-plan';
	}

	@Action({ rawError: true })
	public async submitUpgrade(reinvestmentPreference: DripStatuses): Promise<string> {
		this.UPDATE_HAS_SUBMITTED(true);
		const riaUpgradePreference: RiaUpgradePreference = {
			riaPlanId: this.riaPlanId,
			reinvestmentPreference
		};

		await completeUpgrade(riaUpgradePreference);

		await user.refreshUser();

		return 'ria-upgrade-success';
	}

	@Action({ rawError: true })
	public async getRiaPlans(): Promise<Array<RiaPlan>> {
		if (!this.riaPlans.length) {
			const riaPlans = await getRiaPlansByEntityV2();
			this.UPDATE_RIA_PLANS(riaPlans);
		}

		return this.riaPlans;
	}

	@Action({ rawError: true })
	public async getMarketingContent(): Promise<Array<RiaPlanMarketingStrategy>> {
		if (!this.marketingContent.length) {
			const marketingContent = await getRiaPlanMarketingInfo();
			this.UPDATE_MARKETING_CONTENT(marketingContent);
		}

		return this.marketingContent;
	}

	@Mutation
	public UPDATE_RIA_PLAN_ID(riaPlanId: string): void {
		this.riaPlanId = riaPlanId;
	}

	@Mutation
	public UPDATE_RIA_PLANS(riaPlans: Array<RiaPlan>) {
		this.riaPlans = riaPlans;
	}

	@Mutation
	public UPDATE_MARKETING_CONTENT(marketingContent: Array<RiaPlanMarketingStrategy>) {
		this.marketingContent = marketingContent;
	}

	@Mutation
	public UPDATE_HAS_SUBMITTED(hasSubmitted: boolean): void {
		this.hasSubmitted = hasSubmitted;
	}
}

export const riaUpgrade = getModule(RiaUpgradeModule);
