import { FundriseRouteConfig } from 'vue-router';
import { getPreview } from '@api/prismic';
import { PrismicDocument } from 'types/prismic';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const prismicPreviewRoutes: FundriseRouteConfig[] = [
	{
		path: `/preview`,
		name: 'prismic-preview',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const { token, documentId } = to.query;
			const preview: PrismicDocument = await getPreview(token as string, documentId as string);

			switch (preview.type) {
				case 'blog':
					next({
						path: `/education/${preview.uid}`,
						query: {
							previewRef: token
						}
					});
					break;
				case 'blog_landing':
					next({
						path: `/education`,
						query: {
							previewRef: token
						}
					});
					break;
				case 'categories':
					next({
						path: `/education/category/${preview.uid}`,
						query: {
							previewRef: token
						}
					});
					break;
				case 'premium_landing_page':
					next({
						path: `/advanced-investing`,
						query: {
							previewRef: token
						}
					});
					break;
				case 'landing_page':
					next({
						path: `/campaigns/${preview.uid}`,
						query: {
							previewRef: token
						}
					});
					break;
				default:
					next({
						path: `/404`
					});
					break;
			}
		},
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	}
];

export default prismicPreviewRoutes;
