<template>
	<div v-click-away="onClickAway" class="base-select select-holder">
		<button
			ref="baseSelectRef"
			:class="['base-select-control select-mask', { expanded: isExpanded }]"
			aria-controls="base-select-options"
			:aria-expanded="isExpanded"
			aria-haspopup="listbox"
			:data-test="`${componentId}-select-control`"
			@click="toggleOptions"
			@keydown.up.down.prevent
			@keyup.up.down.prevent="expandOptions()"
		>
			<span class="text">{{ selectedOption.label }}</span>
		</button>
		<ul
			v-show="isExpanded"
			ref="baseSelectOptionsRef"
			class="base-select-options"
			:data-test="`${componentId}-select-options`"
			tabindex="-1"
			role="listbox"
			@keyup.up.prevent="selectPrevOption()"
			@keyup.down.prevent="selectNextOption()"
			@keydown.enter.prevent="collapseOptionsAndSelect()"
			@keydown.esc.prevent="collapseOptionsAndReset()"
		>
			<li
				v-for="(option, i) in options"
				:key="`option-${i}`"
				:data-test="`${componentId}-select-option-${i}`"
				role="option"
				:class="['base-select-option', { selected: option.value === selectedOption.value }]"
				@click="handleOptionClick(option)"
			>
				<div
					class="base-select-option-content display-flex align-items-center justify-content-space-between gap-100 px-100"
				>
					<div class="base-select-option-content--left display-flex flex-direction-column">
						<div class="line-height-150" v-html="option.label"></div>
						<div
							v-if="option.subLabel"
							class="text-color-dark-70 eta line-height-150 text-overflow-ellipsis"
							v-html="option.subLabel"
						></div>
					</div>
					<div
						v-if="option.rightLabel"
						class="text-color-dark-70 eta line-height-150"
						v-html="option.rightLabel"
					></div>
				</div>
			</li>
		</ul>
	</div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { SelectOption } from 'types/layout';

interface Props {
	modelValue: SelectOption;
	options: Array<SelectOption>;
	componentId?: string;
}

interface Emits {
	(e: 'update:modelValue', value: SelectOption): void;
}

const props = withDefaults(defineProps<Props>(), {
	componentId: 'base'
});

const emits = defineEmits<Emits>();

const baseSelectRef = ref<HTMLElement | null>(null);
const baseSelectOptionsRef = ref<HTMLElement | null>(null);

const selectedOption = ref<SelectOption>({ value: '', label: '' });

const isExpanded = ref(false);

const selectedOptionIndex = computed((): number => {
	return props.options.findIndex((option) => option.value === selectedOption.value.value);
});

watch(
	() => props.modelValue,
	() => {
		if (props.options?.length) {
			selectedOption.value =
				props.options.find((option) => option.value === props.modelValue.value) || props.options[0];
		}
	},
	{ immediate: true }
);

function toggleOptions(): void {
	isExpanded.value = !isExpanded.value;
}

function handleOptionClick(option: SelectOption): void {
	if (selectedOption.value !== option) {
		selectedOption.value = option;
		emits('update:modelValue', option);
	}

	toggleOptions();
}

async function expandOptions(): Promise<void> {
	isExpanded.value = true;
	await nextTick();
	baseSelectOptionsRef.value?.focus();
}

async function collapseOptions(): Promise<void> {
	isExpanded.value = false;
	await nextTick();
	baseSelectRef.value?.focus();
}

async function collapseOptionsAndSelect(): Promise<void> {
	emits('update:modelValue', selectedOption.value);
	await collapseOptions();
}

async function collapseOptionsAndReset(): Promise<void> {
	selectedOption.value = props.modelValue;
	await collapseOptions();
}

function onClickAway(): void {
	isExpanded.value = false;
}

function selectPrevOption(): void {
	const prevOption =
		selectedOptionIndex.value > 0 ? props.options[selectedOptionIndex.value - 1] : selectedOption.value;
	selectedOption.value = prevOption;
}

function selectNextOption(): void {
	const lastIndex = props.options.length - 1;
	const nextOption =
		selectedOptionIndex.value < lastIndex ? props.options[selectedOptionIndex.value + 1] : selectedOption.value;
	selectedOption.value = nextOption;
}
</script>
<style lang="scss" scoped>
@use '../styles/constants/colors' as *;

.base-select {
	position: relative;
	cursor: pointer;

	.base-select-control {
		&[aria-expanded] {
			&.select-mask {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		&.expanded {
			border-color: dark(100);

			.field__inner--error & {
				border-color: map-get($validation-colors, 'error');
			}

			&::after {
				transform: none;
			}
		}
	}

	.base-select-options {
		background: #ffffff;
		position: absolute;
		top: 4rem;
		width: 100%;
		max-height: 210px;
		opacity: 1;
		transform: translateY(-1rem);
		transition: 0.25s all ease 0.1s;
		border: 2px solid dark(100);
		border-radius: 4px;
		border-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: 1000;
		overflow-y: scroll;

		&:focus {
			border-color: dark(100);
			outline: none;
		}

		.field__inner--error & {
			border-color: map-get($validation-colors, 'error');
		}

		.base-select-option {
			&:not(:first-child) {
				border-top: 1px solid dark(10);
			}

			&:hover,
			&.selected {
				background-color: dark(5);
			}

			&-content {
				height: 54px;

				&--left {
					min-width: 0;
				}
			}
		}
	}
}
</style>
