import { FundriseRouteConfig } from 'vue-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const legalRoutes: FundriseRouteConfig[] = [
	{
		path: `/legal/ira-fee-payment-program`,
		name: 'ira-paid-fees-terms-and-conditions',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(
				/* webpackChunkName: "ira-paid-terms-and-conditions" */ '@views/ira/general/ira-paid-fees-terms-and-conditions.vue'
			)
	},
	{
		path: `/legal/invitation-program`,
		name: 'referral-terms-conditions',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "referral-terms-conditions" */ '@views/referral/referral-terms-conditions.vue')
	},
	{
		path: `/legal/disclosure`,
		name: 'disclosures',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "disclosures" */ '@views/legal/disclosures-view.vue')
	},
	{
		path: `/legal/new-user-promotion`,
		name: 'legal-new-user-promotion',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "new-user-promotion" */ '@views/legal/new-user-promotion.vue')
	},
	{
		path: `/legal/pro-membership`,
		name: 'pro-membership-agreement',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "pro-membership-agreement" */ '@views/legal/pro-membership-agreement.vue')
	},
	{
		path: `/legal/promo`,
		name: 'starter-promo-terms',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "starter-promo-terms" */ '@views/legal/starter-promo.vue')
	},
	{
		path: `/legal/advisory-fee-waiver`,
		name: 'advisory-fee-waiver',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "advisory-fee-waiver" */ '@views/legal/advisory-fee-waiver.vue')
	},
	{
		path: `/oc`,
		name: 'offering-circulars',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "offering-circulars" */ '@views/legal/offering-circulars.vue')
	},
	{
		path: `/oc/links`,
		name: 'offering-circulars-links',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "offering-circulars-links" */ '@views/legal/offering-circulars.vue')
	},
	{
		path: `/webview/legal/new-user-promotion`,
		name: 'webview-legal-new-user-promotion',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "webview-new-user-promotion" */ '@views/webview/webview-new-user-promotion.vue')
	}
];

export default legalRoutes;
