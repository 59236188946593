import {
	CreateNoteInvestmentRequest,
	NoteInvestmentAcknowledgmentsResponse,
	NoteInvestmentCreatedResponse
} from 'types/notes';
import { investmentEntityRequest } from '@utils/request';

export async function createNoteInvestment(
	noteInvestmentOrderDetails: CreateNoteInvestmentRequest
): Promise<NoteInvestmentCreatedResponse> {
	const response = await investmentEntityRequest.post(`/note-investment`, noteInvestmentOrderDetails);
	return response.data;
}

export async function getNoteInvestmentDocsAndAcks(
	noteInvestmentId: string
): Promise<NoteInvestmentAcknowledgmentsResponse> {
	const response = await investmentEntityRequest.get(`/note-investment/${noteInvestmentId}/acknowledgments`);
	return response.data;
}

export async function placeOrder(
	noteInvestmentId: string,
	debtOfferingDocumentRevisionIds: Array<string>,
	autoRenew = false
): Promise<NoteInvestmentCreatedResponse> {
	const response = await investmentEntityRequest.post(`/note-investment/${noteInvestmentId}/place-order`, {
		autoRenew,
		debtOfferingDocumentRevisionIds
	});
	return response.data;
}
