import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getRiaPlansByEntityV2 } from '@api/ria';
import { RiaPlan } from 'types/plan';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'plans',
	store
})
class PlansModule extends VuexModule {
	selectedPlan: RiaPlan | null = null;
	availablePlans: Array<RiaPlan> = [];
	isFmpCardFlow = false;

	@Action({ rawError: true })
	public async getAvailableRiaPlans(): Promise<void> {
		if (this.availablePlans.length === 0) {
			const availablePlansForEntity = await getRiaPlansByEntityV2();
			this.UPDATE_AVAILABLE_PLANS(availablePlansForEntity);
		}
	}

	@Action({ rawError: true })
	public updateSelectedPlan(planData: { plan: RiaPlan; isFmpCardFlow: boolean }): void {
		this.UPDATE_SELECTED_PLAN(planData.plan);
		this.UPDATE_IS_FMP_CARD_FLOW(planData.isFmpCardFlow);
	}

	@Action({ rawError: true })
	public resetIsFromFmpCard(): void {
		this.UPDATE_IS_FMP_CARD_FLOW(false);
	}

	@Action({ rawError: true })
	public resetAll(): void {
		this.UPDATE_SELECTED_PLAN(null);
		this.UPDATE_AVAILABLE_PLANS([]);
		this.resetIsFromFmpCard();
	}

	@Mutation
	public UPDATE_SELECTED_PLAN(selectedPlan: RiaPlan | null): void {
		this.selectedPlan = selectedPlan;
	}

	@Mutation
	public UPDATE_AVAILABLE_PLANS(availablePlans: Array<RiaPlan>): void {
		this.availablePlans = availablePlans;
	}

	@Mutation
	public UPDATE_IS_FMP_CARD_FLOW(isFmpCardFlow: boolean): void {
		this.isFmpCardFlow = isFmpCardFlow;
	}
}

export const plans = getModule(PlansModule);
