import { apiSecureRequest, investmentEntityRequest } from '@utils/request';
import {
	CustomAllocationOfferingResponse,
	CustomAllocationPlanRequest,
	CustomAllocationPlanResponse,
	CustomAllocationType
} from 'types/custom-plans';
import { SignupCheckoutSelectionResponse } from 'types/checkout';

export const getCustomAllocationPlan = async (): Promise<CustomAllocationPlanResponse> => {
	const response = await investmentEntityRequest.get('/custom-investment-plan');

	return response.data;
};

export const getCustomAllocationsOfferingsListForEntity = async (): Promise<
	Array<CustomAllocationOfferingResponse>
> => {
	const response = await investmentEntityRequest.get('/custom-allocation/offerings');

	return response.data.reits;
};

export const getCustomAllocationsOfferingsList = async (): Promise<Array<CustomAllocationOfferingResponse>> => {
	const response = await apiSecureRequest.get('/custom-allocation/offerings');

	return response.data.reits;
};

export const saveCustomAllocationPlan = async (
	payload: CustomAllocationPlanRequest
): Promise<CustomAllocationPlanResponse> => {
	const response = await investmentEntityRequest.post('/custom-investment-plan', payload);

	return response.data;
};

export const saveCheckoutCustomAllocationPlan = async (
	payload: CustomAllocationPlanRequest
): Promise<SignupCheckoutSelectionResponse> => {
	const response = await apiSecureRequest.post('/signup-checkout/custom-selections', payload);

	return response.data;
};

export const saveAllocationType = async (
	allocationType: CustomAllocationType
): Promise<CustomAllocationPlanResponse> => {
	const response = await investmentEntityRequest.post('/custom-investment-plan/allocation-type', { allocationType });

	return response.data;
};
