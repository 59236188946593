<template>
	<div ref="target" class="module">
		<div class="module-section">
			<ModularDashboardPlaceholderLines
				v-if="loading || isError"
				:placehold="loading"
				:line-count="0"
				:show-header="true"
				:use-animation="loading"
			>
				<div v-if="isError" class="text-color-dark-70" v-html="moduleLoadingErrorMessage" />
			</ModularDashboardPlaceholderLines>
			<ModularDashboardPlaceholderBlock
				:placehold="loading || isError"
				:show-error-icon="isError"
				classes="investor-goals-module-placeholder-block"
				:class="{ 'py-100': loading || isError }"
				:use-animation="loading"
			>
				<div class="display-flex flex-direction-column">
					<div v-if="displayGoal" class="current-goal" data-test="current-goal">
						<BaseLink
							class="display-flex align-items-center justify-content-space-between mb-25"
							:link="{ router: 'investment-goal-edit' }"
							data-test="edit-goal"
						>
							<p class="overline">{{ moduleTitle }}</p>
							<span class="text-color-fundrise-dark">
								<BaseSvgIcon width="24" height="24" name="caret" dir="right"
							/></span>
						</BaseLink>
						<h2 class="ellipsis font-weight-bold mt-75">{{ displayName }} </h2>
						<p class="mt-50">{{ progress }}% Complete</p>
						<ProgressMeter :bar-class="barClass" :progress-percent="progress" class="mt-50" height="6">
							<template #left-side>
								<div>
									<span class="show-for-sr">Progress to goal</span>
								</div>
							</template>
							<template #right-side>
								<div>
									<span class="text-color-dark-70">{{ targetValue }} Goal</span>
								</div>
							</template>
						</ProgressMeter>
					</div>
					<div v-else data-test="create-goal">
						<p class="overline">{{ moduleTitle }}</p>
						<div class="text-align-center">
							<BaseSvgIcon height="96" width="96" name="hd-target-v2" class="icon mt-150" />
							<p class="text-color-dark-70 zeta text-align-left">Set and track a goal.</p>
							<BaseLink
								classes="button button-block button-outline mt-100"
								:link="createLink"
								data-test="create-goal-link"
							/>
						</div>
					</div>
				</div>
			</ModularDashboardPlaceholderBlock>
			<ModularDashboardPlaceholderLines
				v-if="loading || isError"
				:placehold="loading"
				:line-count="2"
				:show-footer="true"
				:use-animation="loading"
			>
				<div v-if="isError" class="pt-50 text-align-center">
					<ModularDashboardRetryButton @retry-clicked="loadModule()" />
				</div>
			</ModularDashboardPlaceholderLines>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'InvestorGoalsModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { currency } from '@filters/shared-filters';
import { GoalResponse } from 'types/investment-goals';
import { investmentEntity } from '@store/modules/investment-entity';
import { investmentGoals } from '@store/modules/investment-goals';
import { isProOrPremium } from '@utils/composables';
import { Link } from 'types/layout';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { ModularDashboardModule } from 'types/modular-dashboard';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants';
import ProgressMeter from '@components/investment-goals/progress-meter.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';

interface Props {
	module: ModularDashboardModule;
}

const props = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

const createLink: Link = {
	router: 'investment-goal',
	text: 'Create goal'
};

const loading = ref(true);
const isError = ref(false);

const goal = computed((): GoalResponse | null => {
	return investmentGoals.currentGoal;
});

const displayGoal = computed((): boolean => {
	return !!goal.value && modularDashboard.modularDashboardMetadata?.settlementState !== 'FULLY_REDEEMED';
});

const displayName = computed((): string => {
	return goal.value?.displayName ?? '';
});

const moduleTitle = computed((): string => {
	return displayGoal.value ? 'Goal Progress' : 'Investing Goal';
});

const progress = computed((): string => {
	return goal.value?.progress ?? '';
});

const targetValue = computed((): string => {
	return currency(goal.value?.targetValue ?? '', 0);
});

const barClass = computed((): string => {
	return isProOrPremium.value ? 'bg-color-data-viz-dark-gold' : 'bg-color-data-viz-slate';
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': 'Investor Goal',
			'View Content': props.module.name,
			'View ID': props.module.type
		});
	}
});

async function loadModule(): Promise<void> {
	loading.value = true;
	isError.value = false;

	try {
		if (investmentEntity.investorGoalExists && !goal.value) {
			await investmentGoals.fetchAndStoreCurrentGoal();
		}
	} catch {
		isError.value = true;
	} finally {
		loading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>
<style lang="scss" scoped>
:deep(.investor-goals-module-placeholder-block) {
	width: 100%;
	min-width: 100%;
	height: 194px;
}
</style>
