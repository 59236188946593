import * as Sentry from '@sentry/vue';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@utils/web-storage';
import {
	MixpanelActionProperties,
	MixpanelClickProperties,
	MixpanelIdDto,
	MixpanelViewProperties,
	MixpanelViewType
} from 'types/analytics';
import { allowedMxpQueryParams } from '@constants';
import { app } from '@store/modules/app';
import { CheckoutSuccess } from 'types/checkout';
import { Dictionary } from 'vue-router';
import { kebabCaseToTitleCase } from '@filters/shared-filters';
import mixpanel from 'mixpanel-browser';
import router from '@router';
import { updateMixpanelId } from '@api/analytics';
import { user } from '@store/modules/user';
import { userAgentIsBot } from '@utils/bot-detection';

export function hasFacebookQuery(): boolean {
	return !!window.fbq && !app.isThirdPartyLogin;
}

export function hasGoogleAnalytics(): boolean {
	return !!window.ga && !app.isThirdPartyLogin;
}

export function hasMixpanel(): boolean {
	return !app.isMobileWebview && app.mixpanelInitialized;
}

function getUrlAndParams() {
	const route = router.currentRoute;
	const params = processQueryParamsForMixpanel(route.value.query);
	let viewName = '';

	if (route.value.meta?.customAnalyticsViewName) {
		viewName = route.value.meta?.customAnalyticsViewName;
	} else if (route.value.name) {
		viewName = kebabCaseToTitleCase(route.value.name as string);
	} else {
		viewName = route.value.path;
	}

	return {
		'Page URL': route.value.path,
		'Sanitized Page URL': sanitizePageUrl(route.value.path),
		'View Name': viewName,
		'View ID': Object.entries(route.value.params).find(([key]) => /id|slug/i.test(key))?.[1],
		...params
	};
}

function sanitizeNumbers(text: string | undefined): string | undefined {
	if (!text) {
		return text;
	}

	return text.replace(/(\d[0-9,.]*\d|\d)[kMB]?/g, 'X');
}

function getImageFileName(text: string): string {
	const matches: RegExpMatchArray | null = text.match(/[^\/]*$/g);
	if (matches) {
		return matches[0];
	}
	return text;
}

function initialTracking(): void {
	if (!mixpanel.get_property('Initial Page View')) {
		mixpanel.track('Initial Page View', {
			'page name': document.title,
			url: window.location.pathname
		});
		mixpanel.register_once({ 'Initial Page View': new Date().getTime() });
	}

	if (window.hj && mixpanel?.get_distinct_id()) {
		window.hj('identify', mixpanel.get_distinct_id(), {});
	}
}

export const initializeMixpanel = () => {
	mixpanel.init(`${process.env.VUE_APP_MIXPANEL_TOKEN}`, {
		api_host: `${process.env.VUE_APP_MIXPANEL_WEB_API_HOST}`,
		persistence: 'localStorage',
		disable_notifications: true,
		api_method: 'GET',
		batch_requests: false,
		store_google: false,
		ignore_dnt: true,
		loaded: (mxp) => {
			app.SET_MIXPANEL_INITIALIZED(true);
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ mixpanel_id: mxp.get_distinct_id() });
			setItemInLocalStorage('distinctid', mxp.get_distinct_id());
			const distinctIdFromQueryParam = sessionStorage.getItem('distinctIdFromQueryParam');
			sessionStorage.removeItem('distinctIdFromQueryParam');

			if (distinctIdFromQueryParam) {
				identifyMixpanel(distinctIdFromQueryParam).then(() => {
					initialTracking();
				});
			} else {
				initialTracking();
			}
		}
	});

	if (userAgentIsBot()) {
		mixpanel.register({ $ignore: true }); // https://docs.mixpanel.com/docs/debugging/bot-traffic
	}
};

export const getFallBackActionTarget = (children: HTMLCollection): string | undefined => {
	if (children.length === 0) {
		return undefined;
	}

	for (let i = 0; i < children.length; i++) {
		const element = children.item(i);

		if (element) {
			const tagName = element.tagName?.toLowerCase();

			if (tagName === 'svg') {
				const name = element.getAttribute('name');
				return `Icon-${name}`;
			}

			if (tagName === 'img') {
				const alt = element.getAttribute('alt');
				const src = element.getAttribute('src');
				if (alt) {
					return `Img-${alt}`;
				} else if (src) {
					return `Img-${getImageFileName(src)}`;
				}
			}

			if (!!element.textContent) {
				return sanitizeNumbers(element.textContent) ?? '';
			}

			if (!!tagName) {
				return `tag-${tagName}`;
			}
		}
	}

	return undefined;
};

export function findMixpanelContext(element: HTMLElement | null | undefined): string {
	if (element) {
		const attr = element.getAttribute('data-context');
		if (attr) {
			return attr;
		} else if (element.parentElement) {
			return findMixpanelContext(element.parentElement);
		}
	}
	return '';
}

export const getActionTargetFromContext = (elm: HTMLElement): string | undefined => {
	const dataActionName = elm?.getAttribute('data-dd-action-name') ?? undefined;
	const elTextContent = !!elm?.textContent ? elm?.textContent : undefined;
	const fallbackText = elm?.children?.length ? getFallBackActionTarget(elm.children) : undefined;
	const dataTestName = elm?.getAttribute('data-test') ?? undefined;
	return dataActionName ?? elTextContent ?? fallbackText ?? dataTestName;
};

const getActionTarget = (clickData: MixpanelClickProperties): string => {
	const definedTarget = clickData['Action Target'];
	const actionTargetFromContext = clickData.element ? getActionTargetFromContext(clickData.element) : undefined;

	return definedTarget ?? actionTargetFromContext ?? 'unknown';
};

export const getMixpanelClickProperties = (clickData: MixpanelClickProperties): MixpanelClickProperties => {
	const clickProperties: MixpanelActionProperties = { 'Action Type': 'Click' };
	const actionContextString = 'Action Context';
	const actionElementString = 'Action Element';

	clickProperties['Action Target'] = getActionTarget(clickData);

	if (clickData[actionContextString]) {
		clickProperties[actionContextString] = clickData[actionContextString];
	} else if (clickData.element) {
		clickProperties[actionContextString] = findMixpanelContext(clickData.element);
	}

	if (clickData[actionElementString]) {
		clickProperties[actionElementString] = clickData[actionElementString];
	} else {
		clickProperties[actionElementString] =
			clickData.element?.tagName.toLowerCase() ?? clickData.element?.parentElement?.tagName.toLowerCase() ?? '';
	}

	if (clickData.Link) {
		clickProperties.Link = clickData.Link;
	}

	return clickProperties;
};

export const getDistinctId = (): string | undefined => {
	if (hasMixpanel() && typeof mixpanel.get_distinct_id == 'function') {
		return mixpanel.get_distinct_id();
	}
	return getItemFromLocalStorage('distinctid') ?? user.user?.mixpanelId ?? undefined;
};

export const identifyMixpanel = async (distinctid: string): Promise<void> => {
	const currentDistinctId = getDistinctId();
	if (currentDistinctId !== distinctid) {
		const updateDto: MixpanelIdDto = {
			oldMixpanelId: `${currentDistinctId}`,
			newMixpanelId: distinctid
		};
		await updateMixpanelId(updateDto);
		setItemInLocalStorage('distinctid', distinctid);
		if (hasMixpanel()) {
			mixpanel.identify(distinctid);
			await app.initializeLaunchDarklyInstance();
		}
		if (window.gtag) {
			window.gtag({ mixpanel_id: distinctid });
		}
	}
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const trackMixpanelEvent = (name: string, properties = {} as any): void => {
	const eventProperties = { ...getUrlAndParams(), ...properties };

	if (hasMixpanel()) {
		mixpanel.track(name, eventProperties);
	}
};

export const trackMixpanelEventWithBeacon = (name: string, properties = {} as any): void => {
	const eventProperties = { ...getUrlAndParams(), ...properties };

	if (hasMixpanel()) {
		mixpanel.track(name, eventProperties, { transport: 'sendBeacon' });
	}
};

export const registerMixpanel = (properties: any): void => {
	if (hasMixpanel()) {
		mixpanel.register(properties);
	}
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const processQueryParamsForMixpanel = (
	query: Dictionary<(string | null) | (string | null)[]>
): { [key: string]: string } => {
	const params: Record<string, string> = {};
	const entries = Object.entries(query);

	for (const [key, val] of entries) {
		if (allowedMxpQueryParams.includes(key)) {
			const localKey = key === 'cta' ? 'Referring CTA' : key;
			if (val) {
				params[localKey] = val.toString();
			}
		}
	}

	return params;
};

const sanitizePageUrl = (pageUrl: string): string => {
	return pageUrl.replace(/\d+/g, 'X');
};

export const trackMixpanelView = (viewType: MixpanelViewType, properties?: Partial<MixpanelViewProperties>): void => {
	if (hasMixpanel()) {
		const { customProperties, ...standardProperties } = properties || {};
		const viewProperties = {
			...standardProperties,
			...customProperties,
			'View Type': viewType
		};

		if (viewType === 'Modal' || viewType === 'Element') {
			trackMixpanelEventWithBeacon('View', viewProperties);
		} else {
			trackMixpanelEvent('View', viewProperties);
		}
	}
};

export const trackMixpanelClick = (clickData: MixpanelClickProperties, useBeacon = false): void => {
	if (hasMixpanel()) {
		const properties = { ...getMixpanelClickProperties(clickData), ...clickData.customProperties };
		if (useBeacon) {
			trackMixpanelEventWithBeacon('Action', properties);
		} else {
			trackMixpanelEvent('Action', properties);
		}

		Sentry.addBreadcrumb({
			category: 'mixpanel',
			message: 'Action',
			level: 'info',
			data: properties
		});
	}
};

export const trackMixpanelScrollIntoView = (
	target: HTMLElement,
	mixpanelProperties: Partial<MixpanelViewProperties>,
	once = true
): void => {
	let emitted = false;
	const threshold = target.offsetHeight > window.innerHeight ? 0.1 : 0.9;

	const observer = new IntersectionObserver(
		(entries: Array<IntersectionObserverEntry>) => {
			if (!(emitted && once) && entries.some((e) => e.isIntersecting)) {
				trackMixpanelView('Element', mixpanelProperties);
				emitted = true;
			}
		},
		{ threshold }
	);

	observer.observe(target);
	/**
	 * useIntersectionObserver creates build issues while we are on TypeScript 4
	 * We can return to using this composable once we bump to 5.2.2 (@JF)
	const threshold = target.offsetHeight > window.innerHeight ? 0.1 : 0.9;



	useIntersectionObserver(
		target,
		([{ isIntersecting }], observerElement) => {
			if (isIntersecting) {
				trackMixpanelView('Element', mixpanelProperties);
				observerElement.unobserve(target);
			}
		},
		{
			threshold
		}
	);
	 */
};

const trackUetqConversion = (checkoutSuccess: CheckoutSuccess): void => {
	window.uetq = window.uetq || [];
	window.uetq.push('event', 'Initial Investment Placement', {
		/* eslint-disable @typescript-eslint/naming-convention */
		event_category: 'Investor',
		event_label: 'Landing Page',
		event_value: '1',
		revenue_value: checkoutSuccess.investmentAmount,
		currency: 'USD'
		/* eslint-enable @typescript-eslint/naming-convention */
	});
};

const trackGoogleAnalyticsConversion = (checkoutSuccess: CheckoutSuccess): void => {
	const investmentType = checkoutSuccess.firstInvestmentForUser ? 'First Time Investment' : 'Repeat Investment';
	if (hasGoogleAnalytics()) {
		window.ga('send', 'event', {
			eventCategory: 'Investment',
			eventAction: 'Complete',
			eventLabel: 'REIT',
			eventValue: checkoutSuccess.investmentAmount
		});

		window.ga('send', 'event', {
			eventCategory: investmentType,
			eventAction: 'Complete',
			eventLabel: 'REIT',
			eventValue: checkoutSuccess.investmentAmount
		});
	}
};

const trackPdstConversion = (checkoutSuccess: CheckoutSuccess): void => {
	if (window.pdst) {
		window.pdst('purchase', {
			value: checkoutSuccess.investmentAmount,
			currency: 'USD',
			discount_code: null,
			order_id: checkoutSuccess.userId
		});
	}
};

const trackGtagConversion = (checkoutSuccess: CheckoutSuccess, totalValueNumber: number): void => {
	if (window.gtag) {
		window.gtag({
			event: 'conversion',
			value: totalValueNumber,
			currency: 'USD',
			transaction_id: checkoutSuccess.userId,
			...checkoutSuccess.analyticsProperties
		});
	}

	if (window.gtag && user.isSubscriptionActive) {
		window.gtag({
			event: 'pro'
		});
	}
};

const trackFacebookQueryConversion = (totalValueWithoutOutliers: number): void => {
	if (hasFacebookQuery()) {
		window.fbq('track', 'Purchase', {
			value: totalValueWithoutOutliers,
			currency: 'USD',
			product_name: 'REIT',
			investor_type: 'new'
		});
	}
};

const trackLetterpressConversion = (checkoutSuccess: CheckoutSuccess): void => {
	if (window.letterpress) {
		const address = checkoutSuccess.userAddress;
		window.letterpress(
			'addTrans',
			checkoutSuccess.userId, // order ID
			'', // affiliation or store name
			checkoutSuccess.investmentAmount, // grand total
			'0', // tax amount
			'0', // shipping amount
			address ? address.city : '', // city
			address ? address.state : '', // state or province
			'USA', // country
			'USD', // currency
			[
				{
					schema: 'iglu:com.getletterpress/address/jsonschema/1-0-0',
					data: {
						name: checkoutSuccess.userName,
						street: address?.address1 ? address.address1 : '',
						street_2: address?.address2 ? address.address2 : '',
						zip: address?.zip ? address.zip : '',
						city: address?.city ? address.city : '',
						state: address?.state ? address.state : '',
						country: 'USA',
						email: checkoutSuccess.userEmail
					}
				}
			]
		);

		window.letterpress(
			'addItem',
			checkoutSuccess.userId, // order ID
			'', // SKU
			'', // product name
			'', // category or variation
			checkoutSuccess.investmentAmount, // unit price
			'1', // quantity
			'USD' // currency
		);

		window.letterpress('trackTrans');
	}
};

export const trackConversionEvents = (checkoutSuccess: CheckoutSuccess): void => {
	if (process.env.VUE_APP_ENABLE_CONVERSION_PIXELS === 'true' && !app.isThirdPartyLogin) {
		trackUetqConversion(checkoutSuccess);
		trackGoogleAnalyticsConversion(checkoutSuccess);

		if (checkoutSuccess.firstInvestmentForUser) {
			const totalValueNumber = Number(checkoutSuccess.investmentAmount || 0);
			const maximumValue = 25000;
			const totalValueWithoutOutliers = Math.min(totalValueNumber, maximumValue);
			trackPdstConversion(checkoutSuccess);
			trackGtagConversion(checkoutSuccess, totalValueNumber);
			trackFacebookQueryConversion(totalValueWithoutOutliers);
			trackLetterpressConversion(checkoutSuccess);
		}
	}
};

export const trackSignupEvents = (): void => {
	setItemInLocalStorage('fundrise__onWaitlist', 'true');
	if (process.env.VUE_APP_ENABLE_CONVERSION_PIXELS && !app.isThirdPartyLogin) {
		if (window.gtag) {
			window.gtag({
				event: 'signup',
				send_to: 'AW-844391139/CP1-CM-jwZ8BEOPF0ZID'
			});
			window.gtag({ event: 'signup' });
		}

		if (hasFacebookQuery()) {
			window.fbq('track', 'CompleteRegistration', {
				accredited: 'false'
			});
		}

		if (window.twttr) {
			window.twttr.conversion.trackPid('nvok3', { tw_sale_amount: 0, tw_order_quantity: 0 });
		}
	}
};

export const getAppleAppStoreLink = (utmCampaign: string): string => {
	return `${process.env.VUE_APP_AVATAR_URL}/app-store-redirect?utm_source=fundrise&utm_medium=website&utm_campaign=${utmCampaign}`;
};
export const getGooglePlayStoreLink = (utmCampaign: string): string => {
	return `${process.env.VUE_APP_AVATAR_URL}/play-store-redirect?utm_source=fundrise&utm_medium=website&utm_campaign=${utmCampaign}`;
};

export const getDatadogSampleRate = (): { sessionSampleRate: number; sessionReplaySampleRate: number } => {
	const isThirdParty = userAgentIsBot();
	const sessionSampleRate = isThirdParty ? 0 : Number(process.env.VUE_APP_DATADOG_RUM_SESSION_SAMPLE_RATE);
	const sessionReplaySampleRate = isThirdParty
		? 0
		: Number(process.env.VUE_APP_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE);

	return { sessionSampleRate, sessionReplaySampleRate };
};
