<template>
	<div :class="['footer__disclaimers eta', textColorClass]">
		<p>
			Fundrise, LLC (&ldquo;Fundrise&ldquo;) operates a website at fundrise.com and certain mobile apps (the
			&ldquo;Platform&ldquo;). By using the Platform, you accept our
			<BaseLink
				classes="link text-color-current-color text-decoration-underline text-link--dark-mode"
				:link="termsOfServiceDocLink"
			/>
			and
			<BaseLink
				classes="link text-color-current-color text-decoration-underline text-link--dark-mode"
				:link="privacyPolicyDocLink"
			/>. All images and return and projection figures shown are for illustrative purposes only, may assume
			additional investments over time, and are not actual Fundrise customer or model returns or projections. Past
			performance is no guarantee of future results. Any historical returns, expected returns, or probability
			projections may not reflect actual future performance. All securities involve risk and may result in partial
			or total loss. While the data we use from third parties is believed to be reliable, we cannot ensure the
			accuracy or completeness of data provided by investors or other third parties. Neither Fundrise nor any of
			its affiliates provide tax advice and do not represent in any manner that the outcomes described herein will
			result in any particular tax consequence. Prospective investors should confer with their personal tax
			advisors regarding the tax consequences based on their particular circumstances. Neither Fundrise nor any of
			its affiliates assume responsibility for the tax consequences for any investor of any investment.
			<BaseLink
				:link="{ router: 'disclosures', target: '_blank' }"
				classes="text-color-current-color text-decoration-underline text-link--dark-mode"
			>
				Full Disclosure
			</BaseLink>
		</p>
		<p>
			The publicly filed offering circulars of the issuers sponsored by Rise Companies Corp., not all of which may
			be currently qualified by the Securities and Exchange Commission, may be found at
			<BaseLink
				classes="text-color-current-color text-decoration-underline text-link--dark-mode"
				:link="{ router: 'offering-circulars' }"
				>fundrise.com/oc</BaseLink
			>. For investors and potential investors who are residents of the State of Washington, please send all
			correspondence, including any questions or comments, to washingtonstate@fundrise.com.
		</p>
		<p>
			Fundrise takes any potential security issues seriously, and encourages the responsible and timely reporting
			of unknown security issues. Please send any discovered security concerns to
			<BaseLink
				:link="{ href: 'mailto:securitydisclosure@fundrise.com' }"
				classes="text-color-current-color text-decoration-underline text-link--dark-mode"
				>securitydisclosure@fundrise.com</BaseLink
			>
		</p>
		<p v-if="isVenture"
			>The Fundrise Innovation Fund is the marketing name for the underlying legal entity, Fundrise Growth Tech
			Fund, LLC.
		</p>
		<p>
			&copy; {{ currentYear }} Fundrise, LLC. All Rights Reserved. eREIT, eFund and eDirect are trademarks of Rise
			Companies Corp.
			<span v-if="footerFull"
				>Google Play and the Google Play logo are registered trademarks of Google LLC. Apple, the Apple logo and
				iPhone are registered trademarks of Apple Inc.&nbsp;NerdWallet and the NerdWallet logo are trademarks
				owned by NerdWallet, Inc. and used with permission. </span
			>Proudly designed and coded in Washington, D.C.
		</p>
	</div>
</template>

<script setup lang="ts">
import { privacyPolicyDocLink, termsOfServiceDocLink } from '@constants/links';
import { computed } from 'vue';

interface Props {
	footerFull?: boolean;
	isVenture?: boolean;
	textColorClass?: string;
}

withDefaults(defineProps<Props>(), {
	footerFull: false,
	isVenture: false,
	textColorClass: 'muted'
});

const currentYear = computed((): number => {
	const today = new Date();
	return today.getFullYear();
});
</script>
