<template>
	<component
		:is="wrapperComponent.component"
		v-bind="wrapperComponent.props"
		class="performance-context-card-wrapper"
		@mouseenter="onHoverEnter"
		@mouseleave="onHoverLeave"
	>
		<div class="performance-context-card p-100" :class="{ active: props.isActive }" data-test="performance-card">
			<component :is="cardComponent.component" :card-data="cardData" />
		</div>
	</component>
</template>
<script setup lang="ts">
import { Component as ComponentType, computed } from 'vue';
import { PerformanceContextCardData, PerformanceContextCardEventData } from 'types/performance-context';
import ArticleCardComponent from '@components/account/performance-context/article-card.vue';
import BaseLink from '@components/_base-link.vue';
import CategoryCardComponent from '@components/account/performance-context/category-card.vue';
import { DynamicComponent } from 'types/vue';
import PerformanceCardComponent from '@components/account/performance-context/performance-card.vue';

interface Props {
	card: PerformanceContextCardData;
	isActive?: boolean;
}

interface Emits {
	(e: 'hovered-card', value: string): void;
}

const props = withDefaults(defineProps<Props>(), {
	isActive: false
});

const emits = defineEmits<Emits>();

const cardData = computed((): PerformanceContextCardEventData => props.card.cardData);

const cardComponent = computed((): DynamicComponent => {
	switch (cardData.value.type) {
		case 'CATEGORY':
			return {
				component: CategoryCardComponent
			};
		case 'PERFORMANCE':
			return {
				component: PerformanceCardComponent
			};
		case 'ARTICLE':
		default:
			return {
				component: ArticleCardComponent
			};
	}
});

const wrapperComponent = computed((): DynamicComponent => {
	if (cardData.value?.cta && cardData.value.cta.targetUrl) {
		return {
			component: BaseLink,
			props: {
				link: {
					href: cardData.value.cta.targetUrl,
					target: cardData.value.cta.newTab ? '_blank' : ''
				},
				classes: 'display-block',
				mixpanelTarget: props.card.eventName
			}
		};
	}

	return {
		component: 'div' as unknown as ComponentType
	};
});

function onHoverEnter(): void {
	emits('hovered-card', props.card.eventName);
}

function onHoverLeave(): void {
	emits('hovered-card', '');
}
</script>

<style lang="scss">
@use '../../../styles/constants/_colors.scss' as *;

.performance-context-card {
	position: relative;
	border: 1px solid dark(10);
	border-radius: 8px;
	white-space: normal;
	height: 100%;

	> * {
		height: inherit;
	}

	&-wrapper {
		min-width: 296px;
		max-width: 296px;
	}

	&:hover,
	&.active {
		border: 1px solid transparent;
		box-shadow: 0 0 0 2px dark(100);
	}
}
</style>
