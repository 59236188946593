import { login } from '@store/modules/login';

const userHasTotpDisabled = (): boolean => {
	return login.twoFactorMetadata?.twoFactorType !== 'TOTP';
};

const codeDeliveryMethodSelected = (): boolean => {
	return login.selectedTwoFactorCodeDeliveryMethod !== 'NONE';
};

export const validateRouteAndNavigate = async (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	next: any,
	routeName: string
): Promise<void> => {
	switch (routeName) {
		case 'login-two-factor-select':
			if (!userHasTotpDisabled()) {
				next({ name: 'login-two-factor-confirm', replace: true });
			}
			break;
		case 'login-two-factor-confirm':
			if (!codeDeliveryMethodSelected()) {
				next({ name: 'login-two-factor-select' });
			}
			break;
		default:
			next({ name: 'home' });
			break;
	}
	await next();
};
