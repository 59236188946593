import {
	EntityAccountTypeListItem,
	IrrevocableTrustSubtypeListItem,
	JointAccountListItem,
	LLCTaxClassificationListItem,
	SingleMemberLLCTaxClassificationListItem,
	TinTypeListItem,
	TrustClassificationListItem,
	TrustTypeListItem
} from 'types/checkout';
import { FundObjective } from 'types/account';

export const accountFundObjective: Record<FundObjective, string> = {
	CASH_FLOW: 'Income',
	BALANCED: 'Balanced',
	APPRECIATION: 'Growth'
};

export const entityTypes: Array<EntityAccountTypeListItem> = [
	{
		label: 'LLC',
		value: 'LLC'
	},
	{
		label: 'C Corporation',
		value: 'C_CORP'
	},
	{
		label: 'S Corporation',
		value: 'S_CORP'
	},
	{
		label: 'Single-Member LLC',
		value: 'SM_LLC'
	},
	{
		label: 'Limited Partnership',
		value: 'LP'
	}
];

export const llcTaxClassification: Array<LLCTaxClassificationListItem> = [
	{
		label: 'Partnership',
		value: 'PARTNERSHIP'
	},
	{
		label: 'S Corporation',
		value: 'S_CORP'
	},
	{
		label: 'C Corporation',
		value: 'C_CORP'
	}
];

export const singleMemberLLCTaxClassification: Array<SingleMemberLLCTaxClassificationListItem> = [
	{
		label: 'Individual',
		value: 'INDIVIDUAL'
	},
	...llcTaxClassification
];

export const jointAccountTypes: Array<JointAccountListItem> = [
	{
		label: 'Rights of Survivorship',
		value: 'RIGHTS_OF_SURVIVORSHIP'
	},
	{
		label: 'Tenants in Common',
		value: 'TENANTS_IN_COMMON'
	},
	{
		label: 'Community Property',
		value: 'COMMUNITY_PROPERTY'
	}
];

export const tinTypeList: Array<TinTypeListItem> = [
	{
		label: 'EIN',
		value: 'EIN'
	},
	{
		label: 'SSN',
		value: 'SSN'
	}
];

export const trustClassificationList: Array<TrustClassificationListItem> = [
	{
		label: 'Grantor',
		value: 'GRANTOR'
	},
	{
		label: 'Non-grantor',
		value: 'NON_GRANTOR'
	}
];

export const trustSubtypeList: Array<IrrevocableTrustSubtypeListItem> = [
	{
		label: 'GRAT',
		value: 'GRAT'
	},
	{
		label: 'CRUT',
		value: 'CRUT'
	},
	{
		label: 'Family',
		value: 'FAMILY'
	},
	{
		label: 'Descendants',
		value: 'DESCENDANTS'
	},
	{
		label: 'Generational',
		value: 'GENERATIONAL'
	},
	{
		label: 'Remainder',
		value: 'REMAINDER'
	},
	{
		label: 'Other',
		value: 'OTHER'
	}
];

export const trustTypeList: Array<TrustTypeListItem> = [
	{
		label: 'Revocable',
		value: 'REVOCABLE'
	},
	{
		label: 'Irrevocable',
		value: 'IRREVOCABLE'
	},
	{
		label: 'Testamentary',
		value: 'TESTAMENTARY'
	}
];
