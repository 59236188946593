import {
	AccountValueChartModuleMetadata,
	ModularDashboardContainer,
	ModularDashboardMetadata
} from 'types/modular-dashboard';
import {
	AccountValueChartTimeHorizon,
	AccountValueChartTimeHorizonType,
	DividendsModuleData,
	NetReturnsOverTimeByTimeHorizon,
	NetReturnsOverTimeChartIntervalOption,
	NetReturnsOverTimeData,
	NewsFeedItem
} from 'types/account';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	dismissDashboardModule,
	getAccountChecklistModuleData,
	getDeltaModuleData,
	getFeaturedModuleData,
	getFundTeaserModuleData,
	getModularDashboardData,
	getModularDashboardMetadata,
	getPerformanceContextData,
	getPortfolioOverviewModuleData,
	getProgressModuleData
} from '@api/modular-dashboard';
import {
	getAccountValueChange,
	getAccountValueModuleChartData,
	getNetReturnsOverTimeData
} from '@api/performance-portfolio';
import { getDashboardNewsFeed, getDividendsModuleData, getPublicDashboardNewsFeed } from '@api/account';
import {
	ModularDashboardAccountChecklist,
	ModularDashboardFundTeaser,
	ModularDashboardPortfolioOverview,
	ModularDashboardProgressModule
} from 'types/modular-dashboard/custom';
import { AccountValueChange } from 'types/performance';
import { app } from './app';
import { FeaturedCard } from 'types/layout';
import { getModuleType } from '@utils/modular-dashboard';
import { investmentEntity } from './investment-entity';
import { investmentGoals } from './investment-goals';
import { ModularDashboardDeltaItem } from 'types/modular-dashboard/delta-module';
import { PerformanceContextCardData } from 'types/performance-context';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'modularDashboard',
	store
})
class ModularDashboardModule extends VuexModule {
	modularDashboardMetadata: ModularDashboardMetadata | null = null;
	containers: Array<ModularDashboardContainer> | null = null;

	accountChecklistModuleData: ModularDashboardAccountChecklist | null = null;
	deltaModuleData: Record<string, ModularDashboardDeltaItem> = {};
	progressModuleDetails: ModularDashboardProgressModule | null = null;
	portfolioOverviewModule: ModularDashboardPortfolioOverview | null = null;
	newsfeedModule: Array<NewsFeedItem> | null = null;
	accountValueChartDataByInterval: NetReturnsOverTimeData | null = null;
	accountValueChartDataByTimeHorizon: Partial<
		Record<AccountValueChartTimeHorizonType, NetReturnsOverTimeByTimeHorizon>
	> = {};
	accountValueChartInterval: NetReturnsOverTimeChartIntervalOption | null = null;
	accountValueChange: AccountValueChange | null = null;
	accountValueChartAvailableTimeHorizons: Array<AccountValueChartTimeHorizon> = [];
	accountValueChartActiveTimeHorizon: AccountValueChartTimeHorizon | null = null;
	accountValuePerformanceContext: {
		timeHorizon: string | null;
		activeEventIds: Array<string>;
		events: Record<string, Array<PerformanceContextCardData>>;
	} = {
		timeHorizon: null,
		activeEventIds: [],
		events: {}
	};

	dividendsModuleData: DividendsModuleData | null = null;

	fundTeaserModuleData: ModularDashboardFundTeaser | null = null;

	featuredModuleData: Record<string, FeaturedCard> = {};

	performanceContextEnabled = false;

	get isInPreSettlementState(): boolean {
		return this.modularDashboardMetadata?.settlementState === 'PRE_SETTLEMENT';
	}

	get accountStatusDescription(): string {
		return this.modularDashboardMetadata?.statusDescription ?? '';
	}

	@Action({ rawError: true })
	public async storeModularDashboardData(force = false): Promise<void> {
		if (this.modularDashboardMetadata === null || force) {
			if (force) {
				this.resetAll();
			}

			const modularDashboardMetadataData = await getModularDashboardMetadata();

			if (modularDashboardMetadataData) {
				this.UPDATE_MODULAR_DASHBOARD_METADATA(modularDashboardMetadataData);
			}

			const containerData = await getModularDashboardData();

			this.UPDATE_CONTAINERS(containerData.containers as Array<ModularDashboardContainer>);
		}
	}

	@Action({ rawError: true })
	public async storePerformanceContextEnabled(): Promise<void> {
		const isPerformanceContextEnabled = await app.isUserInLDTestGroup({
			featureFlag: '2024-07-03-performance-context',
			testGroup: 'test-with-performance-context'
		});

		this.UPDATE_PERFORMANCE_CONTEXT_ENABLED(isPerformanceContextEnabled);
	}

	@Action({ rawError: true })
	public async dismissDashboardModule(payload: {
		container: ModularDashboardContainer;
		module: string;
	}): Promise<void> {
		try {
			await dismissDashboardModule(payload.module);
		} catch {}

		const indexOfContainer = this.containers?.findIndex(
			(item) =>
				item.location.columnIndex === payload.container.location.columnIndex &&
				item.location.sortOrder === payload.container.location.sortOrder
		);

		const indexOfModuleToDismiss = payload.container.modules.findIndex((item) => item.name === payload.module);

		const filteredArr = this.containers?.slice();
		filteredArr?.[indexOfContainer as number]?.modules.splice(indexOfModuleToDismiss, 1);

		if (filteredArr) {
			this.UPDATE_CONTAINERS(filteredArr);
		}
	}

	@Action({ rawError: true })
	public async storeDeltaModuleData(moduleName: string, force = false): Promise<void> {
		if (!this.deltaModuleData[moduleName] || force) {
			const moduleData = await getDeltaModuleData(moduleName);

			this.UPDATE_DELTA_MODULE({ moduleName, data: moduleData });
		}
	}

	@Action({ rawError: true })
	public async storeFeaturedModuleData(moduleName: string, force = false): Promise<void> {
		if (!this.featuredModuleData[moduleName] || force) {
			const moduleData = await getFeaturedModuleData(moduleName);

			this.UPDATE_FEATURED_MODULE({ moduleName, data: moduleData });
		}
	}

	@Action({ rawError: true })
	public async storeAccountChecklistModuleData(force = false): Promise<void> {
		if (!this.accountChecklistModuleData || force) {
			const moduleData = await getAccountChecklistModuleData();

			this.UPDATE_ACCOUNT_CHECKLIST_MODULE_DATA(moduleData);
		}
	}

	@Action({ rawError: true })
	public async updateAccountValueChartByInterval(force = false): Promise<void> {
		if (!this.accountValueChartDataByTimeHorizon || force) {
			const data = await getNetReturnsOverTimeData();

			this.UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_INTERVAL(data);
		}
	}

	@Action({ rawError: true })
	public async updateAccountValueChart(force = false): Promise<void> {
		if (this.performanceContextEnabled) {
			this.updateAccountValueChartByTimeHorizon(force);
		} else {
			this.updateAccountValueChartByTimeInterval(force);
		}
	}

	@Action({ rawError: true })
	public async updateAccountValueChartByTimeHorizon(force = false): Promise<void> {
		if (!this.accountValueChartActiveTimeHorizon) {
			const module = this.containers
				?.find((container) => container.name === 'ACCOUNT_VALUE_CHART')
				?.modules.find((module) => module.name === 'ACCOUNT_VALUE_CHART');

			if (module?.metadata) {
				const moduleMetadata = module.metadata as AccountValueChartModuleMetadata;
				if (moduleMetadata.availableRanges) {
					this.UPDATE_ACCOUNT_VALUE_CHART_AVAILABLE_TIME_HORIZONS(
						moduleMetadata.availableRanges as Array<AccountValueChartTimeHorizon>
					);
				}

				if (moduleMetadata.defaultRange) {
					this.UPDATE_ACCOUNT_VALUE_CHART_ACTIVE_TIME_HORIZON(
						moduleMetadata.defaultRange as AccountValueChartTimeHorizon
					);
				}
			}
		}

		const activeRange = this.accountValueChartActiveTimeHorizon?.name;
		if (
			activeRange &&
			(!this.accountValueChartDataByTimeHorizon ||
				!(activeRange in this.accountValueChartDataByTimeHorizon) ||
				force)
		) {
			const data = await getAccountValueModuleChartData(activeRange);

			this.UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_TIME_HORIZON(data);
		}
	}

	@Action({ rawError: true })
	public async updateAccountValueChartByTimeInterval(force = false): Promise<void> {
		if (!this.accountValueChartDataByInterval || force) {
			const data = await getNetReturnsOverTimeData();

			this.UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_INTERVAL(data);
		}
	}

	@Action({ rawError: true })
	public updateAccountValueChartInterval(chartInterval: NetReturnsOverTimeChartIntervalOption | null): void {
		this.UPDATE_ACCOUNT_VALUE_CHART_INTERVAL(chartInterval);
	}

	@Action({ rawError: true })
	public async storeAccountValueChange(force = false): Promise<void> {
		if (!this.accountValueChange || force) {
			const accountValueChangeData = await getAccountValueChange();

			this.UPDATE_ACCOUNT_VALUE_CHANGE(accountValueChangeData);
		}
	}

	@Action({ rawError: true })
	public async storePerformanceContextEvents(): Promise<void> {
		const timeHorizon = this.accountValueChartActiveTimeHorizon?.name;
		if (timeHorizon) {
			let events = this.accountValuePerformanceContext.events[timeHorizon];
			if (!events) {
				events = await getPerformanceContextData(timeHorizon);
			}
			this.UPDATE_ACCOUNT_VALUE_PERFORMANCE_CONTEXT_EVENTS({
				timeHorizon,
				events
			});
		}
	}

	@Action({ rawError: true })
	public async storeAccountValueAndPerformanceContextData(): Promise<void> {
		await Promise.all([this.updateAccountValueChart(), this.storePerformanceContextEvents()]);
	}

	@Action({ rawError: true })
	public async updateAccountValueChartOnTimeHorizonSelection(
		timeHorizon: AccountValueChartTimeHorizon
	): Promise<void> {
		this.UPDATE_ACCOUNT_VALUE_CHART_ACTIVE_TIME_HORIZON(timeHorizon);
		await this.storeAccountValueAndPerformanceContextData();
	}

	@Action({ rawError: true })
	public async setAccountValueChartActivePerformanceContextEvents(eventIds: Array<string>): Promise<void> {
		if (eventIds.toString() !== this.accountValuePerformanceContext.activeEventIds.toString()) {
			this.UPDATE_ACCOUNT_VALUE_PERFORMANCE_CONTEXT_ACTIVE_EVENTS(eventIds);
		}
	}

	@Action({ rawError: true })
	public async storePortfolioOverviewModuleData(force = false): Promise<void> {
		if (!this.portfolioOverviewModule || force) {
			const data = await getPortfolioOverviewModuleData();

			this.UPDATE_PORTFOLIO_OVERVIEW_MODULE(data);
		}
	}

	@Action({ rawError: true })
	public async storeProgressModuleDetails(): Promise<void> {
		if (!this.progressModuleDetails) {
			const data = await getProgressModuleData();

			this.UPDATE_PROGRESS_MODULE_DETAILS(data);
		}
	}

	@Action({ rawError: true })
	public async storeDividendsModuleData(): Promise<void> {
		if (!this.dividendsModuleData) {
			const data = await getDividendsModuleData();

			this.UPDATE_DIVIDENDS_MODULE_DATA(data);
		}
	}

	@Action({ rawError: true })
	public async storeNewsfeedModuleData(): Promise<void> {
		if (this.newsfeedModule || app.isThirdPartyLogin) return;

		let data = null;
		if (investmentEntity.hasNeverBeenAnInvestor) {
			data = await getPublicDashboardNewsFeed();
		} else {
			const dashboardNewsfeed = await getDashboardNewsFeed();
			data = dashboardNewsfeed.data;
		}

		this.UPDATE_NEWSFEED_MODULE(data as Array<NewsFeedItem>);
	}

	@Action({ rawError: true })
	public async storeFundTeaserModuleData(force = false): Promise<void> {
		if (!this.fundTeaserModuleData || force) {
			const data = await getFundTeaserModuleData();

			this.UPDATE_FUND_TEASER_MODULE_DATA(data);
		}
	}

	@Action({ rawError: true })
	public async resetAll(): Promise<void> {
		this.UPDATE_MODULAR_DASHBOARD_METADATA(null);
		this.UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_INTERVAL(null);
		this.UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_TIME_HORIZON(null);
		this.UPDATE_ACCOUNT_VALUE_CHART_INTERVAL(null);
		this.UPDATE_ACCOUNT_CHECKLIST_MODULE_DATA(null);
		this.UPDATE_DELTA_MODULE_DATA({});
		this.UPDATE_FEATURED_MODULE_DATA({});
		this.UPDATE_PORTFOLIO_OVERVIEW_MODULE(null);
		this.UPDATE_PROGRESS_MODULE_DETAILS(null);
		this.UPDATE_DIVIDENDS_MODULE_DATA(null);
		this.UPDATE_NEWSFEED_MODULE(null);
		this.UPDATE_FUND_TEASER_MODULE_DATA(null);
		this.UPDATE_ACCOUNT_VALUE_CHART_AVAILABLE_TIME_HORIZONS([]);
		this.UPDATE_ACCOUNT_VALUE_CHART_ACTIVE_TIME_HORIZON(null);

		if (investmentEntity.investorGoalExists) {
			await investmentGoals.fetchAndStoreCurrentGoal();
		}
	}

	@Mutation
	public UPDATE_MODULAR_DASHBOARD_METADATA(modularDashboardMetadata: ModularDashboardMetadata | null): void {
		this.modularDashboardMetadata = modularDashboardMetadata;
	}

	@Mutation
	public UPDATE_CONTAINERS(containers: Array<ModularDashboardContainer> | null): void {
		this.containers =
			containers
				?.map((container) => ({
					...container,
					modules: container.modules.filter((module) => getModuleType(module))
				}))
				.filter((container) => container.modules.length > 0 || container.defaultState === 'PLACEHOLDER') ??
			null;
	}

	@Mutation
	public UPDATE_ACCOUNT_CHECKLIST_MODULE_DATA(
		accountChecklistModuleData: ModularDashboardAccountChecklist | null
	): void {
		this.accountChecklistModuleData = accountChecklistModuleData;
	}

	@Mutation
	public UPDATE_DELTA_MODULE_DATA(deltaModuleData: Record<string, ModularDashboardDeltaItem>): void {
		this.deltaModuleData = deltaModuleData;
	}

	@Mutation
	public UPDATE_DELTA_MODULE(moduleData: { moduleName: string; data: ModularDashboardDeltaItem }): void {
		this.deltaModuleData[moduleData.moduleName] = moduleData.data;
	}

	@Mutation
	public UPDATE_FEATURED_MODULE_DATA(featuredModuleData: Record<string, FeaturedCard>): void {
		this.featuredModuleData = featuredModuleData;
	}

	@Mutation
	public UPDATE_FEATURED_MODULE(moduleData: { moduleName: string; data: FeaturedCard }): void {
		this.featuredModuleData[moduleData.moduleName] = moduleData.data;
	}

	@Mutation
	public UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_INTERVAL(chartDataByInterval: NetReturnsOverTimeData | null): void {
		this.accountValueChartDataByInterval = chartDataByInterval;
	}

	@Mutation
	public UPDATE_ACCOUNT_VALUE_CHART_DATA_BY_TIME_HORIZON(chartData: NetReturnsOverTimeByTimeHorizon | null): void {
		if (chartData) {
			if (!this.accountValueChartDataByTimeHorizon) {
				this.accountValueChartDataByTimeHorizon = {};
			}

			const selectedRange = chartData.selectedRange;

			if (!this.accountValueChartDataByTimeHorizon[selectedRange]) {
				this.accountValueChartDataByTimeHorizon = {
					...this.accountValueChartDataByTimeHorizon,
					[selectedRange]: chartData
				};
			}
		}
	}

	@Mutation
	public UPDATE_ACCOUNT_VALUE_CHART_INTERVAL(interval: NetReturnsOverTimeChartIntervalOption | null): void {
		this.accountValueChartInterval = interval;
	}

	@Mutation
	public UPDATE_ACCOUNT_VALUE_CHANGE(accountValueChange: AccountValueChange | null): void {
		this.accountValueChange = accountValueChange;
	}

	@Mutation
	public UPDATE_ACCOUNT_VALUE_CHART_AVAILABLE_TIME_HORIZONS(
		accountValueChartAvailableTimeHorizons: Array<AccountValueChartTimeHorizon>
	): void {
		this.accountValueChartAvailableTimeHorizons = accountValueChartAvailableTimeHorizons;
	}

	@Mutation
	public UPDATE_ACCOUNT_VALUE_CHART_ACTIVE_TIME_HORIZON(
		accountValueChartActiveTimeHorizon: AccountValueChartTimeHorizon | null
	): void {
		this.accountValueChartActiveTimeHorizon = accountValueChartActiveTimeHorizon;
	}

	@Mutation UPDATE_ACCOUNT_VALUE_PERFORMANCE_CONTEXT_EVENTS(
		change: { timeHorizon: string; events: Array<PerformanceContextCardData> } | null
	): void {
		this.accountValuePerformanceContext.activeEventIds = [];
		if (!change) {
			this.accountValuePerformanceContext.events = {};
		} else {
			this.accountValuePerformanceContext.timeHorizon = change.timeHorizon;
			this.accountValuePerformanceContext.events[change.timeHorizon] = change.events;
		}
	}

	@Mutation UPDATE_ACCOUNT_VALUE_PERFORMANCE_CONTEXT_ACTIVE_EVENTS(eventIds: Array<string>): void {
		this.accountValuePerformanceContext.activeEventIds = [...eventIds];
	}

	@Mutation
	public UPDATE_PORTFOLIO_OVERVIEW_MODULE(
		portfolioOverviewModuleData: ModularDashboardPortfolioOverview | null
	): void {
		this.portfolioOverviewModule = portfolioOverviewModuleData;
	}

	@Mutation
	public UPDATE_PROGRESS_MODULE_DETAILS(progressModuleDetails: ModularDashboardProgressModule | null): void {
		this.progressModuleDetails = progressModuleDetails;
	}

	@Mutation
	public UPDATE_DIVIDENDS_MODULE_DATA(dividendsModuleData: DividendsModuleData | null): void {
		this.dividendsModuleData = dividendsModuleData;
	}

	@Mutation
	public UPDATE_NEWSFEED_MODULE(newsfeedModuleData: Array<NewsFeedItem> | null): void {
		this.newsfeedModule = newsfeedModuleData;
	}

	@Mutation
	public UPDATE_FUND_TEASER_MODULE_DATA(fundTeaserModuleData: ModularDashboardFundTeaser | null): void {
		this.fundTeaserModuleData = fundTeaserModuleData;
	}

	@Mutation
	public UPDATE_PERFORMANCE_CONTEXT_ENABLED(performanceContextEnabled: boolean): void {
		this.performanceContextEnabled = performanceContextEnabled;
	}
}

export const modularDashboard = getModule(ModularDashboardModule);
