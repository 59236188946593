import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	CustomAllocationOfferingResponse,
	CustomAllocationPlanRequest,
	CustomAllocationPlanResponse,
	CustomAllocationResponse,
	CustomAllocationType,
	CustomPlansRoute,
	CustomPlansSettingsRoute
} from 'types/custom-plans';
import {
	getCustomAllocationPlan,
	getCustomAllocationsOfferingsListForEntity,
	saveAllocationType,
	saveCustomAllocationPlan
} from '@api/custom-allocation-plan';
import { app } from './app';
import { investmentEntity } from './investment-entity';
import { K1Acknowledgement } from 'types/form';
import { mapCustomPlanAllocations } from '@utils/custom-plans';
import store from '..';
import { user } from './user';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'customPlans',
	store
})
class CustomPlansModule extends VuexModule {
	acknowledgedK1Disclosure: K1Acknowledgement = { acknowledged: false };
	hasAcknowledgedContingencyPlan = false;
	hasSubmittedPlan = false;
	isEditMode = false;
	isFlowStarted = false;

	customAllocationPlan: CustomAllocationPlanResponse | null = null;
	offeringsList: Array<CustomAllocationOfferingResponse> = [];

	customAllocationSelections: Array<CustomAllocationResponse> = [];
	customAllocationType: CustomAllocationType | null = null;

	get requiresContingencyAcknowledgement(): boolean {
		const containsFundRequiringAcknowledgement = this.customAllocationSelections
			.filter((selection) => selection.weight)
			.map((selection) => customPlans.offeringsList.find((offering) => offering.reitId === selection.reitId))
			.some((selection) => selection?.requiresContingencyPlan);
		return containsFundRequiringAcknowledgement && !this.hasAcknowledgedContingencyPlan;
	}

	get hasCustomAllocationSelections(): boolean {
		return this.customAllocationSelections.length > 0;
	}

	get saveCustomAllocationPlanPayload(): CustomAllocationPlanRequest {
		const allocations = this.customAllocationSelections
			.filter((c) => c.weight)
			.map((c) => ({
				reitId: c.reitId,
				weight: +c.weight
			}));

		return { allocations, allocationType: this.customAllocationType as CustomAllocationType };
	}

	@Action({ rawError: true })
	async initializeEditMode(): Promise<void> {
		if (!this.isEditMode || this.customAllocationSelections.length) return;

		if (!this.customAllocationPlan) {
			await this.fetchAndStoreCustomAllocationPlan();
		}

		await this.fetchAndStoreOfferings();

		const allocationType = this.customAllocationPlan?.allocationType as CustomAllocationType;

		this.UPDATE_CUSTOM_ALLOCATION_SELECTIONS(
			mapCustomPlanAllocations(this.customAllocationPlan?.allocations ?? [], this.offeringsList)
		);
		this.UPDATE_CUSTOM_ALLOCATION_TYPE(allocationType);
	}

	@Action({ rawError: true })
	async fetchAndStoreCustomAllocationPlan(force = false): Promise<void> {
		if (!this.customAllocationPlan || force) {
			const data = await getCustomAllocationPlan();
			const plan = { ...data, allocations: data.allocations.sort((a, b) => Number(b.weight) - Number(a.weight)) };
			this.UPDATE_CUSTOM_ALLOCATION_PLAN(plan);
		}
	}

	@Action({ rawError: true })
	async fetchAndStoreOfferings(): Promise<void> {
		if (!this.offeringsList.length) {
			const data = await getCustomAllocationsOfferingsListForEntity();
			this.UPDATE_OFFERINGS_LIST(data);
		}
	}

	@Action({ rawError: true })
	async storeCustomAllocationSelections(
		customAllocationSelections: Array<CustomAllocationResponse>
	): Promise<CustomPlansRoute> {
		this.UPDATE_CUSTOM_ALLOCATION_SELECTIONS(customAllocationSelections);
		this.UPDATE_HAS_ACKNOWLEDGED_CONTINGENCY_PLAN(false);

		return 'custom-plans-backup-plan';
	}

	@Action({ rawError: true })
	async acknowledgeContingencyPlan(): Promise<CustomPlansRoute> {
		this.UPDATE_HAS_ACKNOWLEDGED_CONTINGENCY_PLAN(true);
		return 'custom-plans-allocation-type';
	}

	@Action({ rawError: true })
	async storeCustomAllocationType(customAllocationType: CustomAllocationType): Promise<CustomPlansRoute> {
		this.UPDATE_CUSTOM_ALLOCATION_TYPE(customAllocationType);
		return 'custom-plans-review';
	}

	@Action({ rawError: true })
	async saveCustomAllocationPlan(): Promise<CustomPlansRoute | CustomPlansSettingsRoute> {
		const payload = this.saveCustomAllocationPlanPayload;

		const data = await saveCustomAllocationPlan(payload);
		this.UPDATE_CUSTOM_ALLOCATION_PLAN(data);

		this.UPDATE_HAS_SUBMITTED_PLAN(true);

		if (investmentEntity.currentRiaPlanInvestmentStrategy !== 'CUSTOM_PORTFOLIO') {
			await user.refreshUser();
		}

		if (this.isEditMode) {
			app.ADD_TOAST({
				type: 'success',
				message: 'Your custom plan has been saved.'
			});

			return 'settings-investment-plan-view';
		} else {
			return 'custom-plans-success';
		}
	}

	@Action({ rawError: true })
	async updateCustomAllocationType(allocationType: CustomAllocationType): Promise<void> {
		const newPlan = await saveAllocationType(allocationType);
		this.UPDATE_CUSTOM_ALLOCATION_PLAN(newPlan);
	}

	@Action({ rawError: true })
	async resetAll(): Promise<void> {
		this.UPDATE_OFFERINGS_LIST([]);
		this.UPDATE_CUSTOM_ALLOCATION_SELECTIONS([]);
		this.UPDATE_HAS_SUBMITTED_PLAN(false);
		this.UPDATE_HAS_ACKNOWLEDGED_CONTINGENCY_PLAN(false);
		this.UPDATE_IS_EDIT_MODE(false);
		this.UPDATE_IS_FLOW_STARTED(false);
		this.UPDATE_CUSTOM_ALLOCATION_PLAN(null);
		this.UPDATE_CUSTOM_ALLOCATION_TYPE(null);
	}

	@Mutation
	public UPDATE_ACKNOWLEDGED_K1_DISCLOSURE(acknowledgedK1Disclosure: K1Acknowledgement): void {
		this.acknowledgedK1Disclosure = acknowledgedK1Disclosure;
	}

	@Mutation
	public UPDATE_CUSTOM_ALLOCATION_PLAN(customAllocationPlan: CustomAllocationPlanResponse | null) {
		this.customAllocationPlan = customAllocationPlan;
	}

	@Mutation
	public UPDATE_OFFERINGS_LIST(offeringsList: Array<CustomAllocationOfferingResponse>) {
		this.offeringsList = offeringsList;
	}

	@Mutation
	public UPDATE_CUSTOM_ALLOCATION_SELECTIONS(customAllocationSelections: Array<CustomAllocationResponse>): void {
		this.customAllocationSelections = customAllocationSelections;
	}

	@Mutation
	public UPDATE_HAS_ACKNOWLEDGED_CONTINGENCY_PLAN(acknowledged: boolean): void {
		this.hasAcknowledgedContingencyPlan = acknowledged;
	}

	@Mutation
	public UPDATE_CUSTOM_ALLOCATION_TYPE(customAllocationType: CustomAllocationType | null) {
		this.customAllocationType = customAllocationType;
	}

	@Mutation
	public UPDATE_HAS_SUBMITTED_PLAN(hasSubmittedPlan: boolean): void {
		this.hasSubmittedPlan = hasSubmittedPlan;
	}

	@Mutation
	public UPDATE_IS_EDIT_MODE(isEditMode: boolean): void {
		this.isEditMode = isEditMode;
	}

	@Mutation
	public UPDATE_IS_FLOW_STARTED(isFlowStarted: boolean): void {
		this.isFlowStarted = isFlowStarted;
	}
}

export const customPlans = getModule(CustomPlansModule);
