<template>
	<div class="display-flex gap-50 gap-100-md width-100-smo">
		<BaseLink classes="button button-outline-reversed width-100 fund-teaser-invest-cta" :link="props.learnMoreLink">
			<span v-html="props.learnMoreLink.text" />
		</BaseLink>
		<DirectInvestCTA
			class="fund-teaser-invest-cta width-100"
			:direct-investment-eligibility="props.directInvestmentEligibility"
			:offering-vintage-id="props.vintageId"
			:custom-ctas="customCtas"
			classes="width-100 px-100-smo"
			theme="dark"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import DirectInvestCTA from '@components/offerings/direct-invest-cta.vue';
import { DirectInvestmentEligibility } from 'types/reit-details';
import { FundTeaserModuleCustomCta } from 'types/account';
import { isMobile } from '@utils/composables';
import { Link } from 'types/layout';

interface Props {
	learnMoreLink: Link;
	directInvestmentEligibility: DirectInvestmentEligibility;
	vintageId: string;
	isStaticLayout?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isStaticLayout: false
});

const customCtas = computed((): Array<FundTeaserModuleCustomCta> => {
	return isMobile.value || props.isStaticLayout
		? [
				{
					cta: { INELIGIBLE_REQUIRES_PRO: 'Invest' },
					ctaIcon: 'lock'
				},
				{
					cta: { INELIGIBLE_ACCREDITED_NOT_IN_COHORT: 'Contact us' }
				},
				{
					cta: { INELIGIBLE_IN_COHORT_CAP_EXCEEDED: 'Contact us' }
				}
			]
		: [];
});
</script>

<style lang="scss">
.fund-teaser-invest-cta:not(:first-child:last-child) {
	flex-basis: 50%;
}
</style>
