<template>
	<span :class="['badge badge-circle', themeClasses, typeSize]">
		<slot>{{ text }}</slot>
	</span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CounterBadgeTheme } from 'types/layout';
interface Props {
	text: string | number;
	theme?: CounterBadgeTheme;
	typeSize?: string;
}
const props = withDefaults(defineProps<Props>(), {
	theme: undefined,
	typeSize: 'body-sm'
});

const themeClasses = computed((): string => {
	switch (props.theme) {
		case 'greige':
			return 'text-color-dark-70 bg-color-greige-110';
		case 'rust':
			return 'bg-color-rust text-color-white';
		case 'slate':
		default:
			return 'bg-color-slate-90 ';
	}
});
</script>
