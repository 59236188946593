import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import {
	ReitElementApi,
	ReitElementCapitalStack,
	ReitsStrategyTotals,
	WeightedReitElementApi
} from 'types/reit-element';
import { app } from '@store/modules/app';
import axios from 'axios';
import { InvestorUpdateDetail } from 'types/account';
import ReitElement from '@classes/ReitElement';

export async function getAllReitElements(): Promise<Array<ReitElement>> {
	const response = await apiRequest.get(`/reit-elements`);
	return response.data.map((reitElementFromApi: ReitElementApi) => new ReitElement(reitElementFromApi));
}

export async function getReitElementsForEntity(): Promise<Array<ReitElement>> {
	const response = await investmentEntityRequest.get(`/portfolio/reit-elements`);
	return response.data.map(
		(weightedReitElement: WeightedReitElementApi) =>
			new ReitElement(weightedReitElement.reitElement, weightedReitElement.weightedAllocation)
	);
}

export async function getReitElementsForReit(reitId: string): Promise<Array<ReitElement>> {
	const response = await apiRequest.get(`/reits/${reitId}/reit-elements`);
	return response.data.map(
		(weightedReitElement: WeightedReitElementApi) =>
			new ReitElement(weightedReitElement.reitElement, weightedReitElement.weightedAllocation)
	);
}

export async function getReitElementsForPlan(planId: string): Promise<Array<ReitElement>> {
	const response = await apiRequest.get(`/ria/${planId}/reit-elements`);
	return response.data.map(
		(weightedReitElement: WeightedReitElementApi) =>
			new ReitElement(weightedReitElement.reitElement, weightedReitElement.weightedAllocation)
	);
}

export async function getReitElement(reitElementId: string): Promise<ReitElement> {
	const response = await apiRequest.get(`/reit-elements/${reitElementId}`);
	return response.data;
}

export async function getStrategyTotals(): Promise<ReitsStrategyTotals> {
	const response = await apiRequest.get(`/reit-elements/strategy-totals`);
	return response.data;
}

export async function getRealEstateAssetDetails(
	reitId: string,
	isAuthenticated: boolean
): Promise<{
	reitElement: ReitElement;
	investorUpdates: Array<InvestorUpdateDetail>;
	capitalStack: ReitElementCapitalStack | null;
}> {
	let allResponses;
	if (isAuthenticated) {
		allResponses = await axios.all([
			await apiSecureRequest.get(`/reit-elements/${reitId}`),
			await apiRequest.get(`/reit-elements/${reitId}/investor-updates`),
			await apiRequest.get(`/reit-elements/${reitId}/capital-stack`, {
				headers: { authorization: `Bearer ${app.oauthToken}` }
			})
		]);
		return {
			reitElement: allResponses[0].data,
			investorUpdates: allResponses[1].data,
			capitalStack: allResponses[2].data
		};
	} else {
		allResponses = await axios.all([
			await apiRequest.get(`/reit-elements/${reitId}`),
			await apiRequest.get(`/reit-elements/${reitId}/investor-updates`)
		]);
		return {
			reitElement: allResponses[0].data,
			investorUpdates: allResponses[1].data,
			capitalStack: null
		};
	}
}
