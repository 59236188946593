<template>
	<li
		:class="{
			'main-nav__sub-menu-item main-nav__sub-menu-item--entity display-block': isDesktop,
			'mobile-nav__item': !isDesktop
		}"
	>
		<BaseLinkButton
			:class="[
				'display-flex flex-direction-row align-items-center',
				{
					'main-nav__sub-menu-item-link px-0': isDesktop,
					'mobile-nav__item-link-normal-text pl-100 pt-50 pb-50': !isDesktop,
					'mobile-nav__sub-item-link--state-active pl-100 pt-50 pb-50': !isDesktop && isActiveEntity,
					'pointer-events-none no-hover': isActiveEntity
				}
			]"
			data-dd-action-name="switch entity"
			:data-test="`switch-entity-to-${entity.investmentEntityId}`"
			:click-text="'Switch Entity ' + entity.entityTypeLabel"
			@click="changeInvestmentEntity(entity.investmentEntityId)"
		>
			<span>
				<span v-if="isActiveEntity" class="user-select-circle bg-color-dark-100 display-flex">
					<span class="display-flex justify-content-center mt-25 text-color-white">
						<BaseSvgIcon name="checkmark" />
					</span>
				</span>
				<span v-else class="user-select-circle bg-color-dark-5" />
			</span>
			<span class="ml-100 ellipsis">
				<div class="epsilon ellipsis" :class="{ 'font-weight-bold': isActiveEntity }">
					{{ entity.investmentEntityName }}
				</div>

				<span class="display-block eta">
					{{ accountType }}
				</span>
			</span>
		</BaseLinkButton>
	</li>
</template>

<script setup lang="ts">
import { app } from '@store/modules/app';
import { computed } from 'vue';
import { InvestmentEntity } from 'types/investment-entity';
import { investmentEntity } from '@store/modules/investment-entity';
import { isDesktop } from '@utils/composables';
import { user } from '@store/modules/user';

interface Props {
	entity: InvestmentEntity;
}
const props = defineProps<Props>();

async function changeInvestmentEntity(investmentEntityId: string) {
	try {
		if (app.isCorniceSubstituteUser) {
			await user.updateSelectedEntityWithoutReload({
				investmentEntityId,
				nextRoute: { route: { name: 'profile-investment-accounts-view' } }
			});
		} else {
			await user.updateSelectedEntity(investmentEntityId);
		}
	} catch {
		app.ADD_TOAST({
			type: 'error',
			message: 'We are unable to switch your account at this time. Please try again later.'
		});
	}
}

const selectedInvestmentEntityId = computed((): string => {
	return investmentEntity.investmentEntityId;
});

const isActiveEntity = computed((): boolean => {
	return props.entity.investmentEntityId === selectedInvestmentEntityId.value;
});

const accountType = computed((): string => {
	if (props.entity.isIraEntity) {
		if (props.entity.iraEntityMetadata.iraTypeLabel) {
			return props.entity.iraEntityMetadata.iraTypeLabel + ' IRA';
		}
		return 'Undesignated IRA';
	}
	return props.entity.entityTypeLabel ?? '';
});
</script>

<style lang="scss" scoped>
@use '../../../styles/constants/colors' as *;

.user-select-circle {
	height: 24px;
	width: 24px;
	border-radius: 50%;
	display: inline-block;
}
</style>
