<template>
	<SvgIcon
		:data="iconData"
		:width="width"
		:height="height"
		:scale="scale"
		:dir="dir"
		:fill="fill"
		:name="name"
		:title="title"
	/>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const icons = require.context('@icons/svg', false, /\.svg$/);

interface Props {
	name: string;
	width?: string | number;
	height?: string | number;
	scale?: string | number;
	dir?: string;
	title?: string;
	fill?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	width: undefined,
	height: undefined,
	scale: undefined,
	dir: undefined,
	title: undefined,
	fill: true
});

const iconData = computed(() => {
	const svgPath = `./${props.name}.svg`;
	if (props.name && icons.keys().includes(svgPath)) {
		return icons(svgPath);
	}
	return '';
});
</script>
