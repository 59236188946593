import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AccreditationVerificationType } from 'types/accreditation';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'accreditation',
	store
})
class AccreditationModule extends VuexModule {
	verificationType: AccreditationVerificationType | null = null;

	@Action({ rawError: true })
	public getVerificationType(): AccreditationVerificationType | null {
		return this.verificationType;
	}

	@Mutation
	public UPDATE_VERIFICATION_TYPE(verificationType: AccreditationVerificationType): void {
		this.verificationType = verificationType;
	}
}

export const accreditation = getModule(AccreditationModule);
