import { app } from '@store/modules/app';
import { flattenQueryParam } from '@utils/request';
import { FundriseRouteConfig } from 'vue-router';
import { getVanityUrlMetadata } from '@api/vanity';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const modularLandingPageRoutes: FundriseRouteConfig[] = [
	{
		path: `/campaigns/income`,
		name: 'campaign-income-preset-plan',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "income-preset-plan" */ '@views/marketing-site/campaign/income-preset-plan.vue')
	},
	{
		path: `/campaigns/opportunistic-credit-fund`,
		name: 'campaign-ocf',
		alias: ['/campaigns/ocf'],
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(
				/* webpackChunkName: "opportunistic-credit-fund" */ '@views/marketing-site/opportunistic-credit-fund.vue'
			)
	},
	{
		path: '/campaigns/opportunistic-credit-fund/join-waitlist',
		name: 'campaign-ocf-join-waitlist',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(
				/* webpackChunkName: "opportunistic-credit-fund-join-waitlist" */ '@views/marketing-site/opportunistic-credit-fund-join-waitlist.vue'
			)
	},
	{
		path: `/campaigns/:campaignName`,
		name: 'campaign-partner-custom',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "modular-campaign-landing-page" */ '@views/marketing-site/modular-landing-page.vue'
			)
	},
	{
		path: '/starter-portfolio',
		name: 'starter-landing-page',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'starter-portfolio'
		},
		component: () =>
			import(/* webpackChunkName: "starter-landing-page" */ '@views/marketing-site/modular-landing-page.vue')
	},
	{
		path: `/acq-plus/start`,
		alias: [`/affiliates/start`],
		name: 'campaign-default',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'acq-plus-start',
			isAcqPlusStart: true
		},
		component: () =>
			import(
				/* webpackChunkName: "modular-acqplus-start-landing-page" */ '@views/marketing-site/modular-landing-page.vue'
			)
	},
	{
		path: `/affiliates/no-fees`,
		name: 'campaign-nofees',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'affiliates-no-fees',
			isAffiliateNoFees: true
		},
		component: () =>
			import(
				/* webpackChunkName: "modular-affiliates-no-fees-landing-page" */ '@views/marketing-site/modular-landing-page.vue'
			)
	},
	{
		path: `/referral-program/welcome`,
		name: 'referral-program-welcome-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: (to) => {
			const inviteKey = flattenQueryParam(to.query.referralKey);

			if (inviteKey) {
				return {
					name: 'referral-welcome',
					query: {
						inviteKey
					}
				};
			} else {
				app.ADD_TOAST({
					type: 'error',
					message: 'The invite is not valid'
				});
				return { name: 'home' };
			}
		}
	},
	{
		path: `/invitation-program/welcome`,
		name: 'referral-welcome',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'invitation-program-welcome',
			isInvitationProgram: true
		},
		component: () =>
			import(
				/* webpackChunkName: "modular-invitation-program-landing" */ '@views/marketing-site/modular-landing-page.vue'
			)
	},
	{
		path: `/partner`,
		name: 'campaign-partner-default',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'partner'
		},
		component: () =>
			import(/* webpackChunkName: "campaign-partner-default" */ '@views/marketing-site/campaign/partner-view.vue')
	},
	{
		path: '/partner/income',
		name: 'partner-income',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			isPartnerPage: true
		},
		component: () =>
			import(/* webpackChunkName: "income-preset-plan" */ '@views/marketing-site/campaign/income-preset-plan.vue')
	},
	{
		path: '/partner/venture',
		name: 'partner-venture',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			isPartnerPage: true
		},
		component: () =>
			import(/* webpackChunkName: "marketing-site-venture-capital" */ '@views/marketing-site/venture-capital.vue')
	},
	{
		path: `/partner-promo`,
		name: 'campaign-partner-promo',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'partner-promo'
		},
		component: () =>
			import(/* webpackChunkName: "campaign-partner-promo" */ '@views/marketing-site/campaign/partner-view.vue')
	},
	{
		path: `/advanced-investing`,
		name: 'high-end-offerings',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "premium-landing-page" */ '@views/marketing-site/premium-landing-page.vue')
	},
	{
		path: `/partner/:vanityUrlSlug`,
		alias: [`/campaign/:vanityUrlSlug`],
		name: 'vanity-url-default',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			try {
				const vanityUrlMetadata = await getVanityUrlMetadata(to.params.vanityUrlSlug as string);
				next(vanityUrlMetadata.redirectUrl);
			} catch (error) {
				next({ name: '404' });
			}
		},
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	},
	{
		path: `/sofi`,
		name: 'sofi-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: {
			name: 'campaign-partner-custom',
			params: {
				campaignName: 'sofi'
			}
		}
	},
	{
		path: '/advisor/invite-welcome',
		name: 'advisor-invite-welcome',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			campaignName: 'advisor-invite'
		},
		component: () =>
			import(/* webpackChunkName: "advisor-invite-welcome" */ '@views/marketing-site/modular-landing-page.vue')
	}
];

export default modularLandingPageRoutes;
