import { CustomSpacetimeFormat } from 'types/spacetime-formats';
import { DebtOffering } from 'types/debt-offerings';
import { investmentEntityRequest } from '@utils/request';
import spacetime from 'spacetime';

export async function getActiveDebtOfferings(): Promise<Array<DebtOffering>> {
	const response = await investmentEntityRequest.get(`/debt-offerings`);
	response.data.forEach((debtOffering: DebtOffering) => {
		debtOffering.maturityDate = spacetime(debtOffering.maturityDate).format(
			CustomSpacetimeFormat.MONTH_SHORT_DATE_YEAR
		);
		debtOffering.achTransactionDate = spacetime(debtOffering.achTransactionDate).format(
			CustomSpacetimeFormat.MONTH_SHORT_DATE_YEAR
		);
	});
	return response.data;
}
