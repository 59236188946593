import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	AssetClassV2,
	PerformanceEligibleQuarter,
	ReturnsFormat,
	TimeChipSelection,
	TimePeriodType
} from 'types/performance-portfolio';
import { AssetDetailListResponse, ReitAssetDetail } from 'types/asset-details';
import {
	getNetReturnsOverTimeData,
	getNetReturnsOverTimeDataByAssetClass,
	getNetReturnsOverTimeDataForIpo,
	getPortfolioAssetDetails
} from '@api/performance-portfolio';
import { app } from './app';
import { CompanyElement } from 'types/company-element';
import { NetReturnsOverTimeData } from 'types/account';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'performancePortfolio',
	store
})
class PerformancePortfolioModule extends VuexModule {
	endingAccountValue = '';
	cachedNetReturnsOverTime: Record<string, NetReturnsOverTimeData> = {};
	selectedAdvisorView: AssetClassV2 | 'ALL' = 'ALL';
	selectedTimePeriod: TimePeriodType = 'All';
	previousSelectedTimePeriod: TimePeriodType | null = null;
	selectedCustomYearAndQuarter: PerformanceEligibleQuarter | null = null;
	assetDetails: AssetDetailListResponse | null = null;
	returnsTableFormat: ReturnsFormat = '%';
	performanceReturnsValueFormat: ReturnsFormat = '%';

	filteredPropertyElements: Array<ReitAssetDetail> = [];
	filteredCompanyElements: Array<CompanyElement> = [];

	assetClassTabCache: Record<AssetClassV2, string | null> = {
		REAL_ESTATE: null,
		PRIVATE_CREDIT: null,
		VENTURE: null,
		OTHER: null
	};

	hideAnnualizedReturnFromNewInvestors: boolean | null = null;

	get showAllReturns(): boolean {
		return (
			this.selectedTimePeriod === 'All' ||
			(this.selectedTimePeriod === 'Custom' && this.previousSelectedTimePeriod === 'All')
		);
	}

	get showYtdReturns(): boolean {
		return (
			this.selectedTimePeriod === 'YTD' ||
			(this.selectedTimePeriod === 'Custom' && this.previousSelectedTimePeriod === 'YTD')
		);
	}

	get showCustomYearReturns(): boolean {
		return this.selectedTimePeriod === 'Custom Applied' && this.selectedCustomYearAndQuarter?.quarter === 'All';
	}

	get showQtdReturns(): boolean {
		return (
			this.selectedTimePeriod === 'QTD' ||
			(this.selectedTimePeriod === 'Custom' && this.previousSelectedTimePeriod === 'QTD')
		);
	}

	get showCustomQuarterReturns(): boolean {
		return this.selectedTimePeriod === 'Custom Applied' && this.selectedCustomYearAndQuarter?.quarter !== 'All';
	}

	@Action({ rawError: true })
	public async storeAssetDetails(): Promise<void> {
		const assetDetails = await getPortfolioAssetDetails();
		this.UPDATE_ASSET_DETAILS(assetDetails);
	}

	@Action({ rawError: true })
	public async getNetReturnsOverTimeData({
		assetClass,
		year,
		quarter
	}: {
		assetClass?: AssetClassV2 | 'FUNDRISE_IPO';
		year?: number;
		quarter?: number;
	} = {}): Promise<NetReturnsOverTimeData> {
		const key = [assetClass, year, quarter].map((slug) => slug ?? '').join(':');

		if (!this.cachedNetReturnsOverTime[key]) {
			if (assetClass === 'FUNDRISE_IPO') {
				const data = await getNetReturnsOverTimeDataForIpo();
				this.cachedNetReturnsOverTime[key] = data;
			} else if (assetClass) {
				const data = await getNetReturnsOverTimeDataByAssetClass(assetClass, year, quarter);
				this.cachedNetReturnsOverTime[key] = data;
			} else {
				const data = await getNetReturnsOverTimeData(year, quarter);
				this.cachedNetReturnsOverTime[key] = data;
			}
		}

		return this.cachedNetReturnsOverTime[key];
	}

	@Action({ rawError: true })
	public resetPerformanceStore(): void {
		this.UPDATE_ENDING_ACCOUNT_VALUE('');
		this.UPDATE_SELECTED_TIME_PERIOD('All');
		this.UPDATE_PREVIOUS_SELECTED_TIME_PERIOD(null);
		this.UPDATE_SELECTED_CUSTOM_YEAR_AND_QUARTER(null);
		this.UPDATE_ASSET_DETAILS(null);
		this.UPDATE_HIDE_ANNUALIZED_RETURN_FROM_NEW_INVESTORS(null);
		this.RESET_ASSET_CLASS_TAB_CACHE();

		for (const key in this.cachedNetReturnsOverTime) {
			delete this.cachedNetReturnsOverTime[key];
		}
	}

	@Action({ rawError: true })
	public setSelectedAdvisorView(selectedAdvisorView: AssetClassV2 | 'ALL'): void {
		this.UPDATE_SELECTED_ADVISOR_VIEW(selectedAdvisorView);
	}

	@Action({ rawError: true })
	public setTimeSelections(selection: TimeChipSelection): void {
		if (selection.timePeriod === 'Custom') {
			this.UPDATE_PREVIOUS_SELECTED_TIME_PERIOD(this.selectedTimePeriod);
			this.UPDATE_SELECTED_TIME_PERIOD(selection.timePeriod);
		} else if (selection.timePeriod === 'Custom Applied' && selection.yearAndQtr) {
			this.UPDATE_SELECTED_TIME_PERIOD(selection.timePeriod);
			this.UPDATE_SELECTED_CUSTOM_YEAR_AND_QUARTER(selection.yearAndQtr);
			this.UPDATE_PREVIOUS_SELECTED_TIME_PERIOD(null);
		} else {
			this.UPDATE_SELECTED_TIME_PERIOD(selection.timePeriod);
			this.UPDATE_PREVIOUS_SELECTED_TIME_PERIOD(null);
			this.UPDATE_SELECTED_CUSTOM_YEAR_AND_QUARTER(null);
		}
	}

	@Action({ rawError: true })
	public async storeHideAnnualizedReturnFromNewInvestors(): Promise<void> {
		if (this.hideAnnualizedReturnFromNewInvestors === null) {
			const isFeatureEnabled = await app.isFeatureEnabled('prod-2011-cumulative-net-returns');
			this.UPDATE_HIDE_ANNUALIZED_RETURN_FROM_NEW_INVESTORS(isFeatureEnabled);
		}
	}

	@Action({ rawError: true })
	public setReturnsTableFormat(format: ReturnsFormat): void {
		this.UPDATE_RETURNS_TABLE_FORMAT(format);
	}

	@Action({ rawError: true })
	public setPerformanceReturnsValueFormat(format: ReturnsFormat): void {
		this.UPDATE_PERFORMANCE_RETURNS_VALUE_FORMAT(format);
	}

	@Action({ rawError: true })
	public saveTabSelection(selection: { assetClass: AssetClassV2; tabKey: string }): void {
		this.UPDATE_ASSET_CLASS_TAB_CACHE(selection);
	}

	@Action({ rawError: true })
	public clearTabSelections(): void {
		this.RESET_ASSET_CLASS_TAB_CACHE();
	}

	@Mutation
	public UPDATE_SELECTED_TIME_PERIOD(selectedTimePeriod: TimePeriodType) {
		this.selectedTimePeriod = selectedTimePeriod;
	}

	@Mutation
	public UPDATE_SELECTED_CUSTOM_YEAR_AND_QUARTER(selectedCustomYearAndQuarter: PerformanceEligibleQuarter | null) {
		this.selectedCustomYearAndQuarter = selectedCustomYearAndQuarter;
	}

	@Mutation
	public UPDATE_PREVIOUS_SELECTED_TIME_PERIOD(previousSelectedTimePeriod: TimePeriodType | null) {
		this.previousSelectedTimePeriod = previousSelectedTimePeriod;
	}

	@Mutation
	public UPDATE_HIDE_ANNUALIZED_RETURN_FROM_NEW_INVESTORS(isFeatureEnabled: boolean | null) {
		this.hideAnnualizedReturnFromNewInvestors = isFeatureEnabled;
	}

	@Mutation
	public UPDATE_SELECTED_ADVISOR_VIEW(selectedAdvisorView: AssetClassV2 | 'ALL'): void {
		this.selectedAdvisorView = selectedAdvisorView;
	}

	@Mutation
	public UPDATE_ENDING_ACCOUNT_VALUE(endingAccountValue: string): void {
		this.endingAccountValue = endingAccountValue;
	}

	@Mutation
	public UPDATE_ASSET_DETAILS(assetDetails: AssetDetailListResponse | null): void {
		this.assetDetails = assetDetails;
	}

	@Mutation
	public UPDATE_RETURNS_TABLE_FORMAT(format: ReturnsFormat): void {
		this.returnsTableFormat = format;
	}

	@Mutation
	public UPDATE_PERFORMANCE_RETURNS_VALUE_FORMAT(format: ReturnsFormat): void {
		this.performanceReturnsValueFormat = format;
	}

	@Mutation
	public UPDATE_ASSET_CLASS_TAB_CACHE(selection: { assetClass: AssetClassV2; tabKey: string }): void {
		this.assetClassTabCache = { ...this.assetClassTabCache, [selection.assetClass]: selection.tabKey };
	}

	@Mutation
	public RESET_ASSET_CLASS_TAB_CACHE(): void {
		this.assetClassTabCache = {
			REAL_ESTATE: null,
			PRIVATE_CREDIT: null,
			VENTURE: null,
			OTHER: null
		};
	}
}

export const performancePortfolio = getModule(PerformancePortfolioModule);
