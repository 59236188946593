import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	AdvisorClientDetail,
	AdvisorInvestorRelationshipRow,
	AdvisorInviteRelationshipRequest,
	ClientAllocationsChartData,
	ClientAllocationsChartDrilldownData,
	ClientAllocationsTableData,
	InvestmentAdvisorDetails
} from 'types/advisor';
import { createAdvisorRelationshipRequest, getAllocationOverview, getInvestmentAdvisorDetails } from '@api/advisor';
import { currency, percentage } from '@filters/shared-filters';
import { formattedPerformanceValue, getReturnsColor } from '@utils/performance-portfolio';
import big from 'big.js';
import { ReturnsByAssetClass } from 'types/performance-portfolio';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'advisor',
	store
})
class AdvisorModule extends VuexModule {
	investmentAdvisorDetails: InvestmentAdvisorDetails | null = null;
	selectedInvestmentEntityId = '';
	clientAllocations: ReturnsByAssetClass | null = null;

	get clientAccountsCount(): string | null {
		return this.investmentAdvisorDetails?.managedEntityCount ?? null;
	}

	get totalAum(): string | null {
		return this.investmentAdvisorDetails?.totalManagedAum
			? formattedPerformanceValue(this.investmentAdvisorDetails.totalManagedAum, 'currency')
			: null;
	}

	get hasRelationships(): boolean {
		return !!this.investmentAdvisorDetails?.relationships.length;
	}

	get hasRelationshipRequests(): boolean {
		return !!this.investmentAdvisorDetails?.relationshipRequests.length;
	}

	get clientEntities(): AdvisorClientDetail[] {
		const userEntityList = advisor.investmentAdvisorDetails?.relationships.flatMap((relationship) => {
			return relationship.managedUser.managedEntities?.map((entity) => {
				return {
					firstName: relationship.managedUser.firstName,
					lastName: relationship.managedUser.lastName,
					investmentEntityId: entity.investmentEntityId,
					entity
				};
			});
		});

		return userEntityList ?? [];
	}

	get formattedRelationships(): AdvisorInvestorRelationshipRow[] {
		return (
			this.clientEntities.map((client) => {
				const balance = client.entity.totalAccountValue
					? formattedPerformanceValue(client.entity.totalAccountValue, 'currency')
					: '-';
				const ytdPerformance = client.entity.yearToDateReturns
					? formattedPerformanceValue(client.entity.yearToDateReturns, 'percentage', true, true)
					: '-';
				const ytdPerformanceClass = client.entity.yearToDateReturns
					? getReturnsColor(client.entity.yearToDateReturns)
					: '';
				const allTimePerformance = client.entity.allTimeReturns
					? formattedPerformanceValue(client.entity.allTimeReturns, 'percentage', true, true)
					: '-';
				const allTimePerformanceClass = client.entity.allTimeReturns
					? getReturnsColor(client.entity.allTimeReturns)
					: '';

				return {
					firstName: client.firstName,
					lastName: client.lastName,
					investmentEntityId: client.investmentEntityId,
					accountType: client.entity.entityTypeLabel ?? '-',
					balance,
					ytdPerformance,
					ytdPerformanceClass,
					allTimePerformance,
					allTimePerformanceClass
				};
			}) ?? []
		);
	}

	get allocationBreakdown(): ClientAllocationsChartData[] {
		if (!this.clientAllocations || this.clientAllocations.assetClasses.length === 0) {
			return [];
		}

		return this.clientAllocations.assetClasses
			.filter((assetClass) => big(assetClass.overview.allocation.allocationPercentage).gt(0))
			.map((assetClass): ClientAllocationsChartData => {
				const weight = percentage(assetClass.overview.allocation.allocationPercentage, false, 2, '0', true);
				return {
					name: assetClass.overview.assetClassLabel,
					custom: {
						amount: currency(assetClass.overview.allocation.allocationAmount)
					},
					y: Number(weight),
					drilldown: assetClass.overview.assetClass
				};
			});
	}

	get assetClassBreakdown(): ClientAllocationsChartDrilldownData[] {
		if (!this.clientAllocations || this.clientAllocations.assetClasses.length === 0) {
			return [];
		}

		return this.clientAllocations?.assetClasses.map((assetClass) => {
			return {
				id: assetClass.overview.assetClass,
				name: assetClass.overview.assetClassLabel,
				data: assetClass.offerings
					.filter((offering) => big(offering.overview.allocation.allocationPercentage).gt(0))
					.map((offering) => {
						const offeringWeight = percentage(
							offering.overview.allocation.allocationPercentage,
							false,
							2,
							'0',
							true
						);
						return {
							name: offering.overview.name,
							custom: { amount: currency(offering.overview.allocation.allocationAmount) },
							y: Number(offeringWeight)
						};
					})
			};
		});
	}

	get allocationBreakdownTableData(): ClientAllocationsTableData[] {
		if (!this.clientAllocations || this.clientAllocations.assetClasses.length === 0) {
			return [];
		}

		return this.clientAllocations.assetClasses
			.filter((assetClass) => big(assetClass.overview.allocation.allocationPercentage).gt(0))
			.map((assetClass) => {
				return {
					name: assetClass.overview.assetClassLabel,
					amount: currency(assetClass.overview.allocation.allocationAmount),
					percentage: percentage(assetClass.overview.allocation.allocationPercentage, true),
					funds: assetClass.offerings
						.filter((offering) => big(offering.overview.allocation.allocationPercentage).gt(0))
						.map((offering) => {
							return {
								name: offering.overview.name,
								amount: currency(offering.overview.allocation.allocationAmount),
								percentage: percentage(offering.overview.allocation.allocationPercentage, true)
							};
						})
				};
			});
	}

	@Action({ rawError: true })
	async getAdvisorData(forceRefresh = false): Promise<void> {
		if (!this.investmentAdvisorDetails || forceRefresh) {
			const investmentAdvisorDetails = await getInvestmentAdvisorDetails();
			this.UPDATE_INVESTMENT_ADVISOR_DETAILS(investmentAdvisorDetails);
		}
	}

	@Action({ rawError: true })
	async inviteClient(advisorInviteClientForm: AdvisorInviteRelationshipRequest): Promise<void> {
		await createAdvisorRelationshipRequest(advisorInviteClientForm);
		await this.getAdvisorData(true);
	}

	@Action({ rawError: true })
	reset(): void {
		this.UPDATE_SELECTED_INVESTMENT_ENTITY_ID('');
		this.UPDATE_INVESTMENT_ADVISOR_DETAILS(null);
	}

	@Action({ rawError: true })
	async getClientAllocations(): Promise<void> {
		const allocationResponse: ReturnsByAssetClass = await getAllocationOverview();

		this.UPDATE_CLIENT_ALLOCATIONS(allocationResponse);
	}

	@Mutation
	public UPDATE_CLIENT_ALLOCATIONS(allocations: ReturnsByAssetClass): void {
		this.clientAllocations = allocations;
	}

	@Mutation
	public UPDATE_SELECTED_INVESTMENT_ENTITY_ID(investmentEntityId: string): void {
		this.selectedInvestmentEntityId = investmentEntityId;
	}

	@Mutation
	public UPDATE_INVESTMENT_ADVISOR_DETAILS(investmentAdvisorDetails: InvestmentAdvisorDetails | null): void {
		this.investmentAdvisorDetails = investmentAdvisorDetails;
	}
}

export const advisor = getModule(AdvisorModule);
