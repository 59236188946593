import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { BankAccount, NewManualBankAccount } from 'types/payment';
import {
	checkRolloverCheckDate,
	checkRolloverSourceEligibilityAddFunds,
	checkSimpleIraFirstContributionDateAddFunds,
	checkSourceEligibilityAddFunds,
	completeIraRepeatInvestment,
	completeOnboarding,
	getContactDocuments,
	getEntryData,
	getIncompleteOrder,
	getIncompleteRollover,
	getIraBankAccounts,
	getIraFeesAndContributionLimits,
	getIraFundingType,
	getIraInfoForRepeatInvestment,
	getPendingRollover,
	getVerifyFlowInfo,
	resetIra,
	resubmitPendingRollover,
	sendMtcCode,
	submitAccountType,
	submitAcknowledgedFees,
	submitAcknowledgedRollover,
	submitAddFundsOrderGroup,
	submitAddFundsRollover,
	submitAmount,
	submitContactDocument,
	submitDripPreference,
	submitEmailRequest,
	submitFundingType,
	submitMtcRolloverSignatures,
	submitMtcSignatures,
	submitOnboardingOrderGroup,
	submitOnboardingRollover,
	submitPaymentMethod,
	submitPersonalInfo,
	submitPhoneVerification,
	submitPlan,
	submitRolloverAccountType,
	submitRolloverCheckDate,
	submitRolloverMethod,
	submitSelectedNumber,
	submitSimpleIraFirstContributionDate,
	submitSourceAccountType,
	submitTransferAccountInfo,
	submitTransferAmount,
	uploadVerificationManual
} from '@api/ira';
import {
	DtcParticipant,
	IraAccountType,
	IraAddFundsExistingOrderGroupParams,
	IraAddFundsNewContributionOrderGroupParams,
	IraAddFundsTransferOrderGroupParams,
	IraCompleteRolloverOrderGroupParams,
	IraData,
	IraDocumentSubmission,
	IraDocumentType,
	IraFeesAndContributionLimits,
	IraFlow,
	IraFundingType,
	IraOtpDeliveryType,
	IraPersonalInformation,
	IraRolloverTypeWithLabel,
	IraStateDeterminerStep,
	IraSubmitRolloverParams,
	IraTypeWithLabel,
	IraVerificationDocument,
	IraVerificationStatus,
	MillenniumVerificationType,
	RolloverMethod,
	RolloverType,
	SourceAccountTypeSubmission
} from 'types/ira';
import { placeFirstOrder, placeOrder } from '@api/checkout';
import { DripStatus } from 'types/investment-entity';
import { getReitOfferingVintage } from '@api/reit-offering-vintage';
import { getRepeatOfferingInvestmentData } from '@api/add-funds';
import { getRiaPlanById } from '@api/ria';
import { investmentEntity } from './investment-entity';
import { PhoneNumber } from 'types/user';
import { redirect } from '@utils/client';
import { ReitOfferingVintage } from 'types/reit-offering-vintage';
import { RiaPlan } from 'types/plan';
import store from '..';
import { user } from '@store/modules/user';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'ira',
	store
})
class IraModule extends VuexModule {
	stateDeterminerStep: IraStateDeterminerStep | null = null;
	dataRetrieved = false;
	personalInfo: IraPersonalInformation = {
		firstName: '',
		lastName: '',
		primaryAddress: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: ''
		},
		mailingAddress: null
	};
	currentPhone: PhoneNumber | null = null;
	fundingType: IraFundingType = 'CONTRIBUTION';
	accountType: IraAccountType = 'TRADITIONAL_IRA';
	accountTypeLabel = 'Traditional';
	dripPreference: boolean | null = null;
	plan: RiaPlan | null = null;
	reitOfferingVintage: ReitOfferingVintage | null = null;
	iraBankAccounts: Array<BankAccount> = [];
	selectedPaymentMethod: BankAccount | null = null;
	acknowledgedFees = false;
	orderGroupAcknowledged = false;
	selectedAmount = '';
	selectedTaxYear = '';
	editRedirect = '';
	reitInvestmentOrderGroupId = '';
	contactDocument: IraDocumentType | null = null;
	flow: IraFlow = 'SIGN-UP';
	isFunded = false;
	legacyIra = false;
	verificationStatus: IraVerificationStatus | null = null;
	mtcContactCreated = false;
	addFundsPlanSelectionRequired = false;
	addFundsdripPreferenceRequired = false;
	addFundsEdirect = false;

	// Transfers specific
	sourceAccountType: IraAccountType | null = null;
	sourceAccountTypeLabel = '';
	simpleIraFirstContributionDate = '';
	simpleIraFirstContributionDateValid: boolean | null = null;
	sourceCustodianName = '';
	sourceDtcParticipantNumber = '';
	sourceDtcParticipant: DtcParticipant | null = null;
	sourceAccountNumber = '';

	// Contribution error specific
	maxContributionLimit: string | null = null;

	// Rollover specific
	rolloverType: RolloverType | null = null;
	rolloverTypeLabel = '';
	rolloverMethod: RolloverMethod | null = null;
	rolloverCheckDate = '';
	rolloverCheckDateValid: boolean | null = null;
	rolloverPlanAlreadySet = false;
	rolloverDripAlreadySet = false;
	rolloverAgreementsSubmitted: boolean | null = null;
	initialRolloverAmount = '';
	rolloverInitiationDate = '';
	rolloverConfirmedThreshold: string | null = null;

	// General IRA fee and contribution limit data
	taxYear: string | null = null;
	contributionLimit: string | null = null;
	seniorContributionLimit: string | null = null;
	feeAmount: string | null = null;
	annualThresholdLimit: string | null = null;
	accountSizeThresholdLimit: string | null = null;

	@Action({ rawError: true })
	public async getEntryData(): Promise<void> {
		const entryData = await getEntryData();
		this.setEntryData(entryData);
	}

	@Action({ rawError: true })
	public async getIncompleteOrder(): Promise<void> {
		const incompleteOrder = await getIncompleteOrder();
		this.UPDATE_DATA_RETRIEVED(true);

		if (incompleteOrder.completeOnboardingOrder) {
			this.UPDATE_FLOW('COMPLETE-ORDER-SIGNUP');
		} else {
			this.UPDATE_FLOW('COMPLETE-ORDER-ADD-FUNDS');
		}
		if (incompleteOrder.reitInvestmentOrderGroupId) {
			this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(incompleteOrder.reitInvestmentOrderGroupId);
		}

		this.UPDATE_ORDER_GROUP_ACKNOWLEDGED(true);
	}

	@Action({ rawError: true })
	public async getIncompleteRollover(): Promise<void> {
		const incompleteData = await getIncompleteRollover();
		this.UPDATE_DATA_RETRIEVED(true);
		this.UPDATE_FLOW('COMPLETE-ROLLOVER');
		this.UPDATE_FUNDING_TYPE('ROLLOVER');
		this.UPDATE_INITIAL_ROLLOVER_AMOUNT(incompleteData.millenniumFundingDto.amount);
		this.UPDATE_DRIP_PREFERENCE_FROM_TYPE(
			incompleteData.iraEntityDto.reinvestmentPreferenceDto?.reinvestmentPreference ?? null
		);

		if (incompleteData.iraEntityDto.riaPlanDto) {
			this.UPDATE_PLAN(incompleteData.iraEntityDto.riaPlanDto);
			this.UPDATE_ROLLOVER_PLAN_ALREADY_SET(true);
		}
		if (this.dripPreference !== null) {
			this.UPDATE_ROLLOVER_DRIP_ALREADY_SET(true);
		}
		if (incompleteData.millenniumFundingDto.sourceAccountType) {
			this.UPDATE_SOURCE_ACCOUNT_TYPE(incompleteData.millenniumFundingDto.sourceAccountType);
		}
		if (incompleteData.iraEntityDto.accountType) {
			this.UPDATE_ACCOUNT_TYPE(incompleteData.iraEntityDto.accountType);
		}
		if (incompleteData.millenniumFundingDto.rolloverInitiationDate) {
			this.UPDATE_ROLLOVER_INITIATION_DATE(incompleteData.millenniumFundingDto.rolloverInitiationDate);
		}
		if (incompleteData.millenniumFundingDto.rolloverReitOfferingVintageDto) {
			this.UPDATE_ROLLOVER_PLAN_ALREADY_SET(true);
			this.UPDATE_ROLLOVER_DRIP_ALREADY_SET(true);
			this.UPDATE_ADD_FUNDS_EDIRECT(true);
			this.UPDATE_REIT_OFFERING_VINTAGE(incompleteData.millenniumFundingDto.rolloverReitOfferingVintageDto);
		}
		if (incompleteData.millenniumFundingDto.rolloverConfirmedThreshold) {
			this.UPDATE_ROLLOVER_CONFIRMED_THRESHOLD(incompleteData.millenniumFundingDto.rolloverConfirmedThreshold);
		}
	}

	@Action({ rawError: true })
	public async getPendingRollover(): Promise<void> {
		await getPendingRollover();
		this.UPDATE_DATA_RETRIEVED(true);
		this.UPDATE_FUNDING_TYPE('ROLLOVER');
		this.UPDATE_FLOW('REACKNOWLEDGE-ROLLOVER');

		this.UPDATE_ORDER_GROUP_ACKNOWLEDGED(true);
	}

	@Action({ rawError: true })
	public async getVerifyFlowInfo(): Promise<void> {
		const verifyFlowInfo = await getVerifyFlowInfo();
		this.UPDATE_DATA_RETRIEVED(true);

		if (verifyFlowInfo.verificationStatus) {
			this.UPDATE_VERIFICATION_STATUS(verifyFlowInfo.verificationStatus);
		}
	}

	@Action({ rawError: true })
	public async resetIra(): Promise<string> {
		const iraData = await resetIra();
		this.setEntryData(iraData);
		this.UPDATE_SELECTED_AMOUNT('');
		this.UPDATE_SELECTED_TAX_YEAR('');
		this.UPDATE_SOURCE_ACCOUNT_TYPE(null);
		this.UPDATE_PLAN(null);
		this.UPDATE_DRIP_PREFERENCE(null);
		return 'ira-funding';
	}

	@Action({ rawError: true })
	public async resetAddFunds(): Promise<string> {
		this.UPDATE_SELECTED_AMOUNT('');
		this.UPDATE_FUNDING_TYPE('CONTRIBUTION');
		this.UPDATE_SELECTED_PAYMENT_METHOD(null);
		this.UPDATE_SELECTED_TAX_YEAR('');
		this.UPDATE_SOURCE_ACCOUNT_NUMBER('');
		this.UPDATE_SOURCE_CUSTODIAN_NAME('');
		this.UPDATE_SOURCE_DTC_PARTICIPANT_NUMBER('');
		this.UPDATE_SOURCE_DTC_PARTICIPANT(null);
		this.UPDATE_SOURCE_ACCOUNT_TYPE(null);
		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID('');
		this.UPDATE_REIT_OFFERING_VINTAGE(null);
		this.UPDATE_ADD_FUNDS_EDIRECT(false);
		this.UPDATE_ORDER_GROUP_ACKNOWLEDGED(false);
		return 'ira-add-funds-funding';
	}

	@Action({ rawError: true })
	public async resetAll(): Promise<void> {
		this.UPDATE_SELECTED_AMOUNT('');
		this.UPDATE_FUNDING_TYPE('CONTRIBUTION');
		this.UPDATE_SELECTED_PAYMENT_METHOD(null);
		this.UPDATE_SELECTED_TAX_YEAR('');
		this.UPDATE_SOURCE_ACCOUNT_NUMBER('');
		this.UPDATE_SOURCE_CUSTODIAN_NAME('');
		this.UPDATE_SOURCE_DTC_PARTICIPANT_NUMBER('');
		this.UPDATE_SOURCE_DTC_PARTICIPANT(null);
		this.UPDATE_SOURCE_ACCOUNT_TYPE(null);
		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID('');
		this.UPDATE_REIT_OFFERING_VINTAGE(null);
		this.UPDATE_ADD_FUNDS_EDIRECT(false);
		this.UPDATE_ORDER_GROUP_ACKNOWLEDGED(false);
		this.UPDATE_PLAN(null);
		this.UPDATE_DRIP_PREFERENCE(null);
	}

	@Action({ rawError: true })
	public async resetFlow(): Promise<string> {
		let dest = '';
		if (this.flow === 'SIGN-UP') {
			dest = await this.resetIra();
		} else {
			dest = await this.resetAddFunds();
		}
		return dest;
	}

	@Action({ rawError: true })
	public setEntryData(entryData: IraData): void {
		this.UPDATE_DATA_RETRIEVED(true);
		this.UPDATE_STATE_DETERMINER_STEP(entryData.onboardingStatus);
		this.UPDATE_PERSONAL_INFO(entryData.personalInfo);
		this.UPDATE_ORDER_GROUP_ACKNOWLEDGED(entryData.iraOnboardingMetadata.orderGroupAcknowledged);
		this.UPDATE_FEES_ACKNOWLEDGED(entryData.iraOnboardingMetadata.feesAcknowledged);
		this.UPDATE_MTC_CONTACT_CREATED(entryData.iraEntityInfo.mtcContactCreated);

		if (entryData.iraOnboardingMetadata.rolloverAgreementsSubmitted) {
			this.UPDATE_ROLLOVER_AGREEMENTS_SUBMITTED(entryData.iraOnboardingMetadata.rolloverAgreementsSubmitted);
		}

		if (entryData.iraOnboardingMetadata.selectedAmount) {
			this.UPDATE_SELECTED_AMOUNT(entryData.iraOnboardingMetadata.selectedAmount);
		}
		if (entryData.iraOnboardingMetadata.selectedTaxYear) {
			this.UPDATE_SELECTED_TAX_YEAR(entryData.iraOnboardingMetadata.selectedTaxYear);
		}
		if (entryData.iraOnboardingMetadata.fundingType) {
			this.UPDATE_FUNDING_TYPE(entryData.iraOnboardingMetadata.fundingType);
		}
		if (entryData.iraEntityInfo.accountType) {
			this.UPDATE_ACCOUNT_TYPE(entryData.iraEntityInfo.accountType);
		}
		if (entryData.iraEntityInfo.verificationStatus) {
			this.UPDATE_VERIFICATION_STATUS(entryData.iraEntityInfo.verificationStatus);
		}
		if (entryData.iraOnboardingMetadata.selectedPhoneNumberDto) {
			this.UPDATE_CURRENT_PHONE(entryData.iraOnboardingMetadata.selectedPhoneNumberDto);
		}
		if (entryData.iraOnboardingMetadata.dripEnabled !== undefined) {
			this.UPDATE_DRIP_PREFERENCE(entryData.iraOnboardingMetadata.dripEnabled);
		}
		if (entryData.iraOnboardingMetadata.riaPlanDto) {
			this.UPDATE_PLAN(entryData.iraOnboardingMetadata.riaPlanDto);
		}
		if (entryData.iraOnboardingMetadata.selectedPaymentMethodOptionDto !== undefined) {
			this.UPDATE_SELECTED_PAYMENT_METHOD(entryData.iraOnboardingMetadata.selectedPaymentMethodOptionDto);
		}
		if (entryData.iraOnboardingMetadata.reitInvestmentOrderGroupId) {
			this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(entryData.iraOnboardingMetadata.reitInvestmentOrderGroupId);
		}
		if (entryData.iraOnboardingMetadata.sourceAccountType) {
			this.UPDATE_SOURCE_ACCOUNT_TYPE(entryData.iraOnboardingMetadata.sourceAccountType);
		}
		if (entryData.iraOnboardingMetadata.rolloverType) {
			this.UPDATE_ROLLOVER_TYPE(entryData.iraOnboardingMetadata.rolloverType);
		}
		if (entryData.iraOnboardingMetadata.rolloverMethod) {
			this.UPDATE_ROLLOVER_METHOD(entryData.iraOnboardingMetadata.rolloverMethod);
		}
		if (entryData.iraOnboardingMetadata.indirectRolloverChequeDate) {
			this.UPDATE_ROLLOVER_CHECK_DATE(entryData.iraOnboardingMetadata.indirectRolloverChequeDate);
		}
		if (entryData.iraOnboardingMetadata.indirectRolloverChequeDateValid !== undefined) {
			this.UPDATE_ROLLOVER_CHECK_DATE_VALID(entryData.iraOnboardingMetadata.indirectRolloverChequeDateValid);
		}
		if (entryData.iraOnboardingMetadata.simpleIraFirstContributionDate) {
			this.UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE(
				entryData.iraOnboardingMetadata.simpleIraFirstContributionDate
			);
		}
		if (entryData.iraOnboardingMetadata.simpleIraFirstContributionDateValid !== undefined) {
			this.UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE_VALID(
				entryData.iraOnboardingMetadata.simpleIraFirstContributionDateValid
			);
		}
		if (entryData.iraOnboardingMetadata.sourceCustodianName) {
			this.UPDATE_SOURCE_CUSTODIAN_NAME(entryData.iraOnboardingMetadata.sourceCustodianName);
		}
		if (entryData.iraOnboardingMetadata.sourceDtcParticipantNumber) {
			this.UPDATE_SOURCE_DTC_PARTICIPANT_NUMBER(entryData.iraOnboardingMetadata.sourceDtcParticipantNumber);
		}
		if (entryData.iraOnboardingMetadata.sourceAccountNumber) {
			this.UPDATE_SOURCE_ACCOUNT_NUMBER(entryData.iraOnboardingMetadata.sourceAccountNumber);
		}
		if (entryData.iraOnboardingMetadata.sourceDtcParticipant) {
			this.UPDATE_SOURCE_DTC_PARTICIPANT(entryData.iraOnboardingMetadata.sourceDtcParticipant);
		}
	}

	@Action({ rawError: true })
	async getIraInfoForRepeatInvestment(reitOfferingVintageId: string | null): Promise<void> {
		const iraEntityInfo = await getIraInfoForRepeatInvestment();
		this.UPDATE_DATA_RETRIEVED(true);
		this.UPDATE_IRA_ENTITY_FUNDED_STATUS(iraEntityInfo.funded);
		this.UPDATE_LEGACY_IRA(iraEntityInfo.legacyIra);
		this.UPDATE_MTC_CONTACT_CREATED(iraEntityInfo.mtcContactCreated);
		this.UPDATE_DRIP_PREFERENCE_FROM_TYPE(iraEntityInfo.reinvestmentPreferenceDto?.reinvestmentPreference ?? null);

		if (iraEntityInfo.riaPlanDto) {
			this.UPDATE_PLAN(iraEntityInfo.riaPlanDto);
		} else {
			this.UPDATE_ADD_FUNDS_PLAN_SELECTION_REQUIRED(true);
		}
		if (iraEntityInfo.accountType) {
			this.UPDATE_ACCOUNT_TYPE(iraEntityInfo.accountType);
		}
		if (this.dripPreference === null) {
			this.UPDATE_ADD_FUNDS_DRIP_PREFERENCE_REQUIRED(true);
		}

		if (reitOfferingVintageId !== null && reitOfferingVintageId !== undefined) {
			const reitOfferingVintage = await getReitOfferingVintage(reitOfferingVintageId);
			this.UPDATE_REIT_OFFERING_VINTAGE(reitOfferingVintage);
			this.UPDATE_ADD_FUNDS_PLAN_SELECTION_REQUIRED(false);
			this.UPDATE_ADD_FUNDS_DRIP_PREFERENCE_REQUIRED(false);
			this.UPDATE_ADD_FUNDS_EDIRECT(true);

			const repeatOfferingInvestmentMetadata = await getRepeatOfferingInvestmentData(reitOfferingVintageId);
			if (!repeatOfferingInvestmentMetadata.eligibleToInvestInOffering) {
				throw new Error('EDIRECT_INELIGIBLE');
			}
		}
	}

	@Action({ rawError: true })
	async getBankAccounts(): Promise<Array<BankAccount>> {
		const bankAccounts = await getIraBankAccounts();
		this.UPDATE_IRA_BANK_ACCOUNTS(bankAccounts);
		return this.iraBankAccounts;
	}

	@Action({ rawError: true })
	public async getContactDocuments(): Promise<IraDocumentType> {
		if (!this.contactDocument) {
			const contactDoc = await getContactDocuments();
			this.UPDATE_CONTACT_DOCUMENT(contactDoc);
			return contactDoc;
		}

		return this.contactDocument;
	}

	@Action({ rawError: true })
	public async storeIraFundingType(): Promise<void> {
		const fundingType = await getIraFundingType();
		if (fundingType) {
			this.UPDATE_FUNDING_TYPE(fundingType);
		}
	}

	@Action({ rawError: true })
	public async submitPersonalInfo(): Promise<void> {
		await submitPersonalInfo(this.personalInfo);
	}

	@Action({ rawError: true })
	public async submitSelectedNumber(newNumber: string | PhoneNumber): Promise<PhoneNumber> {
		const submissionObject =
			typeof newNumber === 'string' ? { newPhoneNumber: newNumber } : { phoneNumberId: newNumber.id };
		const phoneNumber = await submitSelectedNumber(submissionObject);
		this.UPDATE_CURRENT_PHONE(phoneNumber);
		user.ADD_PHONE_NUMBER(phoneNumber);
		return phoneNumber;
	}

	@Action({ rawError: true })
	public async submitPlan(planId: string): Promise<string> {
		let dest = '';
		if (this.flow === 'SIGN-UP') {
			const plan = await submitPlan(planId);
			this.UPDATE_PLAN(plan);
			dest = this.fundingType === 'CONTRIBUTION' ? 'ira-funding-source' : 'ira-transfer-fees';
		} else if (this.flow === 'COMPLETE-ROLLOVER') {
			const plan = await getRiaPlanById(planId);
			this.UPDATE_PLAN(plan);
			dest = this.rolloverDripAlreadySet ? 'ira-complete-rollover-confirm' : 'ira-complete-rollover-drip';
		} else {
			const plan = await getRiaPlanById(planId);
			this.UPDATE_PLAN(plan);
			if (this.addFundsdripPreferenceRequired) {
				dest = 'ira-add-funds-drip';
			} else {
				dest = this.fundingType === 'CONTRIBUTION' ? 'ira-add-funds-funding-source' : 'ira-add-funds-confirm';
			}
		}

		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitConfirmation(documentId: string): Promise<string> {
		let dest = this.flow === 'SIGN-UP' ? 'ira-agreements' : 'ira-add-funds-agreements';
		if ((this.flow === 'SIGN-UP' || this.legacyIra) && !this.mtcContactCreated) {
			await submitContactDocument(documentId);
			this.UPDATE_MTC_CONTACT_CREATED(true);
		}

		if (this.fundingType === 'ROLLOVER') {
			await submitAcknowledgedRollover(true);
		}

		if (this.fundingType !== 'ROLLOVER') {
			await this.submitOrderGroup();
		}

		if (this.fundingType === 'ROLLOVER' && this.flow === 'ADD-FUNDS' && !this.legacyIra) {
			await this.submitRollover();
			dest = 'ira-add-funds-success';
		}

		return dest;
	}

	@Action({ rawError: true })
	public async submitCompleteRolloverConfirm(): Promise<string> {
		const params: IraCompleteRolloverOrderGroupParams = {
			amount: this.selectedAmount,
			fundingType: this.fundingType
		};

		if (this.reitOfferingVintage) {
			params.reitOfferingVintageId = this.reitOfferingVintage.reitOfferingVintageId;
		} else if (this.plan) {
			params.riaPlanId = this.plan.id;
		}

		const reitInvestmentOrderGroupId = await submitAddFundsOrderGroup(params);

		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(reitInvestmentOrderGroupId);
		return 'ira-complete-rollover-agreements';
	}

	@Action({ rawError: true })
	public async submitFundingType(fundingType: IraFundingType): Promise<string> {
		let dest = '';

		if (this.flow === 'SIGN-UP') {
			if (this.fundingType !== fundingType) {
				await this.resetIra();
			}
			await submitFundingType(fundingType);
			dest =
				fundingType === 'TRANSFER' || fundingType === 'ROLLOVER'
					? 'ira-important-information'
					: 'ira-account-type';
		} else {
			switch (fundingType) {
				case 'EXISTING':
					dest = 'ira-add-funds-transfer-amount';
					break;
				case 'TRANSFER':
					dest = 'ira-add-funds-important-information';
					break;
				case 'ROLLOVER':
					this.UPDATE_ADD_FUNDS_DRIP_PREFERENCE_REQUIRED(false);
					this.UPDATE_ADD_FUNDS_PLAN_SELECTION_REQUIRED(false);
					dest = 'ira-add-funds-important-information';
					break;
				default:
					dest = 'ira-add-funds-amount';
					break;
			}
		}

		this.UPDATE_FUNDING_TYPE(fundingType);
		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async placeOrder(agreementInfo: {
		fundriseDocumentIds: Array<string>;
		iraDocumentsToSubmit: Array<IraDocumentSubmission>;
	}): Promise<void> {
		let reinvestmentPreferenceEnum: DripStatus | null = null;

		if (!this.addFundsEdirect) {
			reinvestmentPreferenceEnum = this.dripPreference ? 'RIA_REINVESTMENT' : 'CASH_DISTRIBUTION';
		}

		if (this.flow === 'SIGN-UP') {
			await placeFirstOrder(agreementInfo.fundriseDocumentIds);
		} else {
			await placeOrder(
				this.reitInvestmentOrderGroupId,
				agreementInfo.fundriseDocumentIds,
				reinvestmentPreferenceEnum
			);
		}

		this.UPDATE_ORDER_GROUP_ACKNOWLEDGED(true);
	}

	@Action({ rawError: true })
	public async submitRolloverAgreements(agreementInfo: {
		fundriseDocumentIds: Array<string>;
		iraDocumentsToSubmit: Array<IraDocumentSubmission>;
	}): Promise<string> {
		if (this.flow === 'COMPLETE-ROLLOVER') {
			await submitMtcSignatures(this.reitInvestmentOrderGroupId, agreementInfo.iraDocumentsToSubmit);
			await this.placeOrder(agreementInfo);
			await completeIraRepeatInvestment(this.reitInvestmentOrderGroupId);
		} else {
			await submitMtcRolloverSignatures(agreementInfo.iraDocumentsToSubmit);
			this.UPDATE_ROLLOVER_AGREEMENTS_SUBMITTED(true);
		}

		if (
			(this.flow === 'ADD-FUNDS' && this.legacyIra) ||
			(this.flow === 'SIGN-UP' && this.verificationStatus === 'VERIFIED')
		) {
			await this.submitRollover();
		} else if (this.flow === 'REACKNOWLEDGE-ROLLOVER') {
			await resubmitPendingRollover();
		}

		let dest: string;
		switch (this.flow) {
			case 'ADD-FUNDS':
				dest = 'ira-add-funds-success';
				break;
			case 'COMPLETE-ROLLOVER':
				dest = 'ira-complete-rollover-success';
				break;
			case 'REACKNOWLEDGE-ROLLOVER':
				dest = 'ira-reacknowledge-rollover-success';
				break;
			default:
				if (this.verificationStatus === 'VERIFIED') {
					dest = 'ira-success';
				} else {
					dest = 'ira-otp-type';
				}
		}

		return dest;
	}

	@Action({ rawError: true })
	public async submitAgreements(agreementInfo: {
		fundriseDocumentIds: Array<string>;
		iraDocumentsToSubmit: Array<IraDocumentSubmission>;
	}): Promise<string> {
		if (this.fundingType === 'ROLLOVER') {
			return await this.submitRolloverAgreements(agreementInfo);
		}

		await submitMtcSignatures(this.reitInvestmentOrderGroupId, agreementInfo.iraDocumentsToSubmit);

		const flowsToPlaceOrder = this.flow === 'SIGN-UP' || this.flow === 'ADD-FUNDS';

		if (!this.orderGroupAcknowledged && flowsToPlaceOrder) {
			await this.placeOrder(agreementInfo);
		}

		if (this.flow === 'ADD-FUNDS' || this.flow === 'COMPLETE-ORDER-ADD-FUNDS') {
			await completeIraRepeatInvestment(this.reitInvestmentOrderGroupId);
		} else if (
			this.flow === 'COMPLETE-ORDER-SIGNUP' ||
			(this.flow === 'SIGN-UP' && this.verificationStatus === 'VERIFIED')
		) {
			await completeOnboarding();
		}

		let dest: string;
		switch (this.flow) {
			case 'ADD-FUNDS':
				dest = 'ira-add-funds-success';
				break;
			case 'COMPLETE-ORDER-ADD-FUNDS':
			case 'COMPLETE-ORDER-SIGNUP':
				dest = 'ira-complete-order-success';
				break;
			default:
				if (this.verificationStatus === 'VERIFIED') {
					dest = 'ira-success';
				} else {
					dest = 'ira-otp-type';
				}
		}
		return dest;
	}

	@Action({ rawError: true })
	public getAddFundsAmountSubmitNextPage(): string {
		if (this.addFundsPlanSelectionRequired) {
			return 'ira-add-funds-plan';
		} else if (this.addFundsdripPreferenceRequired) {
			return 'ira-add-funds-drip';
		}

		if (this.fundingType === 'CONTRIBUTION') {
			return 'ira-add-funds-funding-source';
		}

		return 'ira-add-funds-confirm';
	}

	@Action({ rawError: true })
	public async submitAmountAndTaxYear(payload: Record<'amount' | 'taxYear', string>): Promise<string> {
		let dest = this.getAddFundsAmountSubmitNextPage();

		if (this.flow === 'SIGN-UP') {
			await submitAmount(payload.amount, payload.taxYear);

			if (ira.plan) {
				dest = await this.submitPlan(ira.plan.id);
			} else {
				dest = 'ira-plan';
			}
		}

		this.UPDATE_SELECTED_AMOUNT(payload.amount);
		this.UPDATE_SELECTED_TAX_YEAR(payload.taxYear);
		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitTransferAmount(amount: string): Promise<string> {
		let dest = this.getAddFundsAmountSubmitNextPage();

		if (this.flow === 'SIGN-UP') {
			await submitTransferAmount(amount);

			if (this.fundingType === 'ROLLOVER') {
				dest = 'ira-transfer-fees';
			} else if (ira.plan) {
				dest = await this.submitPlan(ira.plan.id);
			} else {
				dest = 'ira-plan';
			}
		} else if (this.flow === 'COMPLETE-ROLLOVER') {
			if (this.rolloverPlanAlreadySet && this.rolloverDripAlreadySet) {
				dest = 'ira-complete-rollover-confirm';
			} else if (this.rolloverPlanAlreadySet) {
				dest = 'ira-complete-rollover-drip';
			} else {
				dest = 'ira-complete-rollover-plan';
			}
		}
		this.UPDATE_SELECTED_AMOUNT(amount);
		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitTransferFees(bankAccount: BankAccount): Promise<string> {
		const dest = this.fundingType === 'ROLLOVER' ? 'ira-confirm' : 'ira-drip';

		await this.submitPaymentMethod(bankAccount);
		await this.submitAcknowledgedFees(true);

		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitAccountType(accountType: IraAccountType): Promise<string> {
		let dest = '';
		const apiType = await submitAccountType(accountType);
		this.UPDATE_ACCOUNT_TYPE(apiType);
		switch (this.fundingType) {
			case 'TRANSFER':
				dest = 'ira-transfer-account-info';
				break;
			case 'ROLLOVER':
				dest = 'ira-rollover-method';
				break;
			default:
				dest = 'ira-amount';
		}
		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitBankAccount(bankAccountInfo: NewManualBankAccount): Promise<string> {
		await investmentEntity.submitManualBankAccount(bankAccountInfo);

		let dest = '';
		if (this.flow === 'SIGN-UP') {
			dest = this.fundingType === 'CONTRIBUTION' ? 'ira-funding-source' : 'ira-transfer-fees';
		} else if (this.flow === 'ADD-FUNDS') {
			dest = 'ira-add-funds-funding-source';
		}

		return dest;
	}

	@Action({ rawError: true })
	public async submitDripPreference(dripEnabled: boolean): Promise<string> {
		let dest = '';
		if (this.flow === 'SIGN-UP') {
			await submitDripPreference(dripEnabled);
			dest = 'ira-confirm';
		} else if (this.flow === 'COMPLETE-ROLLOVER') {
			dest = 'ira-complete-rollover-confirm';
		} else if (this.flow === 'ADD-FUNDS') {
			dest = this.fundingType === 'CONTRIBUTION' ? 'ira-add-funds-funding-source' : 'ira-add-funds-confirm';
		}

		this.UPDATE_DRIP_PREFERENCE(dripEnabled);

		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitPaymentMethod(method: BankAccount): Promise<string> {
		let dest = 'ira-add-funds-confirm';

		if (this.flow === 'SIGN-UP') {
			await submitPaymentMethod(method.id);
			dest = 'ira-fee-schedule';
		}

		if (this.selectedPaymentMethod && this.selectedPaymentMethod.id !== method.id) {
			this.UPDATE_FEES_ACKNOWLEDGED(false);
		}
		this.UPDATE_SELECTED_PAYMENT_METHOD(method);

		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitOtpDelivery(selectedOtpDeliveryType: IraOtpDeliveryType): Promise<string> {
		const iraVerification = await sendMtcCode(selectedOtpDeliveryType);
		this.UPDATE_VERIFICATION_STATUS(iraVerification.verificationStatus);
		let dest;
		switch (this.verificationStatus) {
			case 'ERROR':
			case 'DELIVERY_FAILURE':
				dest = 'ira-verification-error';
				break;
			case 'AWAITING_DOCUMENTATION':
				dest = 'ira-documents';
				break;
			case 'VERIFIED':
				if (this.fundingType === 'ROLLOVER') {
					await this.submitRollover();
				} else {
					await completeOnboarding();
				}
				dest = 'ira-success';
				break;
			default:
				dest = 'ira-mobile-verify';
		}
		return dest;
	}

	@Action({ rawError: true })
	public async submitPhoneVerification(code: string): Promise<string> {
		const iraVerification = await submitPhoneVerification(code);
		this.UPDATE_VERIFICATION_STATUS(iraVerification.verificationStatus);
		let dest;
		switch (this.verificationStatus) {
			case 'ERROR':
			case 'DELIVERY_FAILURE':
				dest = 'ira-verification-error';
				break;
			case 'AWAITING_DOCUMENTATION':
				dest = 'ira-documents';
				break;
			default:
				if (this.fundingType === 'ROLLOVER') {
					await this.submitRollover();
				} else {
					await completeOnboarding();
				}
				dest = 'ira-success';
				break;
		}
		await user.getUser();
		return dest;
	}

	@Action({ rawError: true })
	public async submitAcknowledgedFees(acknowledgedFees: boolean): Promise<void> {
		await submitAcknowledgedFees(acknowledgedFees);
		this.UPDATE_FEES_ACKNOWLEDGED(acknowledgedFees);
	}

	/* eslint-disable @typescript-eslint/no-non-null-assertion */
	@Action({ rawError: true })
	public async submitRollover(): Promise<void> {
		if (this.flow === 'SIGN-UP') {
			await submitOnboardingRollover();
		} else {
			const params: IraSubmitRolloverParams = {
				amount: this.selectedAmount,
				rolloverMethod: this.rolloverMethod!,
				rolloverType: this.rolloverType!,
				sourceAccountType: this.sourceAccountType!
			};
			if (this.rolloverCheckDate) {
				params.indirectRolloverChequeDate = this.rolloverCheckDate;
			}
			if (this.reitOfferingVintage) {
				params.rolloverReitOfferingVintageId = this.reitOfferingVintage.reitOfferingVintageId;
			}
			await submitAddFundsRollover(params);
		}
	}
	/* eslint-enable @typescript-eslint/no-non-null-assertion */

	/* eslint-disable @typescript-eslint/no-non-null-assertion */
	@Action({ rawError: true })
	public async submitOrderGroup(): Promise<void> {
		let reitInvestmentOrderGroupId = '';
		if (this.flow === 'SIGN-UP') {
			reitInvestmentOrderGroupId = await submitOnboardingOrderGroup();
		} else {
			const investmentParams = this.reitOfferingVintage
				? {
						reitOfferingVintageId: this.reitOfferingVintage.reitOfferingVintageId
					}
				: {
						riaPlanId: (this.plan as RiaPlan).id
					};

			if (this.fundingType === 'CONTRIBUTION') {
				const params: IraAddFundsNewContributionOrderGroupParams = {
					amount: this.selectedAmount,
					fundingType: this.fundingType,
					paymentMethodId: this.selectedPaymentMethod!.id,
					selectedTaxYear: this.selectedTaxYear,
					...investmentParams
				};
				reitInvestmentOrderGroupId = await submitAddFundsOrderGroup(params);
			} else if (this.fundingType === 'EXISTING') {
				const params: IraAddFundsExistingOrderGroupParams = {
					amount: this.selectedAmount,
					fundingType: this.fundingType,
					...investmentParams
				};
				reitInvestmentOrderGroupId = await submitAddFundsOrderGroup(params);
			} else {
				const params: IraAddFundsTransferOrderGroupParams = {
					amount: this.selectedAmount,
					fundingType: this.fundingType,
					sourceAccountNumber: this.sourceAccountNumber,
					sourceAccountType: this.sourceAccountType!,
					...investmentParams
				};

				if (this.sourceCustodianName && this.sourceDtcParticipantNumber) {
					params.sourceCustodianName = this.sourceCustodianName;
					params.sourceDtcParticipantNumber = this.sourceDtcParticipantNumber;
				} else if (this.sourceDtcParticipant) {
					params.sourceDtcParticipantId = this.sourceDtcParticipant.dtcParticipantId;
				}

				reitInvestmentOrderGroupId = await submitAddFundsOrderGroup(params);
			}
		}

		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(reitInvestmentOrderGroupId);
	}
	/* eslint-enable @typescript-eslint/no-non-null-assertion */

	@Action({ rawError: true })
	public async submitSourceAccountType(type: IraAccountType): Promise<string> {
		let dest = '';
		if (this.flow === 'ADD-FUNDS') {
			const eligibilityData = await checkSourceEligibilityAddFunds(type);
			const addFundsApiType = eligibilityData.sourceAccountType;
			this.UPDATE_SOURCE_ACCOUNT_TYPE(addFundsApiType);
			if (eligibilityData.compatible) {
				dest = type === 'SIMPLE' ? 'ira-add-funds-account-age' : 'ira-add-funds-transfer-account-info';
			} else {
				dest = 'ira-add-funds-transfer-incompatible';
			}
		} else {
			const apiType = await submitSourceAccountType(type);
			this.UPDATE_SOURCE_ACCOUNT_TYPE(apiType);
			dest = type === 'SIMPLE' ? 'ira-account-age' : 'ira-target-account-type';
		}
		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitRolloverType(type: IraRolloverTypeWithLabel): Promise<string> {
		let dest = '';
		if (this.flow === 'ADD-FUNDS') {
			if (type.iraRolloverType === 'DEFINED_BENEFIT' || type.iraRolloverType === 'PROFIT_SHARING') {
				const eligibilityData = await checkRolloverSourceEligibilityAddFunds(type.iraRolloverType);
				const addFundsApiType = eligibilityData.sourceAccountType;
				this.UPDATE_SOURCE_ACCOUNT_TYPE(addFundsApiType);
				dest = eligibilityData.compatible
					? 'ira-add-funds-rollover-method'
					: 'ira-add-funds-transfer-incompatible';
			} else {
				dest = 'ira-add-funds-rollover-source-account-type';
			}
		} else {
			const iraMeta = await submitRolloverAccountType(type.iraRolloverType);
			if (type.iraRolloverType === 'DEFINED_BENEFIT' || type.iraRolloverType === 'PROFIT_SHARING') {
				// Defined Benefit and Profit Sharing are always traditional, so the source account type is already decided
				const sourceAccountType = iraMeta.sourceAccountType;
				if (sourceAccountType) {
					this.UPDATE_SOURCE_ACCOUNT_TYPE(sourceAccountType);
				}
				dest = 'ira-target-account-type';
			} else {
				dest = 'ira-rollover-source-account-type';
			}
		}

		this.UPDATE_ROLLOVER_TYPE(type);

		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitRolloverSourceAccountType(type: IraAccountType): Promise<string> {
		let dest = '';
		if (type === 'ROLLOVER_ROTH' || type === 'ROLLOVER_TRADITIONAL') {
			if (this.flow === 'ADD-FUNDS') {
				const eligibilityData = await checkRolloverSourceEligibilityAddFunds(type);
				const addFundsApiType = eligibilityData.sourceAccountType;
				this.UPDATE_SOURCE_ACCOUNT_TYPE(addFundsApiType);
				dest = eligibilityData.compatible
					? 'ira-add-funds-rollover-method'
					: 'ira-add-funds-transfer-incompatible';
			} else {
				const apiType = await submitSourceAccountType(type);
				this.UPDATE_SOURCE_ACCOUNT_TYPE(apiType);
				dest = 'ira-target-account-type';
			}
		} else {
			this.UPDATE_SOURCE_ACCOUNT_TYPE({
				iraType: 'MIXED',
				iraTypeLabel: 'Mixed'
			});
			dest = this.flow === 'ADD-FUNDS' ? 'ira-add-funds-rollover-mixed-error' : 'ira-rollover-mixed-error';
		}

		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitRolloverMethod(method: RolloverMethod): Promise<string> {
		let dest = method === 'DIRECT' ? 'ira-add-funds-transfer-amount' : 'ira-add-funds-rollover-check-date';
		if (this.flow === 'SIGN-UP') {
			dest = method === 'DIRECT' ? 'ira-transfer-amount' : 'ira-rollover-check-date';
			await submitRolloverMethod(method);
		}
		this.UPDATE_ROLLOVER_METHOD(method);
		return this.editRedirect || dest;
	}

	@Action({ rawError: true })
	public async submitRolloverCheckDate(date: string): Promise<string> {
		let dateValid = false;
		if (this.flow === 'SIGN-UP') {
			dateValid = await submitRolloverCheckDate(date);
			this.UPDATE_ROLLOVER_CHECK_DATE(date);
			this.UPDATE_ROLLOVER_CHECK_DATE_VALID(dateValid);
			return dateValid ? 'ira-transfer-amount' : 'ira-rollover-check-not-eligible';
		} else {
			dateValid = await checkRolloverCheckDate(date);
			this.UPDATE_ROLLOVER_CHECK_DATE(date);
			this.UPDATE_ROLLOVER_CHECK_DATE_VALID(dateValid);
			return dateValid ? 'ira-add-funds-transfer-amount' : 'ira-add-funds-rollover-check-not-eligible';
		}
	}

	@Action({ rawError: true })
	public async submitSimpleIraFirstContributionDate(date: string): Promise<string> {
		let dateValid = false;
		if (this.flow === 'SIGN-UP') {
			dateValid = await submitSimpleIraFirstContributionDate(date);
			this.UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE(date);
			this.UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE_VALID(dateValid);
			return dateValid ? 'ira-target-account-type' : 'ira-account-not-eligible';
		} else {
			dateValid = await checkSimpleIraFirstContributionDateAddFunds(date);
			this.UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE(date);
			this.UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE_VALID(dateValid);
			return dateValid ? 'ira-add-funds-transfer-account-info' : 'ira-add-funds-account-not-eligible';
		}
	}

	@Action({ rawError: true })
	public async submitSimpleIneligibleNextSteps(params: Record<'newIra' | 'emailMe', boolean>): Promise<string> {
		if (params.emailMe && !params.newIra) {
			await submitEmailRequest(this.simpleIraFirstContributionDate);
			if (this.flow === 'SIGN-UP') {
				await this.resetIra();
			}
			redirect('/ira/email-saved');
			return '';
		} else if (params.newIra && params.emailMe) {
			await submitEmailRequest(this.simpleIraFirstContributionDate);
			if (this.flow === 'SIGN-UP') {
				return await this.resetIra();
			} else {
				return await this.resetAddFunds();
			}
		} else if (this.flow === 'SIGN-UP') {
			return await this.resetIra();
		} else {
			return await this.resetAddFunds();
		}
	}

	@Action({ rawError: true })
	public async submitTransferAccountInfo(info: SourceAccountTypeSubmission): Promise<string> {
		if (this.flow === 'SIGN-UP') {
			await submitTransferAccountInfo(info);
		}
		if (info.sourceCustodianName && info.sourceDtcParticipantNumber) {
			this.UPDATE_SOURCE_CUSTODIAN_NAME(info.sourceCustodianName);
			this.UPDATE_SOURCE_DTC_PARTICIPANT_NUMBER(info.sourceDtcParticipantNumber);
			this.UPDATE_SOURCE_DTC_PARTICIPANT(null);
		} else if (info.sourceDtcParticipant) {
			this.UPDATE_SOURCE_DTC_PARTICIPANT({
				dtcParticipantId: info.sourceDtcParticipant.dtcParticipantId,
				dtcCustodianName: info.sourceDtcParticipant.dtcCustodianName
			});
			this.UPDATE_SOURCE_CUSTODIAN_NAME('');
			this.UPDATE_SOURCE_DTC_PARTICIPANT_NUMBER('');
		}
		this.UPDATE_SOURCE_ACCOUNT_NUMBER(info.sourceAccountNumber);

		return this.flow === 'SIGN-UP' ? 'ira-transfer-amount' : 'ira-add-funds-transfer-amount';
	}

	@Action({ rawError: true })
	public async submitVerificationDocuments(uploadForm: {
		[verificationType in MillenniumVerificationType]?: IraVerificationDocument | null;
	}): Promise<string> {
		const iraVerification = await uploadVerificationManual(uploadForm);
		this.UPDATE_VERIFICATION_STATUS(iraVerification.verificationStatus);
		if (this.fundingType === 'ROLLOVER' && this.flow === 'SIGN-UP') {
			await this.submitRollover();
		}
		return this.flow === 'VERIFY' ? 'ira-verify-success' : 'ira-success';
	}

	@Action({ rawError: true })
	public async resetIraForSimpleIraReminderRedirect(): Promise<void> {
		await resetIra();
		await submitFundingType('TRANSFER');
		ira.UPDATE_FUNDING_TYPE('TRANSFER');
	}

	@Action({ rawError: true })
	public async getIraFeesAndContributionLimits(): Promise<void> {
		const nullContributionLimit = this.contributionLimit == null || this.seniorContributionLimit == null;
		const nullThresholdLimit = this.annualThresholdLimit == null || this.accountSizeThresholdLimit == null;
		if (this.taxYear == null || nullContributionLimit || this.feeAmount == null || nullThresholdLimit) {
			const iraFeesAndContributionLimits = await getIraFeesAndContributionLimits();
			ira.UPDATE_IRA_FEES_AND_CONTRIBUTION_LIMITS(iraFeesAndContributionLimits);
		}
	}

	@Mutation
	public UPDATE_IRA_FEES_AND_CONTRIBUTION_LIMITS(iraFeesAndContributionLimits: IraFeesAndContributionLimits): void {
		this.taxYear = iraFeesAndContributionLimits.taxYear;
		this.contributionLimit = iraFeesAndContributionLimits.contributionLimit;
		this.seniorContributionLimit = iraFeesAndContributionLimits.seniorContributionLimit;
		this.feeAmount = iraFeesAndContributionLimits.feeAmount;
		this.annualThresholdLimit = iraFeesAndContributionLimits.annualThresholdLimit;
		this.accountSizeThresholdLimit = iraFeesAndContributionLimits.accountSizeThresholdLimit;
	}

	@Mutation
	public UPDATE_DATA_RETRIEVED(state: boolean): void {
		this.dataRetrieved = state;
	}

	@Mutation
	public UPDATE_STATE_DETERMINER_STEP(step: IraStateDeterminerStep): void {
		this.stateDeterminerStep = step;
	}

	@Mutation
	public UPDATE_PERSONAL_INFO(newInfo: IraPersonalInformation): void {
		this.personalInfo = newInfo;
	}

	@Mutation
	public UPDATE_ADDRESSES(Addresses: Pick<IraPersonalInformation, 'primaryAddress' | 'mailingAddress'>): void {
		this.personalInfo.primaryAddress = Addresses.primaryAddress;
		this.personalInfo.mailingAddress = Addresses.mailingAddress;
	}

	@Mutation
	public UPDATE_CURRENT_PHONE(phone: PhoneNumber | null): void {
		this.currentPhone = phone;
	}

	@Mutation
	public UPDATE_FUNDING_TYPE(type: IraFundingType): void {
		this.fundingType = type;
	}

	@Mutation
	public UPDATE_ACCOUNT_TYPE(type: IraTypeWithLabel | null): void {
		if (type === null) {
			this.sourceAccountType = null;
			this.sourceAccountTypeLabel = '';
		} else {
			this.accountType = type.iraType;
			this.accountTypeLabel = type.iraTypeLabel;
		}
	}

	@Mutation
	public UPDATE_DRIP_PREFERENCE_FROM_TYPE(preferenceType: DripStatus | null): void {
		if (preferenceType === 'CASH_DISTRIBUTION') {
			this.dripPreference = false;
		} else if (preferenceType === 'RIA_REINVESTMENT') {
			this.dripPreference = true;
		} else {
			this.dripPreference = null;
		}
	}

	@Mutation
	public UPDATE_DRIP_PREFERENCE(preference: boolean | null): void {
		this.dripPreference = preference;
	}

	@Mutation
	public UPDATE_PLAN(plan: RiaPlan | null): void {
		this.plan = plan;
	}

	@Mutation
	public UPDATE_IRA_BANK_ACCOUNTS(accounts: Array<BankAccount>): void {
		this.iraBankAccounts = accounts;
	}

	@Mutation
	public UPDATE_SELECTED_AMOUNT(amount: string): void {
		this.selectedAmount = amount;
	}

	@Mutation
	public UPDATE_SELECTED_TAX_YEAR(taxYear: string): void {
		this.selectedTaxYear = taxYear;
	}

	@Mutation
	public UPDATE_SELECTED_PAYMENT_METHOD(paymentMethod: BankAccount | null): void {
		this.selectedPaymentMethod = paymentMethod;
	}

	@Mutation
	public UPDATE_FEES_ACKNOWLEDGED(acknowledgedFees: boolean): void {
		this.acknowledgedFees = acknowledgedFees;
	}

	@Mutation
	public UPDATE_ORDER_GROUP_ACKNOWLEDGED(acknowledged: boolean): void {
		this.orderGroupAcknowledged = acknowledged;
	}

	@Mutation
	public UPDATE_ROLLOVER_AGREEMENTS_SUBMITTED(submitted: boolean): void {
		this.rolloverAgreementsSubmitted = submitted;
	}

	@Mutation
	public UPDATE_EDIT_REDIRECT(editRedirect: string): void {
		this.editRedirect = editRedirect;
	}

	@Mutation
	public UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(id: string): void {
		this.reitInvestmentOrderGroupId = id;
	}

	@Mutation
	public UPDATE_CONTACT_DOCUMENT(contactDocument: IraDocumentType | null): void {
		this.contactDocument = contactDocument;
	}

	@Mutation
	public UPDATE_SOURCE_ACCOUNT_TYPE(type: IraTypeWithLabel | null): void {
		if (type === null) {
			this.sourceAccountType = null;
			this.sourceAccountTypeLabel = '';
		} else {
			this.sourceAccountType = type.iraType;
			this.sourceAccountTypeLabel = type.iraTypeLabel;
		}
	}

	@Mutation
	public UPDATE_ROLLOVER_TYPE(type: IraRolloverTypeWithLabel | null): void {
		if (type === null) {
			this.rolloverType = null;
			this.rolloverTypeLabel = '';
		} else {
			this.rolloverType = type.iraRolloverType;
			this.rolloverTypeLabel = type.iraRolloverTypeLabel;
		}
	}

	@Mutation
	public UPDATE_ROLLOVER_METHOD(method: RolloverMethod | null): void {
		this.rolloverMethod = method;
	}

	@Mutation
	public UPDATE_FLOW(flow: IraFlow): void {
		this.flow = flow;
	}

	@Mutation
	public UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE(date: string): void {
		this.simpleIraFirstContributionDate = date;
	}

	@Mutation
	public UPDATE_SIMPLE_IRA_FIRST_CONTRIBUTION_DATE_VALID(status: boolean): void {
		this.simpleIraFirstContributionDateValid = status;
	}

	@Mutation
	public UPDATE_ROLLOVER_CHECK_DATE(date: string): void {
		this.rolloverCheckDate = date;
	}

	@Mutation
	public UPDATE_ROLLOVER_CHECK_DATE_VALID(status: boolean): void {
		this.rolloverCheckDateValid = status;
	}

	@Mutation
	public UPDATE_SOURCE_CUSTODIAN_NAME(name: string): void {
		this.sourceCustodianName = name;
	}

	@Mutation
	public UPDATE_SOURCE_DTC_PARTICIPANT_NUMBER(number: string): void {
		this.sourceDtcParticipantNumber = number;
	}

	@Mutation
	public UPDATE_SOURCE_ACCOUNT_NUMBER(number: string): void {
		this.sourceAccountNumber = number;
	}

	@Mutation
	public UPDATE_SOURCE_DTC_PARTICIPANT(dtcParticipant: DtcParticipant | null): void {
		this.sourceDtcParticipant = dtcParticipant;
	}

	@Mutation
	public UPDATE_IRA_ENTITY_FUNDED_STATUS(isFunded: boolean): void {
		this.isFunded = isFunded;
	}

	@Mutation
	public UPDATE_LEGACY_IRA(legacyIra: boolean): void {
		this.legacyIra = legacyIra;
	}

	@Mutation
	public UPDATE_VERIFICATION_STATUS(verificationStatus: IraVerificationStatus): void {
		this.verificationStatus = verificationStatus;
	}

	@Mutation
	public UPDATE_MTC_CONTACT_CREATED(created: boolean): void {
		this.mtcContactCreated = created;
	}

	@Mutation
	public UPDATE_MAX_CONTRIBUTION_LIMIT(maxContributionLimit: string): void {
		this.maxContributionLimit = maxContributionLimit;
	}

	@Mutation
	public UPDATE_ADD_FUNDS_PLAN_SELECTION_REQUIRED(required: boolean): void {
		this.addFundsPlanSelectionRequired = required;
	}

	@Mutation
	public UPDATE_ADD_FUNDS_DRIP_PREFERENCE_REQUIRED(required: boolean): void {
		this.addFundsdripPreferenceRequired = required;
	}

	@Mutation
	public UPDATE_REIT_OFFERING_VINTAGE(reitOfferingVintage: ReitOfferingVintage | null): void {
		this.reitOfferingVintage = reitOfferingVintage;
	}

	@Mutation
	public UPDATE_ADD_FUNDS_EDIRECT(addFundsEdirect: boolean): void {
		this.addFundsEdirect = addFundsEdirect;
	}

	@Mutation
	public UPDATE_ROLLOVER_PLAN_ALREADY_SET(alreadySet: boolean): void {
		this.rolloverPlanAlreadySet = alreadySet;
	}

	@Mutation
	public UPDATE_ROLLOVER_DRIP_ALREADY_SET(alreadySet: boolean): void {
		this.rolloverDripAlreadySet = alreadySet;
	}

	@Mutation
	public UPDATE_INITIAL_ROLLOVER_AMOUNT(initialAmount: string): void {
		this.initialRolloverAmount = initialAmount;
	}

	@Mutation
	public UPDATE_ROLLOVER_INITIATION_DATE(date: string): void {
		this.rolloverInitiationDate = date;
	}

	@Mutation
	public UPDATE_ROLLOVER_CONFIRMED_THRESHOLD(threshold: string | null): void {
		this.rolloverConfirmedThreshold = threshold;
	}
}

export const ira = getModule(IraModule);
