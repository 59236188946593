import { advisor } from '@store/modules/advisor';
import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { performancePortfolio } from '@store/modules/performance-portfolio';
import { user } from '@store/modules/user';

const advisorRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/advisor`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const enabled = await app.isFeatureEnabled('advisors-product');

			if (enabled) {
				if (app.isVerified && app.isAdvisor) {
					await Promise.all([advisor.getAdvisorData(), user.storeTwoFactorType()]);
				}

				next();
			} else {
				next({ name: 'forbidden', replace: true });
			}
		},
		component: () => import(/* webpackChunkName: "advisor-subpage" */ '@views/advisor/advisor-subpage.vue'),
		children: [
			{
				path: '',
				name: 'advisor-landing',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import(/* webpackChunkName: "advisor-landing" */ '@views/advisor/advisor-landing.vue')
			},
			{
				path: `get-started`,
				name: 'advisor-signup',
				meta: {
					minTokenScopeRequired: 'UNAUTHENTICATED'
				},
				component: () => import(/* webpackChunkName: "advisor-signup" */ '@views/advisor/advisor-signup.vue'),
				beforeEnter: async (to, from, next) => {
					if (app.isAuthenticated) {
						next({ name: 'home', replace: true });
					} else {
						next();
					}
				}
			},
			{
				path: 'clients/:investmentEntityId/detail',
				name: 'advisor-client-detail',
				meta: {
					minTokenScopeRequired: 'ADVISOR_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					try {
						advisor.UPDATE_SELECTED_INVESTMENT_ENTITY_ID(to.params.investmentEntityId as string);
						performancePortfolio.resetPerformanceStore();
						await investmentEntity.storePerformance();
						performancePortfolio.setSelectedAdvisorView('ALL');
						next();
					} catch {
						app.ADD_TOAST({
							type: 'error',
							message:
								'You are unable to access details for this client. If you believe this is an error, contact us at <a href="mailto:support@fundrise.com">support@fundrise.com</a>.'
						});
						next({ name: 'home' });
					}
				},
				component: () =>
					import(/* webpackChunkName: "advisor-client-detail" */ '@views/advisor/advisor-client-detail.vue')
			},
			{
				path: `clients`,
				name: 'advisor-dashboard',
				meta: { minTokenScopeRequired: 'ADVISOR_ACCESS' },
				beforeEnter: async (to, from, next) => {
					await advisor.getClientAllocations();
					next();
				},
				component: () =>
					import(/* webpackChunkName: "advisor-dashboard" */ '@views/advisor/advisor-dashboard.vue')
			},
			{
				path: `invite-clients`,
				name: 'advisor-invite-clients',
				meta: { minTokenScopeRequired: 'ADVISOR_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!user.twoFactorEnabled) {
						next({ name: 'advisor-dashboard' });
					} else {
						next();
					}
				},
				component: () =>
					import(/* webpackChunkName: "advisor-invite-clients" */ '@views/advisor/advisor-invite-clients.vue')
			},
			{
				path: `invite-success`,
				name: 'advisor-invite-client-success',
				meta: { minTokenScopeRequired: 'ADVISOR_ACCESS' },
				component: () =>
					import(
						/* webpackChunkName: "advisor-invite-client-success" */ '@views/advisor/advisor-invite-client-success.vue'
					)
			},
			{
				path: `unverified-email`,
				name: 'advisor-unverified-email',
				meta: {
					minTokenScopeRequired: 'UNVERIFIED'
				},
				beforeEnter: async (to, from, next) => {
					if (app.isVerified) {
						next({ name: 'advisor-dashboard ' });
					} else {
						next();
					}
				},
				component: () =>
					import(/* webpackChunkName: "advisor-verify-email" */ '@views/advisor/advisor-verify-email.vue')
			},
			{
				path: `welcome`,
				name: 'advisor-welcome',
				meta: {
					minTokenScopeRequired: 'ADVISOR_ACCESS'
				},
				component: () => import(/* webpackChunkName: "advisor-welcome" */ '@views/advisor/advisor-welcome.vue')
			}
		]
	}
];

export default advisorRoutes;
