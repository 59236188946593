<template>
	<div ref="moduleElem" class="fund-teaser-module">
		<div
			class="module fund-teaser-module__content display-flex justify-content-center text-align-center-smo position-relative"
		>
			<div class="position-absolute-smo details-content-positioning width-100-smo">
				<transition name="component-fade" mode="out-in">
					<div
						v-if="displayFundDetails"
						:class="[
							`
						p-200-md p-100-smo
						col-7-md
						width-100-smo
						display-flex-mdo
						flex-direction-column-mdo
						justify-content-center-mdo
						fund-details-positioning
						height-100-md
					`,
							{ 'position-absolute-md': !showActions }
						]"
					>
						<p v-if="tagline" class="text-color-pro-light-2 body-lg-md body-xs-smo fund-details-tagline">
							{{ tagline }}
						</p>
						<h3 class="heading-2 text-color-white fund-details-heading"> {{ fundTitle }} </h3>
						<div class="fund-details-attributes">
							<span
								v-for="(attribute, idx) in fundAttributes"
								:key="attribute"
								class="display-inline-block mt-100-md mt-25-smo text-color-dark-40 body-xs-smo fund-details-attribute"
								>{{ attribute
								}}<span :class="{ 'display-none': idx === fundAttributes.length - 1 }"
									><BaseSvgIcon
										name="dot"
										height="4"
										width="4"
										class="mx-25 text-color-dark-70" /></span
							></span>
						</div>
						<p v-if="!useMobileImage" class="text-color-white col-9-mdo" v-html="subtitle"></p>
						<FundTeaserModuleCtas
							v-if="!useMobileImage && displayFailedState"
							class="mt-100"
							:learn-more-link="learnMoreLink"
							:direct-investment-eligibility="props.data.directInvestmentEligibility"
							:vintage-id="props.data.vintageId"
						/>
					</div>
				</transition>
				<transition name="component-fade" mode="out-in">
					<div
						v-if="displayAssetDetails"
						class="p-100-smo text-color-white fund-teaser-asset-details display-flex-smo flex-direction-column-smo align-items-center-smo width-100-smo position-absolute-md asset-details-positioning"
					>
						<Badge :text="badgeText" background="slate" size="medium" />
						<BaseLink
							:link="assetDetailsLink"
							mixpanel-target="Asset detail"
							class="display-flex align-items-center mt-50 asset-details-heading text-color-dark-50"
						>
							<h3 class="heading-4 text-color-white asset-details-name"> {{ assetName }} </h3>
							<span
								class="ml-50 fund-teaser-circle display-flex align-items-center justify-content-center"
							>
								<BaseSvgIcon v-if="useMobileImage" name="caret" dir="right" />
								<BaseSvgIcon v-else name="short-arrow" width="16" height="16" dir="down" />
							</span>
						</BaseLink>
						<p class="mt-50 mt-25-smo text-color-white body-sm-smo asset-details-subtitle">{{
							featuredAssetSubtitle
						}}</p>
						<p class="mt-50 mt-25-smo text-color-dark-40 body-xs-smo asset-details-metadata">{{
							assetMetadata
						}}</p>
						<FundTeaserModuleCtas
							v-if="!useMobileImage"
							class="mt-100"
							:learn-more-link="learnMoreLink"
							:direct-investment-eligibility="props.data.directInvestmentEligibility"
							:vintage-id="props.data.vintageId"
						/>
					</div>
				</transition>
				<FundTeaserModuleCtas
					v-if="useMobileImage"
					class="fund-teaser-module-ctas-mobile pb-100 px-100"
					:learn-more-link="learnMoreLink"
					:direct-investment-eligibility="props.data.directInvestmentEligibility"
					:vintage-id="props.data.vintageId"
				/>
			</div>

			<transition name="fade" mode="out-in">
				<div v-if="animationComplete" class="replay-button-wrapper">
					<BaseButton aria-label="Replay Animation" classes="text-color-white" @click.stop="replayAnimation">
						<BaseSvgIcon name="play-circle" height="24" width="24" />
					</BaseButton>
				</div>
			</transition>

			<div
				v-if="showActions"
				class="actions-positioning position-absolute col-5-md display-flex flex-direction-column align-items-center justify-content-center gap-50 width-100-smo"
			>
				<div
					v-if="!dataReady && !dataFailed"
					class="display-flex flex-direction-column align-items-center gap-50"
				>
					<div class="loader loader--dark gamma"></div>
					<span class="text-color-dark-20">Loading...</span>
				</div>
			</div>

			<LottieAnimation
				ref="animationRef"
				:key="useMobileImage"
				:lottie-file="animationUrl"
				:loop="false"
				:max-retries="2"
				:thresholds="[75, 95]"
				autoplay-behavior="ON_SCROLL"
				:aria-label="altText"
				@threshold-reached="fadeAnimationDetails"
				@on-fail="displayFailedState = true"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import Badge from '@components/badges/badge-component.vue';
import { deorphanText } from '@filters/shared-filters';
import FundTeaserModuleCtas from '@components/account/dashboard-modules/fund-teaser-module-ctas.vue';
import { isMobile } from '@utils/composables';
import { Link } from 'types/layout';
import LottieAnimation from '@components/layout/lottie-animation.vue';
import { ModularDashboardFundTeaser } from 'types/modular-dashboard/custom';

interface Props {
	data: ModularDashboardFundTeaser;
}

const props = defineProps<Props>();

const displayFundDetails = ref(true);
const displayAssetDetails = ref(false);
const animationRef = ref<null | InstanceType<typeof LottieAnimation>>(null);
const displayFailedState = ref(false);

const moduleElem = ref<HTMLElement | null>(null);

const learnMoreLink: Link = {
	href: props.data.secondaryCtaUrl,
	text: deorphanText(props.data.secondaryCtaLabel)
};

const useMobileImage = ref(false);

const tagline = computed((): string => props.data.tagline ?? '');

const fundTitle = computed((): string => props.data.fundTitle);

const fundAttributes = computed((): Array<string> => props.data.fundAttributes ?? []);

const subtitle = computed((): string => deorphanText(props.data.subtitle ?? ''));

const assetName = computed((): string => props.data.featuredAsset?.assetCardDto.name ?? '');

const featuredAssetSubtitle = computed((): string => props.data.featuredAsset?.subtitle ?? '');

const assetMetadata = computed((): string =>
	props.data.featuredAsset?.assetMetadata ? props.data.featuredAsset?.assetMetadata[0] : ''
);

const badgeText = computed((): string => props.data.featuredAsset?.pillText ?? '');

const altText = computed((): string => props.data.accessibility ?? 'featured fund animation');

const animationUrl = computed(
	(): string => (useMobileImage.value ? props.data.mobileAnimationUrl : props.data.animationUrl) ?? ''
);

const assetDetailsLink = computed(
	(): Link => ({ router: 'company-assets', params: { companyId: props.data.featuredAsset?.assetCardDto.id } })
);

const dataFailed = computed((): boolean => {
	return animationRef.value?.dataFailed ?? false;
});

const dataReady = computed((): boolean => {
	return animationRef.value?.dataReady ?? false;
});

const animationComplete = computed((): boolean => {
	return animationRef.value?.animationComplete ?? false;
});

const showActions = computed((): boolean => {
	return dataFailed.value || !dataReady.value;
});

watch(
	() => useMobileImage.value,
	() => {
		displayFundDetails.value = true;
		displayAssetDetails.value = false;
	}
);

onMounted(() => {
	useEventListener(window, 'resize', useDebounceFn(setUseMobileImage, 100));

	setUseMobileImage();
});

function setUseMobileImage(): void {
	if (isMobile.value) {
		useMobileImage.value = true;
	} else {
		useMobileImage.value = moduleElem.value ? moduleElem.value.offsetWidth <= 520 : false;
	}
}

function fadeAnimationDetails(threshold: number): void {
	if (threshold === 75) {
		displayFundDetails.value = false;
	} else if (threshold === 95) {
		displayAssetDetails.value = true;
	}
}

function replayAnimation(): void {
	displayFundDetails.value = true;
	displayAssetDetails.value = false;
	animationRef.value?.replayAnimation();
}
</script>

<style lang="scss" scoped>
@use '../../../../../styles/utilities/respond-to.scss' as *;
@use '../../../../../styles/constants/_colors.scss' as *;

.component-fade-enter-active,
.component-fade-leave-active {
	transition: opacity 0.3s ease;
}

.component-fade-enter,
.component-fade-leave-to {
	opacity: 0;
}

.fund-teaser-module {
	&__content {
		padding: 10px;
	}

	container-type: inline-size;

	@container (max-width: 700px) {
		.fund-teaser-asset-details {
			top: 15%;
			left: 35%;
			z-index: 100;
		}

		.fund-details-attribute {
			margin-top: 0.25rem;
			font-size: 0.75rem;
		}
	}

	@container (max-width: 520px) {
		.module {
			min-height: 350px;
			display: flex;
			justify-content: center;
			text-align: center;
		}

		.details-content-positioning {
			position: absolute;
			width: 100%;
			bottom: 0;
			z-index: 1;
		}

		.fund-details-positioning {
			width: 100%;
			padding: 1rem;
			position: static;
			display: block;
		}

		.fund-details-tagline {
			font-size: 0.75rem;
		}

		.fund-details-heading {
			font-size: 2rem;
		}

		.asset-details-positioning {
			width: 100%;
			padding: 1rem;
			position: static;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.asset-details-name {
			font-size: 1.5rem;
		}

		.asset-details-subtitle {
			margin-top: 0.25rem;
			font-size: 0.875rem;
		}

		.asset-details-metadata {
			margin-top: 0.25rem;
			font-size: 0.75rem;
		}
	}

	@container (max-width: 330px) {
		.fund-details-positioning {
			padding: 0.5rem 1rem;
		}

		.fund-details-heading {
			font-size: 1.5rem;
		}

		.fund-details-attributes {
			display: none;
		}

		.asset-details-positioning {
			padding: 0.5rem 1rem;
		}

		.asset-details-heading {
			margin-top: 0.25rem;
		}

		.asset-details-subtitle {
			display: none;
		}

		.asset-details-metadata {
			display: none;
		}

		.fund-teaser-module-ctas-mobile {
			flex-direction: column-reverse;
			font-size: 0.875rem;
			gap: 0.5rem;
		}

		:deep(.fund-teaser-invest-cta:not(:first-child:last-child)) {
			flex-basis: auto;
		}
	}
}

.module {
	background: #24272f;
	min-height: 250px;

	@include respond-to(smo) {
		min-height: 350px;
	}
}

.height-100-md {
	@include respond-to(md) {
		height: 100%;
	}
}

.fund-teaser-asset-details {
	@include respond-to(md) {
		top: 15%;
		left: 35%;
		z-index: 100;
	}

	a {
		.fund-teaser-circle {
			height: 36px;
			width: 36px;
			border-radius: 100px;
			transition: background-color 250ms ease-in-out;

			@include respond-to(smo) {
				background-color: dark(90);
				height: 24px;
				width: 24px;
			}
		}
		svg {
			transition: color 250ms ease-in-out;
		}
		&:hover .fund-teaser-circle {
			@include respond-to(md) {
				background-color: dark(90);
				svg {
					color: white;
				}
			}
		}
	}
}

.details-content-positioning {
	z-index: 1;

	@include respond-to(smo) {
		bottom: 0;
	}
}

.fund-details-positioning {
	z-index: 1;

	@include respond-to(smo) {
		bottom: 0;
	}
}

.asset-details-positioning {
	z-index: 1;

	@include respond-to(smo) {
		bottom: 0;
	}
}

.actions-positioning {
	min-height: 150px;
	top: 3rem;
	right: 0;
	bottom: 50%;
	z-index: 5;

	.loader {
		margin: auto;
	}

	@include respond-to(md) {
		top: 0;
		bottom: 0;
	}
}

.replay-button-wrapper {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	z-index: 5;
}

.fade-enter-active {
	transition: opacity 0.5s;
}
.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
