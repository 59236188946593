import { AppState } from './modules/app';
import { AutoInvestState } from './modules/auto-invest';
import { UserState } from './modules/user';
import Vuex from 'vuex';

export interface RootState {
	autoInvest: AutoInvestState;
	app: AppState;
	user: UserState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({});
