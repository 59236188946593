import { FundriseRouteConfig } from 'vue-router';

const investmentPlanningRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/investment-planning`,
		name: 'investment-planning',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () =>
			import(/* webpackChunkName: "investment-planning" */ '@views/investment-planning/investment-planning.vue')
	}
];

export default investmentPlanningRoutes;
