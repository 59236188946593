<template>
	<div>
		<StandardModal data-context="WAF Captcha Modal" :closeable="false" @modal-close="close">
			<template #title>
				<h3 class="heading-5">Let's confirm you are human</h3>
			</template>
			<template #content>
				<div ref="captchaRef"></div>
			</template>
		</StandardModal>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { addScriptTag } from '@utils/dom';
import StandardModal from '@components/modals/standard-modal.vue';

interface Props {
	resolve?: (wafToken: string) => unknown;
	reject?: (error?: unknown) => unknown;
}

interface Emits {
	(e: 'modal-close'): void;
}

const props = withDefaults(defineProps<Props>(), {
	resolve: undefined,
	reject: undefined
});

const emit = defineEmits<Emits>();

const captchaRef = ref<HTMLElement | null>(null);

function close(): void {
	emit('modal-close');
}

function onResolve(wafToken: string): void {
	if (props.resolve) {
		props.resolve(wafToken);
	}
	close();
}

function onReject(error?: unknown): void {
	if (props.reject) {
		props.reject(error);
	}
	close();
}

function renderCaptcha() {
	window.AwsWafCaptcha.renderCaptcha(captchaRef.value, {
		apiKey: process.env.VUE_APP_WAF_CAPTCHA_API_KEY,
		onSuccess: (wafToken: string) => {
			onResolve(wafToken);
		},
		onError: (error?: unknown) => {
			onReject(error);
		},
		skipTitle: true
	});
}

onMounted(() => {
	const wafCaptchaUrl = `${process.env.VUE_APP_WAF_CAPTCHA_URL}`;
	addScriptTag(wafCaptchaUrl, 'wafCaptcha', renderCaptcha);
});
</script>
