import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getInfoDetails, getQuestions, submitQuestionAnswers } from '@api/question-info-framework';
import { NextQuestionResponse, Question, QuestionAnswer, QuestionResponse } from 'types/question-framework';
import { enumToKebab } from '@filters/shared-filters';
import { InfoDetails } from 'types/info-framework';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'questionInfoFramework',
	store
})
class QuestionInfoFramework extends VuexModule {
	currentQuestionResponse: QuestionResponse | null = null;
	currentInfoScreenDetails: InfoDetails | null = null;

	get currentQuestion(): Question | null {
		return this.currentQuestionResponse?.questions[0] ?? null;
	}

	@Action({ rawError: true })
	async getInfo(infoId?: string): Promise<string | undefined> {
		if (!!this.currentInfoScreenDetails && this.currentInfoScreenDetails.infoId === infoId) {
			return enumToKebab(infoId);
		}
		const infoDetails = await getInfoDetails(infoId);
		this.UPDATE_CURRENT_INFO_SCREEN_DETAILS(infoDetails);
		return enumToKebab(infoDetails.infoId);
	}

	@Action({ rawError: true })
	async getQuestion(questionName?: string): Promise<string | undefined> {
		if (!!this.currentQuestionResponse && this.currentQuestionResponse.questions[0].questionId === questionName) {
			return enumToKebab(questionName);
		} else {
			const question = await getQuestions(questionName);
			this.UPDATE_CURRENT_QUESTION_RESPONSE(question);
			return enumToKebab(question.questions[0].questionId);
		}
	}

	@Action({ rawError: true })
	async submitAnswers(answer: QuestionAnswer): Promise<NextQuestionResponse | undefined> {
		const nextQuestion = await submitQuestionAnswers([answer]);
		return nextQuestion.nextQuestionId ? nextQuestion : undefined;
	}

	@Mutation
	public UPDATE_CURRENT_QUESTION_RESPONSE(currentQuestionResponse: QuestionResponse | null): void {
		this.currentQuestionResponse = currentQuestionResponse;
	}

	@Mutation
	public UPDATE_CURRENT_INFO_SCREEN_DETAILS(infoScreenDetails: InfoDetails | null): void {
		this.currentInfoScreenDetails = infoScreenDetails;
	}
}

export const questionInfoFramework = getModule(QuestionInfoFramework);
