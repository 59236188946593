<template>
	<ul class="mt-100">
		<KeyValueListItem class="body-sm" :value-label="initialAmount">
			<template #key>
				<div class="muted"> Initial Amount Invested </div>
			</template>
		</KeyValueListItem>
		<KeyValueListItem class="body-sm" :value-label="activeProjects">
			<template #key>
				<div class="muted"> Active Projects in your Portfolio </div>
			</template>
		</KeyValueListItem>
	</ul>
</template>

<script lang="ts">
export default {
	name: 'NewsFeedCardContentFirstInvestment'
};
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { currency } from '@filters/shared-filters';
import KeyValueListItem from '@components/key-value-list/key-value-list-item.vue';
import { NewsFeedItem } from 'types/account';
interface Props {
	item: NewsFeedItem;
}
const props = defineProps<Props>();

const initialAmount = computed((): string => {
	return currency(props.item.associatedFirstInvestmentSnapshot?.initialInvestmentAmount ?? '');
});

const activeProjects = computed((): string => {
	return props.item.associatedFirstInvestmentSnapshot?.numActiveProjects ?? '';
});
</script>
