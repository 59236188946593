import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { redemptionRequest } from '@store/modules/redemption-request';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const redemptionRequestRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/reits/redemptions/request/',
		name: 'redemption-request-REIT',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () =>
			import(/* webpackChunkName: "redemption-request" */ '@views/redemption-request/redemption-request.vue'),
		beforeEnter: async (to, from, next) => {
			await redemptionRequest.fetchRedemptionRequestInterstitialVariant();
			redemptionRequest.SET_REDEMPTION_TYPE('REIT');
			await redemptionRequest.fetchAndStoreMetadata();
			await investmentEntity.storeBankAccounts();
			next();
		},
		redirect: { name: 'redemption-request-start-REIT' },
		children: [
			{
				path: 'start',
				name: 'redemption-request-start-REIT',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-start" */ '@views/redemption-request/redemption-request-start.vue'
					)
			},
			{
				path: 'form',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				redirect: { name: 'redemption-request-start-REIT' }
			},
			{
				path: 'redemption-preference',
				name: 'redemption-request-preference-REIT',
				beforeEnter: async (to, from, next) => {
					if (redemptionRequest.skipPreferencePage) {
						next({ name: 'redemption-request-form-REIT', replace: true });
					} else if (!redemptionRequest.hasRedeemableReits) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-preference" */ '@views/redemption-request/redemption-request-preference.vue'
					)
			},
			{
				path: 'message',
				name: 'redemption-request-interstitial',
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					await redemptionRequest.fetchRedemptionRequestInterstitialVariant();

					if (redemptionRequest.showRedemptionRequestInterstitial) {
						next();
					} else {
						next({ name: 'redemption-request-REIT' });
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-interstitial" */ '@views/redemption-request/redemption-request-interstitial.vue'
					)
			},
			{
				path: 'request-form',
				name: 'redemption-request-form-REIT',
				beforeEnter: async (to, from, next) => {
					if (redemptionRequest.redemptionPreference === 'FULL') {
						next({ name: 'redemption-request-review-REIT', replace: true });
					} else if (!redemptionRequest.hasRedeemableReits) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: {
					step: redemptionRequest.showRedemptionRequestInterstitial ? 4 : 3,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-form" */ '@views/redemption-request/redemption-request-form.vue'
					)
			},
			{
				path: 'review',
				name: 'redemption-request-review-REIT',
				meta: {
					step: redemptionRequest.showRedemptionRequestInterstitial ? 5 : 4,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					if (!redemptionRequest.hasRedeemableReits || !redemptionRequest.hasRequests) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						redemptionRequest.storeRedemptionPreview();
						redemptionRequest.UPDATE_SHOW_PROCEEDS(false);
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-review" */ '@views/redemption-request/redemption-request-review.vue'
					)
			},
			{
				path: 'survey',
				name: 'redemption-request-survey-REIT',
				meta: {
					step: redemptionRequest.showRedemptionRequestInterstitial ? 6 : 5,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					if (redemptionRequest.redemptionPreview === null) {
						next({ name: 'redemption-request-review-REIT', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-survey" */ '@views/redemption-request/redemption-request-survey.vue'
					)
			},
			{
				path: 'acknowledgements',
				name: 'redemption-request-agreements-REIT',
				meta: {
					step: redemptionRequest.showRedemptionRequestInterstitial ? 7 : 6,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					if (!redemptionRequest.completedSurvey) {
						next({ name: 'redemption-request-survey-REIT', replace: true });
					} else if (!redemptionRequest.hasRequests) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-agreements" */ '@views/redemption-request/redemption-request-agreements.vue'
					)
			},
			{
				path: 'success',
				name: 'redemption-request-success-REIT',
				meta: {
					step: redemptionRequest.showRedemptionRequestInterstitial ? 8 : 7,
					minTokenScopeRequired: 'FULL_ACCESS'
				},
				beforeEnter: async (to, from, next) => {
					if (
						redemptionRequest.redemptionPreview === null ||
						redemptionRequest.redemptionRequestGroupId === ''
					) {
						next({ name: 'redemption-request-start-REIT', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-success" */ '@views/redemption-request/redemption-request-success.vue'
					)
			}
		]
	},
	{
		path: '/ipo/redemptions/request/',
		name: 'redemption-request-IPO',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () =>
			import(/* webpackChunkName: "redemption-request" */ '@views/redemption-request/redemption-request.vue'),
		beforeEnter: async (to, from, next) => {
			redemptionRequest.SET_REDEMPTION_TYPE('IPO');
			await redemptionRequest.fetchAndStoreMetadata();
			await investmentEntity.storeBankAccounts();
			next();
		},
		redirect: { name: 'redemption-request-start-IPO' },
		children: [
			{
				path: 'start',
				name: 'redemption-request-start-IPO',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-start" */ '@views/redemption-request/redemption-request-start.vue'
					)
			},
			{
				path: 'form',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				redirect: { name: 'redemption-request-start-IPO' }
			},
			{
				path: 'redemption-preference',
				name: 'redemption-request-preference-IPO',
				beforeEnter: async (to, from, next) => {
					if (redemptionRequest.skipPreferencePage) {
						next({ name: 'redemption-request-form-IPO', replace: true });
					} else if (!redemptionRequest.hasRedeemableReits) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-preference" */ '@views/redemption-request/redemption-request-preference.vue'
					)
			},
			{
				path: 'request-form',
				name: 'redemption-request-form-IPO',
				beforeEnter: async (to, from, next) => {
					if (redemptionRequest.redemptionPreference === 'FULL') {
						next({ name: 'redemption-request-review-IPO', replace: true });
					} else if (!redemptionRequest.hasRedeemableReits) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-form" */ '@views/redemption-request/redemption-request-form.vue'
					)
			},
			{
				path: 'review',
				name: 'redemption-request-review-IPO',
				meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!redemptionRequest.hasRedeemableReits || !redemptionRequest.hasRequests) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						redemptionRequest.storeRedemptionPreview();
						redemptionRequest.UPDATE_SHOW_PROCEEDS(false);
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-review" */ '@views/redemption-request/redemption-request-review.vue'
					)
			},
			{
				path: 'survey',
				name: 'redemption-request-survey-IPO',
				meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (redemptionRequest.redemptionPreview === null) {
						next({ name: 'redemption-request-review-IPO', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-survey" */ '@views/redemption-request/redemption-request-survey.vue'
					)
			},
			{
				path: 'acknowledgements',
				name: 'redemption-request-agreements-IPO',
				meta: { step: 6, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (!redemptionRequest.completedSurvey) {
						next({ name: 'redemption-request-survey-IPO', replace: true });
					} else if (!redemptionRequest.hasRequests) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else if (redemptionRequest.submitted) {
						next({ name: 'account-overview', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-agreements" */ '@views/redemption-request/redemption-request-agreements.vue'
					)
			},
			{
				path: 'success',
				name: 'redemption-request-success-IPO',
				meta: { step: 7, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					if (
						redemptionRequest.redemptionPreview === null ||
						redemptionRequest.redemptionRequestGroupId === ''
					) {
						next({ name: 'redemption-request-start-IPO', replace: true });
					} else {
						next();
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "redemption-request-success" */ '@views/redemption-request/redemption-request-success.vue'
					)
			}
		]
	}
];
export default redemptionRequestRoutes;
