import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';

/**
 * If a WAF token isn’t present, runs logic to get the token.
 */
async function WafTokenRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig<unknown>> {
	if (window.AwsWafIntegration && !window.AwsWafIntegration.hasToken()) {
		await window.AwsWafIntegration.getToken();
	}

	return config;
}

export const wafTokenRequestInterceptor: AxiosRequestInterceptorParams = [(config) => WafTokenRequest(config)];
