import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	deleteGoal,
	getCurrentGoal,
	getGoalAmountOptions,
	getGoalIncomeAmountSelect,
	getGoalSuggestion,
	saveCurrentGoal,
	saveOneClickGoal,
	updateCurrentGoal
} from '@api/investment-goals';
import {
	GoalIncomeSelectAmount,
	GoalResponse,
	GoalSelectAmount,
	GoalSuggestion,
	SaveInvestorGoal,
	UpdateInvestorGoal
} from 'types/investment-goals';
import { AutoInvestSchedule } from 'types/auto-invest';
import { getRecurringInvestmentSchedule } from '@api/auto-invest';
import { investmentEntity } from './investment-entity';
import store from '..';
import { updateDrip } from '@api/drip';

export interface InvestorGoalState {
	saveInvestorGoal: SaveInvestorGoal | null;
	minimumAmount: string;
	maxAmount: string;
	autoInvestSchedule: AutoInvestSchedule | null;
}

@Module({
	dynamic: true,
	namespaced: true,
	name: 'investmentGoals',
	store
})
class InvestorGoalModule extends VuexModule {
	currentGoal: GoalResponse | null = null;

	saveInvestorGoal: SaveInvestorGoal = {
		goalDripEnabled: true,
		goalName: '',
		goalType: null,
		targetValue: '',
		targetIncome: '',
		years: ''
	};

	minimumAmount = '0';
	maxAmount = '0';
	minimumMonthlyInvestment = '10';
	maximumMonthlyInvestment = '20000';
	autoInvestSchedule: AutoInvestSchedule | null = null;
	autoInvestSuggestedAmount = '';
	autoInvestScheduleUpdated = false;

	get isAutoInvestGreaterThanSuggestedAmount(): boolean {
		return parseInt(this.autoInvestSchedule?.monthlyAmount ?? '0') >= parseInt(this.autoInvestSuggestedAmount);
	}

	@Action({ rawError: true })
	public async getGoalAmountOptions(years: string): Promise<GoalSelectAmount> {
		const goalAmountOptions = await getGoalAmountOptions(years);
		this.UPDATE_MINIMUM_AMOUNT(goalAmountOptions.minimumTargetValue);
		this.UPDATE_MAX_AMOUNT(goalAmountOptions.maxTargetValue);

		if (goalAmountOptions.maxMonthlyAmount) {
			this.UPDATE_MAX_GROWTH_MONTHLY_AMOUNT(goalAmountOptions.maxMonthlyAmount);
		}

		return goalAmountOptions;
	}

	@Action({ rawError: true })
	public async getIncomeGoalAmount(years: string): Promise<GoalIncomeSelectAmount> {
		const goalIncomeSelectAmount = await getGoalIncomeAmountSelect(years);
		this.UPDATE_MINIMUM_AMOUNT(goalIncomeSelectAmount.minimumTargetIncome);
		this.UPDATE_MAX_AMOUNT(goalIncomeSelectAmount.maxTargetIncome);
		return goalIncomeSelectAmount;
	}

	@Action({ rawError: true })
	public async getGoalSuggestion(params: Partial<SaveInvestorGoal>): Promise<GoalSuggestion> {
		const goalSuggestion = await getGoalSuggestion(params);
		const minimum =
			goalSuggestion.goalCategory === 'GROWTH_GOAL'
				? goalSuggestion.minimumTargetValue
				: goalSuggestion.minimumTargetIncome;
		this.UPDATE_MINIMUM_AMOUNT(minimum ?? '0');
		return goalSuggestion;
	}

	@Action({ rawError: true })
	public async submitGoalType(params: Partial<SaveInvestorGoal>): Promise<string> {
		const updatedInvestorGoal = {
			...this.saveInvestorGoal,
			...params
		};
		this.UPDATE_SAVE_INVESTOR_GOAL(updatedInvestorGoal);
		return 'investment-goal-time-horizon';
	}

	@Action({ rawError: true })
	public async submitTimeHorizon(years: string): Promise<string> {
		const updatedInvestorGoal = {
			...this.saveInvestorGoal,
			years
		};
		this.UPDATE_SAVE_INVESTOR_GOAL(updatedInvestorGoal);
		return 'investment-goal-amount';
	}

	@Action({ rawError: true })
	public async submitTargetAmount(targetValue: string): Promise<string> {
		const updatedInvestorGoal = {
			...this.saveInvestorGoal,
			targetValue
		};
		this.UPDATE_SAVE_INVESTOR_GOAL(updatedInvestorGoal);
		return 'investment-goal-confirm';
	}

	@Action({ rawError: true })
	public async submitTargetIncome(targetIncome: string): Promise<string> {
		const updatedInvestorGoal = {
			...this.saveInvestorGoal,
			targetIncome
		};
		this.UPDATE_SAVE_INVESTOR_GOAL(updatedInvestorGoal);
		return 'investment-goal-confirm';
	}

	@Action({ rawError: true })
	public async submitDrip(): Promise<void> {
		await updateDrip('RIA_REINVESTMENT');
	}

	@Action({ rawError: true })
	public async getAutoInvestSchedule(): Promise<void> {
		try {
			const schedule = await getRecurringInvestmentSchedule();
			this.UPDATE_AUTO_INVEST_SCHEDULE(schedule);
		} catch {}
	}

	@Action({ rawError: true })
	public async fetchAndStoreCurrentGoal(): Promise<void> {
		if (investmentEntity.investorGoalExists) {
			const currentGoal = await getCurrentGoal();
			this.UPDATE_CURRENT_GOAL(currentGoal);
		}
	}

	@Action({ rawError: true })
	public async saveOneClickGoal(): Promise<GoalResponse> {
		const currentInvestorGoal = await saveOneClickGoal();
		this.UPDATE_CURRENT_GOAL(currentInvestorGoal);
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);

		return currentInvestorGoal;
	}

	@Action({ rawError: true })
	public async saveCurrentGoal(currentGoal: SaveInvestorGoal): Promise<GoalResponse> {
		const currentInvestorGoal = await saveCurrentGoal(currentGoal);
		this.UPDATE_CURRENT_GOAL(currentInvestorGoal);
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);

		return currentInvestorGoal;
	}

	@Action({ rawError: true })
	public async updateCurrentGoal(currentGoal: UpdateInvestorGoal): Promise<GoalResponse> {
		const currentInvestorGoal = await updateCurrentGoal(currentGoal);
		this.UPDATE_CURRENT_GOAL(currentInvestorGoal);
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);

		return currentInvestorGoal;
	}

	@Action({ rawError: true })
	public async deleteGoal(): Promise<void> {
		await deleteGoal();
		this.UPDATE_CURRENT_GOAL(null);
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(false);
	}

	@Action({ rawError: true })
	public updateDefaultGoalCreatedPreferences(monthlySuggestedAmount: string): void {
		this.UPDATE_SUGGESTED_AUTO_INVEST_AMOUNT(monthlySuggestedAmount);
		investmentEntity.UPDATE_INVESTOR_GOAL_EXISTS(true);
	}

	@Action({ rawError: true })
	public reset(): void {
		const defaultSaveInvestorGoal: SaveInvestorGoal = {
			goalDripEnabled: true,
			goalName: '',
			goalType: null,
			targetValue: '',
			targetIncome: '',
			years: ''
		};
		this.UPDATE_SAVE_INVESTOR_GOAL(defaultSaveInvestorGoal);
		this.UPDATE_AUTO_INVEST_SCHEDULE(null);
		this.UPDATE_AUTO_INVEST_SCHEDULE_UPDATED(false);
	}

	@Mutation
	public UPDATE_CURRENT_GOAL(currentGoal: GoalResponse | null): void {
		this.currentGoal = currentGoal;
	}

	@Mutation
	public UPDATE_SAVE_INVESTOR_GOAL(saveInvestorGoal: SaveInvestorGoal): void {
		this.saveInvestorGoal = saveInvestorGoal;
	}

	@Mutation
	public UPDATE_MAX_AMOUNT(maxAmount: string): void {
		this.maxAmount = maxAmount;
	}

	@Mutation
	public UPDATE_MAX_GROWTH_MONTHLY_AMOUNT(maxMonthlyAmount: string): void {
		this.maximumMonthlyInvestment = maxMonthlyAmount;
	}

	@Mutation
	public UPDATE_MINIMUM_AMOUNT(minimumAmount: string): void {
		this.minimumAmount = minimumAmount;
	}

	@Mutation
	public UPDATE_AUTO_INVEST_SCHEDULE(autoInvestSchedule: AutoInvestSchedule | null): void {
		this.autoInvestSchedule = autoInvestSchedule;
	}

	@Mutation
	public UPDATE_SUGGESTED_AUTO_INVEST_AMOUNT(autoInvestSuggestedAmount: string): void {
		this.autoInvestSuggestedAmount = autoInvestSuggestedAmount;
	}

	@Mutation
	public UPDATE_AUTO_INVEST_SCHEDULE_UPDATED(autoInvestScheduleUpdated: boolean): void {
		this.autoInvestScheduleUpdated = autoInvestScheduleUpdated;
	}
}

export const investmentGoals = getModule(InvestorGoalModule);
