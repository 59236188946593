import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AutoInvestOptions, AutoInvestSchedule, RecurringInvestmentScheduleSelections } from 'types/auto-invest';
import {
	cacheRecurringInvestmentScheduleSelections,
	getAgreements,
	getFrequenciesAndAmounts,
	loadRecurringInvestmentScheduleSelections,
	setRecurringInvestmentSchedule
} from '@api/auto-invest';
import { investmentEntity } from '@store/modules/investment-entity';
import store from '..';

export interface AutoInvestState {
	agreements: Array<string>;
	autoInvestSchedule: AutoInvestSchedule;
	formattedFirstInvestmentDay: string;
}

@Module({
	dynamic: true,
	namespaced: true,
	name: 'autoInvest',
	store
})
class AutoInvestModule extends VuexModule {
	agreements: Array<string> = [];
	autoInvestSchedule: AutoInvestSchedule | null = null;
	formattedFirstInvestmentDay = '';
	frequenciesAndAmounts: AutoInvestOptions = {
		amounts: [],
		frequencies: [],
		investmentRange: {
			maximumInvestment: '0',
			minimumInvestment: '0'
		},
		startDateOptions: []
	};

	@Action({ rawError: true })
	async getAgreements(): Promise<void> {
		const agreements = await getAgreements();
		this.UPDATE_AUTO_INVEST_AGREEMENTS(agreements);
	}

	@Action({ rawError: true })
	async setRecurringInvestmentSchedule(): Promise<void> {
		if (this.autoInvestSchedule) {
			if (
				this.autoInvestSchedule?.frequency === 'MONTHLY' ||
				this.autoInvestSchedule?.frequency === 'SEMI_MONTHLY'
			) {
				this.UPDATE_AUTO_INVEST_SCHEDULE({
					...this.autoInvestSchedule,
					dayOfWeek: undefined,
					startDate: undefined
				});
			} else {
				this.UPDATE_AUTO_INVEST_SCHEDULE({ ...this.autoInvestSchedule, firstInvestmentDayOfMonth: undefined });
			}

			const response = await setRecurringInvestmentSchedule(this.autoInvestSchedule);
			investmentEntity.UPDATE_AUTO_INVEST_SCHEDULE(response);
			investmentEntity.UPDATE_HAS_ACTIVE_RECURRING_INVESTMENT_SCHEDULE(true);
		}
	}

	@Action({ rawError: true })
	public updateAutoInvestSchedule(autoInvestSchedule: AutoInvestSchedule): void {
		this.UPDATE_AUTO_INVEST_SCHEDULE(autoInvestSchedule);
	}

	@Action({ rawError: true })
	public updateFormattedFirstInvestmentDayOfMonth(formattedFirstInvestmentDay: string): void {
		this.UPDATE_FORMATTED_FIRST_INVESTMENT_DAY_OF_MONTH(formattedFirstInvestmentDay);
	}

	@Action({ rawError: true })
	async getFrequenciesAndAmounts(): Promise<void> {
		const frequenciesAndAmounts = await getFrequenciesAndAmounts();
		this.UPDATE_FREQUENCIES_AND_AMOUNTS(frequenciesAndAmounts);
	}

	@Action({ rawError: true })
	async cacheSelections(): Promise<void> {
		if (this.autoInvestSchedule) {
			let selections: RecurringInvestmentScheduleSelections = {
				amount: this.autoInvestSchedule.amount.toString(),
				frequency: this.autoInvestSchedule.frequency
			};

			if (
				this.autoInvestSchedule?.frequency === 'MONTHLY' ||
				this.autoInvestSchedule?.frequency === 'SEMI_MONTHLY'
			) {
				selections = {
					...selections,
					firstInvestmentDayOfMonth: this.autoInvestSchedule.firstInvestmentDayOfMonth?.toString()
				};
			} else {
				selections = {
					...selections,
					dayOfWeek: this.autoInvestSchedule.dayOfWeek,
					startDate: this.autoInvestSchedule.startDate
				};
			}

			await cacheRecurringInvestmentScheduleSelections(selections);
		}
	}

	@Action({ rawError: true })
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async fetchCachedSelections(): Promise<any> {
		try {
			const cachedSelections: RecurringInvestmentScheduleSelections =
				await loadRecurringInvestmentScheduleSelections();

			return cachedSelections;
		} catch {
			return null;
		}
	}

	@Mutation
	public UPDATE_AUTO_INVEST_AGREEMENTS(agreements: Array<string>): void {
		this.agreements = agreements;
	}

	@Mutation
	public UPDATE_AUTO_INVEST_SCHEDULE(autoInvestSchedule: AutoInvestSchedule): void {
		this.autoInvestSchedule = autoInvestSchedule;
	}

	/* eslint-enable @typescript-eslint/no-explicit-any */
	@Mutation
	public UPDATE_FORMATTED_FIRST_INVESTMENT_DAY_OF_MONTH(formattedFirstInvestmentDay: string): void {
		this.formattedFirstInvestmentDay = formattedFirstInvestmentDay;
	}

	@Mutation
	public UPDATE_FREQUENCIES_AND_AMOUNTS(frequenciesAndAmounts: AutoInvestOptions): void {
		this.frequenciesAndAmounts = frequenciesAndAmounts;
	}
}

export const autoInvest = getModule(AutoInvestModule);
