import { checkAndSwitchToIraEntity } from './ira-navigation-guards';
import { FundriseRouteConfig } from 'vue-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraReacknowledgeRolloverRoutes: FundriseRouteConfig = {
	path: `/ira/reacknowledge-rollover`,
	name: 'ira-reacknowledge-rollover',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	beforeEnter: checkAndSwitchToIraEntity,
	component: () =>
		import(
			/* webpackChunkName: "ira-reacknowledge-rollover-start" */ '@views/ira/reacknowledge-rollover/ira-reacknowledge-rollover-start.vue'
		),
	children: [
		{
			path: `agreements`,
			name: 'ira-reacknowledge-rollover-agreements',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(/* webpackChunkName: "ira-start-agreements" */ '@views/ira/signup/ira-agreements.vue')
		},
		{
			path: `success`,
			name: 'ira-reacknowledge-rollover-success',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import(/* webpackChunkName: "ira-start-success" */ '@views/ira/signup/ira-success.vue')
		}
	]
};

export default iraReacknowledgeRolloverRoutes;
