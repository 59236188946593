import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	getTwoFactorMetadata,
	sendEmail2faCode,
	sendPhoneCall2faCode,
	sendSms2faCode,
	submitTwoFactorAuthCode
} from '@api/login';
import { TwoFactorAuthCodeForm, TwoFactorCodeDeliveryMethod, TwoFactorMetadata } from 'types/authentication';
import { app } from '@store/modules/app';
import { OauthTokenResponse } from 'types/security';
import { sendMobileVerificationCode } from '@api/user';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'login',
	store
})
class LoginModule extends VuexModule {
	hasCompletedLoginMobileVerifySelect = false;
	selectedTwoFactorCodeDeliveryMethod: TwoFactorCodeDeliveryMethod = 'NONE';

	twoFactorMetadata: TwoFactorMetadata | null = null;

	@Action({ rawError: true })
	public async storeTwoFactorMetadata(): Promise<void> {
		if (this.selectedTwoFactorCodeDeliveryMethod === 'NONE') {
			const twoFactorMetadata = await getTwoFactorMetadata();

			this.UPDATE_TWO_FACTOR_METADATA(twoFactorMetadata);
			this.UPDATE_2FA_CODE_DELIVERY_METHOD(twoFactorMetadata.twoFactorType === 'TOTP' ? 'TOTP' : 'NONE');
		}
	}

	@Action({ rawError: true })
	public resetTwoFactorSelections(): void {
		this.UPDATE_2FA_CODE_DELIVERY_METHOD('NONE');
	}

	@Action({ rawError: true })
	public async submitLoginMobileVerifySelect(phoneNumberString: string): Promise<string> {
		this.UPDATE_HAS_COMPLETED_LOGIN_MOBILE_VERIFY_SELECT(true);
		await sendMobileVerificationCode(phoneNumberString);
		return 'login-mobile-verify-confirm';
	}

	@Action({ rawError: true })
	public async submitTwoFactorCode(twoFactorAuthForm: TwoFactorAuthCodeForm): Promise<OauthTokenResponse> {
		const successResponse: OauthTokenResponse = await submitTwoFactorAuthCode(twoFactorAuthForm);
		await app.setUserAuthData(successResponse);

		this.UPDATE_2FA_CODE_DELIVERY_METHOD('NONE');
		this.UPDATE_TWO_FACTOR_METADATA(null);

		return successResponse;
	}

	@Action({ rawError: true })
	public async send2faCode(method: TwoFactorCodeDeliveryMethod): Promise<string> {
		const nextRoute = 'login-two-factor-confirm';
		switch (method) {
			case 'EMAIL':
				this.UPDATE_2FA_CODE_DELIVERY_METHOD('EMAIL');
				await sendEmail2faCode();
				return nextRoute;
			case 'SMS':
				this.UPDATE_2FA_CODE_DELIVERY_METHOD('SMS');
				await sendSms2faCode();
				return nextRoute;
			case 'PHONE_CALL':
				this.UPDATE_2FA_CODE_DELIVERY_METHOD('PHONE_CALL');
				await sendPhoneCall2faCode();
				return nextRoute;
			default:
				return '/';
		}
	}

	@Mutation
	public UPDATE_HAS_COMPLETED_LOGIN_MOBILE_VERIFY_SELECT(hasVisited: boolean): void {
		this.hasCompletedLoginMobileVerifySelect = hasVisited;
	}

	@Mutation
	public UPDATE_2FA_CODE_DELIVERY_METHOD(selectedTwoFactorCodeDeliveryMethod: TwoFactorCodeDeliveryMethod): void {
		this.selectedTwoFactorCodeDeliveryMethod = selectedTwoFactorCodeDeliveryMethod;
	}

	@Mutation
	public UPDATE_TWO_FACTOR_METADATA(twoFactorMetadata: TwoFactorMetadata | null): void {
		this.twoFactorMetadata = twoFactorMetadata;
	}
}

export const login = getModule(LoginModule);
