import {
	ModularDashboardAccountChecklist,
	ModularDashboardFundTeaser,
	ModularDashboardPortfolioOverview,
	ModularDashboardProgressModule
} from 'types/modular-dashboard/custom';
import { ModularDashboardData, ModularDashboardMetadata } from 'types/modular-dashboard';
import { AccountValueChartTimeHorizonType } from 'types/account';
import { FeaturedCard } from 'types/layout';
import { investmentEntityRequest } from '@utils/request';
import { ModularDashboardDeltaItem } from 'types/modular-dashboard/delta-module';
import { PerformanceContextCardData } from 'types/performance-context';

export const dismissDashboardModule = async (module: string): Promise<void> => {
	await investmentEntityRequest.post(`/dashboard-modules/dismiss`, { module });
};

export const getModularDashboardData = async (): Promise<ModularDashboardData> => {
	const response = await investmentEntityRequest.get(`/modular-dashboard`);
	return response.data;
};

export const getModularDashboardMetadata = async (): Promise<ModularDashboardMetadata> => {
	const response = await investmentEntityRequest.get(`/modular-dashboard/metadata`);
	return response.data;
};

export const getDeltaModuleData = async (moduleName: string): Promise<ModularDashboardDeltaItem> => {
	const response = await investmentEntityRequest.get(`/modules/DELTA/${moduleName}`);
	return response.data;
};

export const getFeaturedModuleData = async (moduleName: string): Promise<FeaturedCard> => {
	const response = await investmentEntityRequest.get(`/modules/FEATURED_CONTENT/${moduleName}`);
	return response.data;
};

export const getAccountChecklistModuleData = async (): Promise<ModularDashboardAccountChecklist> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/ACCOUNT_CHECKLIST');
	return response.data;
};

export const getPortfolioOverviewModuleData = async (): Promise<ModularDashboardPortfolioOverview> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/PORTFOLIO_OVERVIEW');
	return response.data;
};

export const getProgressModuleData = async (): Promise<ModularDashboardProgressModule> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/PROGRESS');
	return response.data;
};

export const getFundTeaserModuleData = async (): Promise<ModularDashboardFundTeaser> => {
	const response = await investmentEntityRequest.get(`/modules/CUSTOM/FUND_TEASER`);
	return response.data;
};

export const getPerformanceContextData = async (
	timeRange: AccountValueChartTimeHorizonType
): Promise<Array<PerformanceContextCardData>> => {
	const response = await investmentEntityRequest.get('/modules/CUSTOM/ACCOUNT_VALUE_CHART', {
		params: {
			timeRange
		}
	});
	return response.data;
};
