<template>
	<div class="display-flex mt-150">
		<div class="pr-100 ellipsis">
			<div class="overline body-xs ellipsis">Strategy</div>
			<div class="text-align-center display-flex align-items-center">
				<span class="strategy-badge--small mr-50" :style="{ 'background-color': strategyColor }"></span>
				<span class="ellipsis">{{ strategyName }}</span>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'NewsfeedCardContentAsset'
};
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { NewsFeedItem } from 'types/account';
import { realEstateStrategyColor } from '@filters/shared-filters';

interface Props {
	item: NewsFeedItem;
}
const props = defineProps<Props>();

const strategyName = computed((): string => {
	return props.item.associatedReitElements?.strategy?.name ?? '';
});

const strategyColor = computed((): string => {
	return realEstateStrategyColor(props.item.associatedReitElements?.strategy?.id ?? '');
});
</script>
