import { AccountDocumentMonthlyAccountStatement, AccountDocumentsData } from 'types/account';
import { AdvisorInviteRelationshipRequest, AdvisorSignupForm, InvestmentAdvisorDetails } from 'types/advisor';
import { apiRequest, apiSecureRequest } from '@utils/request';
import { advisor } from '@store/modules/advisor';
import { AdvisorAutoInvestSchedule } from 'types/auto-invest';
import { AxiosResponse } from 'axios';
import { CreateUserResponse } from 'types/user';
import { FundListOverviewDto } from 'types/reit-element';
import { ReturnsByAssetClass } from 'types/performance-portfolio';
import { setCookie } from '@utils/web-storage';

export const createAdvisorUser = async (advisorSignupForm: AdvisorSignupForm): Promise<CreateUserResponse> => {
	const response: AxiosResponse = await apiRequest.post(`/investment-advisor`, advisorSignupForm);
	setCookie('userLoggedIn', 'true', response.data.accessToken.refresh_expires_in);
	return response.data;
};

export const getInvestmentAdvisorDetails = async (): Promise<InvestmentAdvisorDetails> => {
	const response: AxiosResponse = await apiSecureRequest.get(`/investment-advisor/details`);
	return response.data;
};

export const createAdvisorRelationshipRequest = async (
	advisorRelationshipRequestForm: AdvisorInviteRelationshipRequest
): Promise<void> => {
	await apiSecureRequest.post(`/investment-advisor/relationship-request`, advisorRelationshipRequestForm);
};

export const getRecurringInvestmentScheduleForEntity = async (
	investmentEntityId: string
): Promise<AdvisorAutoInvestSchedule> => {
	const response = await apiSecureRequest.get(
		`/investment-advisor/investment-entity/${investmentEntityId}/recurring-investment-schedule`,
		{ suppressToast: (resp: AxiosResponse) => resp.status === 404 }
	);
	return response.data;
};
export async function getAdvisorOfferingsList(): Promise<FundListOverviewDto> {
	const response = await apiRequest.get(`/investment-advisor/offering/list`);
	return response.data;
}

export const getClientAccountDocuments = async (): Promise<AccountDocumentsData> => {
	const response = await apiSecureRequest.get(
		`/investment-advisor/investment-entity/${advisor.selectedInvestmentEntityId}/account/account-documents`
	);
	return response.data;
};

export const getClientMonthlyAccountStatement = async (
	yearMonthDay: string
): Promise<AccountDocumentMonthlyAccountStatement> => {
	const response = await apiSecureRequest.get(
		`/investment-advisor/investment-entity/${advisor.selectedInvestmentEntityId}/monthly-account-statement`,
		{
			params: { yearMonthDay },
			suppressToast: () => true
		}
	);
	return response.data;
};

export const referAdvisor = async (advisorEmail: string): Promise<void> => {
	await apiSecureRequest.post('/advisor/refer', {
		advisorEmail
	});
};

export const getAllocationOverview = async (): Promise<ReturnsByAssetClass> => {
	const response = await apiSecureRequest.get('/aps/investment-advisor/allocations');
	return response.data;
};
