<template>
	<div :class="['intercom-launcher', { 'flex-column': showProminentPopup }]">
		<div v-if="showProminentPopup" class="intercom-launcher__popup">
			<IntercomProminentPopup
				:data="prominentPopupData"
				@popup-dismissed="onCloseProminentPopup()"
				@cta-clicked="handlePopupCtaClick"
			/>
		</div>
		<div v-else-if="showSubtlePopup" class="display-flex">
			<div v-if="showSubtlePopupLabel" class="intercom-launcher__label display-none-smo"> Have a question? </div>
			<BaseButton
				button-id="intercom-custom-launcher"
				class="intercom-launcher__bubble"
				data-test="intercom-subtle"
				@click="onClick"
			>
				<BaseSvgIcon name="chat-bubble" width="40" height="40" />
			</BaseButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getItemFromSessionStorageAsBoolean, setItemInSessionStorage } from '@utils/web-storage';
import { isAuthenticated, isMobile } from '@utils/composables';
import { getIntercomPopupContent } from '@api/prismic';
import { IntercomPopupContentSlice } from 'types/prismic';
import IntercomProminentPopup from '@components/layout/intercom-prominent-popup.vue';
import { intercomShowNewMessage } from '@utils/intercom';
import { useRoute } from 'vue-router';

const route = useRoute();

const INTERCOM_TIMEOUT_MS = 60 * 1000;
const INTERCOM_SESSION_STORAGE_KEY = 'intercom-label-shown';
const PROMINENT_POPUP_SESSION_STORAGE_KEY = 'intercom-prominent-popup-dismissed';

const showProminentPopup = ref(false);
const showSubtlePopup = ref(false);
const showSubtlePopupLabel = ref(false);

const prominentPopupData = ref<IntercomPopupContentSlice | null>(null);

const routePath = computed((): string => route.path);

watch(() => routePath.value, render);
watch(() => isAuthenticated.value, render);

function onClick(): void {
	onCloseProminentPopup();
	showSubtlePopupLabel.value = false;
}

function onCloseProminentPopup(): void {
	hideProminentPopup();
	displaySubtlePopup();
}

function handlePopupCtaClick(message: string): void {
	intercomShowNewMessage(message);
	onClick();
}

async function getPopupContent(): Promise<IntercomPopupContentSlice> {
	const content = await getIntercomPopupContent();

	return (
		content.slices.find((slice) => slice.route?.split(',').some((slug) => route.path.startsWith(slug))) ??
		content.primary
	);
}

function displaySubtlePopup(): void {
	showSubtlePopup.value = true;

	const shown = getItemFromSessionStorageAsBoolean(INTERCOM_SESSION_STORAGE_KEY);
	if (!shown && !isMobile.value) {
		showSubtlePopupLabel.value = true;
		setTimeout(() => (showSubtlePopupLabel.value = false), INTERCOM_TIMEOUT_MS);
		setItemInSessionStorage(INTERCOM_SESSION_STORAGE_KEY, JSON.stringify(true));
	}
}

function displayProminentPopup(): void {
	const dismissed = getItemFromSessionStorageAsBoolean(PROMINENT_POPUP_SESSION_STORAGE_KEY);
	if (!dismissed) {
		showProminentPopup.value = true;
		setTimeout(() => onCloseProminentPopup(), INTERCOM_TIMEOUT_MS);
	} else {
		displaySubtlePopup();
	}
}

function hideProminentPopup(): void {
	showProminentPopup.value = false;
	setItemInSessionStorage(PROMINENT_POPUP_SESSION_STORAGE_KEY, JSON.stringify(true));
}

async function render(): Promise<void> {
	if (isAuthenticated.value) {
		hideProminentPopup();
		displaySubtlePopup();
	} else if (!isMobile.value) {
		prominentPopupData.value = await getPopupContent();
		if (prominentPopupData.value) {
			displayProminentPopup();
		} else {
			displaySubtlePopup();
		}
	} else {
		displaySubtlePopup();
	}
}

function setup(): void {
	render();
}

setup();
</script>
<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;
@use '../../styles/utilities/respond-to.scss' as *;

.intercom-launcher {
	display: flex;
	column-gap: 8px;
	position: fixed;
	bottom: 13px;
	right: 13px;
	z-index: 5000;

	&.flex-column {
		flex-direction: column;
		row-gap: 16px;
		align-items: flex-end;
	}

	&__popup {
		position: relative;
		@include respond-to(md) {
			width: 283px;
		}
	}

	&__label {
		position: relative;
		display: flex;
		align-items: center;
		padding: 4px 8px;
		background-color: white;
		border: 2px solid white;
		border-radius: 8px;
		box-shadow:
			0 0 0 1px rgba(0, 0, 0, 0.02),
			0 2px 24px 0 rgba(0, 0, 0, 0.16);
		transform: translateY(-24px);
		&::before {
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
			left: auto;
			right: 2px;
			top: calc(100% - 2px);
			border: 6px solid;
			border-color: white white transparent transparent;
		}
	}
	&__bubble {
		position: relative;
		width: 46px;
		height: 46px;
		padding: 0;
		background-color: map-get($brand-colors, rust) !important;
		border: none;
		border-radius: 50%;
		box-shadow:
			0 0 0 1px rgba(0, 0, 0, 0.02),
			0 2px 12px 0 rgba(0, 0, 0, 0.16);
		transition: transform 250ms ease-in-out;

		&:hover {
			background-color: map-get($brand-colors, fundrise-orange);
			transform: scale(1.1);
		}
	}

	@media print {
		display: none;
	}
}
</style>
