<template>
	<div v-if="showGdprBanner" class="gdpr-cookie-banner pt-100 pb-100">
		<div class="container">
			<div class="position-relative">
				<p class="eta-smo mr-200">
					We use cookies and similar tools to analyze the usage of our site and give you a better experience.
					Your continued use of the site means that you consent to our cookies and similar tools. Read our
					<BaseLink
						classes="text-color-current-color text-decoration-underline"
						:link="privacyPolicyDocLink"
					/>
					for more information and to learn how to amend your&nbsp;settings.
				</p>
				<BaseLinkButton class="modal-close" @click="dismissGdprBanner">
					<BaseSvgIcon name="close" />
				</BaseLinkButton>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { getItemAsBooleanFromLocalStorage, setItemInLocalStorage } from '@utils/web-storage';
import moment from 'moment';
import { privacyPolicyDocLink } from '@constants/links';
import { timezonesInEU } from '@constants';

const dismissed = ref(false);

function dismissGdprBanner(): void {
	setItemInLocalStorage('gdprBannerDismissedTimestamp', moment().format('X'));
	dismissed.value = true;
}

const showGdprBanner = computed((): boolean => {
	const hasDismissedGdprBanner = getItemAsBooleanFromLocalStorage('gdprBannerDismissedTimestamp');
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	return !hasDismissedGdprBanner && timezonesInEU.includes(timezone) && !dismissed.value;
});
</script>
<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;
@use '../../styles/constants/layout' as *;

.gdpr-cookie-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $main-nav-z-index;
	background-color: dark(100);
	color: #fff;
	box-shadow: $overlay-box-shadow;
}
</style>
