import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import type { StucturedErrorContext } from 'types/error-tracking';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorInstance(error: any): error is Error {
	return error instanceof Error;
}

export function isSuppressedStatusCode(error: AxiosError) {
	const statusUnauthorized = 401;
	const statusWafCaptcha = 405;
	const statusMaintenanceMode = 540;
	const suppressedErrorCodes = [statusUnauthorized, statusWafCaptcha, statusMaintenanceMode];

	if (error.response?.status) {
		return suppressedErrorCodes.includes(error.response?.status);
	}

	return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processResponseData(data: any): any {
	if (data.error === 'invalid_token' || data.error_description?.includes('token')) {
		data.error_description = 'XXX';
	}

	return data;
}

export function logError(
	err: Error | string,
	severity: Sentry.SeverityLevel = 'error',
	context?: StucturedErrorContext
) {
	const errorInstance = isErrorInstance(err) ? err : new Error(err);
	Sentry.captureException(errorInstance, (scope) => {
		scope.clear();
		scope.setLevel(severity);
		if (context) {
			scope.setContext(context.key, context.data);
		}
		return scope;
	});

	datadogRum.addError(errorInstance, context?.data);
}

export const denyUrls: Array<string | RegExp> = [
	/d\.impactradius-event\.com/i,
	// Facebook flakiness
	/graph\.facebook\.com/i,
	// Facebook blocked
	/connect\.facebook\.net\/en_US\/all\.js/i,
	// Woopra flakiness
	/eatdifferent\.com\.woopra-ns\.com/i,
	/static\.woopra\.com\/js\/woopra\.js/i,
	// Chrome extensions
	/extensions\//i,
	/^chrome:\/\//i,
	// Other plugins
	/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
	/webappstoolbarba\.texthelp\.com\//i,
	/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	/maps\.googleapis\.com\/maps\/api/i,
	/@safari-extension/i,
	/@safari-web-extension/i,
	/https:\/\/(www\.)?fmdumps\.tools\.yodlee\.com\/404/i,
	/cdn\.userway\.org/i,
	/cdn\.pdst\.fm/i,
	/cloudfront\.net\/lp\.js/i, // snowplow
	/quantum-boost/i,
	/googletagmanager\.com\/gtm\.js\?id=GTM-[A-Z0-9]+/i // Google tag manager
];

export const ignoreErrors: Array<string | RegExp> = [
	// Common Sentry errors
	'SymBrowser_ModifyAnchorTagWithTarget', // common Sentry error
	/ztePageScrollModule/, // common Sentry error
	/vid_mate_check/, // common Sentry error
	'get_mSecure_Captured_Username', // password manager error
	'l.LegacyGlobal.should_do_lastpass_here is not a function.', // password manager error
	'ResizeObserver loop completed with undelivered notifications', // password manager error
	// General errors
	/Network\s?Error/i,
	'Network request failed',
	'Request aborted',
	/Blocked a frame with origin/,
	'Failed to fetch',
	/'?(Loading (CSS )?chunk|ChunkLoadError)'?/i,
	'NotAllowedError', // user permission denies playing video/audio
	'The request is not allowed', // Error on getFile
	'InvalidStateError: The object is in an invalid state.', // Safari specific issue related
	/load failed/i, // Unspecified load errors
	/timeout.*exceeded/i, // Unspecified timeout errors
	'Timeout', // Unspecified timeout errors
	/The operation timed out/i, // Unspecified timeout errors
	'<unknown>', // Unspecificed errors usually caused by extensions
	/captured as promise rejection/i, // Unspecificed errors usually caused by extensions
	/promise rejection captured/i,
	/^\[object/i, // Unspecificed errors usually caused by extensions
	'e.contentWindow.postMessage',
	/shadowRoot/,
	/Failed to initialize WebGL/i,
	/logicalFormForControl/,
	`Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document`, // result of user blocking all cookies
	/The operation is insecure/i, // result of user blocking all cookies
	/Type error/i, // Unspecified type errors
	// Third party errors
	'fuckAdBlock', // library with no updates since 2015 (same as blockAdBlock)
	'blockAdBlock', // library with no updates since 2015
	`UET is not defined`,
	`'UET' is not defined`,
	`Can't find variable: UET`,
	'ttd_dom_ready',
	'CHzr/R.prototype.handlePageWillLeave(i18n/pixel/events)', // TikTok Pixel error
	`Cannot read properties of undefined (reading 'x')`, // AWS WAF
	`undefined is not an object (evaluating 't.x')`, // AWS WAF
	'e.select is not a function', // highcharts
	'The string did not match the expected pattern', // Google text highlighting
	'jQuery is not defined', // jQuery
	'$ is not defined', // jQuery
	// Webview errors
	'mraid', // webview issue
	'webkit.messageHandlers', // webview issue
	// Sandboxed document errors
	"Unexpected token '='", // Document sandboxed error
	'The document is sandboxed' // Document sandboxed error
];
