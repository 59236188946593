import { app } from '@store/modules/app';

async function waitForAwsWafIntegration() {
	if (!app.wafIsLoaded) {
		return new Promise((resolve, reject) => {
			const maxAttempts = 10; // Maximum number of attempts
			const interval = 100; // Interval in milliseconds between attempts
			let attempts = 0;

			const checkAwsWafIntegration = () => {
				attempts++;
				if (window.AwsWafIntegration) {
					app.SET_WAF_IS_LOADED(true);
					resolve(true);
				} else if (attempts >= maxAttempts) {
					app.SET_WAF_IS_LOADED(false);
					reject(new Error('AWS WAF Integration is not available.'));
				} else {
					setTimeout(checkAwsWafIntegration, interval);
				}
			};

			checkAwsWafIntegration();
		});
	}
}

export async function getWafCaptchaToken() {
	if (process.env.VUE_APP_WAF_CHALLENGE_URL && process.env.VUE_APP_WAF_CAPTCHA_URL) {
		await waitForAwsWafIntegration();
		return window.AwsWafIntegration.getToken();
	}
}
