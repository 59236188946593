import {
	forgotPasswordInvalidPasswordResetKey,
	forgotPasswordTooManyAuthAnswerAttempts,
	forgotPasswordTooManyUsernameGuesses,
	forgotPasswordUserIsMalicious
} from '@constants';

export function forgotPasswordErrorRoute(errorCode: string) {
	switch (errorCode) {
		case forgotPasswordInvalidPasswordResetKey:
			return 'forgot-password-invalid-key';
		case forgotPasswordTooManyUsernameGuesses:
			return 'forgot-password-guess-username-attempts';
		case forgotPasswordTooManyAuthAnswerAttempts:
			return 'forgot-password-auth-question-attempts';
		case forgotPasswordUserIsMalicious:
			return 'forgot-password-auth-question-attempts';
		default:
			return 'home';
	}
}
