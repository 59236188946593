import { CopyWithHeadline, QuestionItem } from 'types/layout';

export const opportunisticCreditFundId = '27';

export const ipoTopQuestions: Array<QuestionItem> = [
	{
		question: 'How do I make money?',
		answer: 'By investing in Rise Companies Corp., you are purchasing common stock of a company that owns and operates the Fundrise Platform, among other assets. Your returns are based on the change in the value of the company and its assets. We believe the market potential for Fundrise is substantial and our traction to date demonstrates our performance trajectory. However, there is no guarantee of profit, Rise Companies has not paid dividends, has no current intention of paying dividends in the future, and investors may be subject to partial or total loss of their&nbsp;investment.'
	},
	{
		question: 'When can I expect to see a profit?',
		answer: 'This is a long-term investment with no guarantee of profit. Subject to market conditions, we may consider a sale or registered Initial Public Offering (IPO) in 3-5 years. As an investor, we intend to send you regular updates and reports on our growth, such as annual and semi-annual reports. We want to set appropriate expectations as it takes time to build a truly valuable company. If you are a short-term, risk averse, dividend seeking, or inexperienced investor, the Fundrise iPO is likely not for you.  It is recommended that all potential investors consult with their investment advisor prior to investing in The Fundrise iPO. For more information, please see page 6 of the <a href="https://www.fundrise.com/oc#ipo" target="_blank">Offering&nbsp;Circular</a>.'
	},
	{
		question: 'What exactly am I investing in?',
		answer: 'When you invest in The Fundrise iPO, you are purchasing shares of Rise Companies Corp., the parent company and owner of the Fundrise Platform (&ldquo;Fundrise&rdquo;), as well as other subsidiaries (see full chart on page 3 of the <a href="https://www.fundrise.com/oc#ipo" target="_blank">Offering&nbsp;Circular</a>). Rise Companies also acts as the sponsor of eREIT&trade;.'
	},
	{
		question: 'What are the risks involved in this investment?',
		answer: 'As Rise Companies Corp. is a high growth technology company, investing in our common stock involves a number of risks and should only be considered by sophisticated investors who understand the risks involved and can withstand the loss of their entire investment. All investors should carefully review the Risk Factors section of the <a href="https://www.fundrise.com/oc#ipo" target="_blank">Offering Circular</a>, which contains a detailed discussion of the material risks that each investor should consider before&nbsp;investing.'
	},
	{
		question: 'What are you going to do with the money you raise?',
		answer: 'We are fundraising to increase our capitalization and financial flexibility. We plan to use the funds for general corporate purposes, which could include expanding our team, building new products, and growing our user base. For additional detail, see &ldquo;Use of Proceeds&rdquo; of the <a href="https://www.fundrise.com/oc#ipo" target="_blank">Offering Circular</a>.'
	},
	{
		question: 'What are the fees associated with this investment?',
		answer: 'There are no fees associated with this&nbsp;investment.'
	},
	{
		question:
			'Why are you choosing to raise money from existing customers rather than go a more traditional route?',
		answer: 'The Fundrise iPO is core to our mission to democratize investment through the Internet. We believe that by having investors in our programs, such as eREIT,  become &ldquo;owners&rdquo; of Rise Companies Corp. will increase our brand equity, transforming these investors into champions of our business &mdash; energizing our model in a way that will drive significant value to the company, and therefore for investors in The Fundrise iPO. Just like what Vanguard did for mutual funds, we believe that allowing investors in our programs to participate in the success and ownership of Fundrise will help keep the company&rsquo;s interests aligned with our investors.'
	}
];

export const ipoOverview: Array<CopyWithHeadline> = [
	{
		headline: 'What is the Fundrise iPO?',
		paragraphs: [
			'The Fundrise iPO (internet public offering), is a wholly unique ownership model whereby individual investors on Fundrise are given the opportunity to become investors in the company itself.',
			'We created it to give our investors the opportunity to invest in and become owners of Fundrise itself, and in doing so align the interests of the shareholders of Fundrise with the interests of our customers, by making them one and the same.'
		]
	},
	{
		headline: 'How does it work?',
		paragraphs: [
			'The Fundrise iPO is available exclusively to existing investors on a limited basis. To be eligible to invest, you must be an existing investor with at least $500 invested on the platform.',
			'The Fundrise iPO is meant to be a long-term illiquid investment and, unlike our real estate funds, the Fundrise iPO does not expect to pay a dividend.'
		]
	}
];
