import { FundriseRouteConfig } from 'vue-router';
import { kebabToEnum } from '@filters/shared-filters';
import { orderForm } from '@store/modules/order-form';
import { questionInfoFramework } from '@store/modules/question-info-framework';
import { SignupStepName } from 'types/checkout';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const questionInfoFrameworkRoutes: FundriseRouteConfig[] = [
	{
		path: '/question',
		name: 'question-framework',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const questionName = orderForm.signupCheckoutSelections?.currentStep?.questionName;

			if (to.params?.questionId || questionName) {
				next();
			} else {
				next({ name: 'checkout-resume', replace: true });
			}
		},
		component: () =>
			import(/* webpackChunkName: "question-framework" */ '@views/question-framework/question-framework.vue'),
		children: [
			{
				path: `:questionId`,
				name: 'question',
				meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const questionName = kebabToEnum(to.params.questionId as string);

					if (questionName) {
						const stepName = questionName + '_QUESTION';
						await orderForm.getUserCheckoutDetailsData(stepName as SignupStepName);
						await questionInfoFramework.getQuestion(questionName);
						next();
					} else {
						next({ name: 'checkout-resume', replace: true });
					}
				},
				component: () =>
					import(
						/* webpackChunkName: "question-framework-prompt" */ '@views/question-framework/question-framework-prompt.vue'
					)
			}
		]
	},
	{
		path: '/info',
		name: 'info-framework',
		meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
		beforeEnter: async (to, from, next) => {
			const infoName = orderForm.signupCheckoutSelections?.currentStep?.infoName;

			if (to.params?.infoSlug || infoName) {
				next();
			} else {
				next({ name: 'checkout-resume', replace: true });
			}
		},
		component: () => import(/* webpackChunkName: "info-framework" */ '@views/info-framework/info-framework.vue'),
		children: [
			{
				path: `:infoSlug`,
				name: 'info-screen',
				meta: { minTokenScopeRequired: 'UNVERIFIED', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const infoName = kebabToEnum(to.params.infoSlug as string);

					if (infoName) {
						const stepName = infoName + '_INFO';
						await orderForm.getUserCheckoutDetailsData(stepName as SignupStepName);
						await questionInfoFramework.getInfo(infoName);
						next();
					} else {
						next({ name: 'checkout-resume', replace: true });
					}
				},
				component: () => import(/* webpackChunkName: "info-screen" */ '@views/info-framework/info-screen.vue')
			}
		]
	}
];

export default questionInfoFrameworkRoutes;
