<template>
	<ChartPlaceholder :class="{ 'p-100 mb-100-smo': loading }" :loading="loading">
		<div
			v-if="displayChartValueItems"
			:class="[
				'mb-200',
				{
					'display-flex gap-200-lg gap-100-md ml-400-lg ml-200-md flex-direction-column-mdo':
						useLargeScreenStyles
				}
			]"
			data-test="chart-value-items"
		>
			<div class="display-flex justify-content-space-between">
				<NetReturnsOverTimeChartValueItem
					:class="['no-wrap', { 'mb-150': !useLargeScreenStyles }]"
					:label="valueItemLabels.valueLabel"
					:value="accountValue"
					:value-classes="[useLargeScreenStyles ? 'font-weight-bold paragraph-larger' : 'heading-1']"
					:color="colors.accountValue.line"
				/>
				<slot name="change-since-last-login"> </slot>
			</div>
			<div class="display-flex gap-200 mb-100-smo">
				<NetReturnsOverTimeChartValueItem
					class="mr-100-smo no-wrap"
					:label="valueItemLabels.contributionLabel"
					label-classes="zeta-smo"
					:value="netContribution"
					:value-classes="['font-weight-bold', { 'paragraph-larger': useLargeScreenStyles }]"
					:color="colors.netContributions.line"
				/>
				<NetReturnsOverTimeChartValueItem
					class="no-wrap"
					:label="valueItemLabels.returnLabel"
					label-classes="zeta-smo"
					:value="netReturns"
					:value-classes="['font-weight-bold', { 'paragraph-larger': useLargeScreenStyles }]"
					:color="colors.netReturns.area"
					:stretch="true"
				/>
			</div>
		</div>
		<div :style="`height: ${chartHeight}px`">
			<ValueOverTimeChart
				:custom-options="customOptions"
				:data="activeIntervalChartData"
				:default-to-last-point="true"
				:highlight-last-point="true"
				:reflow-chart="reflowChart"
				:sets="sets"
				@mouse-over="onMouseOver"
				@mouse-out="onMouseOut"
			></ValueOverTimeChart>
		</div>
		<div class="display-flex align-items-baseline justify-content-space-between">
			<Chips
				:chips="chips"
				:selected="activeChip"
				:class="['mt-100', { 'ml-400': useLargeScreenStyles }]"
				@update:model-value="onTimeChipSelected"
			></Chips>
			<slot name="cta"> </slot>
		</div>
	</ChartPlaceholder>
</template>

<script setup lang="ts">
import { computed, onActivated, onDeactivated, Ref, ref, watch } from 'vue';
import { isMobile, isProOrPremium } from '@utils/composables';
import {
	NetReturnsOverTimeChartDataPoint,
	NetReturnsOverTimeChartIntervalOption,
	NetReturnsOverTimeChartValueItemLabels,
	NetReturnsOverTimeData
} from 'types/account';
import {
	ValueOverTimeAnnotation,
	ValueOverTimeChartPoint,
	ValueOverTimeChartSeries
} from 'types/charts/value-over-time-chart';
import ChartPlaceholder from '@charts/chart-placeholder.vue';
import { Chip } from 'types/layout';
import Chips from '@components/chips/chips-component.vue';
import NetReturnsOverTimeChartValueItem from '@components/account/net-returns-over-time-chart-value-item.vue';
import { Options } from 'highcharts';
import spacetime from 'spacetime';
import ValueOverTimeChart from '@charts/value-over-time-chart.vue';

interface Props {
	classes?: string;
	chartData?: NetReturnsOverTimeData | null;
	chartAnnotations?: Array<ValueOverTimeAnnotation>;
	activeAnnotations?: Array<string>;
	chartInterval?: NetReturnsOverTimeChartIntervalOption | null;
	chips?: Array<Chip>;
	hideChartValueItems?: boolean;
	valueItemLabels: NetReturnsOverTimeChartValueItemLabels;
	useLargeScreenStyles?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	classes: '',
	chartData: null,
	chartAnnotations: () => [],
	activeAnnotations: () => [],
	chartInterval: null,
	chips: () => [],
	hideChartValueItems: false,
	useLargeScreenStyles: false
});
interface Emits {
	(e: 'active-point', value: ValueOverTimeChartPoint): void;
	(e: 'chart-interval', value: NetReturnsOverTimeChartIntervalOption): void;
	(e: 'active-annotations', value: Array<ValueOverTimeAnnotation>): void;
}
const emit = defineEmits<Emits>();
interface UpdateActivePoint {
	hoveredPoint: ValueOverTimeChartPoint | null;
	lastPoint: ValueOverTimeChartPoint | null;
}

const hoveredPoint: Ref<ValueOverTimeChartPoint | null> = ref(null);
const loading = ref(true);
const reflowChart = ref(false);

const displayChartValueItems = computed((): boolean => {
	return Boolean(hoveredPoint.value || lastPoint.value) && !props.hideChartValueItems;
});

const accountValue = computed((): string => {
	return hoveredPoint.value?.data?.accountValue.toString() ?? lastPoint.value?.data?.accountValue.toString() ?? '';
});

const netContribution = computed((): string => {
	return (
		hoveredPoint.value?.data?.netContributionInPeriod.toString() ??
		lastPoint.value?.data?.netContributionInPeriod.toString() ??
		''
	);
});

const netReturns = computed((): string => {
	return hoveredPoint.value?.data?.netReturns.toString() ?? lastPoint.value?.data?.netReturns.toString() ?? '';
});

const activeChip = computed((): Chip | null => {
	return props.chips.find((c) => c.value === props.chartInterval) ?? null;
});

const chartHeight = computed((): number => {
	return isMobile.value ? 250 : 350;
});

const customOptions = computed((): Options => {
	return {
		chart: {
			height: chartHeight.value,
			marginBottom: 50
		}
	};
});

const activeIntervalChartData = computed((): Array<ValueOverTimeChartPoint> => {
	return props.chartData && props.chartInterval ? (props.chartData[props.chartInterval] ?? []).map(formatPoint) : [];
});

const colors = computed(
	(): Record<'accountValue' | 'netContributions' | 'netReturns', { line: string; area: string }> => {
		return {
			accountValue: {
				area: isProOrPremium.value ? '#EDDCAE' : '#C3C6CA',
				line: isProOrPremium.value ? '#B68C45' : '#3C4655'
			},
			netContributions: {
				area: isProOrPremium.value ? '#FBF4E2' : '#E0E9F0',
				line: isProOrPremium.value ? '#D6C392' : '#7990A5'
			},
			netReturns: {
				area: isProOrPremium.value ? '#D6C392' : '#A7A8AA',
				line: isProOrPremium.value ? '#D6C392' : '#A7A8AA'
			}
		};
	}
);

const lastPoint = computed((): ValueOverTimeChartPoint | null => {
	if (activeIntervalChartData.value) {
		return activeIntervalChartData.value[activeIntervalChartData.value.length - 1];
	}

	return null;
});

const sets = computed((): Array<ValueOverTimeChartSeries> => {
	return [
		{
			color: colors.value.accountValue.line,
			name: 'Account Value',
			xKey: 'date',
			yKey: 'accountValue',
			enableMouseTracking: true,
			fillColor: {
				linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
				stops: [
					[0, colors.value.accountValue.area],
					[1, '#FFFFFF']
				]
			}
		},
		{
			color: colors.value.netContributions.line,
			name: 'Net Contribution',
			xKey: 'date',
			yKey: 'netContribution',
			enableMouseTracking: true,
			fillColor: {
				linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
				stops: [
					[0, colors.value.netContributions.area],
					[1, '#FFFFFF']
				]
			}
		},
		{
			color: colors.value.accountValue.line,
			name: 'Account Value Line',
			xKey: 'date',
			yKey: 'accountValue',
			fillColor: {
				stops: []
			}
		}
	];
});

const updateActivePoint = computed((): UpdateActivePoint => {
	return { hoveredPoint: hoveredPoint.value, lastPoint: lastPoint.value };
});

watch(
	() => activeIntervalChartData.value,
	() => {
		if (activeIntervalChartData.value && activeIntervalChartData.value.length) {
			hoveredPoint.value = lastPoint.value;
			loading.value = false;
		}
	},
	{ immediate: true }
);

watch(
	() => updateActivePoint.value,
	(newActivePoint) => {
		if (newActivePoint) {
			emit('active-point', newActivePoint.hoveredPoint ?? newActivePoint.lastPoint ?? { data: {} });
		}
	},
	{ immediate: true }
);

onActivated(() => {
	reflowChart.value = true;
});

onDeactivated(() => {
	reflowChart.value = false;
});

function formatPoint(point: NetReturnsOverTimeChartDataPoint): ValueOverTimeChartPoint {
	return {
		data: {
			accountValue: Number(point.totalAccountValue),
			netContribution: Number(point.netContributionsTd),
			netReturns: Number(point.netReturnsInPeriod),
			netContributionInPeriod: Number(point.netContributionsInPeriod),
			date: spacetime(point.date, 'UTC').epoch
		}
	};
}

function onMouseOver(point: ValueOverTimeChartPoint): void {
	hoveredPoint.value = point;
}

function onMouseOut(): void {
	hoveredPoint.value = lastPoint.value;
}

function onTimeChipSelected(chip: Chip | null): void {
	emit('chart-interval', chip?.value as NetReturnsOverTimeChartIntervalOption);
}
</script>

<style lang="scss" scoped>
@use '../../styles/components/investor-dashboard';
</style>
