<template>
	<div v-if="ctaLink || (unavailableState && props.displayUnavailableState)" data-test="direct-invest-cta">
		<BaseLink v-if="ctaLink" :link="ctaLink" :classes="ctaLinkClasses" :data-test="ctaLink.text">
			<span v-if="customCtaIcon" class="display-flex align-items-center">
				<BaseSvgIcon :name="customCtaIcon" class="mr-25 text-color-dark-100" /> {{ ctaLink.text }}
			</span>
		</BaseLink>
		<div
			v-else-if="unavailableState && props.displayUnavailableState"
			class="closed-to-investments display-flex justify-content-center text-align-center p-50 text-color-dark-70"
			:data-test="unavailableState"
			>{{ unavailableState }}</div
		>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { DirectInvestmentEligibility } from 'types/reit-details';
import { FundTeaserModuleCustomCta } from 'types/account';
import { Link } from 'types/layout';

// props
interface Props {
	directInvestmentEligibility: DirectInvestmentEligibility;
	offeringVintageId: string;
	displayUnavailableState?: boolean;
	classes?: string;
	theme?: 'dark' | 'light';
	customCtas?: Array<FundTeaserModuleCustomCta>;
}

const props = withDefaults(defineProps<Props>(), {
	displayUnavailableState: false,
	classes: '',
	theme: 'light',
	customCtas: () => []
});

// computed
const customCtaText = computed((): string => {
	const customText = props.customCtas.find((customCta) => customCta.cta[props.directInvestmentEligibility]);

	return customText ? customText.cta[props.directInvestmentEligibility] ?? '' : '';
});

const customCtaIcon = computed((): string => {
	const customText = props.customCtas.find((customCta) => customCta.cta[props.directInvestmentEligibility]);

	return customText ? customText.ctaIcon ?? '' : '';
});

const showDirectInvestmentLink = computed((): boolean => props.directInvestmentEligibility === 'ELIGIBLE');

const showAccreditationButton = computed(
	(): boolean =>
		props.directInvestmentEligibility === 'INELIGIBLE_REQUIRES_ACCREDITATION' ||
		props.directInvestmentEligibility === 'INELIGIBLE_FUND_LEAD_UP_REQUIRES_ACCREDITATION'
);

const showContactButton = computed(
	(): boolean =>
		props.directInvestmentEligibility === 'INELIGIBLE_ACCREDITED_NOT_IN_COHORT' ||
		props.directInvestmentEligibility === 'INELIGIBLE_IN_COHORT_CAP_EXCEEDED'
);

const ineligibleRequiresPro = computed((): boolean => props.directInvestmentEligibility === 'INELIGIBLE_REQUIRES_PRO');

const showReservationEligibleButton = computed(
	(): boolean => props.directInvestmentEligibility === 'RESERVATION_ELIGIBLE'
);

const showComingSoon = computed(
	(): boolean => props.directInvestmentEligibility === 'INELIGIBLE_FUND_LEAD_UP_ACCREDITED'
);

const showFundClosed = computed((): boolean => props.directInvestmentEligibility === 'INELIGIBLE_FUND_CLOSED');

const showFundNotAvailable = computed((): boolean => props.directInvestmentEligibility === 'INELIGIBLE');

const investButtonLink = computed((): Link => {
	return {
		text: 'Invest',
		router: 'add-funds-offering',
		params: { offeringVintageId: props.offeringVintageId }
	};
});

const accreditationLink = computed((): Link => {
	return {
		text: 'Verify accreditation',
		router: 'accreditation'
	};
});

const contactLink = computed((): Link => {
	return {
		text: 'Contact Investor Relations',
		router: 'contact'
	};
});

const createReservationLink = computed((): Link => {
	return {
		text: 'Reserve a spot',
		router: 'add-funds-offering-reservation-start',
		params: { offeringVintageId: props.offeringVintageId }
	};
});

const ctaLink = computed((): Link | null => {
	let link: Link | null = null;
	if (showDirectInvestmentLink.value) {
		link = investButtonLink.value;
	} else if (showAccreditationButton.value) {
		link = accreditationLink.value;
	} else if (showContactButton.value) {
		link = contactLink.value;
	} else if (showReservationEligibleButton.value) {
		link = createReservationLink.value;
	}

	if (!!customCtaText.value && link) {
		return { ...link, text: customCtaText.value };
	} else {
		return link;
	}
});

const unavailableState = computed((): string | null => {
	if (showComingSoon.value) {
		return 'Coming soon';
	} else if (showFundClosed.value) {
		return 'This fund is closed to investments.';
	} else if (showFundNotAvailable.value) {
		return 'This fund is not available for your account.';
	} else if (ineligibleRequiresPro.value) {
		return 'You are not eligible to invest in this fund.';
	}

	return null;
});

const ctaColor = computed((): string => {
	if (showDirectInvestmentLink.value || showReservationEligibleButton.value) {
		return props.theme === 'light' ? 'button-default' : 'button-primary';
	} else {
		return props.theme === 'light' ? 'button-outline' : 'button-outline-reversed';
	}
});

const ctaLinkClasses = computed((): string =>
	props.classes ? `button no-wrap ${props.classes} ${ctaColor.value}` : `button no-wrap ${ctaColor.value}`
);
</script>

<style lang="scss">
@use '../../styles/constants/colors' as *;
.closed-to-investments {
	background-color: dark(5);
	border-radius: 2px;
}
</style>
