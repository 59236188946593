import { calculateAverage } from '@utils/math';
import Highcharts from 'highcharts';
import { ValueOverTimeAnnotationGroup } from 'types/charts/value-over-time-chart';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface AnnotationGroupMetadata {
	boundary: number; // left edge boundary, to determine if another point overlaps this group
	children: Array<number>; // indices of children contained in the group
}
interface AnnotationPointMetadata {
	data: number; // position relative to the datatype (ie, a date)
	x: number; // position in pixels
	width: number; // width in pixels
}

/**
 * This utility function is useful for grouping chart annotations together so that they do not overlap on the chart.
 * Upon chart load, pass annotation instances to this utility and use the result to represent annotations. Upon interaction,
 * you can use the `children` list to associate them back with their original annotations.
 */
export const highchartsMergeAnnotations = (chart: Highcharts.Chart): Array<ValueOverTimeAnnotationGroup> => {
	const annotations: Array<AnnotationPointMetadata> =
		(chart as any).annotations[0]?.labels.filter(Boolean).map((label: any) => ({
			data: label.options.point.x,
			x: label.graphic.x,
			width: label.graphic.width
		})) ?? [];

	return annotations
		.reverse()
		.reduce((list: Array<AnnotationGroupMetadata>, item: AnnotationPointMetadata, index: number) => {
			const group = list.find((group: AnnotationGroupMetadata) => item.x + item.width / 2 >= group.boundary);
			if (group) {
				const newGroup = {
					boundary: item.x - item.width / 2,
					children: [...group.children, { ...item, index }]
				};
				return list.map((listItem: any) => (listItem === group ? newGroup : listItem));
			} else {
				return [...list, { boundary: item.x - item.width / 2, children: [{ ...item, index }] }];
			}
		}, [])
		.reverse()
		.map((group: any) => {
			const position = Math.ceil(calculateAverage(group.children.map((child: any) => child.data)));
			const children: Array<number> = group.children
				.map((child: { x: number; width: number; index: number }) => annotations.length - child.index - 1)
				.sort();
			return { x: position, children };
		});
};

export const highchartsGetHoveredAnnotationGroup = (
	chart: Highcharts.Chart,
	annotationGroups: Array<ValueOverTimeAnnotationGroup>,
	x: number
): ValueOverTimeAnnotationGroup | null => {
	// the annotation positions are offset by the difference, calculate it to accommodate
	const offset = chart.container.clientWidth - (chart.xAxis[0] as any).width;

	const graphics: Array<any> = (chart as any).annotations[0]?.labels?.map((label: any) => label.graphic);
	const graphicIndex = graphics?.findIndex((g) => x >= g.x - offset && x <= g.x + g.width - offset);

	if (graphicIndex >= 0) {
		return annotationGroups[graphicIndex];
	} else {
		return null;
	}
};

export const highchartsGetOpacityAdjustedColor = (color: string, opacity: number): string => {
	const hexColor = color.substring(1);
	const rgbColors = [
		parseInt(hexColor.substring(0, 2), 16),
		parseInt(hexColor.substring(2, 4), 16),
		parseInt(hexColor.substring(4), 16)
	];

	const adjustedRgbColors = rgbColors.map((color) => Math.round(opacity * color + 255 * (1 - opacity)));

	return `#${adjustedRgbColors.map((color) => color.toString(16).padStart(2, '0')).join('')}`;
};
