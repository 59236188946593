import { UsState } from 'types/form';

export const states: Array<UsState> = [
	{
		name: 'Alabama',
		code: 'AL'
	},
	{
		name: 'Alaska',
		code: 'AK'
	},
	{
		name: 'Arizona',
		code: 'AZ'
	},
	{
		name: 'Arkansas',
		code: 'AR'
	},
	{
		name: 'California',
		code: 'CA'
	},
	{
		name: 'Colorado',
		code: 'CO'
	},
	{
		name: 'Connecticut',
		code: 'CT'
	},
	{
		name: 'Delaware',
		code: 'DE'
	},
	{
		name: 'District of Columbia',
		code: 'DC'
	},
	{
		name: 'Florida',
		code: 'FL'
	},
	{
		name: 'Georgia',
		code: 'GA'
	},
	{
		name: 'Hawaii',
		code: 'HI'
	},
	{
		name: 'Idaho',
		code: 'ID'
	},
	{
		name: 'Illinois',
		code: 'IL'
	},
	{
		name: 'Indiana',
		code: 'IN'
	},
	{
		name: 'Iowa',
		code: 'IA'
	},
	{
		name: 'Kansas',
		code: 'KS'
	},
	{
		name: 'Kentucky',
		code: 'KY'
	},
	{
		name: 'Louisiana',
		code: 'LA'
	},
	{
		name: 'Maine',
		code: 'ME'
	},
	{
		name: 'Maryland',
		code: 'MD'
	},
	{
		name: 'Massachusetts',
		code: 'MA'
	},
	{
		name: 'Michigan',
		code: 'MI'
	},
	{
		name: 'Minnesota',
		code: 'MN'
	},
	{
		name: 'Mississippi',
		code: 'MS'
	},
	{
		name: 'Missouri',
		code: 'MO'
	},
	{
		name: 'Montana',
		code: 'MT'
	},
	{
		name: 'Nebraska',
		code: 'NE'
	},
	{
		name: 'Nevada',
		code: 'NV'
	},
	{
		name: 'New Hampshire',
		code: 'NH'
	},
	{
		name: 'New Jersey',
		code: 'NJ'
	},
	{
		name: 'New Mexico',
		code: 'NM'
	},
	{
		name: 'New York',
		code: 'NY'
	},
	{
		name: 'North Carolina',
		code: 'NC'
	},
	{
		name: 'North Dakota',
		code: 'ND'
	},
	{
		name: 'Ohio',
		code: 'OH'
	},
	{
		name: 'Oklahoma',
		code: 'OK'
	},
	{
		name: 'Oregon',
		code: 'OR'
	},
	{
		name: 'Pennsylvania',
		code: 'PA'
	},
	{
		name: 'Rhode Island',
		code: 'RI'
	},
	{
		name: 'South Carolina',
		code: 'SC'
	},
	{
		name: 'South Dakota',
		code: 'SD'
	},
	{
		name: 'Tennessee',
		code: 'TN'
	},
	{
		name: 'Texas',
		code: 'TX'
	},
	{
		name: 'Utah',
		code: 'UT'
	},
	{
		name: 'Vermont',
		code: 'VT'
	},
	{
		name: 'Virginia',
		code: 'VA'
	},
	{
		name: 'Washington',
		code: 'WA'
	},
	{
		name: 'West Virginia',
		code: 'WV'
	},
	{
		name: 'Wisconsin',
		code: 'WI'
	},
	{
		name: 'Wyoming',
		code: 'WY'
	},
	{
		name: 'Armed Forces - Americas (AA)',
		code: 'AA'
	},
	{
		name: 'Armed Forces - Europe (AE)',
		code: 'AE'
	},
	{
		name: 'Armed Forces - Pacific (AP)',
		code: 'AP'
	}
];

export const stateMap: Record<string, string> = {
	AL: 'Alabama',
	AK: 'Alaska',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
	AA: 'Armed Forces - Americas (AA)',
	AE: 'Armed Forces - Europe (AE)',
	AP: 'Armed Forces - Pacific (AP)'
};
