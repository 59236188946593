import { createIsbot, isbot } from 'isbot';
import { app } from '@store/modules/app';

export const userAgentIsBot = (userAgent = window.navigator.userAgent): boolean => {
	const isIeBot = createIsbot(/MSIE (\d+\.\d+);.*Trident\/(\d+\.\d+)/);
	return checkThirdParty(userAgent) || isbot(userAgent) || isIeBot(userAgent);
};

export const checkThirdParty = (userAgent = window.navigator.userAgent): boolean => {
	const thirdPartyRoutes = ['/forbidden', '/login', '/account/overview', '/account/transactions', '/logout-success'];
	const route: boolean = thirdPartyRoutes.some((tpRoute) => tpRoute === window.location.pathname);
	const isThirdPartyUserAgent = createIsbot(/(Windows|Linux).*Chrome\/(108|91|86)/);

	return (isThirdPartyUserAgent(userAgent) && route) || app.isThirdPartyLogin;
};
