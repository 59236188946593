<template>
	<div ref="moduleElem" class="featured-card">
		<div
			:class="`featured-card__content featured-card--${colorTheme} module display-flex position-relative overflow-hidden`"
		>
			<div
				class="featured-card__copy display-flex flex-direction-column justify-content-space-between pt-50-smo py-150 pl-150 pr-100"
			>
				<div>
					<p v-if="eyebrow" class="featured-card__eyebrow body-xs">{{ eyebrow }}</p>
					<h3 class="featured-card__heading heading-5">{{ heading }}</h3>
					<RichTextComponent
						v-if="description"
						class="featured-card__description pr-200-md"
						:blocks="description"
					/>
				</div>
				<div class="featured-card__bottom-content">
					<BaseTooltip
						v-if="disclaimer"
						:dark-mode="isDarkMode"
						mixpanel-target="Important disclosures"
						class="featured-card__disclaimer"
					>
						<template #trigger><p class="body-xs"> Important disclosures</p> </template>
						<RichTextComponent class="text-align-left" :blocks="disclaimer" />
					</BaseTooltip>
					<div class="featured-card__cta-wrapper display-flex gap-50">
						<BaseLink
							v-if="primaryCta"
							class="featured-card__cta button button-primary button-mini no-wrap"
							:link="primaryCta"
						/>
						<BaseLink
							v-if="secondaryCta"
							:class="`featured-card__cta button ${secondaryCtaButtonStyle} button-mini no-wrap`"
							:link="secondaryCta"
						/>
					</div>
				</div>
			</div>
			<div class="featured-card__media-wrapper">
				<LottieAnimation
					v-if="isAnimation"
					ref="animationRef"
					:key="useMobileMedia"
					:class="['display-flex']"
					:lottie-file="mediaUrl"
					:loop="loopAnimation"
					:max-retries="2"
					autoplay-behavior="ON_SCROLL"
					:aria-label="altText"
				/>
				<img v-if="isImage" class="featured-card__image" :src="mediaUrl" :alt="altText" />
			</div>
			<transition name="fade" mode="out-in">
				<div v-if="isAnimation && animationComplete" class="replay-button-wrapper">
					<BaseButton aria-label="Replay Animation" classes="text-color-white" @click.stop="replayAnimation">
						<BaseSvgIcon name="play-circle" height="24" width="24" />
					</BaseButton>
				</div>
			</transition>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { CtaButton, FeaturedCard, FeaturedCardTheme } from 'types/layout';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import LottieAnimation from '@components/layout/lottie-animation.vue';
import RichTextComponent from '@components/sanity/rich-text-component.vue';
import { SanityRichTextBlock } from 'types/sanity';

interface Props {
	featuredCardData: FeaturedCard;
}

const props = defineProps<Props>();

const animationRef = ref<null | InstanceType<typeof LottieAnimation>>(null);

const moduleElem = ref<HTMLElement | null>(null);

const useMobileMedia = ref(false);

const colorTheme = computed((): FeaturedCardTheme => props.featuredCardData.theme);

const isDarkMode = computed((): boolean => props.featuredCardData.theme === 'DARK');

const eyebrow = computed((): string => props.featuredCardData.eyebrow ?? '');

const heading = computed((): string => props.featuredCardData.heading);

const description = computed((): Array<SanityRichTextBlock> | null => {
	return props.featuredCardData.description ?? null;
});

const disclaimer = computed((): Array<SanityRichTextBlock> | null => {
	return props.featuredCardData.disclaimer ?? null;
});

const primaryCta = computed((): CtaButton | null => props.featuredCardData.primaryCta ?? null);

const secondaryCta = computed((): CtaButton | null => props.featuredCardData.secondaryCta ?? null);

const secondaryCtaButtonStyle = computed((): string =>
	props.featuredCardData.theme === 'LIGHT' ? 'button-outline' : 'button-outline-reversed'
);

const isAnimation = computed((): boolean => props.featuredCardData.mediaContentType === 'ANIMATION');

const isImage = computed((): boolean => props.featuredCardData.mediaContentType === 'IMAGE');

const mediaUrl = computed((): string =>
	useMobileMedia.value ? props.featuredCardData.mobileMediaUrl : props.featuredCardData.mediaUrl
);

const altText = computed((): string => props.featuredCardData.accessibility);

const loopAnimation = computed((): boolean => props.featuredCardData.loopAnimation);

const animationComplete = computed((): boolean => {
	return animationRef.value?.animationComplete ?? false;
});

onMounted(() => {
	useEventListener(window, 'resize', useDebounceFn(setUseMobileMedia, 100));

	setUseMobileMedia();
});

function setUseMobileMedia(): void {
	useMobileMedia.value = moduleElem.value ? moduleElem.value.offsetWidth <= 520 : false;
}

function replayAnimation(): void {
	animationRef.value?.replayAnimation();
}
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors.scss' as *;
@use '../../styles/utilities/respond-to.scss' as *;

.featured-card {
	container-type: inline-size;

	.featured-card__content {
		display: flex;
		align-items: stretch;
		justify-content: space-between;

		.featured-card__copy {
			.featured-card__cta-wrapper {
				@container (min-width: 521px) {
					white-space: nowrap;
				}
			}
		}

		.featured-card__media-wrapper {
			@container (min-width: 521px) {
				max-width: 50%;
				min-width: 25%;
				img {
					height: 100%;
				}
			}

			img {
				object-fit: cover;
				min-height: 254px;
			}
		}
	}

	@container (max-width: 520px) {
		&__content {
			flex-direction: column-reverse;
			text-align: center;

			.featured-card__copy {
				display: flex;
				flex-direction: column;
				gap: 1rem;
			}
		}

		&__description {
			padding-right: 0px;
		}

		&__cta-wrapper {
			justify-content: center;
			flex-wrap: wrap;
		}

		&__cta {
			width: 100%;
			flex: 1 1 118px;
		}
	}

	&__heading {
		margin-top: 0.125rem;
	}

	&__description {
		margin-top: 0.25rem;
	}

	&__bottom-content {
		@container (min-width: 521px) {
			margin-top: 3rem;
		}
	}

	.featured-card__copy:has(.featured-card__disclaimer) .featured-card__cta-wrapper {
		margin-top: 1rem;
	}

	&__cta {
		min-width: 118px;
	}

	&--LIGHT {
		background-color: map-get($brand-colors, 'white');

		.featured-card__eyebrow {
			color: map-get($brand-colors, 'pro-dark-1');
		}
	}

	&--DARK {
		background-color: map-get($brand-colors, 'dark-100');

		.featured-card__eyebrow {
			color: map-get($brand-colors, 'pro-light-2');
		}

		.featured-card__heading,
		.featured-card__description {
			color: map-get($brand-colors, 'white');
		}

		.featured-card__disclaimer {
			color: map-get($brand-colors, 'dark-20');
		}
	}
}

.replay-button-wrapper {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	z-index: 5;
}
</style>
