import axios, { AxiosResponseInterceptorParams } from 'axios';
import { checkCookieExists, getCookieValue } from '@utils/web-storage';
import { app } from '@store/modules/app';
import { appBasePath } from '@constants';
import { getNewAccessToken } from '@api/login';
import router from '@router/index';

let isRefreshing = false;
let subscribers: Array<(args: string) => void> = [];

function addSubscriber(cb: (args: string) => void): void {
	subscribers.push(cb);
}

function onAccessTokenFetched(token: string): void {
	subscribers = subscribers.filter((callback) => callback(token));
}

/**
 * When a request fails with a 401 status, it’s the result of an expired access token.
 * This interceptor attempts to refresh a user’s expired token and automatically retries the request.
 * If it fails, they are automatically directed back to login.
 * Required for authenticated endpoints.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function oAuthTokenRefresh(err: any): Promise<unknown> {
	const { config } = err;
	const status = err?.response?.status;
	const originalRequest = config;
	const minTokenScopeRequiredOnCurrentRoute = router.currentRoute.value.meta?.minTokenScopeRequired;
	const userHasRefreshToken = checkCookieExists('userLoggedIn');
	const isMobileWebview = app.isMobileWebview;

	if (status !== 401) {
		return Promise.reject(err);
	}

	if (minTokenScopeRequiredOnCurrentRoute === 'PRIVILEGED_ACCESS') {
		router.go(0); // re-run global route logic for PRIVILEGED_ACCESS since you can't refresh it
	}

	if (!isRefreshing) {
		if (isMobileWebview) {
			isRefreshing = true;
			const accessToken = getCookieValue('access_token');
			if (!accessToken || app.oauthToken === accessToken) {
				app.revokeMobileWebviewToken();
				window.location.href = `${appBasePath}/forbidden`;
			} else {
				app.authenticateFromMobileWebview()
					.then(() => {
						isRefreshing = false;
						onAccessTokenFetched(accessToken);
					})
					.catch(() => {
						window.location.href = `${appBasePath}/forbidden`;
					});
			}
		} else if (!userHasRefreshToken) {
			window.location.href = `${appBasePath}/login?message=expired-session`;
			return Promise.reject(err);
		} else {
			isRefreshing = true;
			getNewAccessToken()
				.then((refreshToken) => {
					app.setUserAuthData(refreshToken);
					isRefreshing = false;
					onAccessTokenFetched(refreshToken.access_token);
				})
				.catch(() => {
					window.location.href = `${appBasePath}/login?message=expired-session`;
					return Promise.reject(err);
				});
		}
	}

	return new Promise((resolve) => {
		addSubscriber((token: string) => {
			originalRequest.headers.authorization = `Bearer ${token}`;
			resolve(axios(originalRequest));
		});
	});
}

export const oAuthTokenRefreshInterceptor: AxiosResponseInterceptorParams = [
	(response) => response,
	(error) => oAuthTokenRefresh(error)
];
