import { FundriseRouteConfig } from 'vue-router';
import { getWebsiteDocument } from '@api/website-document';
import { makettoDocumentRedirects } from '@constants';

const documentRedirectRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/website-documents/:title',
		name: 'website-document-view',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to) => {
			const title = to.params.title as string;
			try {
				const redirectLink = await getWebsiteDocument(title);
				window.location.href = redirectLink.redirectUrl;
			} catch {
				return { name: '404', replace: true };
			}
		},
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	},
	{
		path: '/offerings/:offeringId/documents/:documentName',
		name: 'maketto-offering-document-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const { offeringId, documentName } = to.params;
			const filePath = makettoDocumentRedirects.get(documentName as string);

			if (offeringId === '1' && filePath) {
				window.location.href = `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/offering-documents/${filePath}`;
			} else {
				next({ name: '404', replace: true });
			}
		},
		// revisit using redirect
		component: () => import(/* webpackChunkName: "marketing-site-home" */ '@views/marketing-site/home-view.vue')
	}
];

export default documentRedirectRoutes;
