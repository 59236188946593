<template>
	<BaseLink v-if="link" :class="tagClasses" :link="link">
		<BaseSvgIcon v-if="iconName" :name="iconName" /> {{ text }}
	</BaseLink>
	<span v-else :class="tagClasses"> <BaseSvgIcon v-if="iconName" :name="iconName" /> {{ text }} </span>
</template>

<script setup lang="ts">
import { Link, TagBackground, TagSize } from 'types/layout';
import { computed } from 'vue';

interface Props {
	text?: string;
	background?: TagBackground;
	iconName?: string;
	size?: TagSize;
	link?: Link;
}
const props = withDefaults(defineProps<Props>(), {
	text: undefined,
	background: 'default',
	iconName: undefined,
	size: 'sm',
	link: undefined
});

const tagClasses = computed((): string => {
	const classes = ['tag', `tag--${props.background}`];

	if (props.iconName) {
		classes.push('tag-has-icon');
	}

	if (!props.text && props.iconName) {
		classes.push('tag-icon-only');
	}

	if (props.size) {
		classes.push(`tag--${props.size}`);
	}

	return classes.join(' ');
});
</script>

<style lang="scss">
@use '../../styles/constants/colors.scss' as *;

.tag {
	align-items: center;
	background-color: dark(5);
	border-radius: 4px;
	color: dark(100);
	display: inline-flex;
	// body-sm
	font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
	font-weight: normal;
	height: 28px;
	padding: 2px 8px;
	vertical-align: middle;
	white-space: nowrap;

	&[href] {
		&:hover,
		&:focus {
			color: initial;
			filter: brightness(95%);
		}
	}

	&.tag-has-icon {
		&:not(.tag-icon-only) {
			.svg-icon {
				margin-right: 4px;
			}
		}
	}

	+ .tag {
		margin-left: 0.5rem;
	}

	&.tag--xs {
		// body-xs
		font-size: 0.75rem;
		height: 24px;
	}

	&.tag--md {
		height: 32px;
	}

	&.tag--blue {
		background-color: map-get($validation-colors, 'info-tint');
	}
	&.tag--green {
		background-color: map-get($validation-colors, 'success-tint');
	}
	&.tag--red {
		background-color: map-get($validation-colors, 'error-tint');
	}
	&.tag--white {
		background-color: map-get($brand-colors, 'white');
	}
	&.tag--pro {
		background-color: map-get($brand-colors, 'pro-primary');
	}
	&.tag--black {
		background-color: map-get($brand-colors, 'dark-100');
		color: map-get($brand-colors, 'white');

		&[href] {
			&:hover,
			&:focus {
				color: white;
				background-color: dark(70);
			}
		}
	}
	&.tag--pro-light-2 {
		background-color: map-get($brand-colors, 'pro-light-2');
	}
}
</style>
