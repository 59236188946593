import { EmailGroupEnum } from 'types/email';

export const emailSubscriptionDetail: { [key in EmailGroupEnum]: string } = {
	FUNDRISE_CONTENT_AND_REMINDERS:
		'Investor tips, articles, white papers, and other information to help you get the most out of Fundrise.',
	USER_RESEARCH:
		'Opportunities to participate in user research (surveys, 1-on-1 calls, etc.) conducted by the Fundrise team.',
	NEW_PRODUCT_AND_FEATURE_ANNOUNCEMENTS: 'New product launches, offerings, and investment features.',
	PROJECT_UPDATES: 'Progress updates about the projects included in your portfolio.',
	MY_INVESTMENTS: 'Updates and account reminders about your Fundrise investments.',
	NEWSLETTER: 'A newsletter dedicated to long-term thinking.',
	NON_SHOPPING_CONTENT: 'Information about investing with Fundrise.',
	ADVISORS_PRODUCT_AND_FEATURE_ANNOUNCMENTS: 'New product launches, offerings, and investment features.',
	ADVISORS_ASSETS_AND_INVESTMENT_UPDATES: `Progress updates about assets and investment in your client's portfolio`,
	ADVISORS_USER_RESEARCH:
		'Opportunities to participate in user research (surveys, 1-on-1 calls, etc.) conducted by the Fundrise team.',
	ADVISORS_FUNDRISE_CONTENT_AND_REMINDERS:
		'Tips, articles, white papers, and other information to help clients get the most out of Fundrise.'
};
