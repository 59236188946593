/**
 * Defines custom Spacetime formatting strings.
 *
 * @property {string} MONTH_SHORT_DATE_YEAR - Jan 1, 2024
 * @property {string} MONTH_DATE_YEAR - January 1, 2024
 * @property {string} DAY_MONTH_DATE_ORDINAL_YEAR - Monday, January 1st 2024
 * @property {string} MONTH_YEAR - January 2024
 * @property {string} YEAR - 2024
 */
export enum CustomSpacetimeFormat {
	/** Jan 1, 2024 */
	MONTH_SHORT_DATE_YEAR = '{month-short} {date}, {year}',
	/** Jan '24 */
	MONTH_SHORT_YEAR_SHORT = '{month-short} {year-short}',
	/** January 1, 2024 */
	MONTH_DATE_YEAR = '{month} {date}, {year}',
	/** Monday, January 1st 2024 */
	DAY_MONTH_DATE_ORDINAL_YEAR = '{day}, {month} {date-ordinal} {year}',
	/** January 2024 */
	MONTH_YEAR = '{month} {year}',
	/** Jan 2024 */
	MONTH_SHORT_YEAR = '{month-short} {year}',
	/** 2024 */
	YEAR = '{year}',
	/** January 1 */
	MONTH_DATE = '{month} {date}'
}
