import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { BankAccount, WirePaymentMethod } from 'types/payment';
import { createNoteInvestment, placeOrder } from '@api/note-investment';
import { NoteInvestmentCreatedResponse, NoteInvestmentOrderDetails } from 'types/notes';
import { DebtOffering } from 'types/debt-offerings';
import { getActiveDebtOfferings } from '@api/debt-offerings';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'noteInvestment',
	store
})
class NoteInvestmentModule extends VuexModule {
	debtOfferings: Array<DebtOffering> = [];

	debtOffering: DebtOffering | null = null;

	debtOfferingDocumentRevisionIds: Array<string> = [];

	noteInvestmentId = '';

	orderAmount = '';

	orderHasBeenPlaced = false;

	paymentMethod: BankAccount | WirePaymentMethod | null = null;

	@Action({ rawError: true })
	public async initializeFlow(): Promise<void> {
		if (this.orderHasBeenPlaced) {
			this.reset();
		}
		const debtOfferings = await getActiveDebtOfferings();
		this.UPDATE_DEBT_OFFERINGS(debtOfferings);
	}

	@Action({ rawError: true })
	public async createNoteInvestment(orderDetails: NoteInvestmentOrderDetails): Promise<void> {
		const response: NoteInvestmentCreatedResponse = await createNoteInvestment({
			amount: orderDetails.amount,
			debtOfferingId: orderDetails.debtOffering.debtOfferingId,
			paymentMethodId: orderDetails.paymentMethod.id
		});
		this.UPDATE_DEBT_OFFERING(orderDetails.debtOffering);
		this.UPDATE_PAYMENT_METHOD(orderDetails.paymentMethod);
		this.UPDATE_NOTE_INVESTMENT_ID(response.noteInvestmentId);
		this.UPDATE_ORDER_AMOUNT(response.orderAmount);
	}

	@Action({ rawError: true })
	public async placeOrder(): Promise<void> {
		const response = await placeOrder(this.noteInvestmentId, this.debtOfferingDocumentRevisionIds);
		this.UPDATE_ORDER_HAS_BEEN_PLACED(response.orderHasBeenPlaced);
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_DEBT_OFFERING(null);
		this.UPDATE_DEBT_OFFERING_DOCUMENT_REVISION_IDS([]);
		this.UPDATE_NOTE_INVESTMENT_ID('');
		this.UPDATE_ORDER_AMOUNT('');
		this.UPDATE_PAYMENT_METHOD(null);
	}

	@Mutation
	public UPDATE_DEBT_OFFERING(debtOffering: DebtOffering | null): void {
		this.debtOffering = debtOffering;
	}

	@Mutation
	public UPDATE_DEBT_OFFERINGS(debtOfferings: Array<DebtOffering>): void {
		this.debtOfferings = debtOfferings;
	}

	@Mutation
	public UPDATE_NOTE_INVESTMENT_ID(noteInvestmentId: string): void {
		this.noteInvestmentId = noteInvestmentId;
	}

	@Mutation
	public UPDATE_ORDER_AMOUNT(orderAmount: string): void {
		this.orderAmount = orderAmount;
	}

	@Mutation
	public UPDATE_ORDER_HAS_BEEN_PLACED(orderHasBeenPlaced: boolean): void {
		this.orderHasBeenPlaced = orderHasBeenPlaced;
	}

	@Mutation
	public UPDATE_PAYMENT_METHOD(paymentMethod: BankAccount | WirePaymentMethod | null): void {
		this.paymentMethod = paymentMethod;
	}

	@Mutation
	public UPDATE_DEBT_OFFERING_DOCUMENT_REVISION_IDS(agreedIds: Array<string>): void {
		this.debtOfferingDocumentRevisionIds = agreedIds;
	}
}

export const noteInvestment = getModule(NoteInvestmentModule);
