import {
	checkCookieExists,
	getItemFromSessionStorage,
	removeItemFromSessionStorage,
	setItemInSessionStorage
} from '@utils/web-storage';
import { flattenQueryParam, includesRequiredQueryParams } from '@utils/request';
import { NavigationGuard, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { app } from '@store/modules/app';
import { datadogRum } from '@datadog/browser-rum';
import { getNewAccessToken } from '@api/login';
import { handleAuthentication } from './auth-guard';
import { investmentEntity } from '@store/modules/investment-entity';
import { storageKeyLoginRedirect } from '@constants';
import { TokenScope } from 'types/security';
import { user } from '@store/modules/user';

export const includesVerifyRoute = (pathString: string): boolean => {
	return ['/signup/verify-signup-checkout', '/signup/verify-email'].some((element) => pathString.includes(element));
};

export const refreshTokenAndRedirect = async (
	redirectTo: RouteLocationNormalized,
	from: RouteLocationNormalized
): Promise<ReturnType<NavigationGuard>> => {
	try {
		const refreshToken = await getNewAccessToken();
		await app.setUserAuthData(refreshToken);

		if (!user.user) {
			await user.getUser();
		}

		if (!refreshToken.scope || !refreshToken.access_token) {
			saveLoginRedirect(redirectTo);
			return { name: 'login' };
		}
	} catch {
		app.resetAuthData();
		saveLoginRedirect(redirectTo);
	}

	return handleAuthentication(redirectTo, from);
};

export const handleThirdPartyScope = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	minTokenScopeRequired: TokenScope
): Promise<ReturnType<NavigationGuard>> => {
	if (minTokenScopeRequired === 'THIRD_PARTY_ACCESS') {
		if (!user.user) {
			await user.getUser();
		}
		return true;
	} else {
		datadogRum.addAction('forbidden route', { from: from.name, to: to.name, scope: app.tokenScope });
		return { name: 'forbidden' };
	}
};

export const handleTwoFactor = async (to: RouteLocationNormalized): Promise<ReturnType<NavigationGuard>> => {
	if (!to.matched.some((route) => (route.name as string)?.includes('login-two-factor'))) {
		return { name: 'login-two-factor' };
	} else {
		return true;
	}
};

export const handlePrivilegedAccess = async (
	tokenScope: TokenScope,
	nextRoute: RouteLocationNormalized,
	cancelRoute: RouteLocationNormalized
): Promise<ReturnType<NavigationGuard>> => {
	const passwordVerified = tokenScope === 'PRIVILEGED_ACCESS' || tokenScope === 'ADVISOR_PRIVILEGED_ACCESS';
	if (!passwordVerified) {
		setItemInSessionStorage('verify-password-redirect', nextRoute.name as string);
		setItemInSessionStorage(
			'verify-password-cancel',
			(cancelRoute.name as string) || 'profile-personal-information-view'
		);
		return { name: 'password-verify' };
	} else {
		return true;
	}
};

export const handleInvestmentEntityQuery = async (
	to: RouteLocationNormalized
): Promise<ReturnType<NavigationGuard>> => {
	const investmentEntityId = flattenQueryParam(to.query.investmentEntityId) ?? '';
	const entityList = user.user?.entityDetails?.investmentEntities ?? [];
	const toRouteName = (to.name ?? '') as string;
	delete to.query.investmentEntityId;

	const selectorRoute = {
		name: 'investment-entity-selector',
		query: to.query,
		state: { ...to.params, redirectRoute: toRouteName }
	};

	if (entityList.length <= 1) {
		return to;
	}

	if (investmentEntityId === 'select') {
		return selectorRoute;
	}

	if (investmentEntityId !== investmentEntity.investmentEntityId) {
		try {
			return await user.updateSelectedEntityWithoutReload({
				investmentEntityId,
				nextRoute: { route: to, return: true }
			});
		} catch (e) {
			return selectorRoute;
		}
	}

	return to;
};

// Check if the route requires entity selection after login (for the IR team macros)
export const routesThatRequireEntitySelectionPostLogin = [
	'/account/investment-accounts/bank-accounts/manage',
	'/account/investment-accounts/personal-information/manage',
	'/account/investment-accounts/advanced-options/view',
	'/account/investment-accounts/recurring-investment-settings/manage',
	'/account/investment-accounts/recurring-investment-settings/view',
	'/account/investment-accounts/ria-dividend-reinvestment/manage',
	'/account/investment-accounts/dividend-reinvestment/manage',
	'/account/investment-accounts/investment-plan/view',
	'/account/investment-accounts/investment-goal/manage'
];

export const handleLoginRedirect = async (loginRedirect: string): Promise<RouteLocationRaw> => {
	const entityList = user.user?.entityDetails?.investmentEntities ?? [];
	const loginRedirectRequiresEntitySelection =
		entityList.length > 1 &&
		routesThatRequireEntitySelectionPostLogin.some((element) => loginRedirect.includes(element));
	removeItemFromSessionStorage(storageKeyLoginRedirect);

	if (loginRedirectRequiresEntitySelection) {
		return { path: loginRedirect, query: { investmentEntityId: 'select' } };
	} else {
		return loginRedirect;
	}
};

const isValidLoginRedirect = (to: RouteLocationNormalized, loginRedirect: string | null): boolean => {
	return !!loginRedirect && to.name !== 'security-questions' && !(to.name as string)?.includes('login-');
};

const isUnverifiedRoute = (to: RouteLocationNormalized): boolean => {
	return !app.isVerified && to.meta?.minTokenScopeRequired === 'UNVERIFIED';
};

const isUserUnverified = (to: RouteLocationNormalized, loginRedirectIncludesVerifyRoute: boolean): boolean => {
	return !app.isVerified && to.meta?.minTokenScopeRequired !== 'UNVERIFIED' && !loginRedirectIncludesVerifyRoute;
};

const shouldPromptForSecurityQuestions = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	loginRedirectIncludesVerifyRoute: boolean
): boolean => {
	const isFromLogin: boolean =
		from.name === 'login' ||
		from.name === 'login-two-factor-confirm' ||
		from.name === 'login-mobile-verify-confirm';
	const isToValidRouteForPrompt = to.name !== 'security-questions' && to.name !== 'login-mobile-verify-select';
	const isValidFlow = isFromLogin && isToValidRouteForPrompt;

	return !user.user?.additionalAuthFactorsAnswered && isValidFlow && !loginRedirectIncludesVerifyRoute;
};

const shouldPromptForMobileVerify = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	loginRedirectIncludesVerifyRoute: boolean
): boolean => {
	const isFromLogin: boolean =
		to.name !== 'login-mobile-verify-select' && (from.name === 'login' || from.name === 'login-two-factor-confirm');
	return !!user.user?.shouldPromptUserForMobileVerify && isFromLogin && !loginRedirectIncludesVerifyRoute;
};

const isAdvisorRoute = (minTokenScopeRequired: TokenScope): boolean => {
	return minTokenScopeRequired === 'ADVISOR_ACCESS' || minTokenScopeRequired === 'ADVISOR_PRIVILEGED_ACCESS';
};

export const handleAuthenticatedScope = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	minTokenScopeRequired: TokenScope
): Promise<ReturnType<NavigationGuard>> => {
	const loginRedirect = getItemFromSessionStorage('login-redirect');
	const validLoginRedirectRoute = isValidLoginRedirect(to, loginRedirect);
	const additionalAllowedScopes = to.meta?.additionalAllowedScopes ?? [];
	const loginRedirectIncludesVerifyRoute = loginRedirect ? includesVerifyRoute(loginRedirect) : false;
	const userNeedsToVerifyEmail = isUserUnverified(to, loginRedirectIncludesVerifyRoute);

	if (!user.user) {
		await user.getUser();
	}

	const securityQuestionsRequired = shouldPromptForSecurityQuestions(to, from, loginRedirectIncludesVerifyRoute);
	const mobileVerifyRequired = shouldPromptForMobileVerify(to, from, loginRedirectIncludesVerifyRoute);

	if (isUnverifiedRoute(to)) {
		return true;
	} else if (userNeedsToVerifyEmail) {
		return { name: 'unverified-email' };
	} else if (mobileVerifyRequired || (mobileVerifyRequired && securityQuestionsRequired)) {
		return { name: 'login-mobile-verify' };
	} else if (securityQuestionsRequired) {
		return { name: 'security-questions' };
	} else if (loginRedirect && validLoginRedirectRoute) {
		return handleLoginRedirect(loginRedirect);
	} else if (app.isAdvisor) {
		if (
			isAdvisorRoute(minTokenScopeRequired) ||
			additionalAllowedScopes.includes('ADVISOR_ACCESS') ||
			additionalAllowedScopes.includes('ADVISOR_READ_ACCESS')
		) {
			return true;
		} else {
			datadogRum.addAction('forbidden route', { from: from.name, to: to.name, scope: app.tokenScope });
			return { name: 'forbidden' };
		}
	} else {
		const includesInvestmentEntityQuery = includesRequiredQueryParams(['investmentEntityId'], to);

		if (isAdvisorRoute(minTokenScopeRequired)) {
			datadogRum.addAction('forbidden route', { from: from.name, to: to.name, scope: app.tokenScope });
			return { name: 'forbidden' };
		} else if (includesInvestmentEntityQuery) {
			return handleInvestmentEntityQuery(to);
		} else {
			return true;
		}
	}
};

export const handleWebviewRoute = async (
	minTokenScopeRequired: TokenScope,
	isAuthenticated: boolean
): Promise<ReturnType<NavigationGuard>> => {
	app.SET_IS_MOBILE_WEBVIEW(true);

	if (!isAuthenticated && minTokenScopeRequired !== 'UNAUTHENTICATED') {
		try {
			await app.authenticateFromMobileWebview();
		} catch {
			return { name: 'forbidden' };
		}
	}

	return true;
};

export const handleUnauthenciatedState = async (
	to: RouteLocationNormalized,
	newRoute?: boolean | RouteLocationRaw
): Promise<ReturnType<NavigationGuard>> => {
	const userHasRefreshToken = checkCookieExists('userLoggedIn');

	if (newRoute === false) {
		return newRoute;
	}

	if (userHasRefreshToken) {
		try {
			const refreshToken = await getNewAccessToken();
			await app.setUserAuthData(refreshToken);
			return newRoute ?? to;
		} catch {
			app.resetAuthData();
			saveLoginRedirect(to);
			return { name: 'login' };
		}
	}

	saveLoginRedirect(to);
	return { name: 'login' };
};

export const saveLoginRedirect = (to: RouteLocationNormalized): void => {
	if (to.redirectedFrom && routesThatRequireEntitySelectionPostLogin.includes(to.redirectedFrom?.path)) {
		setItemInSessionStorage(storageKeyLoginRedirect, to.redirectedFrom?.path);
	} else {
		setItemInSessionStorage(storageKeyLoginRedirect, to.fullPath);
	}
};
