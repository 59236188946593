<template>
	<div class="display-flex flex-direction-row pb-100 align-items-center justify-items-center">
		<Avatar avatar-size="small" />
		<span class="display-flex flex-direction-column align-items-flex-start ml-50 delta">
			<p class="heading-6">{{ name }}</p>
			<div v-if="isProUser" class="display-flex justify-content-center align-items-center mt-25">
				<BaseLink
					v-if="!isAdvisor"
					:link="{ router: 'profile-pro-features', text: 'View Pro features' }"
					data-dd-action-name="pro cta link"
					data-test="join-pro-cta-link"
					class="button button-fundrise-pro body-xs"
				/>
			</div>
		</span>
	</div>
</template>

<script setup lang="ts">
import { isAdvisor, isProUser } from '@utils/composables';
import AuthenticatedNav from '@classes/AuthenticatedNav';
import Avatar from '@components/avatar/avatar-component.vue';
import { computed } from 'vue';

interface Props {
	nav?: AuthenticatedNav;
}
const props = withDefaults(defineProps<Props>(), {
	nav: undefined
});

const name = computed((): string => {
	if (props.nav && props.nav.fullName) {
		return props.nav.fullName;
	} else {
		return 'Your Account';
	}
});
</script>
