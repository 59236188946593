import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import axios, { AxiosResponse } from 'axios';
import { DirectInvestmentDetails, OfferingDetails, OfferingDetailsData } from 'types/reit-details';
import { FundCategory, FundListOverviewDto } from 'types/reit-element';
import { isAdvisor, isAuthenticated } from '@utils/composables';

export async function getOfferingDetailsData(authenticated: boolean, offeringId: string): Promise<OfferingDetailsData> {
	let response;
	if (authenticated) {
		response = await investmentEntityRequest.get(`/offering/${offeringId}/offering-details`, {
			suppressToast: (resp: AxiosResponse) => resp.status === 404
		});
	} else {
		response = await apiRequest.get(`/offering/${offeringId}/offering-details`, {
			suppressToast: (resp: AxiosResponse) => resp.status === 404
		});
	}

	return response.data;
}

export async function getOfferingDetails(offeringId: string): Promise<OfferingDetails> {
	return (await getOfferingDetailsData(isAuthenticated.value && !isAdvisor.value, offeringId)).offeringDetails;
}

export async function getGrowthTechOfferingData(
	authenticated: boolean,
	offeringId: string
): Promise<{ directInvestmentDetails: DirectInvestmentDetails; offeringDetails: OfferingDetails }> {
	let allResponses;
	if (authenticated) {
		allResponses = await axios.all([
			await investmentEntityRequest.get(`/offering/${offeringId}/direct-investment`),
			await investmentEntityRequest.get(`/offering/${offeringId}/details`)
		]);
	} else {
		allResponses = await axios.all([
			await apiRequest.get(`/offering/${offeringId}/direct-investment`),
			await apiRequest.get(`/offering/${offeringId}/details`)
		]);
	}

	return {
		directInvestmentDetails: allResponses[0].data,
		offeringDetails: allResponses[1].data
	};
}

export async function getDirectInvestmentDetails(
	isAuthenticated: boolean,
	offeringId: string
): Promise<DirectInvestmentDetails> {
	let response;
	if (isAuthenticated) {
		response = await investmentEntityRequest.get(`/offering/${offeringId}/direct-investment`);
	} else {
		response = await apiRequest.get(`/offering/${offeringId}/direct-investment`);
	}
	return response.data;
}

export async function getDirectInvestmentDetailsAuth(offeringId: string): Promise<DirectInvestmentDetails> {
	return (await investmentEntityRequest.get(`/offering/${offeringId}/direct-investment`)).data;
}

export async function getAdvisorGrowthTechOfferingData(
	offeringId: string
): Promise<{ offeringDetails: OfferingDetails }> {
	const response = await apiSecureRequest.get(`/offering/${offeringId}/details`);

	return {
		offeringDetails: response.data
	};
}

export async function getOfferingsList(isAuthenticated: boolean): Promise<FundListOverviewDto> {
	if (isAuthenticated) {
		return (await investmentEntityRequest.get(`/offering/list`)).data;
	} else {
		return (await apiRequest.get(`/offering/list`)).data;
	}
}

export async function getOfferingIdFromSlug(urlSlug: string): Promise<{ id: string }> {
	const response = await apiRequest.get(`/offering/${urlSlug}/offering-from-url-slug`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 404
	});
	return response.data;
}

export async function getFundCategories(): Promise<Array<FundCategory>> {
	return (await apiRequest.get(`/fund-category-details`)).data.fundCategories;
}
