<template>
	<span>
		<span v-if="isSimpleLink">
			<BaseLink :link="link" classes="main-nav__menu-item-link">
				<span class="slide-underline">{{ link.text }}</span>
				<Badge v-if="isBadgeLink" class="ml-50" :text="link.badgeText" background="slate" size="medium" />
			</BaseLink>
		</span>
		<NavigationLinkSubmenu v-else :link="link" />
	</span>
</template>

<script setup lang="ts">
import Badge from '@components/badges/badge-component.vue';
import { computed } from 'vue';
import { NavigationLink } from 'types/header';
import NavigationLinkSubmenu from './navigation-link-submenu.vue';
interface Props {
	link: NavigationLink;
	isSticky?: boolean;
	isTransparentDarkText?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	isSticky: false,
	isTransparentDarkText: false
});

const isSimpleLink = computed((): boolean => {
	return 'text' in props.link;
});

const isBadgeLink = computed((): boolean => {
	return 'badgeText' in props.link;
});
</script>
