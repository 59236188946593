<template>
	<div class="display-flex flex-direction-column justify-content-space-between gap-150">
		<div class="body-md text-color-dark-80">{{ props.cardData.headline }}</div>
		<div class="display-flex justify-content-space-between align-items-center">
			<div class="body-xs text-color-dark-70">
				<div v-for="(footnote, idx) in props.cardData.footnotes" :key="idx">{{ footnote }}</div>
			</div>
			<div
				:class="['performance-card__indicator-container', returnStyles.background]"
				data-test="performance-card-value-container"
			>
				<span
					v-if="returnStyles.indicator"
					data-test="return-indicator"
					:class="returnStyles.indicator"
					class="mr-50"
				></span>
				<span class="font-weight-bold" :class="returnStyles.color" data-test="performance-card-value">{{
					performanceValue
				}}</span>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { PerformanceContextCardPerformanceEvent, PerformanceContextMetricIndicator } from 'types/performance-context';
import { computed } from 'vue';

interface Props {
	cardData: PerformanceContextCardPerformanceEvent;
}

const props = defineProps<Props>();

const metricIndicator = computed((): PerformanceContextMetricIndicator => {
	return props.cardData.metric.indicator;
});

const returnStyles = computed((): { background: string; indicator: string; color: string } => {
	switch (metricIndicator.value) {
		case 'POSITIVE':
			return {
				background: 'positive',
				indicator: 'performance-portfolio__positive-returns',
				color: 'text-color-green'
			};

		case 'NEGATIVE':
			return {
				background: 'negative',
				indicator: 'performance-portfolio__negative-returns',
				color: 'text-color-red'
			};
		case 'NEUTRAL':
		default:
			return {
				background: 'neutral',
				indicator: '',
				color: 'text-color-dark-70'
			};
	}
});

const performanceValue = computed((): string => {
	return props.cardData.metric.value;
});
</script>

<style lang="scss" scoped>
@use '../../../styles/constants/_colors.scss' as *;
@use '../../../styles/components/performance-portfolio';

.performance-card {
	&__indicator-container {
		display: flex;
		align-items: center;
		padding: 4px 12px 4px 8px;
		border-radius: 8px;

		&.positive {
			background-color: map-get($validation-colors, success-tint);
		}

		&.negative {
			background-color: map-get($validation-colors, error-tint);
		}

		&.neutral {
			border: 1px solid dark(10);
			border-radius: 4px;
			padding: 4px 8px;
		}
	}
}
</style>
