import { FundriseRouteConfig } from 'vue-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const fundLandingPageRoutes: FundriseRouteConfig[] = [
	{
		path: '/campaigns/fund/flagship',
		name: 'fund-flagship',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "fund-flagship-fund" */ '@views/marketing-site/fund/flagship-fund.vue')
	},
	{
		path: '/campaigns/fund/income',
		name: 'fund-income',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "income-fund" */ '@views/marketing-site/fund/income-fund.vue')
	},
	{
		path: '/campaigns/fund/innovation',
		name: 'fund-innovation',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		props: {
			isFundExpensed: true
		},
		component: () =>
			import(/* webpackChunkName: "fund-innovation" */ '@views/marketing-site/fund/innovation-fund.vue')
	},
	{
		path: '/incomefund',
		redirect: { name: 'fund-income' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	}
];

export default fundLandingPageRoutes;
