import { accountDetailsRoute, getNextRoute } from '@utils/checkout';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { BankAccount, WirePaymentMethod } from 'types/payment';
import {
	CheckoutAccountFormStatus,
	CheckoutAutoInvestDetails,
	CheckoutOrderGroupDetails,
	EntityAccountDetails,
	JointAccountDetails,
	SignupCheckoutSelectionResponse,
	SignupStepName,
	TrustAccountDetails
} from 'types/checkout';
import {
	clearAccountType,
	clearOrderGroup,
	completeSignupStep,
	createOrderGroup,
	getSignupCheckoutSelections,
	isEligibleForAdditionalInvestment,
	placeFirstOrder,
	submitNewEntityAccountDetails,
	submitNewJointAccountDetails,
	submitNewTrustAccountDetails,
	updateAutoInvest,
	updateExistingEntityAccountDetails,
	updateExistingJointAccountDetails,
	updateExistingTrustAccountDetails,
	updateInvestmentSelections
} from '@api/checkout';
import { clearPlanAndProSelections, getAvailableSignupPlansV2, syncSignupFlow } from '@api/investment-entity';
import { InvestmentEntity, InvestmentEntityType } from 'types/investment-entity';
import { RiaPlan, RiaPlanMarketingStrategy } from 'types/plan';
import { app } from './app';
import { attachPaymentMethod } from '@api/bank-accounts';
import { EmailCaptureFields } from 'types/layout/lead';
import { getRiaPlanMarketingInfo } from '@api/ria';
import { investmentEntity } from '@store/modules/investment-entity';
import { ira } from '@store/modules/ira';
import { Link } from 'types/layout';
import { RouteLocationRaw } from 'vue-router';
import store from '..';
import { user } from '@store/modules/user';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'orderForm',
	store
})
class OrderFormModule extends VuexModule {
	email: string | undefined;
	emailCaptureFields: EmailCaptureFields = { existingUser: false, mixpanelId: '', newLead: false };
	orderGroupId: string | null = null;
	acknowledgmentsDocIds: Array<string> | null = null;
	recommendedPlans: Array<RiaPlan> = [];
	allOtherPlans: Array<RiaPlan> | null = null;
	recommendedPlansMarketingInfo: Array<RiaPlanMarketingStrategy> = [];
	signupCheckoutSelections: SignupCheckoutSelectionResponse | null = null;

	get isPresetOfferingFlow(): boolean {
		return this.signupCheckoutSelections?.signupFlowType === 'PRESET_OFFERING';
	}

	get isPresetPlanFlow(): boolean {
		return this.signupCheckoutSelections?.signupFlowType === 'PRESET_PLAN';
	}

	get isStandardFlow(): boolean {
		return this.signupCheckoutSelections?.signupFlowType === 'STANDARD';
	}

	get isIraCheckout(): boolean {
		return this.signupCheckoutSelections?.currentStep?.signupStepName === 'IRA_CHECKOUT_STEP';
	}

	get userSelectedIraAccount(): boolean {
		return this.signupCheckoutSelections?.iraCheckout ?? false;
	}

	@Action({ rawError: true })
	public async completeStep(stepName?: SignupStepName): Promise<RouteLocationRaw> {
		let signupCheckoutSelections;

		if (stepName) {
			signupCheckoutSelections = await completeSignupStep(stepName);
		} else if (orderForm.signupCheckoutSelections?.currentStep) {
			signupCheckoutSelections = await completeSignupStep(
				orderForm.signupCheckoutSelections.currentStep.signupStepName
			);
		} else {
			signupCheckoutSelections = await getSignupCheckoutSelections();
		}

		await this.storeCheckoutDetails(signupCheckoutSelections);
		return getNextRoute(signupCheckoutSelections.currentStep);
	}

	@Action({ rawError: true })
	public async getUserCheckoutDetailsData(signupStepName?: SignupStepName): Promise<void> {
		const signupCheckoutSelections = await getSignupCheckoutSelections(signupStepName);
		await this.storeCheckoutDetails(signupCheckoutSelections);
	}

	@Action({ rawError: true })
	public async goToNextStep(isPreviousStep = false): Promise<RouteLocationRaw> {
		const stepName = isPreviousStep
			? orderForm.signupCheckoutSelections?.previousStep?.signupStepName
			: orderForm.signupCheckoutSelections?.currentStep?.signupStepName;

		await orderForm.getUserCheckoutDetailsData(stepName);
		return getNextRoute(orderForm.signupCheckoutSelections?.currentStep);
	}

	@Action({ rawError: true })
	public async submitPassword(): Promise<string> {
		return 'checkout-resume';
	}

	@Action({ rawError: true })
	public async submitPlanSelection(plan: RiaPlan): Promise<RouteLocationRaw> {
		if (this.orderGroupId) {
			await clearOrderGroup();
		}

		await updateInvestmentSelections(plan);
		return orderForm.completeStep();
	}

	@Action({ rawError: true })
	public async getAvailableSignupRiaPlans(filter?: 'recommended'): Promise<void> {
		await clearPlanAndProSelections();

		const response = await getAvailableSignupPlansV2(filter ?? undefined);
		await this.getPlanMarketingInfo(response.riaPlans);
		this.UPDATE_RECOMMENDED_PLANS(response.riaPlans);
		this.UPDATE_ALL_OTHER_PLANS(response.otherPlans ?? null);
	}

	@Action({ rawError: true })
	public async getPlanMarketingInfo(plans: Array<RiaPlan>): Promise<void> {
		const filters = plans.map((plan) => plan.investmentStrategy).join(',');
		const marketingInfo: Array<RiaPlanMarketingStrategy> = await getRiaPlanMarketingInfo(filters);
		this.UPDATE_RECOMMENDED_PLANS_MARKETING_INFO(marketingInfo);
	}

	@Action({ rawError: true })
	public async submitUserCountrySelections(payload: {
		citizenshipCountryCode: string;
		residenceCountryCode: string;
	}): Promise<RouteLocationRaw> {
		await user.setCountrySelections(payload);

		const route = await this.completeStep();
		if (payload.residenceCountryCode === 'US') {
			if (this.isIraCheckout) {
				return { name: 'ira-checkout-individual-details' };
			} else {
				return route;
			}
		} else {
			return { name: 'checkout-country-not-supported' };
		}
	}

	@Action({ rawError: true })
	public async updatePaymentMethod(accountDetails: {
		newAccount: BankAccount | WirePaymentMethod;
	}): Promise<RouteLocationRaw> {
		await attachPaymentMethod(accountDetails.newAccount.id, 'ACH');
		return this.completeStep();
	}

	@Action({ rawError: true })
	public async submitSelectedAccount(selectedInvestmentEntity: InvestmentEntity): Promise<void> {
		const { investmentEntityId, entityType } = selectedInvestmentEntity;
		const isRepeatInvestor = await isEligibleForAdditionalInvestment(investmentEntityId);
		const nextRoute = isRepeatInvestor
			? { name: 'add-funds-ria' }
			: await accountDetailsRoute(entityType, 'update');
		await user.updateSelectedEntityWithoutReload({
			nextRoute: { route: nextRoute },
			investmentEntityId
		});
	}

	@Action({ rawError: true })
	public async submitAccountDetails(submissionDetails: {
		investmentType: InvestmentEntityType;
		userRoute: CheckoutAccountFormStatus;
		payload: JointAccountDetails | TrustAccountDetails | EntityAccountDetails;
		settingsEntityCreation?: boolean;
	}): Promise<RouteLocationRaw | string> {
		let route;
		if (submissionDetails.userRoute === 'update') {
			let stepName: SignupStepName | undefined = undefined;
			switch (submissionDetails.investmentType) {
				case 'JOINT_ACCOUNT':
					stepName = 'JOINT_INFORMATION_STEP';
					await updateExistingJointAccountDetails(submissionDetails.payload as JointAccountDetails);
					break;
				case 'LLC':
					stepName = 'ENTITY_INFORMATION_STEP';
					await updateExistingEntityAccountDetails(submissionDetails.payload as EntityAccountDetails);
					break;
				case 'TRUST':
					stepName = 'TRUST_INFORMATION_STEP';
					await updateExistingTrustAccountDetails(submissionDetails.payload as TrustAccountDetails);
					break;
				default:
					break;
			}
			route = await orderForm.completeStep(stepName);
		} else {
			let investmentEntityId;
			let stepName: SignupStepName | undefined = undefined;
			switch (submissionDetails.investmentType) {
				case 'JOINT_ACCOUNT':
					stepName = 'JOINT_INFORMATION_STEP';
					investmentEntityId = (
						await submitNewJointAccountDetails(submissionDetails.payload as JointAccountDetails)
					).investmentEntityId;
					break;
				case 'LLC':
					stepName = 'ENTITY_INFORMATION_STEP';
					investmentEntityId = (
						await submitNewEntityAccountDetails(submissionDetails.payload as EntityAccountDetails)
					).investmentEntityId;
					break;
				case 'TRUST':
					stepName = 'TRUST_INFORMATION_STEP';
					investmentEntityId = (
						await submitNewTrustAccountDetails(submissionDetails.payload as TrustAccountDetails)
					).investmentEntityId;
					break;
				default:
					break;
			}

			if (investmentEntityId) {
				if (!submissionDetails.settingsEntityCreation) {
					await user.updateInvestmentEntityAndTrackEvents(investmentEntityId);
					if (this.isPresetOfferingFlow) {
						await syncSignupFlow();
					}
				} else {
					return investmentEntityId ?? '';
				}
			}

			route = await orderForm.completeStep(stepName);
		}

		await user.getUser();

		return route;
	}

	@Action({ rawError: true })
	public async submitOrderForm(): Promise<RouteLocationRaw> {
		if (this.signupCheckoutSelections?.selections?.plan) {
			await updateInvestmentSelections(this.signupCheckoutSelections.selections.plan);
		}

		if (this.isIraCheckout) {
			return { name: 'ira-select-country' };
		} else {
			return this.completeStep();
		}
	}

	@Action({ rawError: true })
	public async placeOrder(): Promise<Link> {
		await placeFirstOrder(this.acknowledgmentsDocIds as Array<string>);

		if (app.isVerified) {
			await user.getUser();
			await investmentEntity.refreshPositionsAndPerformance();
			return {
				router: this.isPresetOfferingFlow ? 'checkout-preset-investment-success' : 'reits-checkout-success'
			};
		} else {
			return { router: 'checkout-verify-email' };
		}
	}

	@Action({ rawError: true })
	public async submitOrderGroup({
		initialContribution,
		reitOfferingVintageId
	}: {
		initialContribution: string;
		reitOfferingVintageId?: string;
	}): Promise<RouteLocationRaw> {
		const orderGroupPayload: CheckoutOrderGroupDetails = {
			amount: initialContribution
		};

		if (reitOfferingVintageId) {
			orderGroupPayload.reitOfferingVintageId = reitOfferingVintageId;
		} else {
			orderGroupPayload.riaPlanId =
				this.signupCheckoutSelections?.selections?.plan.id ??
				this.signupCheckoutSelections?.presetPlan?.id ??
				'';
		}

		const orderGroupResponse = await createOrderGroup(orderGroupPayload);
		const orderGroupId = orderGroupResponse?.orderGroupId ?? '';
		this.UPDATE_ORDER_GROUP_ID(orderGroupId);

		return this.completeStep();
	}

	@Action({ rawError: true })
	public async submitAutoInvestSelections(selections: {
		addAutoInvest: boolean;
		autoInvestAmount: string;
		frequency: string;
		isMonthlyFrequency: boolean;
		firstInvestmentDay: string;
		dayOfWeek: string;
		startDate: string;
	}): Promise<RouteLocationRaw> {
		const autoInvestSelections: CheckoutAutoInvestDetails = {
			addRecurringInvestmentSchedule: selections.addAutoInvest
		};

		if (selections.addAutoInvest) {
			autoInvestSelections.recurringAmount = selections.autoInvestAmount;
			autoInvestSelections.frequency = selections.frequency;

			if (selections.isMonthlyFrequency) {
				autoInvestSelections.firstInvestmentDayOfMonth = selections.firstInvestmentDay;
			} else {
				autoInvestSelections.investmentDayOfWeek = selections.dayOfWeek;
				autoInvestSelections.startDate = selections.startDate;
			}
		}
		await updateAutoInvest(autoInvestSelections);
		return this.completeStep();
	}

	@Action({ rawError: true })
	public async clearUserSelections(): Promise<string> {
		await clearAccountType();
		await clearPlanAndProSelections();
		return 'checkout-resume';
	}

	@Action({ rawError: true })
	async storeCheckoutDetails(signupCheckoutResponse: SignupCheckoutSelectionResponse): Promise<void> {
		this.UPDATE_SIGNUP_CHECKOUT_SELECTIONS(signupCheckoutResponse);

		if (signupCheckoutResponse.selections?.plan && !user.isExistingInvestor) {
			ira.UPDATE_PLAN(signupCheckoutResponse.selections.plan);
		}
	}

	@Mutation
	public UPDATE_EMAIL(email: string): void {
		this.email = email;
	}

	@Mutation
	public UPDATE_EMAIL_CAPTURE_FIELDS(newFields: EmailCaptureFields): void {
		this.emailCaptureFields = newFields;
	}

	@Mutation
	public UPDATE_SIGNUP_CHECKOUT_SELECTIONS(signupCheckoutSelections: SignupCheckoutSelectionResponse): void {
		this.signupCheckoutSelections = signupCheckoutSelections;
	}

	@Mutation
	public UPDATE_ORDER_GROUP_ID(id: string | null): void {
		this.orderGroupId = id;
	}

	@Mutation
	public UPDATE_ACKNOWLEDGMENTS_DOC_IDS(acknowledgmentDocIds: Array<string>): void {
		this.acknowledgmentsDocIds = acknowledgmentDocIds;
	}

	@Mutation
	public UPDATE_RECOMMENDED_PLANS(recommendedPlans: Array<RiaPlan>): void {
		this.recommendedPlans = recommendedPlans;
	}

	@Mutation
	public UPDATE_ALL_OTHER_PLANS(allOtherPlans: Array<RiaPlan> | null): void {
		this.allOtherPlans = allOtherPlans;
	}

	@Mutation
	public UPDATE_RECOMMENDED_PLANS_MARKETING_INFO(marketingInfo: Array<RiaPlanMarketingStrategy>): void {
		this.recommendedPlansMarketingInfo = marketingInfo;
	}
}

export const orderForm = getModule(OrderFormModule);
