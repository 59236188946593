import { RealEstateStrategyColor, RealEstateStrategySlug } from 'types/real-estate-strategy';

export const realEstateStrategySlugs: Array<RealEstateStrategySlug> = [
	'fixed-income',
	'core-plus',
	'value-add',
	'opportunistic'
];

export const realEstateStrategies = [
	{
		name: 'Fixed Income',
		slug: realEstateStrategySlugs[0]
	},
	{
		name: 'Core Plus',
		slug: realEstateStrategySlugs[1]
	},
	{
		name: 'Value Add',
		slug: realEstateStrategySlugs[2]
	},
	{
		name: 'Opportunistic',
		slug: realEstateStrategySlugs[3]
	}
];

export const realEstateStrategyColors: Array<RealEstateStrategyColor> = [
	{
		id: '1',
		name: 'Fixed Income',
		color: '#3C3CCF'
	},
	{
		id: '2',
		name: 'Core Plus',
		color: '#4D7FE5'
	},
	{
		id: '3',
		name: 'Value Add',
		color: '#EFA046'
	},
	{
		id: '4',
		name: 'Opportunistic',
		color: '#b71b1b'
	}
];
