import { NavigationGuard, RouteLocationRaw } from 'vue-router';
import { app } from '@store/modules/app';
import { getNextRoute } from '@utils/checkout';
import { investmentEntity } from '@store/modules/investment-entity';
import { orderForm } from '@store/modules/order-form';
import { SignupStepName } from 'types/checkout';
import { signupStepToRouteMap } from '@constants/checkout';

export const canEnterCheckout = async (): Promise<RouteLocationRaw | undefined> => {
	const isMaintenanceWindow = await app.isFeatureEnabled('app-down-maintenance-window');
	if (isMaintenanceWindow) {
		return { name: 'maintenance' };
	} else {
		await orderForm.getUserCheckoutDetailsData();
		if (investmentEntity.isLegacyInvestorWithoutPlan) {
			return { name: 'ria-upgrade', replace: true };
		} else if (investmentEntity.isIraEntity) {
			return { name: 'ira-start', replace: true };
		} else if (orderForm.isIraCheckout) {
			return { name: 'ira-signup-start' };
		} else {
			return;
		}
	}
};

export const signupGuard: NavigationGuard = async (to, from, next) => {
	const routeLocation = await canEnterCheckout();

	if (routeLocation) {
		next(routeLocation);
	} else {
		next();
	}
};

export const resumeCheckoutGuard: NavigationGuard = async (to, from, next) => {
	const routeLocation = await canEnterCheckout();

	if (routeLocation) {
		next(routeLocation);
	} else {
		const route = await getNextRoute(orderForm.signupCheckoutSelections?.currentStep);
		next(route);
	}
};

export const navigateToNextStepGuard: NavigationGuard = async (to, from, next) => {
	let currentStep = (Object.keys(signupStepToRouteMap) as Array<SignupStepName>).find(
		(key) => signupStepToRouteMap[key] === to.name
	);

	if (to.name === 'checkout-acknowledgments') {
		if (orderForm.isPresetOfferingFlow) {
			currentStep = 'ORDER_ACKNOWLEDGEMENTS_STEP';
		} else {
			currentStep = 'DRIP_PREFERENCE_STEP';
		}
	}

	await orderForm.getUserCheckoutDetailsData(currentStep);

	if (currentStep === orderForm.signupCheckoutSelections?.currentStep?.signupStepName) {
		next();
	} else {
		next(await getNextRoute(orderForm.signupCheckoutSelections?.currentStep));
	}
};

export const planMenuGuard: NavigationGuard = async (to, from, next) => {
	const isMaintenanceWindow = await app.isFeatureEnabled('app-down-maintenance-window');
	if (isMaintenanceWindow) {
		next({ name: 'maintenance' });
	} else if (investmentEntity.isLegacyInvestorWithoutPlan) {
		next({ name: 'ria-upgrade', replace: true });
	} else {
		next();
	}
};

export const checkoutAuthGuard: NavigationGuard = async (to, from, next) => {
	const isMaintenanceWindow = await app.isFeatureEnabled('app-down-maintenance-window');
	if (isMaintenanceWindow) {
		next({ name: 'maintenance' });
	} else if (app.isAuthenticated) {
		const routeLocation = await canEnterCheckout();

		if (routeLocation) {
			next(routeLocation);
		} else {
			next();
		}
	} else {
		next();
	}
};
