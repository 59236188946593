<template>
	<BaseModal id="modal" :data-context="props.dataContext" :closeable="closeable" @modal-close="close">
		<div class="grid-md gutters-300-md">
			<div :class="props.modalSizeClasses">
				<div @click.stop>
					<section class="module">
						<header class="module-header">
							<div class="module-header-heading modal-close-parent">
								<slot name="title" />
								<BaseButton
									v-if="props.closeable"
									data-test="close-modal"
									classes="modal-close"
									aria-label="Close modal"
									controls="modal"
									@click="close"
									><BaseSvgIcon name="close"
								/></BaseButton>
							</div>
						</header>
						<article :class="['module-section', modalCtaSectionClass]">
							<div class="modal-content-container">
								<slot name="content" />
							</div>
							<footer class="modal-cta-section">
								<slot name="button-section">
									<div class="display-flex justify-content-flex-end text-align-right">
										<slot name="buttons" />
									</div>
								</slot>
							</footer>
						</article>
					</section>
				</div>
			</div>
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

interface Props {
	closeable?: boolean;
	modalSizeClasses?: string;
	dataContext?: string;
}

const props = withDefaults(defineProps<Props>(), {
	closeable: true,
	modalSizeClasses: 'col-8-md col-offset-2-md col-6-lg col-offset-3-lg',
	dataContext: 'StandardModal'
});

interface Emits {
	(e: 'modal-close'): void;
}

const emit = defineEmits<Emits>();
const slots = useSlots();

function close(): void {
	emit('modal-close');
}

const modalCtaSectionClass = computed((): string => {
	return slots['button-section'] ? 'modal-section--cta' : '';
});
</script>
