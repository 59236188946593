import { AccountClosureCompletionAction, AccountDeletionFlowEnum } from 'types/account/account-closure';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getAccountClosureEligibility, submitAccountClosure } from '@api/account';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'accountClosure',
	store
})
class AccountClosureModule extends VuexModule {
	private readonly PRO_ACKNOWLEDGEMENT_FLOWS: Array<AccountDeletionFlowEnum> = [
		'PRO_LEAD_NO_SHARES',
		'PRO_INVESTOR_NO_SHARES'
	];

	public flow: AccountDeletionFlowEnum | null = null;
	public completionAction: AccountClosureCompletionAction | null = null;
	public repeatRequest = false;

	get requiresProAcknowledgement(): boolean {
		return !!this.flow && this.PRO_ACKNOWLEDGEMENT_FLOWS.includes(this.flow);
	}

	@Action({ rawError: true })
	public async getAccountClosureEligibility(): Promise<void> {
		const eligibility = await getAccountClosureEligibility();

		this.SET_ACCOUNT_CLOSURE_FLOW(eligibility.accountDeletionFlow);
		this.SET_ACCOUNT_CLOSURE_COMPLETION_ACTION(eligibility?.completionAction ?? null);
	}

	@Action({ rawError: true })
	public async submitAccountClosure(): Promise<void> {
		const completionAction = await submitAccountClosure();
		this.SET_ACCOUNT_CLOSURE_COMPLETION_ACTION(completionAction);
	}

	@Action({ rawError: true })
	public setRepeatRequest(repeatRequest: boolean): void {
		this.SET_REPEAT_REQUEST(repeatRequest);
	}

	@Action({ rawError: true })
	public resetAll(): void {
		this.SET_ACCOUNT_CLOSURE_FLOW(null);
		this.SET_ACCOUNT_CLOSURE_COMPLETION_ACTION(null);
	}

	@Mutation
	public SET_ACCOUNT_CLOSURE_FLOW(flow: AccountDeletionFlowEnum | null): void {
		this.flow = flow;
	}

	@Mutation
	public SET_ACCOUNT_CLOSURE_COMPLETION_ACTION(action: AccountClosureCompletionAction | null): void {
		this.completionAction = action;
	}

	@Mutation
	public SET_REPEAT_REQUEST(repeatRequest: boolean): void {
		this.repeatRequest = repeatRequest;
	}
}

export const accountClosure = getModule(AccountClosureModule);
