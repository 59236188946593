import { PlanInfo } from 'types/plan';

export const supplementalIncomePlanId = '1';

export const ventureCapitalPresetPlanId = '16';

export const realEstatePresetPlanId = '19';

export const realEstateVintageId = '86';

export const ventureCapitalExpensedFunnelPlanId = '25';

export const ventureCapitalOfferingVintageId = '114';

export const premiumOfferingId = '27';

export const marketingFunnelFundRealEstateInterval = '23';

export const marketingFunnelFundIncome = '24';

export const plansInfo: Array<PlanInfo> = [
	{
		name: 'Starter Portfolio',
		investmentStrategy: 'STARTER_PORTFOLIO',
		isCorePlan: false,
		subtitle: "Now it's easier than ever to try Fundrise.",
		urlSlug: 'starter-portfolio'
	},
	{
		name: 'Basic Portfolio',
		investmentStrategy: 'BASIC_PORTFOLIO',
		isCorePlan: false,
		subtitle: 'Get started with a diversified real estate portfolio.',
		urlSlug: 'basic-portfolio'
	},
	{
		name: 'Supplemental Income',
		investmentStrategy: 'SUPPLEMENTAL_INCOME',
		isCorePlan: true,
		subtitle: 'Create an attractive, consistent income stream.',
		headline: 'Create an attractive, consistent income stream by investing primarily in cash-flowing real estate.',
		planGraphic: '/images/marketing-site/ria/supplemental-income-hero-overlay.png',
		targetReturnProfile: {
			dividends: 100,
			appreciation: 40,
			totalReturn: 60
		},
		expectedTimingOfReturns:
			'Aim to produce the most consistent returns, but with a lower total potential return over the life of the investment',
		typicalPortfolioAllocation: {
			description:
				'Weighted toward assets expected to generate consistent <strong class="dark">cash flow</strong>',
			income: {
				low: 70,
				mid: 75,
				high: 80
			},
			growth: {
				low: 20,
				mid: 25,
				high: 30
			}
		},
		strategyHeadline: 'We execute an income-oriented real estate private equity business on your behalf.',
		strategySection: [
			{
				strategyType: '',
				strategyDetail: [
					{
						title: 'Acquire assets that generate consistent cash flow',
						description: [
							'We invest primarily in assets that generate predictable returns from the moment we make the investment. This could mean lending to the developer of a new property, or buying an existing building with tenants in place.'
						],
						imageUrl: '/images/marketing-site/ria/income-acquire.png'
					},
					{
						title: 'Improve the real estate to increase margin of safety',
						description: [
							'To maintain and grow a high-income stream, we prefer to invest in a business plan that improves the value of the property. Investors can more predictably capture above-market returns by increasing the value of a building.'
						],
						imageUrl: '/images/marketing-site/ria/income-improve.png'
					},
					{
						title: 'Realize returns over time via rent or interest payments',
						description: [
							'Unlike in a growth strategy, where returns are typically “back-ended” when the property is sold, investors in an income strategy can look forward to earning consistent distributions over time as we collect rent or interest payments.'
						],
						imageUrl: '/images/marketing-site/ria/income-realize.png'
					}
				]
			}
		],
		planFeaturedAsset: {
			backgroundUrl: '/images/marketing/ria/real-assets-bg-lg.jpg',
			description:
				'A luxury retail and apartment building on Washington, DC’s high-end shopping and nightlife corridor',
			heading: 'Example Completed Project',
			assetId: '24',
			assetImages: [
				'/images/marketing/ria/plan-featured-asset-elysium-14-1.jpg',
				'/images/marketing/ria/plan-featured-asset-elysium-14-2.jpg',
				'/images/marketing/ria/plan-featured-asset-elysium-14-3.jpg'
			],
			darkTheme: true
		},
		portfolioHeadline: 'Allocated primarily to debt real estate assets',
		urlSlug: 'supplemental-income',
		icon: '/images/marketing-site/ria/plan-card-supplemental.png'
	},
	{
		name: 'Balanced Investing',
		investmentStrategy: 'BALANCED_INVESTING',
		isCorePlan: true,
		subtitle: 'Build wealth confidently with high diversification.',
		headline: 'Build wealth confidently through a mix of cash-flowing and growth-oriented real estate.',
		planGraphic: '/images/marketing-site/ria/balanced-investing-overlay.png',
		targetReturnProfile: {
			dividends: 60,
			appreciation: 60,
			totalReturn: 80
		},
		expectedTimingOfReturns:
			'Lower returns early on, but with the potential for higher returns later on in the life of the investment',
		typicalPortfolioAllocation: {
			description: 'Weighted relatively evenly across income and growth oriented real estate assets',
			income: {
				low: 40,
				mid: 50,
				high: 60
			},
			growth: {
				low: 40,
				mid: 50,
				high: 60
			}
		},
		strategyHeadline: 'We execute two distinct real estate private equity businesses on your behalf.',
		strategySection: [
			{
				strategyType: 'Income',
				strategyDetail: [
					{
						title: 'Acquire assets that generate consistent cash flow',
						description: [
							'We invest primarily in assets that generate predictable returns from the moment we make the investment. This could mean lending to the developer of a new property, or buying an existing building with tenants in place.'
						],
						imageUrl: '/images/marketing-site/ria/income-acquire.png'
					},
					{
						title: 'Improve the real estate to increase margin of safety',
						description: [
							'To maintain and grow a high-income stream, we prefer to invest in a business plan that improves the value of the property. Investors can more predictably capture above-market returns by increasing the value of a building.'
						],
						imageUrl: '/images/marketing-site/ria/income-improve.png'
					},
					{
						title: 'Realize returns over time via rent or interest payments',
						description: [
							'Unlike in a growth strategy, where returns are typically “back-ended” when the property is sold, investors in an income strategy can look forward to earning consistent distributions over time as we collect rent or interest payments.'
						],
						imageUrl: '/images/marketing-site/ria/income-realize.png'
					}
				]
			},
			{
				strategyType: 'Growth',
				strategyDetail: [
					{
						title: 'Acquire assets with a high potential to grow in value',
						description: [
							'To capture maximum growth potential in real estate, the key is to buy ahead of major demographic or cultural shifts, understand emerging neighborhood growth, or recognize untapped property potential.'
						],
						imageUrl: '/images/marketing-site/ria/growth-acquire.png'
					},
					{
						title: 'Improve the real estate to increase the sale price',
						description: [
							'In the real economy, we can create value from hard work. Most classically in real estate, we produce value through intelligent zoning, development and leasing – building new urban housing, renovating run-down apartments, and renting vacant buildings.'
						],
						imageUrl: '/images/marketing-site/ria/growth-improve.png'
					},
					{
						title: 'Realize returns mostly at the end when we sell the property',
						description: [
							'There is normally greater demand for complete real estate developments. There are many more potential homebuyers for a renovated home than a rundown shack, and more renters for new luxury apartments near a metro than for the previously vacant land.'
						],
						imageUrl: '/images/marketing-site/ria/growth-realize.png'
					}
				]
			}
		],
		planFeaturedAsset: {
			backgroundUrl: '/images/marketing/ria/plan-featured-asset-bg-revolve-craft.jpg',
			description: 'Newly-constructed boutique apartments in downtown Seattle near the Amazon campus',
			heading: 'Example Completed Project',
			assetId: '29',
			assetImages: [
				'/images/marketing/ria/plan-featured-asset-revolve-craft-1.jpg',
				'/images/marketing/ria/plan-featured-asset-revolve-craft-2.jpg',
				'/images/marketing/ria/plan-featured-asset-revolve-craft-3.jpg'
			],
			darkTheme: false
		},
		portfolioHeadline: 'Allocated across debt and equity real estate assets',
		urlSlug: 'balanced-investing',
		icon: '/images/marketing-site/ria/plan-card-balanced.png'
	},
	{
		name: 'Long-Term Growth',
		investmentStrategy: 'LONG_TERM_GROWTH',
		isCorePlan: true,
		subtitle: 'Pursue superior overall returns over the long term.',
		headline: 'Pursue superior overall returns by investing primarily in high potential growth real estate.',
		planGraphic: '/images/marketing-site/ria/long-term-growth-overlay.png',
		targetReturnProfile: {
			dividends: 40,
			appreciation: 90,
			totalReturn: 90
		},
		expectedTimingOfReturns:
			'Low to no returns for the first couple of years, but with a high potential return over the long term (J-curve)',
		typicalPortfolioAllocation: {
			description:
				'Weighted toward assets that have a high potential to <strong class="dark">appreciate</strong> in value',
			income: {
				low: 20,
				mid: 25,
				high: 30
			},
			growth: {
				low: 70,
				mid: 75,
				high: 80
			}
		},
		strategyHeadline: 'We execute a growth-oriented real estate private equity business on your behalf.',
		strategySection: [
			{
				strategyType: '',
				strategyDetail: [
					{
						title: 'Acquire assets with a high potential to grow in value',
						description: [
							'To capture maximum growth potential in real estate, the key is to buy ahead of major demographic or cultural shifts, understand emerging neighborhood growth, or recognize untapped property potential.'
						],
						imageUrl: '/images/marketing-site/ria/growth-acquire.png'
					},
					{
						title: 'Improve the real estate to increase the sale price',
						description: [
							'In the real economy, we can create value from hard work. Most classically in real estate, we produce value through intelligent zoning, development and leasing – building new urban housing, renovating run-down apartments, and renting vacant buildings.'
						],
						imageUrl: '/images/marketing-site/ria/growth-improve.png'
					},
					{
						title: 'Realize returns mostly at the end when we sell the property',
						description: [
							'There is normally greater demand for complete real estate developments. There are many more potential homebuyers for a renovated home than a rundown shack, and more renters for new luxury apartments near a metro than for the previously vacant land.'
						],
						imageUrl: '/images/marketing-site/ria/growth-realize.png'
					}
				]
			}
		],
		planFeaturedAsset: {
			backgroundUrl: '/images/marketing/ria/plan-featured-asset-bg-haven-mt-vernon.jpg',
			description: 'Apartment renovation and hold near new Amazon headquarters in Northern Virginia',
			heading: 'Example Completed Project',
			assetId: '15',
			assetImages: [
				'/images/marketing/ria/plan-featured-asset-haven-mt-vernon-1.jpg',
				'/images/marketing/ria/plan-featured-asset-haven-mt-vernon-2.jpg',
				'/images/marketing/ria/plan-featured-asset-haven-mt-vernon-3.jpg'
			],
			darkTheme: true
		},
		portfolioHeadline: 'Allocated primarily to equity real estate assets',
		urlSlug: 'long-term-growth',
		icon: '/images/marketing-site/ria/plan-card-long-term.png'
	},
	{
		name: 'Custom Portfolio',
		investmentStrategy: 'CUSTOM_PORTFOLIO',
		isCorePlan: false,
		subtitle: '',
		urlSlug: 'custom-portfolio',
		icon: '/images/marketing-site/ria/plan-card-basic.png'
	},
	{
		name: 'Venture Capital',
		investmentStrategy: 'VENTURE_CAPITAL',
		isCorePlan: true,
		subtitle: 'Invest in the next generation of great tech companies.',
		urlSlug: 'venture-capital',
		icon: '/images/marketing-site/ria/plan-card-venture.png',
		planGraphic: '/images/marketing-site/ria/balanced-investing-overlay.png',
		expectedTimingOfReturns:
			'Due to the nature of high-growth tech companies, it is expected that returns would be in the form of long-term appreciation',
		typicalPortfolioAllocation: {
			description:
				'Fully weighted towards assets that have a high potential to <strong>appreciate</strong> in value',
			income: {
				low: 0,
				mid: 0,
				high: 10
			},
			growth: {
				low: 90,
				mid: 100,
				high: 100
			}
		},
		targetReturnProfile: {
			dividends: 5,
			appreciation: 100,
			totalReturn: 100
		}
	},
	{
		name: 'Fundrise Core Portfolio',
		investmentStrategy: 'CORE_MANAGED',
		isCorePlan: false,
		subtitle: '',
		urlSlug: ''
	}
];
