import {
	AdvancedOptionsDetails,
	DocumentDeliveryPreferenceEnum,
	EmailRecipient,
	KnownDevice,
	SecurityQuestionLists,
	SecurityQuestionsPayload
} from 'types/settings';
import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import { Beneficiary, BeneficiaryDetails, BeneficiaryPayload, Relationship } from 'types/account';

export const updateEmailAddress = async (email: string): Promise<void> => {
	await apiSecureRequest.post(`/user/email`, { email });
};

export const updateUserPassword = async (currentPassword: string, newPassword: string): Promise<void> => {
	await apiSecureRequest.post(`user/password`, { currentPassword, newPassword });
};

export async function getKnownLoginDevices(): Promise<Array<KnownDevice>> {
	const response = await apiSecureRequest.get(`/user/known-devices`);
	const devices: Array<KnownDevice> = response.data.knownDevices;
	const currentDeviceId = response.data.currentDeviceId;
	devices.forEach((device) => {
		if (device.deviceId === currentDeviceId) {
			device.currentSession = true;
		}
	});

	return devices;
}

export async function getDeviceDetail(deviceId: string): Promise<KnownDevice> {
	const response = await apiSecureRequest.get(`/user/known-device/${deviceId}`);
	return response.data;
}

export async function deleteKnownLoginDevice(deviceId: string): Promise<void> {
	await apiSecureRequest.delete(`/user/known-device/${deviceId}`);
}

export const updateNickname = async (newNickname: string): Promise<void> => {
	const params = { newNickname };
	await investmentEntityRequest.post(`/entity-nickname/update`, null, { params });
};

export const deleteNickname = async (): Promise<void> => {
	await investmentEntityRequest.delete(`/entity-nickname/delete`);
};

export async function getAdvancedOptionsDetails(): Promise<AdvancedOptionsDetails> {
	const response = await investmentEntityRequest.get(`/settings/advanced-options`);
	return response.data;
}

export async function deleteInvestmentEntity(): Promise<string> {
	const response = await investmentEntityRequest.delete(`/delete`);
	return response.data.investmentEntityId;
}

export const getRelationships = async (): Promise<Array<Relationship>> => {
	const response = await apiRequest.get(`/beneficiary/relationships`);
	return response.data;
};

export const getBeneficiary = async (beneficiaryId: number): Promise<BeneficiaryDetails> => {
	const response = await investmentEntityRequest.get(`/beneficiary/${beneficiaryId}`);
	return response.data;
};

export const addBeneficiary = async (payload: BeneficiaryPayload): Promise<void> => {
	await investmentEntityRequest.post(`/beneficiary`, payload);
};

export const updateBeneficiary = async (beneficiaryId: number, payload: object): Promise<void> => {
	await investmentEntityRequest.put(`/beneficiary/${beneficiaryId}`, payload);
};

export const deleteBeneficiary = async (beneficiaryId: number): Promise<void> => {
	await investmentEntityRequest.delete(`/beneficiary/${beneficiaryId}`);
};

export const getBeneficiaries = async (): Promise<Array<Beneficiary>> => {
	const response = await investmentEntityRequest.get(`/beneficiaries`);
	return response.data;
};

export const getDocumentDeliveryPreference = async (): Promise<DocumentDeliveryPreferenceEnum> => {
	const response = await investmentEntityRequest.get(`/document-delivery-preference`);
	return response.data.documentDeliveryPreference;
};

export const updateDocumentDeliveryPreference = async (
	newPreference: DocumentDeliveryPreferenceEnum
): Promise<void> => {
	await investmentEntityRequest.post(`/document-delivery-preference/update`, {
		documentDeliveryPreference: newPreference
	});
};

export async function getEmailRecipient(recipientId: string): Promise<EmailRecipient> {
	const response = await investmentEntityRequest.get(`/email-recipient/${recipientId}`, {
		suppressToast: () => true
	});
	return response.data;
}

export async function getEmailRecipients(): Promise<Array<EmailRecipient>> {
	const response = await investmentEntityRequest.get(`/email-recipients`);
	return response.data;
}

export async function addNewEmailRecipient(payload: EmailRecipient): Promise<void> {
	await investmentEntityRequest.post(`/email-recipient/new`, payload);
}

export async function updateEmailRecipient(recipientId: string, payload: EmailRecipient): Promise<void> {
	await investmentEntityRequest.put(`/email-recipient/${recipientId}/update`, payload);
}

export async function deleteEmailRecipient(recipientId: string): Promise<void> {
	await investmentEntityRequest.delete(`/email-recipient/${recipientId}`);
}

export async function getSecurityQuestionOptions(): Promise<SecurityQuestionLists> {
	const response = await apiRequest.get(`/security-questions`);
	return response.data;
}

export async function setSecurityQuestions(payload: SecurityQuestionsPayload): Promise<void> {
	await apiSecureRequest.post(`/user/security-questions`, payload);
}

export async function disableSmsTwoFactorAuthentication(): Promise<void> {
	await apiSecureRequest.post(`/two-factor-auth/sms/disable`);
}

export async function disableTotpTwoFactorAuthentication(): Promise<void> {
	await apiSecureRequest.post(`/two-factor-auth/totp/disable`);
}

export async function resendSmsTwoFactorEnrollmentAuthCode(): Promise<void> {
	await apiSecureRequest.post(`/two-factor-auth/sms/enrollment/resend`, null, {
		suppressToast: () => true
	});
}

export async function startSmsTwoFactorEnrollment(
	phoneNumberObj: Partial<Record<'newPhoneNumber' | 'phoneNumberId', string>>
): Promise<void> {
	await apiSecureRequest.post(`/two-factor-auth/sms/enrollment/start`, phoneNumberObj);
}

export async function startTotpTwoFactorEnrollment(): Promise<string> {
	const response = await apiSecureRequest.get(`/two-factor-auth/totp/enrollment/start`);
	return response.data;
}

export async function verifySmsTwoFactorEnrollment(authCode: string): Promise<void> {
	await apiSecureRequest.post(`/two-factor-auth/sms/enrollment/verify`, { authCode });
}

export async function verifyTotpTwoFactorEnrollment(authCode: string): Promise<void> {
	await apiSecureRequest.post(`/two-factor-auth/totp/enrollment/verify`, { authCode });
}
