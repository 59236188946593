<template>
	<div
		ref="target"
		class="module standard-module display-flex dark height-100"
		:style="`background-color: ${styles.backgroundColor};`"
	>
		<div v-if="displayStatusBar" :class="['standard-module-border', moduleTypeClass]" data-test="status-bar"></div>
		<img
			v-else-if="standardModuleImage"
			:src="standardModuleImage"
			class="standard-module-image mr-50"
			data-test="standard-module-image"
			alt=""
			role="presentation"
		/>
		<img
			v-else-if="standardModuleIcon"
			:src="standardModuleIcon"
			class="mx-50 mt-25"
			style="height: 24px; width: 24px"
			role="presentation"
			alt=""
			data-test="standard-module-icon"
		/>
		<div class="standard-module-wrapper">
			<div
				:class="[
					displayStatusBar ? 'standard-module-content' : 'standard-module-content-no-status',
					'display-flex flex-direction-column height-100'
				]"
			>
				<div class="pr-200-md position-relative">
					<h4 v-if="props.module.title" class="body-md font-weight-bold">{{ props.module.title }}</h4>
					<BaseButton
						v-if="props.module.dismissible"
						:classes="'cta-link dismiss-button dark position-absolute standard-module-dismiss'"
						style="top: 0.2rem; right: 0.5rem"
						data-test="dismiss"
						mixpanel-element="Module"
						mixpanel-target="Dismiss"
						:mixpanel-context="props.module.name"
						aria-label="Dismiss module"
						@click="dismiss"
					>
						<BaseSvgIcon name="close" height="16" width="16" />
					</BaseButton>
					<p
						v-if="props.module.subText"
						data-test="description"
						class="standard-module-description mt-25"
						v-html="props.module.subText"
					/>
				</div>
				<div v-if="props.module.keyValuePairs" class="mt-100" data-test="standard-module-key-val-pairs">
					<div
						v-for="(keyValuePair, idx) in props.module.keyValuePairs"
						:key="idx"
						class="display-flex justify-content-space-between-smo gap-50 mb-50 standard-module-key-value-pair"
					>
						<span class="text-color-dark-70">{{ keyValuePair.key }}: </span>
						<span>{{ keyValuePair.value }}</span>
					</div>
				</div>
				<BaseLink
					v-if="link"
					:link="link"
					class="cta-link--rust align-self-flex-end pt-25 mt-auto standard-module-cta"
					data-test="standard-module-cta"
					:click-text="props.module.cta?.text"
					mixpanel-element="Module"
					:mixpanel-target="props.module.title"
					:mixpanel-context="props.module.name"
					>{{ link.text }}</BaseLink
				>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'StandardModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import {
	ModularDashboardStandardModule,
	ModularDashboardStandardModuleStyles
} from 'types/modular-dashboard/standard-module';
import BaseLink from '@components/_base-link.vue';
import { Link } from 'types/layout';
import { trackMixpanelScrollIntoView } from '@utils/analytics';

interface Props {
	module: ModularDashboardStandardModule;
}

interface Emits {
	(e: 'dismiss'): void;
}

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const target = ref<HTMLElement | null>(null);

const link = computed((): Link | undefined => {
	if (props.module.cta && props.module.cta.targetUrl) {
		return {
			text: props.module.cta.text,
			href: props.module.cta.targetUrl,
			target: props.module.cta.newTab ? '_blank' : '_self'
		};
	} else {
		return undefined;
	}
});

const displayStatusBar = computed((): boolean => {
	return props.module.standardType !== 'DEFAULT';
});

const styles = computed(
	(): ModularDashboardStandardModuleStyles => ({
		backgroundColor: '#FFFFFF', // default background color
		...props.module.style
	})
);

const moduleTypeClass = computed((): string => {
	return `${props.module.standardType.toLowerCase()}-border`;
});

const standardModuleImage = computed((): string => props.module.imageUrl ?? '');

const standardModuleIcon = computed((): string => props.module.iconUrl ?? '');

function dismiss(): void {
	emits('dismiss');
}

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': props.module.title,
			'View Content': props.module.name,
			'View ID': props.module.type
		});
	}
});
</script>

<style lang="scss" scoped>
@use '../../../../styles/constants/_colors.scss' as *;

.standard-module {
	padding: 10px;
	width: 100%;
	position: relative;

	&-wrapper {
		flex-basis: 100%;
	}
	&-border {
		border-left: 6px solid;
		border-radius: 3px;
	}
	&-image {
		height: 5.625rem;
		min-width: 5.625rem;
		width: 5.625rem;
		object-fit: cover;
	}
	&-content {
		padding: 4px 6px 6px 16px;
	}
	&-content-no-status {
		padding: 4px 6px 6px 6px;
	}
	&-description {
		white-space: pre-wrap;
	}
	&-dismiss {
		z-index: 2;
	}
	&-cta::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: '';
	}
	&:hover {
		color: dark(100);
	}
}

.warning-border {
	border-color: map-get($validation-colors, 'warning');
}

.success-border {
	border-color: map-get($validation-colors, 'success');
}

.info-border {
	border-color: map-get($validation-colors, 'info');
}

.error-border {
	border-color: map-get($validation-colors, 'error');
}

.module {
	container-type: inline-size;

	@container (max-width: 362px) {
		.standard-module-key-value-pair {
			justify-content: space-between;
		}
	}
}

.standard-module-key-value-pair {
	&:not(:first-of-type) {
		margin-top: 0.5rem;
	}
}
</style>
