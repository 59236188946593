import { checkAndSwitchToIraEntity } from './ira-navigation-guards';
import { FundriseRouteConfig } from 'vue-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraCompleteOrderRoutes: FundriseRouteConfig = {
	path: `/ira/complete-order`,
	name: 'ira-complete-order',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	beforeEnter: checkAndSwitchToIraEntity,
	component: () =>
		import(
			/* webpackChunkName: "ira-complete-order-start" */ '@views/ira/complete-order/ira-complete-order-start.vue'
		),
	children: [
		{
			path: `agreements`,
			name: 'ira-complete-order-agreements',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(/* webpackChunkName: "ira-start-agreements" */ '@views/ira/signup/ira-agreements.vue')
		},
		{
			path: `success`,
			name: 'ira-complete-order-success',
			meta: { minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import(/* webpackChunkName: "ira-start-success" */ '@views/ira/signup/ira-success.vue')
		}
	]
};

export default iraCompleteOrderRoutes;
