import { apiRequest, investmentEntityRequest } from '@utils/request';
import { InvestmentStrategy, PlanType, RiaPlan, RiaPlanMarketingStrategy } from 'types/plan';
import { RiaFeeWaiverOriginType } from 'types/layout/lead';
import { RiaUpgradePreference } from 'types/ria';

export async function getAnnualizedYield(planId: string): Promise<string> {
	const response = await apiRequest.get(`/ria-plan/${planId}/current-yield`);
	return response.data;
}

export async function getFeeWaiverOriginTypeEnumByName(feeWaiver: string): Promise<RiaFeeWaiverOriginType> {
	const response = await apiRequest.get(`/ria/fee-waiver/${feeWaiver}`);
	return response.data;
}

export async function getRiaPlanByStrategyAndType(
	investmentStrategy: InvestmentStrategy,
	planType: PlanType
): Promise<RiaPlan> {
	const response = await apiRequest.get(`/ria/investment-strategy/${investmentStrategy}/plan-type/${planType}`);
	return response.data;
}

export async function getRiaPlansByEntityV2(): Promise<Array<RiaPlan>> {
	const {
		data: { riaPlans }
	} = await investmentEntityRequest.get('/ria-plans-v2');

	return riaPlans;
}

export async function getRiaPlanById(planId: string): Promise<RiaPlan> {
	const response = await apiRequest.get(`/ria-plan/${planId}`);
	return response.data;
}

export const completeUpgrade = async (riaUpgradePreference: RiaUpgradePreference): Promise<void> => {
	await investmentEntityRequest.post(`/ria/upgrade`, riaUpgradePreference);
};

export const getRiaPlanMarketingInfo = async (filter?: string): Promise<Array<RiaPlanMarketingStrategy>> => {
	const response = await apiRequest.get('/ria-plan/marketing-info', {
		params: {
			filter
		}
	});

	return response.data.strategies;
};
