import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getIpoHypotheticalInvestment, getIpoMetadata } from '@api/ipo';
import { IpoHypotheticalInvestment, IpoMetadata } from 'types/ipo';
import big from 'big.js';
import { CurrentPrincipal } from 'types/portfolio';
import { getCurrentPrincipal } from '@api/investment-entity';
import store from '..';

@Module({
	dynamic: true,
	namespaced: true,
	name: 'ipo',
	store
})
class IpoModule extends VuexModule {
	ipoMetadata: IpoMetadata | null = null;
	currentPrincipal: CurrentPrincipal | null = null;
	ipoHypotheticalInvestment: IpoHypotheticalInvestment | null = null;

	get isLeadUpEligible(): boolean {
		return this.ipoMetadata?.ipoEligibility.ipoPhaseAndEligibilityEnum === 'LEAD_UP_ELIGIBLE';
	}

	get hasPendingInvestmentInLeadUp(): boolean {
		return (
			this.isLeadUpEligible && this.currentPrincipal !== null && big(this.currentPrincipal.pendingPrincipal).gt(0)
		);
	}

	@Action({ rawError: true })
	public async fetchAndStoreMetadata(): Promise<void> {
		const ipoMetadata = await getIpoMetadata();
		this.UPDATE_IPO_METADATA(ipoMetadata);
	}

	@Action({ rawError: true })
	public async fetchAndStoreCurrentPrincipal(): Promise<void> {
		const currentPrincipal = await getCurrentPrincipal();
		this.UPDATE_CURRENT_PRINCIPAL(currentPrincipal);

		if (this.hasPendingInvestmentInLeadUp || this.isLeadUpEligible) {
			await this.fetchAndStoreIpoHypotheticalInvestment(currentPrincipal.pendingPrincipal);
		}
	}

	@Action({ rawError: true })
	public async fetchAndStoreIpoHypotheticalInvestment(pendingPrincipal: string): Promise<void> {
		const ipoHypotheticalInvestment = await getIpoHypotheticalInvestment(pendingPrincipal);
		this.UPDATE_IPO_HYPOTHETICAL_INVESTMENT(ipoHypotheticalInvestment);
	}

	@Mutation
	public UPDATE_IPO_METADATA(ipoMetadata: IpoMetadata): void {
		this.ipoMetadata = ipoMetadata;
	}

	@Mutation
	public UPDATE_CURRENT_PRINCIPAL(currentPrincipal: CurrentPrincipal): void {
		this.currentPrincipal = currentPrincipal;
	}

	@Mutation
	public UPDATE_IPO_HYPOTHETICAL_INVESTMENT(ipoHypotheticalInvestment: IpoHypotheticalInvestment | null): void {
		this.ipoHypotheticalInvestment = ipoHypotheticalInvestment;
	}
}

export const ipo = getModule(IpoModule);
