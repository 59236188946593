<template>
	<div ref="moduleElem" class="fund-teaser-module">
		<div
			class="module fund-teaser-module__content display-flex justify-content-center text-align-center-smo position-relative overflow-hidden"
		>
			<div class="position-absolute-smo details-content width-100-smo">
				<div
					:class="[
						`
						p-200-md p-100-smo
						col-7-md
						width-100-smo
						display-flex-md
						flex-direction-column-md
						justify-content-center-md
						details-positioning
						height-100-md
					`,
						{ 'position-absolute-md': !showActions }
					]"
				>
					<p v-if="tagline" class="text-color-pro-light-2 body-lg-md body-xs-smo module-tagline">
						{{ tagline }}
					</p>
					<h3 class="heading-2 text-color-white module-fund-heading"> {{ fundTeaserTitle }} </h3>
					<div v-if="isMobile" class="module-fund-attributes">
						<span
							v-for="(attribute, idx) in fundAttributes"
							:key="attribute"
							class="display-inline-block mt-100-md mt-25-smo text-color-dark-40 body-xs-smo"
							>{{ attribute
							}}<span :class="{ 'display-none': idx === fundAttributes.length - 1 }"
								><BaseSvgIcon name="dot" height="4" width="4" class="mx-25 text-color-dark-70" /></span
						></span>
					</div>
					<p v-else class="text-color-white module-subtitle" v-html="subtitle"></p>
					<FundTeaserModuleCtas
						class="fund-teaser-module-ctas mt-100"
						:learn-more-link="learnMoreLink"
						:direct-investment-eligibility="props.data.directInvestmentEligibility"
						:vintage-id="props.data.vintageId"
						:is-static-layout="true"
					/>
				</div>
			</div>

			<transition name="fade" mode="out-in">
				<div v-if="animationComplete" class="replay-button-wrapper">
					<BaseButton aria-label="Replay Animation" classes="text-color-white" @click.stop="replayAnimation">
						<BaseSvgIcon name="play-circle" height="24" width="24" />
					</BaseButton>
				</div>
			</transition>

			<div
				v-if="showActions"
				class="actions-positioning position-absolute col-5-md display-flex flex-direction-column align-items-center justify-content-center gap-50 width-100-smo"
			>
				<div
					v-if="!dataReady && !dataFailed"
					class="display-flex flex-direction-column align-items-center gap-50"
				>
					<div class="loader loader--dark gamma"></div>
					<span class="text-color-dark-20">Loading...</span>
				</div>
			</div>

			<LottieAnimation
				ref="animationRef"
				:key="useMobileImage"
				:lottie-file="animationUrl"
				:loop="false"
				:max-retries="2"
				autoplay-behavior="ON_SCROLL"
				:aria-label="altText"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { deorphanText } from '@filters/shared-filters';
import FundTeaserModuleCtas from '@components/account/dashboard-modules/fund-teaser-module-ctas.vue';
import { isMobile } from '@utils/composables';
import { Link } from 'types/layout';
import LottieAnimation from '@components/layout/lottie-animation.vue';
import { ModularDashboardFundTeaser } from 'types/modular-dashboard/custom';

interface Props {
	data: ModularDashboardFundTeaser;
}

const props = defineProps<Props>();

const animationRef = ref<null | InstanceType<typeof LottieAnimation>>(null);

const moduleElem = ref<HTMLElement | null>(null);

const learnMoreLink: Link = {
	href: props.data.secondaryCtaUrl,
	text: deorphanText(props.data.secondaryCtaLabel)
};

const useMobileImage = ref(false);

const tagline = computed((): string => props.data.tagline ?? '');

const fundTeaserTitle = computed((): string => props.data.fundTitle);

const fundAttributes = computed((): Array<string> => props.data.fundAttributes ?? []);

const subtitle = computed((): string => deorphanText(props.data.subtitle ?? ''));

const altText = computed((): string => props.data.accessibility ?? 'featured fund animation');

const animationUrl = computed(
	(): string => (useMobileImage.value ? props.data.mobileAnimationUrl : props.data.animationUrl) ?? ''
);

const dataFailed = computed((): boolean => {
	return animationRef.value?.dataFailed ?? false;
});

const dataReady = computed((): boolean => {
	return animationRef.value?.dataReady ?? false;
});

const animationComplete = computed((): boolean => {
	return animationRef.value?.animationComplete ?? false;
});

const showActions = computed((): boolean => {
	return dataFailed.value || !dataReady.value;
});

onMounted(() => {
	useEventListener(window, 'resize', useDebounceFn(setUseMobileImage, 100));

	setUseMobileImage();
});

function setUseMobileImage(): void {
	if (isMobile.value) {
		useMobileImage.value = true;
	} else {
		useMobileImage.value = moduleElem.value ? moduleElem.value.offsetWidth <= 520 : false;
	}
}

function replayAnimation(): void {
	animationRef.value?.replayAnimation();
}
</script>

<style lang="scss" scoped>
@use '../../../../../styles/utilities/respond-to.scss' as *;
@use '../../../../../styles/constants/_colors.scss' as *;

.fund-teaser-module {
	&__content {
		padding: 10px;
	}

	container-type: inline-size;

	@container (max-width: 705px) {
		.module-fund-heading {
			font-size: 2rem;
		}

		.module-subtitle {
			font-size: 0.875rem;
			margin-top: 0.5rem;
		}

		.actions-positioning {
			width: 100%;
		}
	}

	@container (max-width: 520px) {
		.module {
			min-height: 350px;
			display: flex;
			justify-content: center;
			text-align: center;
		}

		.details-content {
			position: absolute;
			width: 100%;
			bottom: 0;
			z-index: 1;
		}

		.details-positioning {
			width: 100%;
			padding: 1rem;
			position: static;
			display: block;
		}

		.module-tagline {
			font-size: 0.75rem;
		}
	}

	@container (max-width: 400px) {
		.module-subtitle {
			font-size: 0.75rem;
			margin-top: 0.25rem;
			color: dark(40);
		}
	}

	@container (max-width: 305px) {
		.module-fund-heading {
			font-size: 1.5rem;
		}

		.module-fund-attributes {
			display: none;
		}

		.module-subtitle {
			display: none;
		}

		.fund-teaser-module-ctas {
			flex-direction: column-reverse;
			font-size: 0.875rem;
			gap: 0.5rem;
		}

		:deep(.fund-teaser-invest-cta:not(:first-child:last-child)) {
			flex-basis: auto;
		}
	}
}

.module {
	background: #24272f;
	min-height: 250px;

	@include respond-to(smo) {
		min-height: 350px;
	}
}

.height-100-md {
	@include respond-to(md) {
		height: 100%;
	}
}

.details-content {
	@include respond-to(smo) {
		bottom: 0;
	}

	z-index: 1;
}

.actions-positioning {
	min-height: 150px;
	top: 3rem;
	right: 0;
	bottom: 50%;
	z-index: 5;

	.loader {
		margin: auto;
	}

	@include respond-to(md) {
		top: 0;
		bottom: 0;
	}
}

.replay-button-wrapper {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	z-index: 5;
}

.fade-enter-active {
	transition: opacity 0.5s;
}
.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
