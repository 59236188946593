<template>
	<highcharts
		v-if="showChart"
		class="highcharts-container"
		:highcharts="localHc"
		:callback="onChartLoaded"
		:options="chartOptions"
	></highcharts>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import Highcharts, {
	AnnotationsLabelsOptions,
	AnnotationsOptions,
	Options,
	PointOptionsObject,
	SeriesAreaOptions,
	XAxisOptions
} from 'highcharts';
import { highchartsGetHoveredAnnotationGroup, highchartsMergeAnnotations } from '@utils/highcharts';
import spacetime, { Format, Spacetime, TimeUnit } from 'spacetime';
import {
	ValueOverTimeAnnotation,
	ValueOverTimeAnnotationGroup,
	ValueOverTimeChartPoint,
	ValueOverTimeChartSeries
} from 'types/charts/value-over-time-chart';
import Annotations from 'highcharts/modules/annotations';
import { app } from '@store/modules/app';
import { appCloudfrontPath } from '@constants';
import BaseChart from '@charts/BaseChartOptions';
import { CustomSpacetimeFormat } from 'types/spacetime-formats';
import deepMerge from 'deepmerge';
import { Chart as highcharts } from 'highcharts-vue';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { ScreenSizeType } from 'types/client';
import { YAxisBounds } from 'types/charts';

Annotations(Highcharts);

interface Props {
	data?: Array<ValueOverTimeChartPoint>;
	sets?: Array<ValueOverTimeChartSeries>;
	annotations?: Array<ValueOverTimeAnnotation>;
	activeAnnotations?: Array<string>;
	customOptions?: Options;
	isHoverable?: boolean;
	defaultToLastPoint?: boolean;
	highlightLastPoint?: boolean;
	reflowChart?: boolean;
	showMarker?: boolean;
	yAxisBounds?: YAxisBounds;
}

interface Emits {
	(e: 'mouseOver', hoveredPoint: ValueOverTimeChartPoint): void;
	(e: 'mouseOut'): void;
	(e: 'annotationsFocused', annotation: Array<ValueOverTimeAnnotation>): void;
}

const props = withDefaults(defineProps<Props>(), {
	data: () => [],
	sets: () => [],
	annotations: () => [],
	activeAnnotations: () => [],
	customOptions: () => ({}),
	isHoverable: true,
	defaultToLastPoint: false,
	highlightLastPoint: false,
	reflowChart: false,
	showMarker: false,
	yAxisBounds: () => ({ yAxisMin: null, yAxisMax: null })
});

const emits = defineEmits<Emits>();

type XAxisIntervalOption = 'month' | 'quarter' | 'biannual' | 'year';
type XAxisInterval = {
	ticks: Array<number>;
	format: string;
};

const localHc = Highcharts;

const chart = ref(null as Highcharts.Chart | null);

const showChart = ref(false);
const hoveredPoint = ref(null as ValueOverTimeChartPoint | null);

const screenSize = computed((): ScreenSizeType => {
	return app.screenSize;
});

const series = computed((): Array<SeriesAreaOptions> => {
	return props.sets.map((set: ValueOverTimeChartSeries) => ({
		...set,
		lineWidth: 3,
		enableMouseTracking: set.enableMouseTracking ?? true,
		type: 'area',
		data: props.data.map((d) => {
			return {
				x: d.data[set.xKey],
				y: d.data[set.yKey],
				data: { ...d.data }
			};
		})
	}));
});

const annotationGroups = ref<Array<ValueOverTimeAnnotationGroup>>([]);

const annotations = computed((): Array<AnnotationsOptions> | undefined => {
	if (!props.annotations.length) return undefined;

	const groups = annotationGroups.value;
	let labels: Array<AnnotationsLabelsOptions> = [];

	const formatter = (x: number, count: number): string => {
		const badgeClasses = ['annotation-count', 'body-xs'];
		const chartHeight = chart.value?.plotHeight ?? 0;

		if (count <= 1) badgeClasses.push('display-none');
		return `<div style="height: 10px; width: 16px; position: relative;">
      			<b class="${badgeClasses.join(' ')}">${count}</b>
      			<img style="transform: translate(1px, -2px)" src="${appCloudfrontPath}/images/performance-context/chart-annotation-dark.svg" />
      			<div class="pseudo-crosshair" style="height: ${chartHeight ? chartHeight - 40 : 0}px;"></div>
      		</div>`;
	};

	// annotations can only be grouped after they're first rendered, since we need to do positioning math
	if (groups.length) {
		labels = groups.map((group: ValueOverTimeAnnotationGroup) => {
			return {
				point: {
					x: group.x,
					y: chart.value?.yAxis[0].min ?? props.yAxisBounds.yAxisMin ?? 0,
					xAxis: 0,
					yAxis: 0
				},
				formatter: (): string => formatter(group.x, group.children.length)
			};
		});
	} else {
		labels = props.annotations.map((anno: ValueOverTimeAnnotation) => ({
			point: {
				x: anno.date,
				y: chart.value?.yAxis[0].min ?? props.yAxisBounds.yAxisMin ?? 0,
				xAxis: 0,
				yAxis: 0
			},
			formatter: (): string => formatter(anno.date, 1)
		}));
	}

	return [
		{
			draggable: '',
			labelOptions: {
				overflow: 'allow',
				useHTML: true,
				allowOverlap: false,
				padding: 8,
				y: -6
			},
			labels
		}
	];
});

const isPerformanceContextEnabled = computed((): boolean => modularDashboard.performanceContextEnabled);

const chartOptions = computed((): Options => {
	let xAxis = {
		lineWidth: 0,
		maxPadding: 0,
		minPadding: 0,
		offset: 12,
		tickLength: 0,
		type: 'datetime',
		labels: {
			enabled: true,
			style: {
				color: '#646769',
				fontFamily: '"Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
				fontSize: '14px',
				fontWeight: '400'
			}
		},
		crosshair: {
			color: '#646769',
			width: 1,
			zIndex: 3
		}
	} as XAxisOptions;

	if (props.data.length > 0) {
		const seriesData = series.value?.[0]?.data;
		const start = spacetime((seriesData?.[0] as PointOptionsObject)?.x);
		const end = spacetime((seriesData?.[seriesData?.length - 1] as PointOptionsObject)?.x);

		let interval: XAxisInterval;
		if (start.diff(end, 'years') >= 3) {
			// horizon >= 3yrs, yearly intervals
			interval = generateXAxisInterval(start, end, 'year');
		} else if (start.diff(end, 'years') >= 1) {
			// 3yrs > horizon >= 1yr, biannual intervals
			interval = generateXAxisInterval(start, end, 'biannual');
		} else if (start.diff(end, 'months') >= 3) {
			// 1yr > horizon >= 3mo, quarterly intervals
			interval = generateXAxisInterval(start, end, 'quarter');
		} else {
			// 3mo > horizon, monthly intervals
			interval = generateXAxisInterval(start, end, 'month');
		}

		xAxis = {
			...xAxis,
			labels: {
				...xAxis.labels,
				formatter(this) {
					return spacetime(this.value, 'UTC').format(interval.format);
				}
			},
			tickPositions: interval.ticks
		};
	}

	const options: Options = {
		chart: {
			type: 'area',
			backgroundColor: '#ffffff',
			spacingRight: 0,
			spacingLeft: 0,
			plotBorderWidth: 0,
			marginTop: isPerformanceContextEnabled.value ? 28 : undefined,
			events: { load: mergeAnnotations }
		},
		tooltip: {
			animation: false,
			borderWidth: 0,
			enabled: true,
			hideDelay: 0,
			padding: 0,
			shape: 'square',
			shadow: false,
			shared: true,
			useHTML: true,
			formatter(): string {
				if (isPerformanceContextEnabled.value) {
					return `<div class="text-align-center body-xs font-weight-bold text-color-dark-70">${Highcharts.dateFormat('%B %e, %Y', this.x)}</div>`;
				} else {
					return `<div class="text-align-center">${Highcharts.dateFormat('%B %e, %Y', this.x)}</div>`;
				}
			},
			...(!isPerformanceContextEnabled.value && { className: 'highcharts-custom-tooltip' })
		},
		responsive: {
			rules: [
				{
					condition: {
						minWidth: 0
					},
					chartOptions: {
						tooltip: {
							positioner(labelWidth, labelHeight, point): Highcharts.PositionObject {
								const minLeft = this.chart.plotLeft - labelWidth / 8;
								const maxRight = this.chart.chartWidth - labelWidth + labelWidth / 8;

								let x = this.chart.plotLeft + point.plotX - labelWidth / 2;
								if (x < minLeft) {
									x = minLeft;
								} else if (x > maxRight) {
									x = maxRight;
								}

								return {
									x,
									y: 2 - labelHeight / 4
								};
							}
						}
					}
				}
			]
		},
		xAxis,
		yAxis: {
			min: props.yAxisBounds.yAxisMin,
			max: props.yAxisBounds.yAxisMax,
			endOnTick: true,
			gridLineColor: '#E7E9E9',
			gridLineDashStyle: 'LongDash',
			gridLineWidth: 1.25,
			offset: 12,
			visible: true,
			labels: {
				enabled: true,
				padding: 10,
				style: {
					color: '#646769',
					fontFamily: '"Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
					fontSize: '14px',
					fontWeight: '400'
				},
				formatter(this): string {
					const value = Math.abs(this.value);

					let formatted: string;
					if (value >= 1e9) {
						formatted = `$${value / 1e9}B`;
					} else if (value >= 1e6) {
						formatted = `$${value / 1e6}M`;
					} else if (value >= 1e3) {
						formatted = this.axis.max !== 1e3 ? `$${value / 1e3}k` : `$${value}`;
					} else {
						formatted = `$${value}`;
					}

					return this.value < 0 ? `(${formatted})` : formatted;
				}
			},
			plotLines: [{ value: 0, dashStyle: 'Solid', zIndex: 1, color: '#E7E9E9' }]
		},
		plotOptions: {
			series: {
				turboThreshold: 0,
				states: {
					hover: {
						enabled: props.isHoverable,
						halo: null,
						lineWidthPlus: undefined
					},
					inactive: {
						opacity: 1
					}
				},
				lineWidth: 2,
				marker: {
					enabled: false,
					symbol: 'circle',
					states: {
						hover: {
							enabled: isPerformanceContextEnabled.value
						}
					}
				},
				point: {
					events: {
						mouseOver
					}
				},
				events: {
					mouseOut
				}
			}
		},
		series: series.value,
		annotations: annotations.value
	};

	const combinedOptions = deepMerge(options, props.customOptions);

	return deepMerge(new BaseChart().getOptions(), combinedOptions);
});

/**
 * This function is needed to fix a UI bug where the chart crosshair bisects
 * chart line markers, if shown. This is accomplished by manipulating the DOM
 * to move the markers relative to the crosshair's parent to be after the
 * crosshair element so any markers are no longer bisected.
 */
localHc.addEvent(Highcharts.Axis, 'afterDrawCrosshair', function () {
	if (isPerformanceContextEnabled.value && this.cross?.element) {
		this.chart.series.forEach((s) => {
			this.cross.element.after(s.markerGroup?.element);
		});
	}
});

watch(
	() => series.value,
	() => {
		redrawChart();
	},
	{ immediate: true }
);

watch(
	() => screenSize.value,
	() => {
		redrawChart();
	}
);

watch(
	() => hoveredPoint.value,
	(newPoint: ValueOverTimeChartPoint | null): void => {
		if (newPoint === null && props.highlightLastPoint) {
			selectLastPoint();
		}
	}
);

watch(
	() => props.reflowChart,
	(newValue) => {
		if (newValue) {
			chart.value?.reflow();
		}
	}
);

watch(
	() => props.activeAnnotations,
	() => {
		const annotationLabels = chart.value?.container.querySelectorAll<HTMLDivElement>(
			'div.highcharts-annotation-label'
		);
		const annotationSvgs = chart.value?.container.querySelectorAll('g.highcharts-annotation-label');

		annotationGroups.value.forEach((group: ValueOverTimeAnnotationGroup, index: number) => {
			const highlighted = group.children
				.map((child: number) => props.annotations[child])
				.some((child: ValueOverTimeAnnotation) => props.activeAnnotations.includes(child.id));

			// highcharts draws the annotations in reverse, so invert the index
			const label = annotationLabels?.[annotationGroups.value.length - index - 1];
			const labelImg = label?.querySelector<HTMLImageElement>('img');
			const svg = annotationSvgs?.[annotationGroups.value.length - index - 1];

			label?.classList.toggle('active', highlighted);
			label?.classList.toggle('has-pseudo-crosshair', !hoveredPoint.value);
			svg?.classList.toggle('active', highlighted);

			if (labelImg) {
				labelImg.src = `${appCloudfrontPath}/images/performance-context/chart-annotation-${highlighted ? 'light' : 'dark'}.svg`;
			}
		});
	}
);

function mouseOver(event: Event): void {
	if (!props.isHoverable) return;

	const point = event.target as unknown as ValueOverTimeChartPoint;

	hoveredPoint.value = point;
	emits('mouseOver', hoveredPoint.value);

	// determine which annotation group, if any, is hovered
	const hoveredGroup = highchartsGetHoveredAnnotationGroup(
		chart.value as Highcharts.Chart,
		annotationGroups.value,
		point.clientX!
	);
	if (hoveredGroup) {
		const focusedAnnotations = hoveredGroup.children.map((child) => props.annotations[child]);
		emits('annotationsFocused', focusedAnnotations);
	} else {
		emits('annotationsFocused', []);
	}
}

function mouseOut(): void {
	if (!props.isHoverable) return;

	hoveredPoint.value = null;
	emits('mouseOut');
	emits('annotationsFocused', []);
}

function selectLastPoint(): void {
	if (props.defaultToLastPoint && chart.value?.series[0].data.length) {
		chart.value.series[0].data[chart.value.series[0].data.length - 1].onMouseOver();
	}
}

function onChartLoaded(loadedChart: Highcharts.Chart): void {
	chart.value = loadedChart;
	if (props.highlightLastPoint) {
		selectLastPoint();
	}
}

function mergeAnnotations(chartEvent: Event): void {
	annotationGroups.value = highchartsMergeAnnotations(chartEvent.target as unknown as Highcharts.Chart);
}

async function redrawChart(): Promise<void> {
	showChart.value = false;

	annotationGroups.value = [];

	await nextTick(() => {
		showChart.value = true;
	});
}

function generateXAxisInterval(start: Spacetime, end: Spacetime, unit: XAxisIntervalOption): XAxisInterval {
	const config: {
		[key: string]: {
			interval: TimeUnit;
			intervalCount?: number;
			format?: Format;
			threshold: {
				amount: number;
				unit: TimeUnit;
			};
		};
	} = {
		month: { interval: 'month', threshold: { amount: 1, unit: 'week' } },
		quarter: { interval: 'quarter', threshold: { amount: 4, unit: 'week' } },
		biannual: { interval: 'quarter', intervalCount: 2, threshold: { amount: 6, unit: 'week' } },
		year: { interval: 'year', format: 'year', threshold: { amount: 2, unit: 'month' } }
	};

	const interval = config[unit].interval;
	const threshold = config[unit].threshold;
	const format: Format = config[unit].format ?? CustomSpacetimeFormat.MONTH_SHORT_YEAR_SHORT;
	const startOf = start.startOf(config[unit].interval);
	const ticks = startOf.every(interval, end.endOf(interval), config[unit].intervalCount ?? 1);
	const lastTick = ticks.length > 0 && ticks[ticks.length - 1]?.add(config[unit].intervalCount ?? 1, interval);

	// add a starting interval if it is within threshold
	if (startOf.diff(start, threshold.unit) <= threshold.amount && !start.isEqual(startOf)) {
		ticks.splice(0, 1, start);
	}

	if (
		lastTick &&
		end.diff(lastTick, threshold.unit) <= threshold.amount &&
		end.format(format) !== ticks[ticks.length - 1].format(format)
	) {
		ticks.push(end);
	}

	return { ticks: ticks.map((d) => d.epoch), format };
}
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors.scss' as *;

$annotation-animation-timing: ease-in-out 250ms;
$annotation-animation-shift: 2px;

:deep(.highcharts-container),
:deep(.highcharts-container svg) {
	width: 100% !important;
	height: 100% !important;
}

:deep(.highcharts-container .highcharts-annotation-toolbar) {
	display: none !important;
}

:deep(.highcharts-annotation) {
	clip-path: none;
}

:deep(div.highcharts-label.highcharts-annotation-label) {
	transform: translateY(0);
	transition: transform $annotation-animation-timing;

	&.active {
		transform: translateY($annotation-animation-shift);
	}
	b.annotation-count {
		position: absolute;
		transform: translate(16px, -16px);
		height: 16px;
		width: 16px;
		text-align: center;
		border-radius: 0.5rem;
		line-height: 1.25;
		background-color: dark(100);
		color: dark(5);
		transition:
			transform $annotation-animation-timing,
			background-color $annotation-animation-timing,
			color $annotation-animation-timing;
	}

	.pseudo-crosshair {
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 0;
		border-left: 1px dashed dark(100);
		transform: translateY(-18px);
		opacity: 0;
		transition: opacity $annotation-animation-timing;
	}

	&.active {
		b.annotation-count {
			background-color: dark(5);
			color: dark(100);
		}
		&.has-pseudo-crosshair .pseudo-crosshair {
			opacity: 1;
		}
	}
}

:deep(g.highcharts-label.highcharts-annotation-label) {
	path {
		fill: white;
		stroke: dark(20);
		transform: translateY(0);
		transition:
			transform $annotation-animation-timing,
			fill $annotation-animation-timing;
	}
	&.active path {
		fill: dark(100);
		stroke: dark(100);
		transform: translateY($annotation-animation-shift);
	}
}

:deep(.highcharts-custom-tooltip > span) {
	background-color: map-get($validation-colors, 'info-tint');
	padding: 3px 12px;
	border-radius: 12px;
}

:deep(g.highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line:first-of-type) {
	display: none;
}
</style>
