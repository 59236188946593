import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	EmailEntryUnsubscribeForm,
	EmailGroup,
	EmailGroupEnum,
	EmailSubscription,
	IterableUnsubscribeRequest,
	UnsubscribeEmailResponse
} from 'types/email';
import {
	getEmailGroupSubscriptions,
	unsubscribeInviteEmail,
	unsubscribeUserEmail,
	unsubscribeUserIterableEmail
} from '@api/email';
import store from '..';

const INITIAL_STATE = {
	unsubscribeEmailResponse: {
		recentUnsubscribeGroup: undefined,
		emailGroupSubscriptionDtos: [],
		allEmailOptOut: undefined
	}
};

@Module({
	dynamic: true,
	namespaced: true,
	name: 'email',
	store
})
class EmailModule extends VuexModule {
	emailGroup: EmailGroupEnum | null = null;
	invitation = false;
	messageTypeId = '';
	unsubscribeEmailResponse: UnsubscribeEmailResponse = INITIAL_STATE.unsubscribeEmailResponse;
	unsubscribeKey = '';

	get recentUnsubscribeGroup(): EmailGroup | undefined {
		return this.unsubscribeEmailResponse.recentUnsubscribeGroup;
	}

	get isInvitationUnsubscribe(): boolean {
		return this.invitation;
	}

	@Action({ rawError: true })
	public async getEmailGroupSubscriptions(unsubscribeKey: string): Promise<UnsubscribeEmailResponse> {
		const unsubscribeEmailResponse = await getEmailGroupSubscriptions(unsubscribeKey);
		this.UPDATE_UNSUBSCRIBE_KEY(unsubscribeKey);
		this.UPDATE_UNSUBSCRIBE_EMAIL_RESPONSE(unsubscribeEmailResponse);

		return unsubscribeEmailResponse;
	}

	@Action({ rawError: true })
	public async oneClickUnsubscribeIterable(messageTypeId: string): Promise<UnsubscribeEmailResponse> {
		const iterableUnsubscribeRequest: IterableUnsubscribeRequest = {
			unsubscribeKey: this.unsubscribeKey,
			messageTypeId
		};

		const updatedEmailSubscriptions = await unsubscribeUserIterableEmail(iterableUnsubscribeRequest);
		this.UPDATE_MESSAGE_TYPE_ID(messageTypeId);
		this.UPDATE_UNSUBSCRIBE_EMAIL_RESPONSE(updatedEmailSubscriptions);

		return updatedEmailSubscriptions;
	}

	@Action({ rawError: true })
	public async oneClickUnsubscribeEmailGroup(emailGroup: EmailGroupEnum): Promise<UnsubscribeEmailResponse> {
		const form: EmailEntryUnsubscribeForm = {
			unsubscribeKey: this.unsubscribeKey,
			updateEmailGroupSubscriptions: [{ subscribed: false, emailGroup }],
			oneClickUnsubscribe: true,
			allEmailOptOut: false
		};

		const updatedEmailSubscriptions = await unsubscribeUserEmail(form);
		this.UPDATE_EMAIL_GROUP(emailGroup);
		this.UPDATE_UNSUBSCRIBE_EMAIL_RESPONSE(updatedEmailSubscriptions);

		return updatedEmailSubscriptions;
	}

	@Action({ rawError: true })
	public async oneClickUnsubscribeInvitation(unsubscribeKey: string): Promise<void> {
		await unsubscribeInviteEmail(unsubscribeKey);
		this.UPDATE_INVITATION(true);
	}

	@Action({ rawError: true })
	public async updateEmailGroupSubscriptions(): Promise<UnsubscribeEmailResponse> {
		const updateEmailGroupSubscriptions =
			this.unsubscribeEmailResponse?.emailGroupSubscriptionDtos.map((e) => ({
				emailGroup: e.emailGroup.emailGroupEnum,
				subscribed: e.subscribed
			})) ?? [];

		const form: EmailEntryUnsubscribeForm = {
			unsubscribeKey: this.unsubscribeKey,
			updateEmailGroupSubscriptions,
			oneClickUnsubscribe: false,
			allEmailOptOut: this.unsubscribeEmailResponse?.allEmailOptOut
		};

		return unsubscribeUserEmail(form);
	}

	@Action({ rawError: true })
	public toggleAllEmailOptOut(checked: boolean): void {
		const updatedEmailSubscriptionDtos = this.unsubscribeEmailResponse?.emailGroupSubscriptionDtos.map((e) => ({
			...e,
			subscribed: !checked
		}));

		this.UPDATE_EMAIL_SUBSCRIPTION_DTOS(updatedEmailSubscriptionDtos ?? []);
		this.UPDATE_ALL_EMAIL_OPT_OUT(checked);
	}

	@Action({ rawError: true })
	public updateAllEmailOptOut(checked: boolean): void {
		this.UPDATE_ALL_EMAIL_OPT_OUT(checked);
	}

	@Mutation
	public UPDATE_UNSUBSCRIBE_KEY(unsubscribeKey: string) {
		this.unsubscribeKey = unsubscribeKey;
	}

	@Mutation
	public UPDATE_MESSAGE_TYPE_ID(messageTypeId: string) {
		this.messageTypeId = messageTypeId;
	}

	@Mutation
	public UPDATE_EMAIL_GROUP(emailGroup: EmailGroupEnum) {
		this.emailGroup = emailGroup;
	}

	@Mutation
	public UPDATE_INVITATION(invitation: boolean) {
		this.invitation = invitation;
	}

	@Mutation
	public UPDATE_UNSUBSCRIBE_EMAIL_RESPONSE(unsubscribeEmailResponse: UnsubscribeEmailResponse) {
		this.unsubscribeEmailResponse = unsubscribeEmailResponse;
	}

	@Mutation
	public UPDATE_EMAIL_SUBSCRIPTION_DTOS(emailGroupSubscriptionDtos: Array<EmailSubscription>) {
		this.unsubscribeEmailResponse = { ...this.unsubscribeEmailResponse, emailGroupSubscriptionDtos };
	}

	@Mutation
	public UPDATE_ALL_EMAIL_OPT_OUT(allEmailOptOut: boolean) {
		this.unsubscribeEmailResponse = { ...this.unsubscribeEmailResponse, allEmailOptOut };
	}
}

export const email = getModule(EmailModule);
