import { Link } from 'types/layout';

export const advisorAuthorizationFormLink: Link = {
	router: 'website-document-view',
	params: { title: 'RIA Authorization Form' },
	target: '_blank',
	text: 'Download the form'
};

export const advisorDashboardLink: Link = {
	text: 'Go to dashboard',
	router: 'advisor-dashboard'
};
