import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { ira } from '@store/modules/ira';
import { redirect } from '@utils/client';
import { user } from '@store/modules/user';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraCompleteRolloverRoutes: FundriseRouteConfig = {
	path: `/ira/rollover/complete`,
	name: 'ira-complete',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	component: () =>
		import(
			/* webpackChunkName: "ira-complete-rollover-start" */ '@views/ira/complete-rollover/ira-complete-rollover-start.vue'
		),
	beforeEnter: async (to, from, next) => {
		if (!user.hasIraEntity) {
			next({ name: 'account-overview', replace: true });
			app.ADD_TOAST({
				type: 'error',
				message: 'No IRA is available for rollover.'
			});
		} else if (investmentEntity.entityType !== 'IRA') {
			await user.updateSelectedEntity(user.iraEntities[0].investmentEntityId);
		} else if (!ira.dataRetrieved) {
			try {
				await ira.getIncompleteRollover();
				next({ name: 'ira-complete-rollover-amount', replace: true });
			} catch (error) {
				redirect('/account/overview');
			}
		} else {
			next();
		}
	},
	children: [
		{
			path: `amount`,
			name: 'ira-complete-rollover-amount',
			meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(/* webpackChunkName: "ira-start-transfer-amount" */ '@views/ira/signup/ira-transfer-amount.vue')
		},
		{
			path: `plan`,
			name: 'ira-complete-rollover-plan',
			beforeEnter: async (to, from, next) => {
				if (ira.rolloverPlanAlreadySet) {
					next({ name: 'ira-complete-rollover-drip' });
				} else {
					next();
				}
			},
			meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import(/* webpackChunkName: "ira-start-plan" */ '@views/ira/signup/ira-plan.vue')
		},
		{
			path: `drip`,
			name: 'ira-complete-rollover-drip',
			beforeEnter: async (to, from, next) => {
				if (ira.rolloverDripAlreadySet) {
					next({ name: 'ira-complete-rollover-confirm' });
				} else {
					next();
				}
			},
			meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import(/* webpackChunkName: "ira-start-drip" */ '@views/ira/signup/ira-drip.vue')
		},
		{
			path: `confirm`,
			name: 'ira-complete-rollover-confirm', //needs all of the above
			meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				if (!ira.addFundsEdirect) {
					if (ira.dripPreference === null) {
						next({ name: 'ira-complete-rollover-drip' });
					} else if (ira.plan === null) {
						next({ name: 'ira-complete-rollover-plan' });
					} else {
						next();
					}
				} else if (ira.selectedAmount === '') {
					next({ name: 'ira-complete-rollover-amount' });
				} else {
					next();
				}
			},
			component: () =>
				import(
					/* webpackChunkName: "ira-start-confirm" */ '@views/ira/complete-rollover/ira-complete-rollover-confirm.vue'
				)
		},
		{
			path: `agreements`,
			name: 'ira-complete-rollover-agreements',
			beforeEnter: async (to, from, next) => {
				if (ira.reitInvestmentOrderGroupId === '') {
					next({ name: 'ira-complete-rollover-confirm' });
				} else {
					next();
				}
			},
			meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () =>
				import(/* webpackChunkName: "ira-start-agreements" */ '@views/ira/signup/ira-agreements.vue')
		},
		{
			path: `success`,
			name: 'ira-complete-rollover-success',
			meta: { step: 6, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import(/* webpackChunkName: "ira-start-success" */ '@views/ira/signup/ira-success.vue')
		}
	]
};

export default iraCompleteRolloverRoutes;
