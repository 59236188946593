<template>
	<div class="newsfeed-card-content-dividend display-flex">
		<div
			data-test="dividend-amount"
			class="newsfeed-card-content-dividend-amount pr-100 mr-100"
			:style="displayPreference ? `border-right: 2px solid rgb(144, 146, 148, ${opacity});` : ''"
		>
			<div class="overline body-xs">Amount</div>
			<div>
				{{ amount }}
			</div>
		</div>
		<div v-if="displayPreference" class="pr-100 mr-100 ellipsis" data-test="dividend-preference">
			<div class="overline body-xs ellipsis">Preference</div>
			<div class="ellipsis">
				{{ preference }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'NewsfeedCardContentDividend'
};
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { NewsFeedItem } from 'types/account';

interface Props {
	item: NewsFeedItem;
}
const props = defineProps<Props>();

const opacity = computed((): string => {
	return props.item.pinned ? '0.5' : '0.2';
});

const amount = computed((): string => {
	return props.item.bodyText ?? '';
});

const displayPreference = computed((): boolean => {
	return props.item.type !== 'REIT_DIVIDEND';
});

const preference = computed((): string => {
	if (props.item.type === 'DIVIDEND_DISBURSEMENT') {
		return 'Distributed';
	} else {
		return 'Reinvested';
	}
});
</script>
