import { ReitTaxDocumentsMap } from 'types/reit-details';

export const taxDocumentsMap: ReitTaxDocumentsMap = {
	'2': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2018+Fundrise+Equity+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2018 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2017+Fundrise+Equity+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2017 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2016+Fundrise+Equity+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2016 Tax Form 8937'
		}
	],
	'3': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2020+East+Coast+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2019+East+Coast+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2019 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2018+East+Coast+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2018 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2017+Fundrise+East+Coast+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2017 Tax Form 8937'
		}
	],
	'4': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2020+Midland+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+Midland+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2019 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+Midland+Opportunistic+REIT+LLC_Signed.pdf.pdf`,
			target: '_blank',
			text: '2018 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2017+Fundrise+Midland+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2017 Tax Form 8937'
		}
	],
	'5': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2019+West+Coast+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2019 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2018+West+Coast+Opportunistic+REIT+LLC_Signed.pdf`,
			target: '_blank',
			text: '2018 Tax Form 8937'
		}
	],
	'12': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2020+Growth+eREIT+II+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2019+Growth+eREIT+II+LLC_Signed.pdf`,
			target: '_blank',
			text: '2019 Tax Form 8937'
		}
	],
	'13': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2020+Income+eREIT+III+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		},
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2019+Income+eREIT+III+LLC_Signed.pdf`,
			target: '_blank',
			text: '2019 Tax Form 8937'
		}
	],
	'14': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2019+Growth+eREIT+III+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		}
	],
	'16': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2020+Growth+eREIT+2019+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		}
	],
	'18': [
		{
			href: `${process.env.VUE_APP_CLOUDFRONT_DOMAIN}/website-documents/Form+8937+-+2020+Growth+eREIT+V+LLC_Signed.pdf`,
			target: '_blank',
			text: '2020 Tax Form 8937'
		}
	]
};
