<template>
	<figure class="position-relative mx-0 my-0">
		<highcharts :options="chartOptions" :highcharts="localHc" class="highcharts-container" />
		<figcaption
			class="coterminous text-align-center dark display-flex align-items-center justify-content-center pt-50"
		>
			<slot>
				<span class="beta ff-ivar-display font-weight-bold">{{ props.percentage }}</span
				><span class="eta ff-ivar-text">%</span>
			</slot>
		</figcaption>
	</figure>
</template>
<script setup lang="ts">
import Highcharts, { Options } from 'highcharts';
import BaseChart from '@charts/BaseChartOptions';
import { computed } from 'vue';
import deepMerge from 'deepmerge';
import { Chart as highcharts } from 'highcharts-vue';

interface Props {
	percentage: string;
	chartSize?: string;
}

const props = withDefaults(defineProps<Props>(), {
	chartSize: '80'
});

const localHc = Highcharts;

const chartOptions = computed((): Options => {
	const percentNum = parseInt(props.percentage);
	const localOptions = {
		chart: {
			type: 'pie',
			spacing: [0, 0, 0, 0],
			height: props.chartSize,
			width: props.chartSize
		},
		plotOptions: {
			series: {
				animation: false
			},
			pie: {
				borderWidth: 0,
				innerSize: '90%',
				size: props.chartSize,
				states: {
					hover: {
						enabled: false
					},
					inactive: {
						enabled: false
					}
				}
			}
		},
		series: [
			{
				type: 'pie',
				data: [
					{
						y: percentNum,
						color: '#AA4528'
					},
					{
						y: 100 - percentNum,
						color: '#e7e9e9'
					}
				]
			}
		]
	};

	return deepMerge(new BaseChart().getOptions(), localOptions);
});
</script>
