import { FundriseRouteConfig } from 'vue-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const educationRoutes: FundriseRouteConfig[] = [
	{
		path: `/education`,
		name: 'blog-landing',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "blog-landing" */ '@views/blog/landing-view.vue'),
		children: [
			{
				path: 'blog',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				redirect: { name: 'blog-landing' }
			},
			{
				path: 'knowledge-base',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				redirect: { name: 'blog-landing' }
			},
			{
				path: 'kb-articles/:blogPostUrlSlug',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				redirect: { name: 'blog-post' }
			},
			{
				path: 'blog-posts/:blogPostUrlSlug',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				redirect: { name: 'blog-post' }
			},
			{
				path: 'faq',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				redirect: { name: 'help-center-landing' }
			},
			{
				path: 'the-capital-stack',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				redirect: {
					name: 'blog-post',
					params: {
						blogPostUrlSlug: 'the-capital-stack-real-estate-investing'
					}
				}
			}
		]
	},
	{
		path: `/education/glossary`,
		name: 'blog-glossary',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "blog-glossary" */ '@views/blog/glossary-view.vue')
	},
	{
		path: `/education/glossary/:termUrlSlug`,
		name: 'blog-glossary-term',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "blog-glossary-term" */ '@views/blog/glossary-term.vue')
	},
	{
		path: `/resources/cross-market-efficiency-gains`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: {
			name: 'blog-post',
			params: {
				blogPostUrlSlug: 'why-private-markets-outperform-traditional-publicly-traded'
			}
		}
	},
	{
		path: `/education/:blogPostUrlSlug`,
		name: 'blog-post',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "blog-article" */ '@views/blog/article-view.vue')
	},
	{
		path: `/education/category/:categoryUrlSlug`,
		name: 'blog-category',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import(/* webpackChunkName: "blog-category" */ '@views/blog/category-view.vue')
	},
	{
		path: `/webview/education/:blogPostUrlSlug`,
		name: 'webview-blog-post',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () =>
			import(/* webpackChunkName: "blog-article-webview" */ '@views/webview/article-body-content.vue')
	}
];

export default educationRoutes;
